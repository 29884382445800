import { styled } from '@mui/material/styles';

const StyledAccordianList = styled('div')(
  ({ theme }) => `
  border-radius: 12px;
  background: var(--White, #FFF);
  overflow: hidden;
  .MuiAccordion-root{
    padding: 16px 24px;
    // color: white;
    // padding: 0px 24px;
    // margin: 12px 0px;
    // border-radius: 16px;
    // background: ${theme.palette.secondary.main};
    // box-shadow: ${theme.palette.boxShadow.tertiary};
    @media (max-width: 768px) {
    padding: 6px 8px 6px 8px;
    border: none !important;
        box-shadow: none;
   
    }

  }
  .MuiAccordionSummary-root{
   padding: 0px;
   align-items: center;
   
  }
  .MuiAccordionSummary-content {
    display: grid;
    gap: 12px;
    grid-template-columns: 6fr 6fr 4fr 4fr 1fr;
    justify-content: space-between;
    margin: 0px;
    align-items: center;
    text-align: left;
  }
  .heading{
    color: ${theme.palette.customColor.grey_7};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
  }
  .content{
    color: ${theme.palette.customColor.primaryBlack};
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .button-wrap{
    color: ${theme.palette.customColor.primaryBlack};
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 4px 30px;
    gap: 10px;
    border-radius: 6px;
    text-decoration: none;
    border: 1px solid var(--Primary-Blue, #415DF3);
  }
  .Mui-expanded{
    margin: 0px !important;
    min-height: unset !important;
  }
  .MuiAccordionDetails-root{
    display: grid;
    grid-template-columns: 4fr 4fr 2fr 2fr 2fr 1fr;
    padding: 0px;
    text-align: left;
    overflow-wrap: anywhere;
    padding-top: 20px;
  }
  .MuiSvgIcon-root{
    // color: ${theme.palette.customColor.purpleLight};
  }
  .displayBlockWrap{
    display: none;
  }
  .fullHeightWrap{
    height: 100%;  
  }

  @media (max-width: 768px) {
    padding: 12px 0px;
    .MuiAccordionSummary-content {
      grid-template-columns: 1fr; /* Single column layout */
      row-gap: 16px; /* Optional: adds space between rows */
    }
    .button-wrap{
    padding: 4.5px 11.5px;
    }
    .MuiPaper-root {
      position: relative;

    }
      .MuiPaper-root:before{
      display: none;
      }
    .MuiButtonBase-root{
      position: unset;  
      
    }
      .MuiAccordionSummary-root{
        border: 1px solid #E2E4E7;
        padding:12px;
        border-radius: 8px;
  }
    .serviceNameButtonWrap{
      display: flex;
      justify-content: space-between;
      width; 100%;
    }
    .displayNoneWrap{
      display: none;
    }
    .displayBlockWrap{
      display: block;
    }
    .threeDotsWrap{
      position: absolute;
      right: 52px;
      bottom: 16px;
    }
    
    .MuiAccordionSummary-expandIconWrapper{
      position: absolute;
      right: 12px;
      bottom: 22px;
    }
    .MuiAccordionDetails-root{
      grid-template-columns: 4fr 2.1fr;
      padding-bottom: 6px;
    }
  }
`,
);

export default StyledAccordianList;
