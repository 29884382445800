// import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import homeSection2 from '../../assets/images/home-section2.png';
// import tiltedArrow from '../../assets/svg/tiltedArrow.svg';
import homeSection2Mobile from '../../assets/images/home-section2-mobile.png';
import homeSection4 from '../../assets/images/home-section4.png';
import homeSection3 from '../../assets/images/home-section3.png';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import Header from '../../components/Home/Header';
import StyledHomePage from './StyledHomePage';
import Footer from '../../components/Home/Footer';
// import Accordian from '../../components/Accordian';
import { fetchExpertSessionCancel } from '../../store/reducers/expertProfile/apiThunk';
import Modal from '../../components/Modal';
import LeftSection from './welcomeSection/LeftSection';
import RightSection from './welcomeSection/RightSection';
import IconCards from '../../components/Common/IconCard/IconCards';
import BlogsSlider from './blogsSlider/BlogsSlider';
// import { tempAccordion } from '../../constants/constants';
const seekerCardData = [
  {
    icon: '👩‍💼',
    description: "View our expert's profile",
  },
  {
    icon: '✅ ',
    description: 'Book a slot',
  },
  {
    icon: '📞',
    description: 'Schedule a call',
  },
  {
    icon: '🤓',
    description: 'Increase your knowledge',
  },
];

function Landing() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [seekerData, setSeekerData] = useState('');
  const [openCancelSessionModal, setOpenCancelSessionModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      const sessionId = params.get('sessionId');
      const id = params.get('id');
      const type = params.get('type');
      setSeekerData({
        sessionId,
        id,
        type: parseInt(type),
      });
      setOpenCancelSessionModal(true);
    }
  }, []);

  const handleCancelSession = async () => {
    await dispatch(fetchExpertSessionCancel(seekerData));
    setOpenCancelSessionModal(false);
  };

  const handleCloseCancelSessionDialoag = () => {
    setOpenCancelSessionModal(false);
  };

  const cancelSessionModalContent = () => (
    <>
      <div>Are you sure you want to cancel this session?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleCancelSession()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseCancelSessionDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledHomePage>
      <Modal
        openDialog={openCancelSessionModal}
        closeDialog={handleCloseCancelSessionDialoag}
        dialogContent={cancelSessionModalContent()}
        noHeader={false}
        heading="Cancel Session"
        dialogCss="logoutDialogWrap"
      />

      <div className="homePage-wrap">
        <div className="bg-image-wrap">
          <Header />
          <div className="welcome-section-wrap">
            <LeftSection />
            <RightSection />
          </div>
        </div>
        <div className="page-content-wrap">
          <div className="getStarted-section-wrap">
            <div className="left-section-wrap">
              <div className="heading">
                Get Started with your
                <br />
                Landing Page
              </div>
              <div className="description">
                Create Your Profile Page, Connect Your Social Handles, Offer Services, Schedule 1:1
                Meetings, Get Invitations for Speaking Gigs and Podcasts, Sell Digital Products -
                Courses, Research Papers, and Books!
              </div>
              <div className="getStarted-button-wrap">
                <PrimaryButton onClick={() => navigate('/login')} sx={{ width: '175px' }}>Start with us</PrimaryButton>
              </div>
            </div>
            <div className="right-section-wrap">
              <img src={homeSection2} className="expertImage" alt="Landing Page" />
              <img src={homeSection2Mobile} className="mobile-expertImage" alt="Landing Page" />
            </div>
          </div>
          <div className="forExpert-section-wrap">
            <div className="left-section-wrap">
              <div className="subHeading">For Expert 😎</div>
              <div className="heading">
                Set Up Your
                <br />
                Profile Page in
                <br />
                Under
                <span className="highlightText"> 5 Minutes!</span>
              </div>
              <PrimaryButton
                onClick={() => navigate('/login')}
                endIcon={<EastIcon />}
                sx={{
                  width: '200px',
                  '@media (max-width: 480px)': {
                    marginLeft: '16px',
                  },
                }}>
                Set up Now
              </PrimaryButton>
            </div>
            <div className="right-section-wrap">
              <img src={homeSection3} alt="" className="expertContentImage" srcSet="" />
            </div>
          </div>
          <div className="forSeeker-section-wrap">
            <div className="right-section-wrap">
              <img src={homeSection4} className="seekerImage" alt="SeEker" />
            </div>
            <div className="left-section-wrap">
              <div className="header-wrap">
                <div className="subHeading">For Seeker 👀</div>
                <div className="heading">
                  Grow &
                  <br />
                  Learn
                  <span className="highlightText"> 10x </span>
                  faster
                </div>
                <div className="description">
                  <li>Get the best mentors from anywhere on the Globe</li>
                  <li>No more hassle in getting appointments</li>
                  <li>Have every question answered</li>
                  <li>Send invites for Gigs & events</li>
                </div>
              </div>
              <div className="content-wrap">
                {seekerCardData?.map((item) => (
                  <IconCards
                    icon={item.icon}
                    description={item.description}
                    className="left-card-wrap"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="practice-section-wrap">
            <div className="header-wrap">
              <div className="subHeading">PRACTICE ADVICE 🥸</div>
              <div className="heading">
                Schedule Sessions &
                <br />
                Monetise your time
              </div>
              <div className="description">
                Take control of your schedule: Easily plan sessions, maximise your
                <br />
                time, and monetise your expertise!
              </div>
            </div>
            <div className="content-wrap">
              <div className="card-wrap">
                <div className="card-header">⏰</div>
                <div className="card-content">
                  <div className="heading">Schedule Sessions</div>
                  <hr />
                  <div className="description">
                    The gradual accumulation and small-scale. The gradual accumulation and
                    small-scale..
                  </div>
                </div>
                {/* <div className="card-footer">
                  <PrimaryButton endIcon={<img src={tiltedArrow} alt="arrow" />}>
                    Know More
                  </PrimaryButton>
                </div> */}
              </div>
              <div className="card-wrap">
                <div className="card-header">🤑</div>
                <div className="card-content">
                  <div className="heading">Earn Money</div>
                  <hr />
                  <div className="description">
                    The gradual accumulation and small-scale. The gradual accumulation and
                    small-scale..
                  </div>
                </div>
                {/* <div className="card-footer">
                  <PrimaryButton endIcon={<img src={tiltedArrow} alt="arrow" />}>
                    Know More
                  </PrimaryButton>
                </div> */}
              </div>
            </div>
          </div>
          <div className="ourBlogs-section-wrap">
            <div className="heading-wrap">
              <div className="heading">
                The Latest from Our
                <span className="highlightText"> Blogs</span>
              </div>
              <div className="description">
                Discover compelling insights, expert tips, and inspiring stories in our blog.
                <br />
                Dive into a world of knowledge with Superconnects.
              </div>
            </div>
            <div>
              <BlogsSlider />
            </div>
            <div className="button-wrap">
              <SecondaryButton border onClick={() => navigate('/blogs')}>
                See all
              </SecondaryButton>
            </div>
          </div>
          {/* <div className="faq-section-wrap">
            <div className="faq-left-section">
              <div className="subHeading">FAQ’s</div>
              <div className="heading">Frequently Asked Questions</div>
              <div className="description">
                We’ve complied a list of commonly asked questions to provide you with quick and
                informative answers.😀
              </div>
            </div>
            <div className="content">
              <Accordian accrodianArray={tempAccordion} icon={<AddIcon />} />
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </StyledHomePage>
  );
}

export default Landing;
