import { styled } from '@mui/material/styles';

const StyledService = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1C1C1C;
  font-family: "Plus Jakarta Sans";
  text-align: start;

  .card_container{
    border-radius: 12px;
    background: var(--White, #FFF);
    padding: 24px;
    @media screen and (max-width: 600px){
      padding: 16px;
      border-radius: 0px;
    }
  }

  .card_container2{
    border-radius: 12px;
    border: 2px solid var(--Primary-Light, #CDD2F0);
    background: rgba(205, 210, 240, 0.20);
    padding: 16px;
    max-width: 776px;
    @media screen and (max-width: 600px){
      padding: 12px;
    }
  }

  .active_speaker{
    border: 2px solid #415DF3;
  }

  .card_body{
    color: var(--Para, #525252);
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    max-width: 556px;
    @media screen and (max-width: 600px){
      font-size: 12px;
      line-height: 18px;
    }
  }

  .card_heading{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    @media screen and (max-width: 600px){
      font-size: 17px;
    }
  }

  .card_heading2{
    color: var(--Black, #1C1C1C);
    font-family: Sora;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: 600px){
      font-size: 14px;
    }

    span{
      color: var(--Primary-Blue, #415DF3);
    }
  }
  .checkbox_wrap{
    align-self: baseline;

    img{
      cursor: pointer;
    }
  }


  .page-wrap{
    background: ${theme.palette.customColor.primaryWhite};
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: ${theme.palette.customColor.primaryBlack};
    overflow: hidden;
    .header-wrap{
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 700;
      font-size: 18px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      padding: 25px 30px;
    }
    .content-wrap{
      padding-top: 24px;
      @media screen and (max-width: 600px){
        padding-top: 16px;
      }
      .emptyMsg{
        padding-top: 0px;
        opacity: 0.5;
      }
    .MuiAutocomplete-root {
        width: auto !important;
    }
    .customButtons{
      border: 1px solid ${theme.palette.primary.main};
      filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.098));
      border-radius: 4px;
      color: ${theme.palette.customColor.primaryBlack};
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      width: 30%;
      padding: 15px 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-right: 5px;
      }
    }
    .listHeader{
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #757575;
    }
    .listContent{
      width: fit-content;
      // max-width:300px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #1C1C1C;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
      .desc_new{
      max-width:300px;
      }
    .startAdornment{
      border-radius: 4px 0px 0px 4px;
      padding: 13px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.9);
      background: ${theme.palette.customColor.secondaryThree};
      margin-left: -9px;
    }
    .input-fields-wrap{
      .forCancellation{
        font-family: "Plus Jakarta Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;      
        color: #1C1C1C;
        .linkHighlight{
          color: #415DF3;
          text-decoration: underline;
        }
      }
    }

    .AddMoreBtn{
      margin: 30px 0px;
      width: 90px;
      float: right;
      @media screen and (max-width: 480px){
        margin-right: 20px;
      }
    }
    .service-list-wrap{
      
      .serviceList{
        .disableBtn{
          cursor: not-allowed;
        }
      }
        @media screen and (max-width: 600px){
        display:none;
      } 
    }

    .service_list_wrap_resp{
      @media screen and (min-width: 600px){
        display:none;
      } 

      border-top: 1px solid #E2E4E7;
      padding: 24px 0px;
      display:flex;
      flex-direction: column;
      gap:16px;

      .service_list_outer_resp{
        width: 100%;
        border: 1px solid #E2E4E7;
        border-radius: 8px;
        padding: 12px 16px;
      }

      .heading{
        font-family: Plus Jakarta Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.12px;
        text-align: left;
        color:#757575;
        margin-bottom:6px;
        }

        .desc{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
        color:#1C1C1C;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }
      
    }
  }
  .MuiFormHelperText-contained {
    margin-left: 0px;
  }
  
  
  .MuiFormControl-root{
    margin-bottom: 0px !important;
  }
  .price_currency, .price_amt{
    .MuiInput-input{
      padding-right: 0px!important;
    }
  }
  .price_amt{
    .MuiInputBase-root{
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
    .MuiInputBase-input{
      outline: 0 !important;
      box-sizing: border-box;
      height: 48px;
      padding: 13px 16px !important;
    }

  }
  .price_currency{
    .MuiInputBase-root{
      width: fit-content;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .MuiInputBase-input{
      width: 77px;
      box-sizing: border-box;
      height: 48px;
      padding: 13px 24px !important;
    }
    }
  }

  .MuiInputBase-root{
    textarea{
      height:auto !important;
    }
  }
  .add-bankaccount-link-btn{
    position:absolute;position: absolute;
    top: 16px;
    right: 0;
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.12px;
    text-align: center;
    text-decoration: underline;
    color: #415DF3;
    display:inline-flex;
    gap:2px;
    cursor:pointer;
  }
}
  `,
);

export default StyledService;
