import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledAvailabilityModal = styled(Dialog)(
  ({ theme }) => `
&.dialogBox {
    background: #fff;
  .MuiDialog-paper {
    box-shadow: 0px 7px 20px rgba(9, 9, 10, 0.15) !important;
    border-radius: 10px;
    background: #fff;
    color: ${theme.palette.customColor.primaryBlack};
    max-width: 700px;
    .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      padding: 20px 25px;
      border-bottom: 1px solid ${theme.palette.customColor.grey_6};
      @media screen and (max-width: 600px){
          padding: 20px 16px 16px 16px;
          }
      .dialog-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 88%;
        .title-heading{
            color: ${theme.palette.customColor.primaryBlack};
            margin-bottom: 10px;
            font-family: Sora;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .title-subHeading{
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: ${theme.palette.customColor.secondaryGrey};
        }
          @media screen and (max-width: 600px){
            .title-heading{
            font-size: 18px;
            font-weight: 600;
            }
            .title-subHeading{
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            }
          }
        
       }
       .crossIcon {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
       }
      }
      .MuiDialogContent-root{
        text-align: center;
        padding: 0px;
        padding-top: 25px;

        .important{
          color: #525252;
          
        }
        .selectdayHeading{
          color: #525252;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .weekButtons{
          border: 0.5px solid #415DF3;
          padding: 15px 26px;
          box-shadow: none;
          border-radius: 10px;
          cursor: pointer;
          color: #1C1C1C;
          text-align: center;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          @media screen and (max-width: 600px){
            margin-right: 10px;
            margin-bottom: 15px;
            padding: 8px 16px;
            font-size: 12px;
          }
      
        }
        .ActiveButton{
          background : #415DF3;
          color: #fff;

        }
        .dayTitle{
          color: #1C1C1C;
          height: 50px;
          align-items: center;
          cursor: default;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          .titleName{
            width: 65%;
            @media screen and (max-width: 700px){
              width: 50%;
            }
            @media screen and (max-width: 480px){
              width: auto;
            }
          }
          img{
            cursor: pointer;
          }
        }
        .logout-button-wrap{
          display: flex;
          margin-top: 40px;
          margin-bottom: 15px;
          justify-content: end;
          margin-right: 20px;
          @media screen and (max-width: 600px) {
            justify-content: flex-end;
            margin-top: 16px;
          margin-right: 16px;


          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .dialogBox {
      .MuiDialog-paper {
        .MuiDialogTitle-root {
          .dialog-title {
            padding: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
      .MuiDialog-paper {
          max-height:100dvh;
          margin:0;
          border-radius: 0 !important;
        .MuiDialogTitle-root {
          .dialog-title {
            padding: 0;
          }
        }
      }
  }
  
  .MuiFormHelperText-root {
    color: #DA3333;
  }
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
  .MuiStack-root {
    padding-top: 7px;
  }
}

.slots-card{
  @media screen and (max-width: 600px){
    background-color: #fff;
    padding: 12px;
    margin: 16px 0px;
    border-radius: 10px;
  }
}
.weekDay-wrap{
  @media screen and (max-width: 600px){
    flex-flow: nowrap !important;
    overflow: auto !important;
  }
}

.cursorPointer{
  cursor: pointer;
}
`,
);
export default StyledAvailabilityModal;
