import { styled } from '@mui/material/styles';
import { theme } from '../../theme';

const StyledCalendar = styled('div')(
  () => `
    // min-height: 80vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 40px;
    color: ${theme.palette.customColor.primaryBlack};
    background: #F8F4F0;
    @media screen and (max-width: 480px){
      padding: 30px;
    }
    .heading {
      font-weight: 600;
      line-height: 36px;
      font-size: 24px;
      text-align: left;
    }
      .heading_desc{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #525252;
      }
    .slot-div{
      display: flex;
      padding: 12px 18px 12px 12px;
      border-radius: 31px;
      background: var(--White, #FFF);
      color: var(--Black, #1C1C1C);
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      .color-slot-available{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        background: #F8F4F0;

      }
      .color-slot-busy{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(218, 51, 51, 0.10);
        margin-right: 10px;
      }
    }

    .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-events-container{
      border-radius: 0;
      .rbc-event-label{
        display: none;
      }
     
      .rbc-event{
        color: #1C1C1C;
        background: transparent;
        font-size: 12.987px;
        font-weight: 700;
        border: none;
        border-right: none;
        border-top: none;
        border-radius: 0;

        padding: 0;
        .rbc-event-content{
          
          .custom-tag{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-wrap: anywhere;
            background: #7e0f0f;
            border: 1px solid #ffffff59;
            font-size: 12px;
            cursor: not-allowed;
          }
          .normal-tag{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 0px 8px;
            align-items: center;
            font-size: 12px;
            overflow-wrap: anywhere;
            // opacity: 0.5;
            // background: #0d6b30fa;
            background: #313131fa;
            border: 1px solid #ffffff59;
          }
        }
      }
    }
  `,
);

export default StyledCalendar;
