/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { Box, Stack } from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import useRazorpay from 'react-razorpay';
import StyledWeeklyCalendars from './StyledWeeklyCalendars';
import Modal from '../../components/Modal';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { fetchCreateSession } from '../../seekerStore/reducers/seekerProfile/apiThunk';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { updateLoading } from '../../seekerStore/reducers/loader';
import CalenderBackIcon from './CalenderBackIcon';
import CalenderForwardIcon from './CalenderForwardIcon';
import { seekerRazorPayPaymentIntent } from '../../store/reducers/expertProfile/apiThunk';

const CustomToolbar = (toolbar) => {
  const goToPrevious = () => {
    const newDate = moment(toolbar.date).subtract(7, 'days').toDate();
    toolbar.onNavigate('prev', newDate);
  };

  const goToNext = () => {
    const newDate = moment(toolbar.date).add(7, 'days').toDate();
    toolbar.onNavigate('next', newDate);
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <CalenderBackIcon onClick={goToPrevious} />
        {/* <CalenderBackIcon /> */}
        <span className="rbc-toolbar-label">
          {momentLocalizer(moment).format(toolbar.date, 'MMMM, yyyy', 'en')}
        </span>
        <CalenderForwardIcon onClick={goToNext} />

        {/* <NavigateNextRoundedIcon /> */}
      </div>
      <div className="week-button">Week</div>
    </div>
  );
};

const CustomHeader = ({ date }) => {
  const weekday = moment(date).format('ddd');
  const getDate = moment(date).format('D');
  const month = moment(date).format('MMM');

  return (
    <div className="custom-header">
      <div className="block-wrap">
        <div className="weekday-block">{weekday}</div>
        <div className="date-block">{`${getDate} ${month}`}</div>
      </div>
    </div>
  );
};

export default function WeeklyCalendar({
  startDate,
  endDate,
  events,
  type,
  expertDetail,
  landingData,
}) {
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openScheduleSessionModal, setOpenScheduleSessionModal] = useState(false);
  const [dateContent, setDateContent] = useState({});
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const [confirmBookingSessionModal, setConfirmBookingSessionModal] = useState(false);
  const [openChooseZeroModal, setOpenChooseZeroModal] = useState(false);
  const [selectData, setSelectData] = useState({
    start: '',
    end: '',
    date: '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const handleSelect = async ({ start, end, isAvailable, ...others }) => {
    if (type === 'schedule-seeker') {
      setDateContent({
        start,
        end,
      });
      setSelectData({
        start: moment(start, 'HH:mm').format('h:mm A'),
        end: moment(end, 'HH:mm').format('h:mm A'),
        date: moment(start).format('MMMM D, YYYY'),
      });
      if (isAvailable) {
        setOpenScheduleSessionModal(true);
        await landingData(sessionStorage.getItem('serviceid'));
      }
    }
  };

  const CustomEventComponent = ({ event }) => {
    // Custom formatting logic for event content
    const formattedContent = `${event.title}`;
    if (!event.isAvailable) {
      return (
        <div className="custom-tag">
          <div>{formattedContent}</div>
        </div>
      );
    }
    if (event.isAvailable) {
      return (
        <div className="normal-tag">
          <div>{formattedContent}</div>
        </div>
      );
    }
  };

  const closeQuickView = () => {
    navigate(`/${sessionStorage.getItem('expertUserName')}`);
  };

  const handleBackButton = (event) => {
    closeQuickView();
  };

  React.useEffect(() => {
    window.history.pushState('fakeing-route', document.title, window.location.href);
    window.onpopstate = handleBackButton;
    // Remove the event listener when the component is unmounted
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleRangeChange = (range) => {
    startDate(range[0]);
    endDate(range[range.length - 1]);
  };

  const formats = {
    timeGutterFormat: (date) => moment(date).format('h A'),
  };

  const handleCloseConfirmDialoag = () => {
    sessionStorage.setItem('errorMsg', '');
    setOpenLogoutModal(false);
    setOpenScheduleSessionModal();
  };

  const handleCloseChooseDialoag = () => {
    setOpenChooseModal(false);
    setOpenScheduleSessionModal();
  };

  const handleCloseChooseZeroDialoag = () => {
    setOpenChooseZeroModal(false);
    setOpenScheduleSessionModal(false);
  };

  const handleCloseLogoutScheduleDialoag = () => {
    setOpenScheduleSessionModal(false);
  };

  const dateCoverter = (dateEvent) => {
    const originalDatetime = moment(dateEvent).local().format('');
    const datetimeWithoutOffset = originalDatetime.slice(0, -6);
    const newDate = `${datetimeWithoutOffset}.000Z`;
    return newDate;
  };

  const typeConfirm = async () => {
    const dataItem = {
      id: sessionStorage.getItem('serviceid'),
      content: {
        primaryEmail: JSON.parse(sessionStorage.getItem('seeker')),
        type: 2,
        eventStartTime: {
          dateTime: moment(dateContent.start).local().format(''),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        eventEndTime: {
          dateTime: moment(dateContent.end).local().format(''),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const response = await dispatch(fetchCreateSession(dataItem)).then((val) => val);
    const { payload: { data, status } = {} } = response;
    if (status) {
      dispatch(updateLoading(false));
      if (data?.isSessionScheduledWithMeeting) {
        sessionStorage.setItem('isSessionScheduledWithMeeting', true);
        sessionStorage.setItem('transactionSuccess', false);
      } else {
        sessionStorage.setItem('isSessionScheduledWithMeeting', false);
        sessionStorage.setItem('accountId', data?.expertConnectAccountId);
        sessionStorage.setItem('transactionSuccess', true);
      }
      sessionStorage.setItem('sessionId', data?.sessionId);
      sessionStorage.setItem('errorMsg', '');
      navigate('/seeker-subscription');
      setOpenScheduleSessionModal(false);
      // ToastNotifySuccess(message);
    } else {
      dispatch(updateLoading(false));
      setConfirmBookingSessionModal(false);
      setOpenLogoutModal(true);
      setErrorMsg(sessionStorage.getItem('errorMsg'));
    }
  };

  const bookFun = async () => {
    const dataItem = {
      id: sessionStorage.getItem('serviceid'),
      content: {
        primaryEmail: JSON.parse(sessionStorage.getItem('seeker')),
        type: 1,
        eventStartTime: {
          dateTime: moment(dateContent.start).local().format(''),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        eventEndTime: {
          dateTime: moment(dateContent.end).local().format(''),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    dispatch(updateLoading(true));
    const response = await dispatch(fetchCreateSession(dataItem)).then((val) => val);
    const { payload: { data, status, message } = {} } = response;
    if (status && !data?.isAlreadyScheduled) {
      typeConfirm();
    } else if (status && data?.isAlreadyScheduled) {
      dispatch(updateLoading(false));
      setOpenChooseModal(true);
      setErrorMsg(message);
    } else {
      dispatch(updateLoading(false));
      setOpenLogoutModal(true);
      setErrorMsg(sessionStorage.getItem('errorMsg'));
    }
  };
 
  const [Razorpay] = useRazorpay();

  const razorPayHandler = (paymentOrderId, price) => {
    if (paymentOrderId) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, 
        amount: price * 100,
        currency: 'INR',
        name: 'Super Connects',
        order_id: paymentOrderId,
  
        handler(response) {
          if (response.razorpay_payment_id) {
            navigate('/seeker-subscription');
          } else {
            setOpenScheduleSessionModal(false);
            ToastNotifyError('Your transaction has been failed');
            navigate('/schedule');
          }
        },
        theme: {
          color: '#1A1E22',
        },
      };
      
      const rzp1 = new Razorpay(options);
  
      rzp1.on('payment.failed', (response) => {
        if (response.error.reason === 'payment_failed') {
          navigate('/schedule');
        }
      });
  
      rzp1.open();
    }
  };

  const handleRazorPay = async () => {
    const price = expertDetail?.expertServices?.price;
    if (price > 0) {
      const dataItem = {
        id: sessionStorage.getItem('serviceid'),
        content: {
          primaryEmail: JSON.parse(sessionStorage.getItem('seeker')),
          type: 2,
          eventStartTime: {
            dateTime: moment(dateContent.start).local().format(''),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          eventEndTime: {
            dateTime: moment(dateContent.end).local().format(''),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      setConfirmBookingSessionModal(false);
      dispatch(updateLoading(true));
      const resp = await dispatch(fetchCreateSession(dataItem)).then((val) => val);
      const { payload: { data: { sessionId: sessionIdRes = '', isAlreadyScheduled = false } = {}, data = {}, status, message } = {} } = resp;

      if (status && !isAlreadyScheduled) {
        const sessionId = sessionIdRes;
        if (data?.isSessionScheduledWithMeeting) {
          sessionStorage.setItem('isSessionScheduledWithMeeting', true);
          sessionStorage.setItem('transactionSuccess', false);
        } else {
          sessionStorage.setItem('isSessionScheduledWithMeeting', false);
          sessionStorage.setItem('accountId', data?.expertConnectAccountId);
          sessionStorage.setItem('transactionSuccess', true);
        }
        sessionStorage.setItem('sessionId', sessionIdRes);
        sessionStorage.setItem('errorMsg', ''); 
        const razorpayCustomerId = sessionStorage.getItem('customerID');
  
        const dataSend = {
          sessionId,
          razorpayCustomerId,
          price,
        };
        const res = await dispatch(seekerRazorPayPaymentIntent(dataSend));
  
        const { payload: { data: { orderDetail: { paymentOrderId = '' } = {} } = {} } = {} } = res;
      
        if (paymentOrderId) {
          razorPayHandler(paymentOrderId, price);
          setOpenScheduleSessionModal(false);
        }
      } else if (status && isAlreadyScheduled) {
        dispatch(updateLoading(false));
        setOpenChooseModal(true);
        setErrorMsg(message);
      } else {
        dispatch(updateLoading(false));
        setOpenLogoutModal(true);
        setErrorMsg(sessionStorage.getItem('errorMsg'));
      }
    } else {
      typeConfirm();
    }
  };

  const logoutModalSchedulContent = () => (
    <div className="content-wrap">
      <div className="session_name">{expertDetail?.expertServices?.serviceName}</div>

      <Stack flexDirection={'column'} spacing={2} className="date-block">
        <Stack flexDirection={'column'} spacing={1}>
          <div className="heading">Date</div>
          <div className="content">{selectData?.date}</div>
        </Stack>
        <Stack flexDirection={'column'} spacing={1}>
          <div className="heading">Time</div>
          <div className="content">
            {selectData?.start} - {selectData.end}
          </div>
        </Stack>
      </Stack>
      <div className="detail-content">
        <div className="head">Expert Details</div>
        <Stack flexDirection={'column'} spacing={2} className="detail">
          <Stack flexDirection={'column'} spacing={1}>
            <Box className="heading">Expert Name</Box>
            <Box className="content">
              {expertDetail?.expert?.firstName} {expertDetail?.expert?.lastName}
            </Box>
          </Stack>
          <Stack flexDirection={'column'} spacing={1}>
            <div className="heading">Price</div>
            <div className="content">
              {expertDetail?.expertServices?.unit} {expertDetail?.expertServices?.price}
            </div>
          </Stack>
        </Stack>
      </div>
      <div className="book-block">
        <PrimaryButton
          onClick={() => {
            handleCloseLogoutScheduleDialoag(false);
            setConfirmBookingSessionModal(true);
          }}>
          Book Session
        </PrimaryButton>
      </div>
    </div>
  );

  const confirmModalContent = () => (
    <>
      <div>{errorMsg}</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleCloseConfirmDialoag()}>
          Close
        </SecondaryButton>
      </Box>
    </>
  );

  const confirmBookingModalContent = () => (
    <>
      <div>{`Are you sure you want to book this session ${selectData?.date} at ${selectData?.start}?`}</div>
      <Box mt={4} display={'flex'} justifyContent={'center'} gap={2}>
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => setConfirmBookingSessionModal(false)}>
          Cancel
        </SecondaryButton>
        {/* <PrimaryButton sx={{ width: '150px' }} onClick={() => bookFun()}> */}
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleRazorPay()}>
          Procced to pay
        </PrimaryButton>
      </Box>
    </>
  );

  const chooseModalContent = () => (
    <>
      <div>
        Your payment for another session requested for ‘{expertDetail?.expertServices?.serviceName}’
        at ‘{selectData?.start} - {selectData.end}’ with the same expert is already in progress. Do
        you want to proceed with this new request?
      </div>
      <div className="logout-button-wrap">
        <SecondaryButton sx={{ width: '150px', border: '1px solid #415DF3' }} onClick={typeConfirm}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseChooseDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  // const chooseModalZeroContent = () => (
  //   <>
  //     <div>Currently, the session price is set to zero as it is currently under development.</div>
  //     <div className="logout-button-wrap">
  //       <SecondaryButton
  //         sx={{ width: '150px', border: '1px solid #415DF3' }}
  //         onClick={() => handleCloseChooseZeroDialoag()}>
  //         Close
  //       </SecondaryButton>
  //     </div>
  //   </>
  // );

  function handleSelecting(range) {
    // Prevent dragging and creating new events by returning false
    return false;
  }
 
  return (
    <StyledWeeklyCalendars>
      <div className="seeker-page-content-wrap">
        <div className="content-wrap">
          <Calendar
            selectable
            localizer={localizer}
            onSelectSlot={handleSelect}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            defaultDate={new Date()}
            views={['week']}
            className={type === 'schedule-seeker' ? 'seekerCalender' : ''}
            onRangeChange={handleRangeChange}
            onSelectEvent={handleSelect}
            onSelecting={handleSelecting}
            components={{
              toolbar: CustomToolbar,
              header: CustomHeader,
              event: CustomEventComponent,
            }}
            formats={formats}
            draggable={false}
          />
        </div>
      </div>
      <Modal
        openDialog={openScheduleSessionModal}
        closeDialog={handleCloseLogoutScheduleDialoag}
        dialogContent={logoutModalSchedulContent()}
        noHeader={false}
        heading={
          <div className="head" style={{ display: 'block' }}>
            <div className="heading">Session Details</div>
          </div>
        }
        dialogCss="logoutDialogWrap schedule-session"
      />
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseConfirmDialoag}
        dialogContent={confirmModalContent()}
        noHeader={false}
        heading="Book Session"
        dialogCss="logoutDialogWrap"
        closeButVisible
      />
      <Modal
        openDialog={openChooseModal}
        closeDialog={handleCloseChooseDialoag}
        dialogContent={chooseModalContent()}
        noHeader={false}
        heading="Book Session"
        dialogCss="logoutDialogWrap"
      />

      {/* <Modal
        openDialog={openChooseZeroModal}
        closeDialog={handleCloseChooseZeroDialoag}
        dialogContent={chooseModalZeroContent()}
        noHeader={false}
        heading="Book Session"
        dialogCss="logoutDialogWrap"
      /> */}

      <Modal
        openDialog={confirmBookingSessionModal}
        closeDialog={() => setConfirmBookingSessionModal(false)}
        dialogContent={confirmBookingModalContent()}
        noHeader={false}
        heading="Book Session"
        dialogCss="logoutDialogWrap"
        // closeButVisible
      />
    </StyledWeeklyCalendars>
  );
}
