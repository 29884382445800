import { theme } from '../../theme';

const {
  palette: {
    primary: {
      main,
    },
  } = {},
} = theme;

const PersonPinIcon = ({
  color = main,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.1665 17.5H5.83317C2.49984 17.5 1.6665 16.6667 1.6665 13.3333V6.66667C1.6665 3.33333 2.49984 2.5 5.83317 2.5H14.1665C17.4998 2.5 18.3332 3.33333 18.3332 6.66667V13.3333C18.3332 16.6667 17.4998 17.5 14.1665 17.5Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6665 6.66699H15.8332" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 10H15.8333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1665 13.333H15.8332" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.08328 9.40827C7.91631 9.40827 8.59162 8.73296 8.59162 7.89993C8.59162 7.0669 7.91631 6.3916 7.08328 6.3916C6.25025 6.3916 5.57495 7.0669 5.57495 7.89993C5.57495 8.73296 6.25025 9.40827 7.08328 9.40827Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99984 13.6085C9.88317 12.4001 8.92484 11.4501 7.7165 11.3418C7.29984 11.3001 6.87484 11.3001 6.44984 11.3418C5.2415 11.4585 4.28317 12.4001 4.1665 13.6085" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PersonPinIcon;
