import { styled } from '@mui/material/styles';

const StyledPersonalInfo = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.secondary.main};
    border-radius: 12px;
    overflow: hidden;
    padding: 24px;
    @media screen and (max-width: 480px){
      padding: 16px;
      border-radius: 0px;
    }
    .header-wrap{
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      @media screen and (max-width: 480px){
        font-size: 17px;
      }
    }
    .content-wrap{
      display: flex;
      width: 90%;
      margin: auto;
      padding-top: 29px;
      column-gap: 60px;  
      @media screen and (max-width: 768px){
        width: 100%;
        flex-direction: column;
      }
      .content-wrap-grid{
        @media screen and (max-width: 899px){
          display: flex;
          flex-direction: column;
        }
        .profile-section-grid{
          @media screen and (max-width: 899px){
            margin: auto;
            padding: 0px;
            padding-top: 30px; 
            max-width: 100%;       
          }
        }
      }
      .MuiAutocomplete-root {
        width: auto !important;
      }
      .calendarbtn-wrap{
        display: flex;
        margin-top: 12px;
        align-items: center;
        column-gap: 20px;
        @media screen and (max-width: 480px){
          flex-direction: column;
          align-items: baseline;
          row-gap: 15px;
        }
        .calendarLinked{
          color: ${theme.palette.customColor.success};
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: flex;
          align-items: center;
          column-gap: 8px;
          width: max-content;
        }
      }
      .profileSection-wrap{
        // width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1025px){
          min-width: 40px;
          width: auto;      
        }
        .profilepic-wrap{
          width: 130px;
          border-radius: 150px;
          height: 130px;
          position: relative;
          .profilePic{
            width: 100%;
            height: 100%;
            max-width: none;
            border-radius: 150px;
            border: 1.5px solid ${theme.palette.customColor.grey_6};
          }
          .changeProfile{
            position: absolute;
            bottom: 0px;
            right: 0px;
            cursor: pointer;
            box-shadow: ${theme.palette.boxShadow.eight};
            border-radius: 150px;        
          }
          .fileUpload{
            display: none;
          }
        }
        .name-wrap{
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: normal;
          color: ${theme.palette.customColor.primaryBlack};
          margin-top: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .right-section-wrap{
        width: 100%;
        .flex-row{
          display:flex;
          column-gap: 20px;
          @media screen and (max-width: 480px){
            flex-direction: column;
          }
        }
        .whoAmI{
          // .input-Label_text {
          //   margin-top: 7px;
          //   margin-bottom: 7px;
          // }
        }
        .expertise{
          .input-Label_text {
              margin-bottom: 1px;
              line-height: 0px;          
            }
        }
        .description{
          // .input-Label_text {
          //   margin-bottom: 9px;
          // }
        }
        .MuiInput-input{
          // height: 30px !important;
          padding-bottom: 20px !important;
          padding-right: 55px !important;
        }
      }
    }
  }
  `,
);

export default StyledPersonalInfo;
