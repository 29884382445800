import { styled } from '@mui/material/styles';
import homeScreenStar from '../../../assets/svg/homeScreenStar.svg';
import homeScreenThreeLines from '../../../assets/svg/homeScreenThreeLines.svg';
import footerArrow from '../../../assets/svg/footerArrow.svg';

const StyledFooter = styled('div')(
  ({ theme }) => `
  .page-footer-wrap{
    background: linear-gradient(180deg, #FFF 0%, #FFF5EC 114.75%);
    @media screen and (max-width: 480px){
      background: #FFF9F3;
      margin-top: 40px;
    }
    .footer-header{
      position: relative;
      display: flex;
      justify-content: space-between; 
      padding: 50px 100px;
      padding-top: 145px;
      @media screen and (max-width: 1250px){
        padding-top: 100px;
      }
      @media screen and (max-width: 769px){
        padding: 50px;
      }
      @media screen and (max-width: 550px){
        padding: 50px 25px;
        flex-direction: column;
      }
      @media screen and (max-width: 480px){
        padding: 30px 16px;
        flex-direction: column;
        padding-top: 50px;
        overflow: hidden;
      }
      .header{
        text-align: left;
        width: 37%;
        @media screen and (max-width: 821px){
          width: 50%;
        }
        @media screen and (max-width: 550px){
          width: 100%;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 480px){
          width: 100%;
          margin-bottom: 20px;
        }
        .subHeading{
          .logo{
            width: 304px;
            @media screen and (max-width: 480px){
              width: 193px;
            }
          }
        }
        .heading{
          position: relative;
          color: ${theme.palette.customColor.primaryBlack};
          font-family: Sora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          margin-top: 32px;
          margin-bottom: 15px;
          z-index: 2;
          @media screen and (max-width: 1250px){
            font-size: 25px;
            line-height: normal;
          }
          @media screen and (max-width: 480px){
            font-size: 18px;
          }
        }
        .heading::before{
          content: '';
          position: absolute;
          background-image: url(${homeScreenStar});
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          z-index: -1;
          width: 86px;
          height: 86px;
          top: -31px;
          left: -54px;
          @media screen and (max-width: 480px){
            display: none;
          }
        }
        .description{
          color: ${theme.palette.customColor.secondaryGrey};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          @media screen and (max-width: 480px){
            font-size: 14px;
            line-height: normal;
          }
        }
        .contact-mail{
          display: flex;
          align-items: center;
          margin-top: 38px;
          @media screen and (max-width: 480px){
            margin: 20px 0px;
          }
          .icon-wrap{
            display: flex;
            align-items: center;
          }
          .description-wrap{
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 10px;
            a{
              color: inherit;
              text-decoration: none; 
            }
            @media screen and (max-width: 1250px){
              font-size: 16px;
              line-height: normal;
            }
          }
        }
      }
      .content{
        display: flex;
        justify-content: space-between;
        width: 54%;
        @media screen and (max-width: 1025px){
          width: 57%;
        }
        @media screen and (max-width: 821px){
          width: 40%;
          flex-wrap: wrap;
        }
        @media screen and (max-width: 550px){
          width: 100%;
        }
        @media screen and (max-width: 480px){
          width: 100%;
        }
        .quicklinks-section-wrap{
          text-align: left;
          width: 57%;
          z-index:1;
          @media screen and (max-width: 821px){
            width: 100%;
          }
          .heading{
            position: relative;
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 166.667% */
            text-transform: uppercase;
            width: max-content;
            color: ${theme.palette.primary.main};
            @media screen and (max-width: 1250px){
              font-size: 16px;
              line-height: normal;
            }
          }
          .heading::after{
            content: '';
            position: absolute;
            background-image: url(${homeScreenThreeLines});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: 0;
            width: 33px;
            height: 36px;
            top: -19px;
            right: -28px;        
          }
          .content-wrap{
            display: grid;
            grid-template-columns: 1fr 1fr;
            @media screen and (max-width: 480px){
              margin: 10px 0px;
            }
            .links{
              font-family: "Plus Jakarta Sans";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px; /* 166.667% */
              cursor: pointer;
              color: ${theme.palette.customColor.primaryBlack};
              margin: 20px 0px;
              @media screen and (max-width: 1250px){
                font-size: 16px;
                line-height: normal;
              }
              @media screen and (max-width: 480px){
                font-size: 14px;
              }
            }
            /* Default styles for right-disclose (applies when not in the 481px-821px range) */
            .right-disclose {
              display: block; 
            }
            /* Default hide left-disclose */
            .left-disclose {
              display: none; /* Hide by default */
            }
            /* Media query for screens between 481px and 821px */
            @media (min-width: 481px) and (max-width: 821px) {
              .left-disclose {
                display: block; /* Show left-disclose in this range */
              }
              .right-disclose {
                display: none; /* Hide right-disclose in this range */
              }
            }
          }
        }
        .connect-section-wrap{
          width: 33%;
          text-align: left;
          @media screen and (max-width: 1025px){
            width: 40%;
          }
          @media screen and (max-width: 821px){
            width: 100%;
          }
          @media screen and (max-width: 550px){
            margin: 15px 0px;
          }
          @media screen and (max-width: 480px){
            margin: 15px 0px;
          }
          .heading{
            position: relative;
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 166.667% */
            text-transform: uppercase;
            color: ${theme.palette.primary.main};
            width: max-content;
            @media screen and (max-width: 1250px){
              font-size: 16px;
              line-height: normal;
            }
          }
          .heading::after{
            content: '';
            position: absolute;
            background-image: url(${homeScreenThreeLines});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: 0;
            width: 33px;
            height: 36px;
            top: -19px;
            right: -28px;
          }
          .icons{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            @media screen and (max-width: 550px){
              justify-content: left;
              .icons-links{
                margin-right: 15px;
              }
            }
            @media screen and (max-width: 480px){
              justify-content: left;
              .icons-links{
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
    .footer-header::before{
      content: '';
      position: absolute;
      background-image: url(${footerArrow});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 0;
      width: 178px;
      height: 207px;
      right: 0;
      bottom: 55px;
      @media screen and (max-width: 480px){
        width: 117px;
        height: 134px;
        bottom: -14px;
        transform: rotateZ(-25deg);
        right: -9px;
      }
    }

    .divideLine{
      border: 1px solid rgba(255, 255, 255, 0.2);
      width: 89%;
      margin: auto;
      background: #FFE1C3;  
    }
    .footer-content{
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
      color: ${theme.palette.customColor.primaryBlack};
      padding: 20px 0px;
      @media screen and (max-width: 1250px){
        font-size: 15px;
        line-height: normal;
      }
      @media screen and (max-width: 480px){
        font-size: 14px;
      }
    }
  }
}
`,
);

export default StyledFooter;
