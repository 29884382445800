import { styled } from '@mui/material/styles';

const StyledAbout = styled('div')(
  () => `
    .about-page-wrap{
      background: #fff;
      color: #1C1C1C;
      text-align: left;
      padding: 24px 100px;
      
      .bread_text, .MuiBreadcrumbs-ol, .MuiBreadcrumbs-li .MuiTypography-root{
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      
      @media screen and (max-width: 900px){
        padding: 50px;
      }
      @media screen and (max-width: 600px){
        padding: 16px;
      }
      
      .about_sc_container{
        .about_details{
          width: 590px;
          height: 397px;
        }

        display:flex;

        .about_content_container{
          width:calc(100% - 590px);
        }

        .about_img_container{
          width:590px;
        }

        @media screen and (max-width: 1200px){
          flex-direction:column;
          gap:30px;
          .about_content_container{
            width:100%;
          }

          .about_img_container{
            width:100%;
          }

        }
        
        @media screen and (max-width: 600px){
          .about_details{
            width: 100%;
            height: 100%;
          }
        }
        

        .about_sc_heading{
          color:  #1C1C1C;
          font-family: Sora;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          @media screen and (max-width: 600px){
            font-size: 20px;
          }
        }

        .about_sc_dec{
          color:  #1C1C1C;
          font-family: "Plus Jakarta Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 29px; 

          @media screen and (max-width: 600px){
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .about_sc_offer{
        border-radius: 20px;
        background: linear-gradient(0deg, #FFF -14.33%, #CDD2F0 100%);
        padding:50px 40px 70px 40px;

        .about_sc_offer_heading{
          color:  #1C1C1C;
          text-align: center;
          font-family: Sora;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .offer_container{
          border-radius: 20px;
          background: var(--White, #FFF);
          box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.06);
          position: relative;
          padding: 38px 16px 25px 16px;
          display:flex;
          flex-direction:column;
          flex:1;
          height: 100%;
          box-sizing: border-box;

          .offer_sub_heading{
            color: var(--Black, #1C1C1C);
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            @media screen and (max-width: 600px){
              font-size: 16px;
            }
          }
          .offer_content{
            color: var(--Para, #525252);
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; 
            @media screen and (max-width: 600px){
              font-size: 14px;
            }
          }

          .emoji_container{
            padding:9px;
            position:absolute;
            top:-24px;
            left:8px;
            border-radius: 30px;
            background: var(--White, #FFF);
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.05);
            font-size: 28px;
          }
        }

        @media screen and (max-width: 600px){
          padding: 30px 16px 50px 16px;
        }
      }
      .sc_join_container{
        .sc_join_heading{
          color: var(--Black, #1C1C1C);
          text-align: center;
          font-family: Sora;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          span{
            color: var(--Primary-Blue, #415DF3);
          }

          @media screen and (max-width: 600px){
            font-size: 24px;
          }

        }
        .sc_join_content_container {
          display:flex;
          justify-content:center;
        }
        .sc_join_content{
          max-width: 872px;
          color: var(--Para, #525252);
          text-align: center;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
`,
);

export default StyledAbout;
