import { styled } from '@mui/material/styles';
import nextBg from '../../../assets/svg/nextBg.svg';

const StyledPreviewPage = styled('div')(
  ({ theme }) => `
  height: 100dvh;
  width: 100vw;
  .preview-page-wrap{
    background: linear-gradient(180deg, #FFFFFF 0%, #FFF5EC 114.75%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    .next_layer_wrap{
      position:absolute;
      top:0;
      height: 100dvh;
      width: 100vw;
      .left_image_wrap{
        padding-left:52px;
        padding-top:52px;
        text-align: left;
        @media screen and (max-width: 900px){
          padding-left:0px;
          padding-top:0px;
        }
        @media screen and (max-width: 600px){
          margin-left: -33px;
          padding-left: 0px;
          padding-top: 0px;
          img{
            width: 121px;
            height: 121px;
          }
        }
      }
      .right_image_wrap{
        background: url(${nextBg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .preview-content-wrap{
      z-index:2;
      position: relative;
      height: 100dvh;

      .header-wrap{
        position: relative;
        box-sizing: border-box;
        padding-top:58px;
        width: 100%;
        height: 11.54vh;

        .logoImage {
          width: 16.39vw;
          height: 3.17vh;
        }

        @media screen and (max-width: 900px) {
          padding-top: 2.71vh;
          height: 5.20vh;

          .logoImage {
            width: 187px;
            height: 22px;
          }
        }

        .closePreview-btn-wrap {
          position: absolute;
          bottom: 0vh;
          right: 2.78vw;

          .closePreview-btn {
            display: flex;
            gap: 0.69vw;
            align-items: center;
            padding: 2.04vh 2.08vw;
            border: 1px solid #415DF3;
            border-radius: 8px;
            cursor: pointer;

            .webCloseText {
              color: var(--Black, #1C1C1C);
              font-family: "Plus Jakarta Sans";
              font-size: 2.04vh;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }

          @media screen and (max-width: 900px) {
            bottom: 0vh;
            right: 1.67vw;
            
            .closePreview-btn {
              display: flex;
              gap: 0vw;
              align-items: center;
              padding: 0.57vh 0.57vh;
            }

            .webCloseText {
              display: none;
            }
          }

          @media screen and (max-width: 600px) {
            bottom: 0;
            right: 16px;
            
            .closePreview-btn {
              padding: 5px;
            }
          }
        }
      }
      //header-end

      .component-wrap{
        width: 100%;
        padding: 4.98vh 0 6.33vh 0;
        height:calc(100vh - 11.54vh - 10.18vh);
        overflow:hidden;
        box-sizing: border-box;

        @media screen and (max-width: 900px){
          height: calc(100vh - 13.57vh);
          padding: 2.27vh 0 1.81vh 0;
        }

        @media (min-width: 900px) and  (max-height: 800px) {
          height: calc(100vh - 13.57vh - 40px);
          padding: 24px 0 16px 0;
        }

        .dots-wrap{
          display: flex;
          justify-content: center;

          .MuiMobileStepper-root{
            background: none;
            flex-grow: 0;
          }

          .MuiMobileStepper-dots{
            flex-direction: column;
            justify-content: space-between;
            gap:4px;

            .MuiMobileStepper-dotActive{
              background-color: ${theme.palette.primary.main} !important;
              height: 40px !important;
              width: 8px;
              border-radius: 10px;
            }
            
            .MuiMobileStepper-dot{
              background-color: ${theme.palette.customColor.purpleLight};
              height: 20px;
              width: 8px;
              border-radius: 10px;
            }
          }
        }
      }
      .footer-wrap{
        position: sticky;
        bottom: 0px;
        z-index: 10;
        height: 10.18vh;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        max-width: 100%;
        margin:0px 20px;

        .footer_nav_items{
          width:fit-content;
          display: flex;
          gap:60px;
          height:fit-content;
        }

        .tabs_inactive{
          cursor: pointer;
          color: ${theme.palette.customColor.primaryBlack};
          font-size: 2.71vh;
          font-style: normal;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          height:fit-content;
        }

        .active_tab{
          color:#415DF3;
          font-weight: 700;
        }

        .active_tab_border{
          width:19px;
          height:2px;
          background:#415DF3;
        }
        
        @media screen and (max-width: 900px){   
          border-radius: 20px 20px 0px 0px;
          background: #FFF;
          bottom: 0px;
          box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.05);
          padding: 2.27vh 1.11vw;
          height: 8.38vh;
          margin:0px 20px;
          .footer_nav_items{
            display: flex;
            gap:40px;
          }

          .tabs_inactive{
            font-size: 2.27vh;
          }
        }

        @media (min-width: 900px) and  (max-height: 800px) {
        height: auto;
        margin:0px 20px;
        }

        @media screen and (max-width: 600px){
          height: 70px;
          margin:0px 20px;
          .tabs_inactive{
            font-size: 12px;
          }
          
          .footer_nav_items{
            width:calc(100% - 0px);
            gap:16px;
            justify-content: space-evenly;
          }    
        }
      }
    }
  }
  `,
);

export default StyledPreviewPage;
