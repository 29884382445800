import React from 'react';

export default function LinkIcon({ color = '#757575' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2819_10714)">
        <path
          d="M6 10L10 6"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33276 4.00004L7.64143 3.64271C8.26663 3.01759 9.11456 2.66644 9.99867 2.6665C10.8828 2.66657 11.7306 3.01784 12.3558 3.64304C12.9809 4.26824 13.332 5.11617 13.332 6.00028C13.3319 6.88439 12.9806 7.73226 12.3554 8.35737L11.9994 8.66671"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.66733 12.0002L8.40266 12.3562C7.77016 12.9816 6.91652 13.3324 6.027 13.3324C5.13747 13.3324 4.28383 12.9816 3.65133 12.3562C3.33957 12.0479 3.09206 11.6808 2.92314 11.2763C2.75422 10.8717 2.66724 10.4376 2.66724 9.99916C2.66724 9.56073 2.75422 9.12665 2.92314 8.72207C3.09206 8.31748 3.33957 7.95043 3.65133 7.64216L4.00066 7.3335"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2819_10714">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
