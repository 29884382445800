import React from 'react';
import StyledIconsCard from './StyledIconsCard';

export default function IconCards({ icon = '', description = '', className = '' }) {
  return (
    <StyledIconsCard className={className}>
      <div className="card-wrap">
        <div className="icon-wrap">{icon}</div>
        <div className="card-description">{description}</div>
      </div>
    </StyledIconsCard>
  );
}
