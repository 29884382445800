/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/seekerApi';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchSendOtp = createAsyncThunk('seeker/SendOtp', async (data, { dispatch, getState }) => {
  const API = getAuthorization(getState);
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.SEND_OTP, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});
const fetchSeekerVerifyOtp = createAsyncThunk(
  'seeker/VerifyOtp',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.VERIFY_OTP, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSeekerExpertList = createAsyncThunk(
  'seeker/SeekerExpertList',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const { header, item } = data;
    const head = { timezone: header };
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.SEEKER_EXPERT_LIST, item, { headers: head });
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchBookSpeaker = createAsyncThunk(
  'expertProfile/fetchBookSpeaker',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const { header, item, id } = data;
    const head = { timezone: header };
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.SPEAKER}/${id}`, item, {
        headers: head,
      });
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchCreateSession = createAsyncThunk('seeker/experEventData', async (data, { getState }) => {
  const API = getAuthorization(getState);
  try {
    const { id, content, header } = data;
    const head = { timezone: header };
    const response = await API.post(`${API_PATH.CREATE_SESSION}${id}`, content, { headers: head });
    return response?.data;
  } catch (err) {
    return err;
  }
});

export {
  fetchSendOtp,
  fetchSeekerVerifyOtp,
  fetchSeekerExpertList,
  fetchCreateSession,
  fetchBookSpeaker,
};
