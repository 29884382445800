import { styled } from '@mui/material/styles';

const StyledTimePicker = styled('div')(
  ({ theme }) => `
  width: 100%;
  .input-Label_text {
    color: ${theme.palette.customColor.secondaryGrey};
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 6px;
  }
  .MuiStack-root{
    padding: 0px;
  }
  .MuiInputBase-root {
    border-radius: 4px !important;
    border: 1px solid ${theme.palette.customColor.grey_6};
    color: ${theme.palette.customColor.primaryBlack};
  }
  .MuiInputAdornment-root{
    color: ${theme.palette.customColor.secondaryGreyText};
    .MuiIconButton-root{
        color: ${theme.palette.customColor.primaryBlack};
    }
  }
  .MuiFormHelperText-root {
    color: ${theme.palette.customColor.stateError};
}
  .MuiInput-input {
    padding: 10px !important;
    /*background: #f5f5f7e6 !important; */
    border-radius: 10px !important;
  }
  .MuiOutlinedInput-input {
    padding:  13px 0px 13px 14px;
  }
  
  .MuiTextField-root{
    width: 100%;
  }
  `,
);

export default StyledTimePicker;
