/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import PreviewLayout from '../Layout';
import CardsLayout from '../Layout/CardsLayout';
import lang from '../../../constants/lang';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import linkedin from '../../../assets/svg/socialBlack/linkedinBlack.svg';
import twitter from '../../../assets/svg/socialBlack/twitterBlack.svg';
import youtube from '../../../assets/svg/socialBlack/youtubeBlack.svg';
import whatsapp from '../../../assets/svg/socialBlack/whatsappBlack.svg';
import telegram from '../../../assets/svg/socialBlack/telegramBlack.svg';
import substack from '../../../assets/svg/socialBlack/substackBlack.svg';
import linkArrow from '../../../assets/svg/linkArrow.svg';
import { CONSTANTS } from '../../../constants/constants';
import previewBook from '../../../assets/svg/previewBook.svg';
import previewCourse from '../../../assets/svg/previewCourse.svg';

import StyledLinks from './StyledLinks';

const Links = () => {
  const { POSTS, BOOK, COURSE, SOCIALS, BOOKS_COURSES } = lang;

  const {
    SOCIALLINKS: { YOUTUBE, TWITTER, WHATSAPP, TELEGRAM, SUBSTACK, LINKEDIN },
  } = CONSTANTS;

  const {
    userLandingData: {
      data: { readAndExplore: { books = [], courses = [] } = {}, socialMedia = [] } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  const [selectedTab, setSelectedTabs] = useState(0);

  const getIcon = (type) => {
    switch (type) {
      case TWITTER:
        return twitter;
      case YOUTUBE:
        return youtube;
      case SUBSTACK:
        return substack;
      case WHATSAPP:
        return whatsapp;
      case TELEGRAM:
        return telegram;
      case LINKEDIN:
        return linkedin;
      default:
        return '';
    }
  };

  const getTitleValue = (type) => {
    switch (type) {
      case YOUTUBE:
        return 'Youtube';
      case TWITTER:
        return 'Twitter';
      case WHATSAPP:
        return 'Whatsapp';
      case TELEGRAM:
        return 'Telegram';
      case SUBSTACK:
        return 'Substack';
      case LINKEDIN:
        return 'LinkedIn';
      default:
        return '';
    }
  };

  const renderSocialsContent = () => {
    let totalPost = 0;
    socialMedia.forEach((item) => {
      item.postUrl.forEach((element) => {
        totalPost += 1;
      });
    });
    return socialMedia.length === 0 || totalPost === 0 ? (
      <div className="noContentTitle">No social added yet</div>
    ) : (
      <Grid container spacing="20px">
        {socialMedia.map(({ mediaType = '', postUrl = [] }, index) =>
          postUrl.map(({ url = '', title = '' }, postIndex) => (
            <Grid item xs={12} sm={6} key={`${index}-${postIndex}`}>
              <div className="card-wrap">
                <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                  <Box display="flex" gap={1} alignItems="center" className="type_container">
                    <img src={getIcon(mediaType)} className="mediaImage" alt="" />
                    {getTitleValue(mediaType)}
                  </Box>
                  <div>
                    <a href={url} target="_blank" className="button-wrap" rel="noreferrer">
                      <img src={linkArrow} className="courseImage" alt="" />
                    </a>
                  </div>
                </Box>
                <div className="title">{title}</div>
              </div>
            </Grid>
          )),
        )}
      </Grid>
    );
  };

  const renderCourseContent = () =>
    books.length === 0 && courses.length === 0 ? (
      <div className="noContentTitle">No Books & Courses added yet</div>
    ) : (
      <Grid container spacing="20px">
        {books.map(({ name = '', url = '' }, index) => (
          <Grid item xs={12} sm={6} key={name + index}>
            <div className="card-wrap">
              <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={1} alignItems="center" className="type_container">
                  <img src={previewBook} className="courseImage" alt="" />
                  <div className="tag-name">{BOOK}</div>
                </Box>
                <div>
                  <a href={url} target="_blank" className="button-wrap" rel="noreferrer">
                    <img src={linkArrow} className="courseImage" alt="" />
                  </a>
                </div>
              </Box>
              <div className="title">{name}</div>
            </div>
          </Grid>
        ))}
        {courses.map(({ name = '', url = '' }, index) => (
          <Grid item xs={12} sm={6} key={name + index}>
            <div className="card-wrap">
              <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={1} alignItems="center" className="type_container">
                  <img src={previewCourse} className="courseImage" alt="" />
                  <div className="tag-name">{COURSE}</div>
                </Box>
                <div>
                  <a href={url} target="_blank" className="button-wrap" rel="noreferrer">
                    <img src={linkArrow} className="courseImage" alt="" />
                  </a>
                </div>
              </Box>
              <div className="title">{name}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    );

  const renderContent = () => (selectedTab === 0 ? renderSocialsContent() : renderCourseContent());

  return (
    <StyledLinks>
      <div className="links-page-wrap">
        <div className="links-header-wrap">{POSTS}</div>
        <div className="links-content-wrap">
          <div className="inner-header-wrap">
            <div
              className={`button-tabs ${selectedTab === 0 ? 'active' : ''}`}
              onClick={() => setSelectedTabs(0)}>
              {SOCIALS}
            </div>
            <div
              className={`button-tabs ${selectedTab === 1 ? 'active' : ''}`}
              onClick={() => setSelectedTabs(1)}>
              {BOOKS_COURSES}
            </div>
          </div>
          <div className="content-wrap">{renderContent()}</div>
        </div>
      </div>
    </StyledLinks>
  );
};

export default PreviewLayout(CardsLayout(Links));
