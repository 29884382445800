import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)(
  ({ theme }) => `
&.dialogBox {
    backdrop-filter: blur(20px);
  .MuiDialog-paper {
    box-shadow: ${theme.palette.boxShadow.seventh} !important;
    border-radius: 10px !important;
    color: white;
    margin: 0px;
    .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      @media screen and (max-width: 480px){
        padding: 24px 16px;
      }
      .dialog-title {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: Sora;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 480px){
          font-size: 18px;
        }
       }
       .crossIcon {
          cursor: pointer;
          display: flex;
          align-items: center;
          position: absolute;
          right: 16px;
          top: 16px;
       }
      }
    }
    .MuiDialogContent-root{
      @media screen and (max-width: 480px){
        padding: 16px;
        padding-top: 0px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .dialogBox {
      .MuiDialog-paper {
        .MuiDialogTitle-root {
          .dialog-title {
            padding: 0;
          }
        }
      }
    }
  }
}

.logoutDialogWrap{
  .MuiDialog-paper {
    width: 400px;
    @media screen and (max-width: 480px) {
      width: 95vw;
    }
    .MuiDialogTitle-root{
      .dialog-title{
        margin: auto;
      }
    }
  }
  .MuiDialogContent-root {
    text-align: center;
    color: ${theme.palette.customColor.primaryBlack};
      .logout-button-wrap{
        display: flex;
        margin-top: 40px;
        justify-content: space-between;
        column-gap: 14px;
      }
    .google-calendar-button-wrap{
      display: flex;
      margin-top: 15px;
      margin-bottom: 20px;
      justify-content: space-evenly;
    }
    .arrow-icon{
      padding-left: 14px;
    }
    .dialog-title{
      color: ${theme.palette.customColor.primaryBlack}
      font-weight: 700;
      font-size: 20px;
      padding-top: 10px;
    }
  }
  
}
.email-modal-content{
  .MuiDialog-paper {
    width: 40%;
  }
  
  .head{
    display: block;
    .heading{
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: white;
      text-align: center;
    }
  }
  .crossIcon{
    margin-bottom: 40px;
  }
  .email-content{
    margin: 0px 50px 10px;
  }
  @media screen and (max-width: 1200px) {
    .MuiDialog-paper {
      width: 50%;
    }
  }
  @media screen and (max-width: 1000px) {
    .MuiDialog-paper {
      width: 60%;
    }
    .head{
      .heading{
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
      }
      .otp-heading{
        font-size: 14px;
        line-height: 26px;
      }
    }
    .email-content{
      margin: 0px 20px 10px;
    }
  }
  @media screen and (max-width: 600px) {
    .MuiDialog-paper {
      width: 80%;
    }
    .head{
      .heading{
        font-weight: 700;
        font-size: 15px;
        line-height: 23px;
      }
      .otp-heading{
        font-size: 12px;
        line-height: 26px;
        margin-top: 0px;
      }
    }
    .email-content{
      margin: 0px 0px 10px;
    }
    .crossIcon{
      img{
        width: 26px;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .head{
      .heading{
        font-weight: 700;
        font-size: 15px;
        line-height: 23px;
      }
      .otp-heading{
        font-size: 10px;
        line-height: 26px;
        margin-top: 0px;
      }
    }
  }
}

.sessionDetailPopup{
  .MuiDialog-paper{
    width: 370px;
    .MuiDialogTitle-root {
      padding: 13px 20px !important;
      border-bottom: 1px solid ${theme.palette.customColor.grey_6};
      .dialog-title{
        width: auto !important;
        padding-left: 0px !important;
      }
    }
  }
  .MuiDialogContent-root{
    padding: 0px;
    .content-wrap{
      padding: 0px 24px;
      @media screen and (max-width: 600px) {
        padding: 0px 16px;
      }
      .popup-title{
        color: #415DF3;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin-top: 16px;
      }
      .duration{
        margin: 24px 0px;
        @media screen and (max-width: 600px) {
            margin: 20px 0px;
        }
        .heading{
          margin-bottom: 8px;
          color: ${theme.palette.customColor.grey_7};
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
          
        }
        .description{
          color: ${theme.palette.customColor.primaryBlack};
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .meet-link{
          color: #415DF3 !important;
          text-decoration-line: underline;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          text-underline-position: from-font;
        }
      }
    }
    .footer-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 20px 24px 20px;
      cursor: default;

      .cancel_session_btn{
        cursor: pointer;
        border-radius: 8px;
        background: rgba(218, 51, 51, 0.10);
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #DA3333;
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  
}
.schedule-session{
  .MuiDialog-paper{
    max-width: 440px;
    .dialog-title{
      padding-left: 0 !important;
      justify-content: left !important;
      .head{
        display: block;
        .heading{
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          color: white;
          text-align: left;
          color: var(--Black, #1C1C1C);
        }
        .otp-heading{
          color: #A1A1A1;
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
          margin-top: 5px;
          overflow-wrap: anywhere;
        }
      }
      
    }
    .crossIcon{
      margin-bottom: 40px;
    }
    .MuiDialogContent-root{
      padding: 0 !important;
      .content-wrap{
        text-align:left;
        .session_name{
          color: var(--Primary-Blue, #415DF3);
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px; 
          text-align:left;
          padding: 0px 24px;
        }
        .heading{
          color: var(--Placeholder, #757575);
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .content{
          color: var(--Black, #1C1C1C);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .detail-content{
          padding:24px;
        }
        .date-block{
          border-bottom:1px solid #E2E4E7;
          padding:24px;
        }
        .head{
          margin-bottom: 20px;
          color: var(--Black, #1C1C1C);
          font-family: "Plus Jakarta Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .book-block{
          padding: 0px 24px 20px 24px;
          display: flex;
          justify-content: right;
          .MuiButtonBase-root {
            width: fit-content;
            padding: 15px 33px;
          }
        }
      }
    }
  }
}

.dashboardSessionDetail{
  .MuiDialog-paper{
    width: 365px;
    max-width: unset;
    .MuiDialogTitle-root {
      padding: 12px 24px !important;
      padding-top: 20px !important;
      border-bottom: 1px solid ${theme.palette.customColor.grey_6};
      @media screen and (max-width: 480px){
        padding: 13px 16px !important;
      }
      .dialog-title{
        width: auto !important;
        padding-left: 0px !important;
      }
    }
  }
  .MuiDialogContent-root{
    padding: 0px;
    .modal-wrap{
      .serviceName{
        color: ${theme.palette.primary.main};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 180% */
        padding: 0px 24px;
        padding-top: 13px;
        @media screen and (max-width: 480px){
          padding: 0px 16px;
          padding-top: 13px;
        }
      }
      .content-wrap{
        padding: 13px 24px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        @media screen and (max-width: 480px){
          padding: 13px 16px;
        }
        .content{
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          .title{
            color: ${theme.palette.customColor.grey_7};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media screen and (max-width: 480px){
              font-size: 12px;
            }    
          }
          .description{
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 480px){
              font-size: 14px;
            }    
          }
        }
      }
      .meet-link{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .title{
          color: ${theme.palette.customColor.grey_7};
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }
        .description{
          color: ${theme.palette.primary.main};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-bottom: 1px solid;
          width: fit-content;
          text-decoration: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;      
        }
      }
      .ellipsisText{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 200px;
      }
    }
    }
  .footer-wrap{
    width: fit-content;
    padding: 8px 10px;
    cursor: pointer;
    color: ${theme.palette.customColor.stateError};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    float: right;
    margin: 24px;
    margin-top: 20px;
    background: ${theme.palette.customColor.bg_red};
    @media screen and (max-width: 480px){
      margin-top: 10px;
    }
    img{
      margin-right: 7px;
    }
  }
}

.issueBox{
  .MuiDialog-container{
    .MuiDialog-paper{
      .MuiDialogTitle-root{
        padding-bottom: 0px;
        @media screen and (max-width: 480px){
          padding: 20px 26px;
          padding-bottom: 0px;
          margin-bottom: 10px;
        }
        .dialog-title{
          align-items: baseline;
          padding-left: 0px;
          @media screen and (max-width: 480px){
            width: 100%;
            justify-content: flex-start;
          }
          .head{
            .heading{
              @media screen and (max-width: 480px){
                text-align: left;
              }
            }
            .otp-heading{
              font-size: 14px;
              margin: 0px;
              @media screen and (max-width: 480px){
                line-height: normal;
              }
            }
          }
        }
        .crossIcon{
          align-items: baseline;
        }
      }
    } 
  }
  .email-content{
    margin: 0px;
    .MuiDialogTitle-root{
      padding-bottom: 0px;
    }
    .description{
      .MuiInput-input{
        padding-bottom: 20px !important;
      }
    }
    .footer-wrap{
      display: flex;
      margin: 15px 0px;
      justify-content: space-between;
      @media screen and (max-width: 480px){
        flex-direction: column;
        align-items: center;
        min-height: 115px;
      }
    }
  }
}

.postAddModalContent{
  .MuiDialog-container{
    .MuiDialog-paper{
      width: 700px;
      max-height: 700px;
    .MuiDialogTitle-root{
      padding-bottom: 0;
      border-bottom: 1px solid ${theme.palette.customColor.grey_6};
      .dialog-title{
        padding-left: 0px;
        width: auto;
        .heading{
          text-align: left;
          color: #1C1C1C;
          font-family: Plus Jakarta Sans;
          font-size: 18px;
            font-weight: 600;
          line-height: 22.68px;
          text-align: left;

        }
        .otp-heading{
          font-size: 14px;
          margin-top:16px;
          margin-bottom:16px;
            color: #6D6D6D;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.01em;

        }
      }
    }
    .MuiDialogContent-root{
      padding: 20px 0px;
      padding-bottom: 0px;
    }
  }
}
  .content-wrap{
    padding: 0 24px;
    .header-wrap{
      display: flex;
      justify-content: end;
      padding: 0 20px;
    }
    .url-section{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border-radius:10px;
      background:#F4F4F4;
      padding: 12px;
      margin: 16px 0px;
      border-radius: 10px;  
      .card-header-wrap{
        display: flex;
        width: 100%;
        justify-content: space-between;    
        .heading{
            color: #1C1C1C;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;        
        }
        .buttons-wrap{
          .deleteIcon{
            width: 34px;
            @media screen and (max-width: 480px){
              width: 30px;
            }
          }
          .addIcon{
            width: 34px;
            @media screen and (max-width: 480px){
              width: 30px;
            }
          }
        }
      }
      .title-wrap{
        width: 100%;
      }
      .textfield-wrap{
        // width: 480px;
        // margin-right: 20px;
        width: 100%;    
      }
    }
  }
  .footer-wrap{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(91, 91, 91, 0.60);
    padding: 25px 24px;
    margin-top: 15px;
    @media screen and (max-width: 520px){
      flex-direction: column;
      min-height: 160px;
    }
  }
}

.postGuideModalContent{
  .MuiDialog-container{
    .MuiDialog-paper{
      .MuiDialogTitle-root{
        padding-bottom: 15px;
        .dialog-title{
          width: 100%;
          .head{
            display: flex;
            width: 100%;
            .backArrow{
              cursor: pointer;
            }
            .heading{
              margin-left: 10px;
              width: 90%;
            }
            .icon-wrap{
              display: flex;
              width: 10%;
              justify-content: end;          
            }
          }
        }
      }
    }
  }
  .content-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 5px;
    .heading{
      color: ${theme.palette.customColor.grey_7};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .subHeading{
      color: #1C1C1C;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;
      text-align: left;
    }
  }
  .footer-wrap{
    display: flex;
    justify-content: end;
      align-items: end;
    margin-top: 0px;
    @media screen and (max-width: 480px){
      min-height: auto;
      align-items: end;
    }
  }
}
.razorpayDialogWrap{
  .MuiDialog-container{
    .MuiDialog-paper{
      max-width: 498px;
      max-height: calc(100vh - 64px);
    .MuiDialogTitle-root{
      padding-bottom: 20px;
      padding-top:20px;
      .dialog-title{
        justify-content:left;
        padding-left: 0px;
        width: 100%;
        .heading{
          text-align: left;
          color: #1C1C1C;
          font-family: Plus Jakarta Sans;
          font-size: 18px;
          font-weight: 600;
          line-height: 22.68px;
          text-align: left;
        }
        .sub-heading{
          font-family: Plus Jakarta Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color:${theme.palette.customColor.secondaryGrey};
          margin-top:12px;
        }
      }
    }
    .MuiDialogContent-root{
      padding:0px;
      padding-bottom: 0px;
        .content-wrap {
          .input-fields-wrap {
            border:none;
            padding: 0 24px 17px 24px;
          }
        }
        .input-Label_text {
          font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: ${theme.palette.customColor.secondaryGrey};
          text-align: left;
          margin-bottom: 7px;
          display: flex;
          align-items: center;
        }
        .feedback-content-wrap {
          padding: 0 20px 20px;
          .description {
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color:${theme.palette.customColor.black};
          }
          .content-top-wrap{
            display:flex;
            align-items:center;
            justify-content: space-between;
          .content-top{
            display:inline-flex;
            align-items:center;
            gap:12px;
            .social-img:{
                width:44px;
                height:44px;
            }
            .top-right{
                .seeker-name{
                    font-family: Sora;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: left;
                    color:${theme.palette.customColor.primaryBlack};
                }
                .feedback-date{
                    margin-top:4px;
                    font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15.12px;
                    text-align: left;
                    color:${theme.palette.customColor.secondaryGrey};
                }
            }
            .quote-img{
              width:46px;
              height:46px;
          }
        }
        }
      }
      .session-info-box{
        margin-top:12px;
        padding:8px;
        background-color:#F8F4F0;
        border-radius: 10px;
        .heading{
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.12px;
            text-align: left;
            color:${theme.palette.customColor.primaryBlack};
        }
        .title{
            margin-top:4px;
            font-family: Sora;
            font-size: 14px;
            font-weight: 600;
            line-height: 17.64px;
            text-align: left;
            color:${theme.palette.customColor.primaryBlack};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

        }
    }
    } 
  }      
`,
);
export default StyledModal;
