import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemIcon from '@mui/material/ListItemIcon';
// import Avatar from '@mui/material/Avatar';
import { FormHelperText, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledSelectBox from './StyledSelectBox';
import CustomTooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  placeholder: {
    color: '#A0A0A0',
  },
  option: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    lineHeight: '40px',
  },
  selectMenu: {
    padding: '0',
    background: '#fff',
    color: '#1C1C1C',
    maxHeight: '300px',
    overflowY: 'auto',
  },
});
export default function SelectBox({
  label = '',
  options = [],
  handleChange,
  selectValue = '',
  isIitemIcon = false,
  placeholderValue = '',
  important = false,
  errorMsg = '',
  tooltip = '',
  showTooltip = false,
  ...restData
}) {
  const classes2 = useStyles();
  return (
    <StyledSelectBox>
      <div className="input-Label_text label-space">
        {label}
        {important ? <span className="important">*</span> : ''}
        {showTooltip ? <CustomTooltip title={tooltip} /> : ''}
      </div>
      <Select
        value={selectValue}
        variant="standard"
        onChange={handleChange}
        displayEmpty
        inputProps={{
          'aria-label': 'Without label',
        }}
        MenuProps={{
          classes: {
            list: classes2.selectMenu,
          },
        }}
        IconComponent={ExpandMoreIcon}
        {...restData}>
        {placeholderValue ? (
          <MenuItem value="" disabled className={classes2.option}>
            <span className={classes2.placeholder}>{placeholderValue}</span>
          </MenuItem>
        ) : (
          ''
        )}
        {options.map(({ title = '', value = '', icon = '' }) => (
          <MenuItem value={value} key={value} className={classes2.option}>
            {isIitemIcon && <ListItemIcon sx={{ marginRight: '10px' }}>{icon}</ListItemIcon>}
            {title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMsg}</FormHelperText>
    </StyledSelectBox>
  );
}
