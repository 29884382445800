import { styled } from '@mui/material/styles';

const StyledContactFooter = styled('div')(
  ({ theme }) => `
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-top: 1px solid ${theme.palette.customColor.secondaryOrange};
  color: ${theme.palette.customColor.primaryBlack};
  width: 95%;
  margin: 0px auto;
  padding: 20px 0px;
  .link-wrap-heading{
    @media screen and (max-width: 480px){
      font-size: 14px;
    }
  }
  .link-wrap{
    margin-top: 10px;
    color: ${theme.palette.customColor.secondaryGrey};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .link {
      font-weight: 500;
      color: ${theme.palette.primary.main};
      text-decoration: none;
      border-bottom: 1px solid;
      cursor: pointer;
    }
  }
  `,
);

export default StyledContactFooter;
