import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountrieslists, fetchGetProfile } from '../../../store/reducers/authentication/apiThunk';
import { validationSchemaBankDetails } from '../../../utils/validations/authValidation';
import { PrimaryButton } from '../../Button';
import TextField from '../../TextField';
import Modal from '../../Modal';
import SelectBox from '../../SelectBox';
import StyledRadioGroup from './StyledRadioGroup';
import { addAccountDetails, fetchBankDetails, updateAccountDetails } from '../../../store/reducers/expertProfile/apiThunk';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import { ToastNotifySuccess } from '../../Toast/ToastNotify';

const accountTypeOptions = [
  { value: 'savings', label: 'Saving' },
  { value: 'current', label: 'Current' },
];

const AccountsDetailsModal = ({ openDialog, closeDialog }) => {
  const dispatch = useDispatch();
  const [countryLists, setCountryLists] = useState([]);

  const {
    bankDetails:
    { data:
      { _id: id = '',
        country: bankCountry = '',
        accountType: bankAccountType = 'savings',
        accountHolderName: bankAccountHolderName = '',
        ifscCode: bankIfscCode = '',
        accountNumber: bankAccountNumber = '' } = {},
    } = {} } = useSelector(selectorExpertProfile);

  const fetchCountriesLists = async () => {
    const response = await dispatch(fetchCountrieslists()).then((val) => val);
    const { payload: { data = [] } = {} } = response;
    const filteredData = data?.map(({ nicename }) => ({
      title: nicename,
      value: nicename,
    }));
    setCountryLists(filteredData);
  };

  useEffect(() => {
    fetchCountriesLists();
  }, []);

  const accountsfieldsEmptyValues = {
    country: bankCountry ?? '',
    accountType: bankAccountType ?? 'savings',
    accountHolderName: bankAccountHolderName ?? '',
    ifscCode: bankIfscCode ?? '',
    accountNumber: bankAccountNumber ?? '',
  };

  const submitHandler = async (values, { resetForm }) => {
    try {
      const { country, accountType, accountHolderName, ifscCode, accountNumber } = values;

      const conAccountNumber = accountNumber.toString();
      const data = { country,
        accountType,
        accountHolderName,
        ifscCode,
        accountNumber: conAccountNumber };
      const requestData = {
        id,
        body: data,
      };
      if (id) {
        const response = await dispatch(updateAccountDetails(requestData));
        const { payload: { status = false, message = '' } = {} } = response;
        if (status) {
          ToastNotifySuccess(message);
        }
      } else {
        const response = await dispatch(addAccountDetails(data));
        const { payload: { status = false, message = '' } = {} } = response;
        if (status) {
          ToastNotifySuccess(message);
        }
      }
      await dispatch(fetchBankDetails());
      await dispatch(fetchGetProfile());
      resetForm({ values: accountsfieldsEmptyValues });
      closeDialog();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const formik = useFormik({
    initialValues: accountsfieldsEmptyValues,
    validationSchema: validationSchemaBankDetails,
    onSubmit: submitHandler,
  });

  const handleRadioChange = (newValue) => {
    formik.setFieldValue('accountType', newValue);
  };

  useEffect(() => {
    formik.resetForm({ values: accountsfieldsEmptyValues });
  }, [closeDialog]);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values: {
      country,
      accountType,
      accountHolderName,
      ifscCode,
      accountNumber,
    } = {},
    touched: {
      country: tucCountry = false,
      accountHolderName: tucAccountHolderName = false,
      ifscCode: tucIfscCode = false,
      accountNumber: tucAccountNumber = false,
      accountType: tucAccountType = false,
    } = {},
    errors: {
      country: errCountry,
      accountHolderName: errAccountHolderName,
      ifscCode: errIfscCode,
      accountNumber: errAccountNumber,
      accountType: errAccountType,
    } = {},
  } = formik;

  useEffect(() => {
    const { setFieldValue } = formik;
    setFieldValue('country', bankCountry);
    setFieldValue('accountType', bankAccountType);
    setFieldValue('accountHolderName', bankAccountHolderName);
    setFieldValue('ifscCode', bankIfscCode);
    setFieldValue('accountNumber', bankAccountNumber.toString());
  }, [openDialog]);

  const handleAccountNumberChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    formik.setFieldValue('accountNumber', numericValue);
  };

  const handleChangeName = (e) => {
    const { name, value } = e.target;
    // Remove all characters except alphabets and spaces
    const sanitizedValue = value
      .replace(/[^A-Za-z\s]/g, '') // Remove non-alphabet and non-space characters
      .replace(/\s{2,}/g, ' '); // Replace consecutive spaces with a single space

    // Update the form value (assuming you have Formik or a similar setup)
    formik.setFieldValue(name, sanitizedValue);
  };

  const razorpayModalContent = () => (
    <div className="content-wrap">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          columnSpacing={2.5}
          rowSpacing={2}
          className="input-fields-wrap"
        >
          <Grid item xs={12}>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} mb={2}>
                <SelectBox
                  label="Country"
                  important
                  name="country"
                  options={countryLists}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  placeholderValue="Select a country"
                  selectValue={country}
                  error={tucCountry && !!errCountry}
                  errorMsg={tucCountry && errCountry}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <div className="input-Label_text label-space">Account Type</div>
                <StyledRadioGroup
                  options={accountTypeOptions}
                  value={accountType} // Ensure using Formik state
                  onChange={(e) => handleRadioChange(e)} />
                {tucAccountType && errAccountType && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall">{errAccountType}</p>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="accountHolderName"
                  type="text"
                  label="Account Holder Name"
                  important
                  onChange={handleChangeName}
                  onBlur={handleBlur}
                  value={accountHolderName}
                  error={tucAccountHolderName && !!errAccountHolderName}
                  helperText={tucAccountHolderName && errAccountHolderName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="ifscCode"
                  type="text"
                  label="IFSC Code"
                  important
                  onChange={(e) => {
                    const regex = /^[A-Za-z0-9]*$/;
                    if (regex.test(e.target.value)) {
                      formik.setFieldValue('ifscCode', e.target.value.toUpperCase());
                    }
                  }}
                  onBlur={handleBlur}
                  value={ifscCode}
                  error={tucIfscCode && !!errIfscCode}
                  helperText={tucIfscCode && errIfscCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="accountNumber"
                  label="Account Number"
                  important
                  onChange={handleAccountNumberChange}
                  onBlur={handleBlur}
                  value={accountNumber}
                  error={tucAccountNumber && !!errAccountNumber}
                  helperText={tucAccountNumber && errAccountNumber}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={3} px={3}>
          <PrimaryButton sx={{ width: '100%' }} type="submit">
            Save Changes
          </PrimaryButton>
        </Grid>
      </form>
    </div>
  );

  return (
    <Modal
      openDialog={openDialog}
      closeDialog={closeDialog}
      noHeader={false}
      dialogCss="razorpayDialogWrap"
      dialogContent={razorpayModalContent()}
      heading={
        <div>
          <div>
            Bank Account Details
          </div>
          <div className="sub-heading">Please confirm your country and other bank account details that you want to receive your payout in.
          </div>
        </div>
        }
    />
  );
};

export default AccountsDetailsModal;
