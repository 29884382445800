/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { FormHelperText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import StyledAvailabilityModal from './StyledAvailabilityModal';
import { PrimaryButton, SecondaryButton } from '../Button';
import crossIcon from '../../assets/svg/crossIcon.svg';
import SelectBox from '../SelectBox';
import TimePickerValue from './TimePicker';
import addSlot from '../../assets/svg/addSlot.svg';
import { validationAvailabilitySlots } from '../../utils/validations/authValidation';
import {
  fetchLandingPage,
  fetchLandingPageData,
} from '../../store/reducers/expertProfile/apiThunk';
import { selectorExpertProfile } from '../../store/reducers/expertProfile/index';
import Modal from '../Modal';

const initialWeekDaysValue = [
  {
    title: 'Monday',
    value: 'Mon',
    key: 1,
  },
  {
    title: 'Tuesday',
    value: 'Tue',
    key: 2,
  },
  {
    title: 'Wednesday',
    value: 'Wed',
    key: 3,
  },
  {
    title: 'Thursday',
    value: 'Thu',
    key: 4,
  },
  {
    title: 'Friday',
    value: 'Fri',
    key: 5,
  },
  {
    title: 'Saturday',
    value: 'Sat',
    key: 6,
  },
  {
    title: 'Sunday',
    value: 'Sun',
    key: 0,
  },
];

const selectOptions = [
  {
    value: 1,
    title: 'Days',
  },
  {
    value: 2,
    title: 'Specific Date',
  },
];

const AvailabilityModal = ({ openDialog = false, closeDialog = '' }) => {
  const dayListArray = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dispatch = useDispatch();
  const expertProfile = useSelector(selectorExpertProfile);
  const dateOptions = [];

  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }

    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }

  for (let day = 1; day <= 31; day++) {
    const title = `${getDayWithSuffix(day)} of every month`;

    dateOptions.push({
      value: day,
      title,
    });
  }

  const [typeSelected, setTypeSelected] = useState(1);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [dateSelected, setDateSelected] = useState('');
  const [selectedDay, setSelectedDay] = useState(JSON.parse(JSON.stringify(initialWeekDaysValue)));

  const {
    userLandingData: {
      data: {
        bio = '',
        whoAmI = '',
        expertise = '',
        aboutMe = '',
        dateAvailability: fetchedDateAvailability = [],
        dayAvailability: fetchedDayAvailability = [],
      } = {},
    } = {},
  } = expertProfile;

  useEffect(() => {
    if (fetchedDateAvailability.length === 0) {
      setTypeSelected(1);
    } else {
      setTypeSelected(2);
    }
  }, []);

  const submitHandler = async (formik) => {
    const { slotLists } = formik;

    const data = {
      pageType: 1,
      whoAmI,
      expertise,
      bio,
      aboutMe,
      dayAvailability: [],
      dateAvailability: [],
    };
    const requestData = slotLists.map((obj) => {
      const newObject = {
        type: typeSelected,
        value: obj.key,
        timeRange: [...obj.timeLists],
      };
      return newObject;
    });
    if (typeSelected === 1) {
      data.dayAvailability = requestData;
    } else {
      data.dateAvailability = requestData;
    }
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: data,
    };
    await dispatch(fetchLandingPage(dataRequest));
    dispatch(fetchLandingPageData());
    closeDialog();
  };

  const formik = useFormik({
    initialValues: {
      slotLists: [],
    },
    validationSchema: validationAvailabilitySlots,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: submitHandler,
  });

  const { handleSubmit, values, errors: { slotLists: errSlotLists = [] } = {} } = formik;

  const convertFetchedDayData = () => {
    const tempData = fetchedDayAvailability.map((obj) => {
      const newObj = {
        day: dayListArray[obj.value],
        key: obj.value,
        timeLists: obj.timeRange,
      };
      return newObj;
    });
    return JSON.parse(JSON.stringify(tempData));
  };

  const convertFetchedDateData = () => {
    const tempData = fetchedDateAvailability.map((obj) => {
      const newObj = {
        day: dateOptions[obj.value - 1].title,
        key: obj.value,
        timeLists: obj.timeRange,
      };
      return newObj;
    });
    return JSON.parse(JSON.stringify(tempData));
  };

  useEffect(() => {
    if (fetchedDateAvailability.length === 0) {
      const slotLists = convertFetchedDayData();
      formik.setValues({ ...values, slotLists });
    } else {
      const slotLists = convertFetchedDateData();
      formik.setValues({ ...values, slotLists });
    }
  }, [expertProfile]);

  const handleAddSlot = (dayTitle) => {
    const newSlot = {
      startTime: {
        hours: 0,
        minutes: 0,
      },
      endTime: {
        hours: 0,
        minutes: 30,
      },
    };
    let tempData = [...values.slotLists];
    tempData = tempData.map((tempObj) => {
      if (tempObj.day === dayTitle) {
        tempObj.timeLists.push(newSlot);
      }
      return tempObj;
    });
    const slotLists = tempData;
    formik.setValues({ values, slotLists });
  };

  const handleTypeSelected = (e) => {
    formik.setErrors([]);
    let slotLists;
    if (e.target.value === 1) {
      slotLists = convertFetchedDayData();
    } else {
      slotLists = convertFetchedDateData();
    }
    if (values.slotLists.length !== 0) {
      setOpenLogoutModal(true);
    } else {
      formik.setValues({ ...values, slotLists });
      setTypeSelected(e.target.value);
    }
  };

  const handleIsActiveDay = (key) => {
    let found = false;
    values.slotLists.forEach((obj) => {
      if (obj.key === key) {
        found = true;
      }
    });
    return found;
  };

  const handleDaySelected = (key) => {
    const updatedData = selectedDay.map((obj) => {
      if (obj.key === key) {
        let tempData = [...values.slotLists];
        if (!handleIsActiveDay(key)) {
          const newListObj = {
            day: obj.title,
            key: obj.key,
            timeLists: [
              {
                startTime: {
                  hours: 0,
                  minutes: 0,
                },
                endTime: {
                  hours: 0,
                  minutes: 30,
                },
              },
            ],
          };
          tempData.push(newListObj);
        } else {
          tempData = tempData.filter((item) => key !== item.key);
        }
        tempData.sort((a, b) => a.key - b.key);
        const slotLists = tempData;
        formik.setValues({ ...values, slotLists });
      }
      return obj;
    });
    setSelectedDay(updatedData);
  };

  const handleDeleteSlot = (dayTitle, listIndex) => {
    let tempData = [...values.slotLists];
    tempData = tempData.map((item) => {
      if (item.day === dayTitle) {
        const filteredArr = item.timeLists.filter((listObj, index) => index !== listIndex);
        item.timeLists = filteredArr;
      }
      return item;
    });
    const finalArray = tempData.filter((obj) => {
      if (obj.timeLists.length === 0) {
        handleDaySelected(obj.key);
        return '';
      }
      return obj;
    });
    const slotLists = finalArray;
    formik.setValues({ values, slotLists });
  };

  const customTimeChange = (e, name, itemIndex, slotsIndex) => {
    formik.setErrors([]);
    if (e?.hour() || e?.minute()) {
      formik.setFieldValue(
        `slotLists[${itemIndex}].timeLists[${slotsIndex}].${name}.hours`,
        e?.hour(),
      );
      formik.setFieldValue(
        `slotLists[${itemIndex}].timeLists[${slotsIndex}].${name}.minutes`,
        e?.minute(),
      );
    } else {
      formik.setFieldValue(`slotLists[${itemIndex}].timeLists[${slotsIndex}].${name}.hours`, 0);
      formik.setFieldValue(`slotLists[${itemIndex}].timeLists[${slotsIndex}].${name}.minutes`, 0);
    }
  };

  const convertMinutesToFormat = ({ hours = 0, minutes = 0 }) => {
    let formattedTime = dayjs();
    formattedTime = formattedTime.set('hour', hours).set('minute', minutes);
    return formattedTime;
  };

  const handleDateChange = (e) => {
    let found = false;
    const tempData = [...values.slotLists];
    if (e.target.value !== '') {
      tempData.forEach((obj) => {
        if (obj.key === e.target.value) {
          found = true;
        }
      });
      if (!found) {
        tempData.push({
          day: dateOptions[e.target.value - 1].title,
          key: e.target.value,
          timeLists: [
            {
              startTime: {
                hours: 0,
                minutes: 0,
              },
              endTime: {
                hours: 0,
                minutes: 30,
              },
            },
          ],
        });
      }
    }
    tempData.sort((a, b) => a.key - b.key);
    const slotLists = tempData;
    formik.setValues({ values, slotLists });
    setDateSelected('');
  };

  const renderDaysLists = () => {
    return (
      values.slotLists.length > 0 &&
      values.slotLists.map((item, itemIndex) => {
        return (
          <Grid
            container
            className="slots-card"
            xs={12}
            padding={{ md: '20px 25px', sm: '20px 0px', xs: '25px 15px' }}
            paddingLeft={{ sm: '15px' }}
            direction={{ xs: 'column', sm: 'row' }}
            paddingBottom="20px"
            borderBottom="1px solid #E2E4E7">
            <Grid
              container
              sm={3}
              xs={12}
              className="dayTitle"
              alignItems="center"
              justifyContent={{ xs: 'space-between', sm: 'normal' }}>
              {typeSelected === 2 ? <div className="titleName">{item.day}</div> : item.day}
              <img src={addSlot} alt="" onClick={() => handleAddSlot(item.day)} />
            </Grid>
            <Grid container xs={12} sm={9} direction={{ xs: 'column', sm: 'row' }}>
              {item.timeLists.map((slotsObj, slotsIndex) => {
                return (
                  <Grid
                    container
                    xs={12}
                    direction={{ xs: 'row', sm: 'row' }}
                    marginBottom="15px"
                    gap={{ xs: 1, sm: 0 }}>
                    <Grid
                      container
                      xs={5}
                      sm={5}
                      margin={{ sm: '0px', xs: '10px 0px' }}
                      marginRight={{ sm: 2, xs: 0 }}>
                      <TimePickerValue
                        customLabel="Start time"
                        name={`slotLists[${itemIndex}].timeLists[${slotsIndex}].startTime`}
                        onChange={(e) => customTimeChange(e, 'startTime', itemIndex, slotsIndex)}
                        value={convertMinutesToFormat(slotsObj.startTime)}
                      />
                    </Grid>
                    <Grid
                      container
                      xs={5}
                      sm={5}
                      margin={{ sm: '0px', xs: '10px 0px' }}
                      marginRight={{ sm: 2, xs: 0 }}>
                      <TimePickerValue
                        customLabel="End time"
                        name={`slotLists[${itemIndex}].timeLists[${slotsIndex}].endTime`}
                        onChange={(e) => customTimeChange(e, 'endTime', itemIndex, slotsIndex)}
                        value={convertMinutesToFormat(slotsObj.endTime)}
                      />
                    </Grid>
                    <Box pt={{ xs: '43px', sm: '30px' }}>
                      <img
                        src={crossIcon}
                        className="cursorPointer"
                        onClick={() => handleDeleteSlot(item.day, slotsIndex)}
                        alt=""
                      />
                    </Box>
                    <FormHelperText className="customError">
                      {typeof errSlotLists[itemIndex]?.timeLists[slotsIndex] === 'string'
                        ? errSlotLists[itemIndex]?.timeLists[slotsIndex]
                        : errSlotLists[itemIndex]?.timeLists[slotsIndex]?.startTime}
                    </FormHelperText>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })
    );
  };

  const handleSave = () => {
    if (fetchedDateAvailability.length === 0 && fetchedDayAvailability.length === 0) {
      handleSubmit(formik);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const dialogContent = () => (
    <>
      <Grid
        container
        xs={12}
        padding={{ xs: '0 16px', sm: '0 25px' }}
        paddingBottom="20px"
        borderBottom="1px solid #E2E4E7">
        <Grid container xs={12} marginBottom="30px" direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs={5} marginRight={{ sm: 5, xs: 0 }} marginBottom={{ sm: 0, xs: 3 }}>
            <SelectBox
              label="Available for"
              important
              typeSelected
              selectValue={typeSelected}
              onChange={(event) => handleTypeSelected(event)}
              options={selectOptions}
            />
          </Grid>
          {typeSelected === 2 ? (
            <Grid item xs={5}>
              <SelectBox
                label="Date"
                important
                typeSelected
                placeholderValue="Select a Date"
                selectValue={dateSelected}
                onChange={(event) => handleDateChange(event)}
                options={dateOptions}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {typeSelected === 1 ? (
          <>
            <div className="selectdayHeading">Select the days of the week to be active</div>
            <Grid
              container
              className="weekDay-wrap"
              xs={12}
              justifyContent={{ sm: 'space-between', xs: 'normal' }}
              margin={{ sm: '20px 0px', xs: '10px 0px' }}>
              {selectedDay.map(({ value = '', key = 0 }) => (
                <div
                  className={handleIsActiveDay(key) ? 'ActiveButton weekButtons' : 'weekButtons'}
                  onClick={() => handleDaySelected(key)}>
                  {value}
                </div>
              ))}
            </Grid>
          </>
        ) : (
          ''
        )}
      </Grid>
      {renderDaysLists()}
      <div className="logout-button-wrap">
        <PrimaryButton
          sx={{ width: '206px' }}
          disabled={values.slotLists.length === 0}
          onClick={() => handleSave()}>
          Save
        </PrimaryButton>
      </div>
    </>
  );

  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };

  const handleTypeChange = () => {
    let slotLists;
    if (typeSelected === 1) {
      slotLists = convertFetchedDateData();
      setTypeSelected(2);
    } else {
      slotLists = convertFetchedDayData();
      setTypeSelected(1);
    }
    formik.setValues({ ...values, slotLists });
    setOpenLogoutModal(false);
  };

  const logoutModalContent = () => (
    <>
      <div>
        You can either set your availability day wise or date wise. Are you sure you want to change
        your availability?
      </div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{
            width: '150px',
            border: '1px solid #415DF3',
          }}
          onClick={() => handleTypeChange()}>
          Yes
        </SecondaryButton>
        <PrimaryButton
          sx={{
            width: '150px',
          }}
          onClick={() => handleCloseLogoutDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  const handleCloseConfirmationDialoag = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirmationSave = () => {
    handleSubmit(formik);
    handleCloseConfirmationDialoag();
  };

  const confirmationModalContent = () => (
    <>
      <div>Are you sure you want to save the changes?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleConfirmationSave()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseConfirmationDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialog();
  };

  return (
    <>
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Attention please!"
        dialogCss="logoutDialogWrap"
      />
      <Modal
        openDialog={openConfirmationModal}
        closeDialog={handleCloseConfirmationDialoag}
        dialogContent={confirmationModalContent()}
        noHeader={false}
        heading="Availability"
        dialogCss="logoutDialogWrap"
      />
      <StyledAvailabilityModal
        open={openDialog}
        disableBackdropClick="true"
        className="Availability dialogBox"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-title">
            <div className="title-heading">Set your Availability</div>
            <div className="title-subHeading">
              Let Superconnects know when you’re typically available to accept meeting. The selected
              availability will remain same for every week/month
            </div>
          </div>
          <div onClick={handleClose} className="crossIcon" role="presentation">
            <img
              src={crossIcon}
              alt=""
              style={{ color: '#212B36', cursor: 'pointer' }}
              onClick={handleClose}
              role="presentation"
            />
          </div>
        </DialogTitle>
        <DialogContent>{dialogContent()}</DialogContent>
      </StyledAvailabilityModal>
    </>
  );
};

export default AvailabilityModal;
