/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import StyledSocialLink from './StyledSocialLink';
import TextField from '../../../components/TextField';
import SelectBox from '../../../components/SelectBox';
import linkedinIcon from '../../../assets/svg/linkedinNew.svg';
import youtubeIcon from '../../../assets/svg/youtubeNew.svg';
import twitterIcon from '../../../assets/svg/twitterNew.svg';
import substackIcon from '../../../assets/svg/substackNew.svg';
import whatsappIcon from '../../../assets/svg/whatsappNew.svg';
import telegramIcon from '../../../assets/svg/telegram.svg';
import deleteIcon from '../../../assets/svg/deleteIcon.svg';
import crossIcon from '../../../assets/svg/crossIcon.svg';
import crossIcon2 from '../../../assets/svg/crossIcon2.svg';
import addIcon from '../../../assets/svg/addMore.svg';
import addIcon2 from '../../../assets/svg/addMore2.svg';
import InfoIcon from '../../../components/icons/InfoIcon';
import { SecondaryButton, PrimaryButton } from '../../../components/Button';
import { CONSTANTS } from '../../../constants/constants';
import Modal from '../../../components/Modal';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile/index';
import {
  fetchLandingPage,
  fetchLandingPageData,
} from '../../../store/reducers/expertProfile/apiThunk';
import {
  validationSocialLinkSchema,
  validationLinksUrl,
} from '../../../utils/validations/authValidation';
import LandingButtons from '../../../components/LandingButtons';
import BasicModal from '../../../components/Modal/BasicModal';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

const {
  SOCIALLINKS: { YOUTUBE, TWITTER, WHATSAPP, TELEGRAM, SUBSTACK, LINKEDIN },
} = CONSTANTS;

const SocialLink = ({ stepNumber = 0, setActiveStep }) => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openPostAddModal, setOpenPostAddModal] = useState(false);
  const [openPostGuideModal, setOpenPostGuideModal] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [postModalData, setPostModalData] = useState('');
  const dispatch = useDispatch();
  const {
    suggestions: { data: { socialMedia: suggestionsSocialMedia = [] } = {} } = {},
    userLandingData: { data: { socialMedia: fetchedSocialMedia = [] } = {} } = {},
  } = useSelector(selectorExpertProfile);

  const getIcon = (type) => {
    switch (type) {
      case 'twitterLogo':
        return <img src={twitterIcon} className="selectIcon" alt="twitterLogo" />;
      case 'youTubeLogo':
        return <img src={youtubeIcon} className="selectIcon" alt="youTubeLogo" />;
      case 'sobStackLogo':
        return <img src={substackIcon} className="selectIcon" alt="sobStackLogo" />;
      case 'whatsAppLogo':
        return <img src={whatsappIcon} className="selectIcon" alt="whatsapp" />;
      case 'telegramLogo':
        return <img src={telegramIcon} className="selectIcon" alt="telegramLogo" />;
      case 'linkedinLogo':
        return <img src={linkedinIcon} className="selectIcon" alt="linkedinIcon" />;
      default:
        return '';
    }
  };

  const getIconByType = (type) => {
    switch (type) {
      case TWITTER:
        return twitterIcon;
      case YOUTUBE:
        return youtubeIcon;
      case SUBSTACK:
        return substackIcon;
      case WHATSAPP:
        return whatsappIcon;
      case TELEGRAM:
        return telegramIcon;
      case LINKEDIN:
        return linkedinIcon;
      default:
        return '';
    }
  };

  const getPlaceholder = (type) => {
    switch (type) {
      case YOUTUBE:
        return 'Enter your Youtube channel URL/handle URL';
      case TWITTER:
        return ' Enter your Twitter profile URL/Twitter handle';
      case WHATSAPP:
        return 'Enter your Whatsapp group link';
      case TELEGRAM:
        return 'Enter your Telegram group link';
      case SUBSTACK:
        return 'Enter your Substack profile URL';
      case LINKEDIN:
        return 'Enter your LinkedIn profile URL';
      default:
        return 'Enter URL here';
    }
  };

  const getTitleValue = (type) => {
    switch (type) {
      case YOUTUBE:
        return 'Youtube';
      case TWITTER:
        return 'Twitter';
      case WHATSAPP:
        return 'Whatsapp';
      case TELEGRAM:
        return 'Telegram';
      case SUBSTACK:
        return 'Substack';
      case LINKEDIN:
        return 'LinkedIn';
      default:
        return '';
    }
  };

  const submitHandler = async (values) => {
    const { socialLinks } = values;
    const dataTemp = socialLinks.map(({ mediaType, mediaProfileUrl, postUrl }) => ({
      mediaType,
      mediaProfileUrl,
      postUrl,
    }));
    const data = {
      pageType: 3,
      socialMedia: [...dataTemp],
    };

    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: data,
    };

    await dispatch(fetchLandingPage(dataRequest));
    await dispatch(fetchLandingPageData());
    setDisableSaveBtn(true);
  };

  const popupSubmitHandler = async () => {
    formik.setFieldValue(`socialLinks[${postModalData?.index - 1}].postUrl`, popupUrlsData);
    handleSubmit();
    setOpenPostAddModal(false);
    popupFormik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      socialLinks: [],
    },
    validationSchema: validationSocialLinkSchema,
    onSubmit: submitHandler,
  });

  const popupFormik = useFormik({
    initialValues: {
      popupUrlsData: [],
    },
    validationSchema: validationLinksUrl,
    onSubmit: popupSubmitHandler,
  });

  useEffect(() => {
    const { setFieldValue } = formik;
    setFieldValue('socialLinks', [...fetchedSocialMedia]);
  }, [fetchedSocialMedia]);

  const {
    handleSubmit,
    values: { socialLinks } = {},
    touched: { socialLinks: tucSocialLinks = [] } = {},
    errors: { socialLinks: errSocialLinks = [] } = {},
  } = formik;

  const {
    handleSubmit: popupHandleSubmit,
    values: { popupUrlsData } = {},
    touched: { popupUrlsData: tucPopupUrlsData = [] } = {},
    errors: { popupUrlsData: errPopupUrlsData = [] } = {},
  } = popupFormik;

  const getListWithIcon = (skipId = '') => {
    let optionList = [];
    const selectedIds = socialLinks
      .filter((item) => item.mediaType !== skipId)
      .map((item) => item.mediaType);
    optionList = suggestionsSocialMedia
      .filter((item) => !selectedIds.includes(item.value))
      .map((item) => {
        const obj = {
          title: item.title,
          value: item.value,
          icon: getIcon(item.icon),
        };
        return obj;
      });
    return optionList;
  };

  const deleteSocialLink = (index) => {
    setDisableSaveBtn(false);
    let tempData = [...socialLinks];
    tempData = tempData.filter((item, indexItem) => indexItem !== index);
    formik.setFieldValue('socialLinks', tempData);
    handleSubmit();
  };

  const handleFieldChange = (e, type, index) => {
    setDisableSaveBtn(false);
    formik.setFieldValue(`socialLinks[${index}][${type}]`, e.target.value);
  };

  const isEmptyExist = () => {
    const emptyEle = socialLinks.filter((item) => item.mediaType === '');
    return emptyEle && emptyEle.length === 0;
  };

  const handleShowAdd = () => {
    setDisableSaveBtn(false);
    const { setFieldValue } = formik;

    if (isEmptyExist() && socialLinks.length < 6) {
      setFieldValue(`socialLinks[${socialLinks.length}]`, {
        id: Math.random(),
        icon: '',
        mediaType: '',
        mediaProfileUrl: '',
        postUrl: [],
      });
    }
  };

  const handleSelectPostBtn = (index, postUrl, mediaType) => {
    setOpenPostAddModal(true);
    setPostModalData({ index: index + 1, mediaType });
    // if (postUrl.length === 0) {
    //   postUrl.push({
    //     url: '',
    //   });
    // }
    popupFormik.setFieldValue('popupUrlsData', postUrl);
  };

  const renderlist = () => {
    if (socialLinks && socialLinks.length) {
      return (
        <>
          {socialLinks.map((item, index) => (
            <Grid
              container
              columns={12}
              key={item.mediaType}
              justifyContent="space-between"
              alignItems="flex-start"
              className="card-wrap">
              <Grid item xs={12} sm={3.5} pb="15px" width={{ xs: '100%', sm: 'auto' }}>
                <SelectBox
                  disabled={index < socialLinks.length - 1 || item.postUrl.length !== 0}
                  label="Social Media"
                  name={`socialLinks[${index}].mediaType`}
                  options={getListWithIcon(item.mediaType)}
                  isIitemIcon
                  selectValue={item.mediaType}
                  onChange={(event) => handleFieldChange(event, 'mediaType', index)}
                  placeholderValue="Select Social Media"
                  error={tucSocialLinks[index]?.mediaType && errSocialLinks[index]?.mediaType}
                  errorMsg={
                    tucSocialLinks[index]?.mediaType ? errSocialLinks[index]?.mediaType || '' : ''
                  }
                  helperText={
                    tucSocialLinks[index]?.mediaType &&
                    errSocialLinks[index] &&
                    errSocialLinks[index].mediaType
                  }
                />
              </Grid>
              {item?.mediaType ? (
                <>
                  <Grid item xs={12} sm={item.mediaType !== 3 && item.mediaType !== 4 ? 5.5 : 7.5}>
                    <TextField
                      name={`socialLinks[${index}].mediaProfileUrl`}
                      label="URL"
                      type="text"
                      placeholderValue={getPlaceholder(item.mediaType)}
                      value={item.mediaProfileUrl}
                      onChange={(event) => handleFieldChange(event, 'mediaProfileUrl', index)}
                      error={
                        tucSocialLinks[index]?.mediaProfileUrl &&
                        errSocialLinks[index]?.mediaProfileUrl
                      }
                      helperText={
                        tucSocialLinks[index] &&
                        errSocialLinks[index] &&
                        errSocialLinks[index].mediaProfileUrl
                      }
                    />
                  </Grid>
                  {item.mediaType !== 3 && item.mediaType !== 4 ? (
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      // pb={{ sm: '22px', xs: 0 }}
                      pt={{ sm: '30px', xs: 0 }}
                      display={'flex'}
                      justifyContent={{ sm: 'center', xs: 'flex-start' }}>
                      <SecondaryButton
                        sx={{
                          border: '1px solid #415DF3',
                          minWidth: 'fit-content',
                          padding: '0px 12px',
                          width: '130px',
                          height: '34px',
                          minHeight: '34px',
                          fontFamily: 'Plus Jakarta Sans',
                          fontSize: '14px',
                          fontWeight: '600',
                          '@media (max-width: 600px)': {},
                        }}
                        disabled={!item?.mediaProfileUrl}
                        onClick={() => handleSelectPostBtn(index, item.postUrl, item.mediaType)}>
                        {item.postUrl.length === 0 ? 'Select Post' : 'View/Edit Post'}
                      </SecondaryButton>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid
                    item
                    xs={item.mediaType !== 3 && item.mediaType !== 4 ? 6 : 12}
                    sm={0.6}
                    // pb={{ sm: '20px', xs: 0 }}
                    pt={{ sm: '24px', xs: 0 }}
                    justifyContent={{ sm: 'center', xs: 'flex-end' }}
                    display={'flex'}>
                    <IconButton onClick={() => setOpenLogoutModal(index + 1)}>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        role="presentation"
                        className="socialDelete"
                      />
                    </IconButton>
                  </Grid>
                  {/* <Grid
                    item
                    xs={6}
                    sm={0.6}
                    display={{ xs: 'grid', sm: 'none' }}
                    pb="22px"
                    width={{ xs: '50%', sm: 'auto' }}>
                    <SecondaryButton
                      onClick={() => setOpenLogoutModal(index + 1)}
                      sx={{
                        justifyContent: 'center',
                        border: '1px solid #e74c3c',
                        '&:hover': {
                          backgroundColor: '#e74c3c',
                        },
                      }}>
                      <img src={deleteIcon} alt="delete" role="presentation" />
                      Delete
                    </SecondaryButton>
                  </Grid> */}
                </>
              ) : (
                ''
              )}
            </Grid>
          ))}
        </>
      );
    }
    return <div className="no_content_social">Add your social accounts here</div>;
  };

  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };
  const handleClosePostAddDialoag = () => {
    popupFormik.resetForm();
    setOpenPostAddModal(false);
  };
  const handleClosePostGuideDialoag = () => {
    setOpenPostGuideModal(false);
  };

  const handleModalDelete = () => {
    deleteSocialLink(openLogoutModal - 1);
    setOpenLogoutModal(false);
  };

  const logoutModalContent = () => (
    <>
      <div>Are you sure you want to delete this social link?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleModalDelete()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseLogoutDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  const handlePopupUrlField = (e, index, key) => {
    popupFormik.setFieldValue(`popupUrlsData[${index}][${key}]`, e.target.value);
  };

  const handleAddUrl = () => {
    const tempData = [...popupUrlsData];
    if (tempData.length < 3) {
      tempData.push({
        title: '',
        url: '',
      });
      popupFormik.setFieldValue('popupUrlsData', tempData);
    }
  };

  const handleDeleteUrl = (index) => {
    const tempData = [...popupUrlsData];
    const filteredData = tempData.filter((item, itemIndex) => itemIndex !== index);
    popupFormik.setFieldValue('popupUrlsData', filteredData);
  };

  const handleEmbedPopup = () => {
    setOpenPostGuideModal(true);
  };

  const postAddModalContent = () => (
    <>
      <div className="content_wrap">
        {popupUrlsData?.length === 0 ? (
          <div className="header-wrap">
            <SecondaryButton
              sx={{ width: '100px', border: '0px solid #415DF3', background: 'transparent' }}
              disabled={popupUrlsData.length === 3}
              onClick={() => handleAddUrl()}>
              Add
            </SecondaryButton>
          </div>
        ) : (
          ''
        )}

        <Stack spacing={1.5}>
          {popupUrlsData.map((item, index) => (
            <div className="url-section">
              <div className="card-header-wrap">
                <div className="heading">{`Post ${index + 1}`}</div>
                <div className="buttons-wrap">
                  {popupUrlsData?.length - 1 === index && popupUrlsData?.length < 3 ? (
                    <img
                      src={addIcon2}
                      alt="add"
                      style={{ cursor: 'pointer' }}
                      role="presentation"
                      className="addIcon"
                      onClick={() => handleAddUrl()}
                    />
                  ) : (
                    <div style={{ width: '6%' }} />
                  )}
                  <img
                    src={crossIcon2}
                    style={{ marginLeft: '12px', cursor: 'pointer' }}
                    alt="delete"
                    className="deleteIcon"
                    role="presentation"
                    onClick={() => handleDeleteUrl(index)}
                  />
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name={`popupUrlsData[${index}].title`}
                    label="Title"
                    type="text"
                    value={item.title}
                    inputProps={{
                      style: {
                        background: '#fff',
                      },
                    }}
                    placeholderValue="Enter title here"
                    onChange={(event) => handlePopupUrlField(event, index, 'title')}
                    error={tucPopupUrlsData[index]?.title && errPopupUrlsData[index]?.title}
                    helperText={tucPopupUrlsData[index]?.title && errPopupUrlsData[index]?.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name={`popupUrlsData[${index}].url`}
                    label="URL"
                    type="text"
                    value={item.url}
                    inputProps={{
                      style: {
                        background: '#fff',
                      },
                    }}
                    placeholderValue="Enter URL here"
                    onChange={(event) => handlePopupUrlField(event, index, 'url')}
                    error={tucPopupUrlsData[index]?.url && errPopupUrlsData[index]?.url}
                    helperText={tucPopupUrlsData[index]?.url && errPopupUrlsData[index]?.url}
                  />
                </Grid>
              </Grid>
              {/* <div className="title-wrap"></div>
              <div className="textfield-wrap"></div> */}
            </div>
          ))}
        </Stack>
      </div>
      <div className="footer-wrap">
        <SecondaryButton
          onClick={() => handleEmbedPopup()}
          sx={{
            width: 'auto',
            border: '0px solid #415DF3',
          }}>
          <span
            style={{
              textDecoration: 'underline',
              color: '#415DF3',
            }}>
            {`How to add your ${getTitleValue(postModalData?.mediaType)} post?`}
          </span>
        </SecondaryButton>
        <PrimaryButton
          onClick={() => popupHandleSubmit()}
          sx={{
            width: '160px',
            '@media screen and (max-width: 520px)': {
              width: 'auto',
            },
          }}>
          Save
        </PrimaryButton>
      </div>
    </>
  );

  const postGuideModalContent = () => (
    <>
      <Stack spacing={2.5} className="content_wrap">
        <Stack spacing={1}>
          <div className="heading">Method 1</div>
          <div className="desc">
            Open a post of your choice, copy the URL & paste it in the field provided for URL
          </div>
        </Stack>
        <Stack spacing={1}>
          <div className="heading">Method 2</div>
          <div className="desc">
            Go to the menu of the post of your choice, click on copy URL & paste it in the field
            provided for URL
          </div>
        </Stack>
      </Stack>
      <div className="footer-wrap">
        <PrimaryButton
          sx={{ width: '206px', fontWeight: '500' }}
          onClick={() => handleClosePostGuideDialoag()}>
          Okay, got it
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledSocialLink>
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Delete Social Link"
        dialogCss="logoutDialogWrap"
      />
      <BasicModal
        openDialog={openPostAddModal}
        closeDialog={handleClosePostAddDialoag}
        dialogContent={postAddModalContent()}
        noHeader={false}
        heading={
          <Stack className="head" spacing={1.5}>
            <div className="heading_pop_social">{`Add your ${getTitleValue(postModalData?.mediaType)} post`}</div>
            <div className="sub_heading_social">{`Select upto 3 ${getTitleValue(postModalData?.mediaType)} posts which you want to feature on your landing page.`}</div>
          </Stack>
        }
        dialogCss="social_post_content"
        closeButVisible
      />
      <BasicModal
        openDialog={openPostGuideModal}
        dialogContent={postGuideModalContent()}
        noHeader={false}
        heading={
          <Stack
            className="head"
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={1.5}
            width={'100%'}>
            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
              <ArrowBackIosNewRoundedIcon
                onClick={() => handleClosePostGuideDialoag()}
                sx={{ width: '20px', cursor: 'pointer' }}
              />
              <div className="heading_pop_social">{`How to add your ${getTitleValue(postModalData?.mediaType)} post?`}</div>
            </Stack>

            <img src={getIconByType(postModalData?.mediaType)} alt="" />
          </Stack>
        }
        dialogCss="social_post_how"
      />
      <div className="page-wrap">
        <div className="header-wrap">
          <div className="name-wrap">
            Social Links
            <CustomTooltip title="Provide your social links here" />
          </div>
          <SecondaryButton
            className="addButton"
            sx={{
              border: '0px solid #415DF3',
            }}
            disabled={!isEmptyExist() || socialLinks.length === 6}
            onClick={() => handleShowAdd()}>
            <img src={addIcon} alt="add" role="presentation" />
            Add
          </SecondaryButton>
        </div>
        <div className="content-wrap">{renderlist()}</div>
      </div>
      <LandingButtons
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={handleSubmit}
        disableSave={disableSaveBtn}
      />
    </StyledSocialLink>
  );
};

export default SocialLink;
