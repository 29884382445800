import { styled } from '@mui/material/styles';

const StyledLeftSection = styled('div')(
  ({ theme }) => `
  width: 100%;
  .left-section-wrap{
    text-align: left;
    @media screen and (max-width: 769px){
      width: 100%;
    }
    .subHeading{
      color: ${theme.palette.customColor.primaryBlack};
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.1px;
      @media screen and (max-width: 480px){
        font-size: 14px;
      }
    }
    .left-card-container{
      display: flex;
      width: 100%;
      margin-top: 64px;
      gap: 20px;
      @media screen and (max-width: 1250px){
        margin-top: 52px;
      }
      @media screen and (max-width: 480px){
        overflow-x: auto;
      }
      .left-card-wrap{
        max-width: 200px;
        width: 53%;
        @media screen and (max-width: 480px){
          max-width: unset;
          min-width: 200px;
        }
        .card-wrap{
          @media screen and (max-width: 480px){
            margin-top: 20px;
          }
        }
      }
    }
    .left-card-container::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    
  }
    `,
);

export default StyledLeftSection;
