import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)(
  ({ theme }) => `
&.dialogBox {
  backdrop-filter: blur(20px);
  .MuiDialog-paper {
    min-width: 500px;
    box-shadow: ${theme.palette.boxShadow.seventh} !important;
    border-radius: 10px !important;
    color: white;
    margin: 0px;
    @media screen and (max-width: 480px){
      min-width: unset;
      width: 95vw;
    }
    .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      padding: 24px !important;
      @media screen and (max-width: 480px){
        padding: 16px !important;
      }
      .dialog-title {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: Sora;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 480px){
          font-size: 18px;
        }
       }
       .crossIcon {
          cursor: pointer;
          display: flex;
          align-items: center;
       }
      }
    }
  }
  .MuiDialogContent-root{
    padding: 24px;
    padding-top: 0px;
    @media screen and (max-width: 480px){
      padding: 16px;
      padding-top: 0px;
    }
    .profileFormWrap{
      .wrapper-1{
        display: flex;
        column-gap: 40px;
        @media screen and (max-width: 480px){
          column-gap: 16px;
        }
        .profilePicWrap{
          position: relative;
          height: fit-content;
          @media screen and (max-width: 480px){
            width: 115px;
          }
          .profileImage{
            width: 100px;
            height: 100px;
            overflow: hidden;
            border-radius: 100px;
            object-fit: cover;
            border: 1.5px solid ${theme.palette.customColor.grey_6};
          }
          .fileUpload{
            display: none;
          }
          .changeProfile{
            position: absolute;
            width: 28px;
            bottom: 5px;
            right: 0px;
            cursor: pointer;
            border-radius: 100px;
            box-shadow: ${theme.palette.boxShadow.eight};
          }
        }
        .splitInput{
          width: 100%;
          @media screen and (max-width: 480px){
            flex-direction: column;
            gap: 0px;
          }
        }
      }
    }
  }
}
`,
);
export default StyledModal;
