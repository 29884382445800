/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLandingSuggestions = createAsyncThunk(
  'expertProfile/landingSuggestions',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.GET_LANDING_SUGGESTIONS, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchLandingPage = createAsyncThunk(
  'expertProfile/fetchLandingPage',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const { header, item } = data;
    const head = { timezone: header };
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.LANDING_PAGE, item, { headers: head });
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchLandingPageData = createAsyncThunk(
  'expertProfile/fetchLandingPageData',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.LANDING_PAGE, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchLandingPageDataByUserName = createAsyncThunk(
  'expertProfile/fetchLandingPageDataByUserName',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.LANDING_PAGE}/${data.username}`, {
        isVisited: !sessionStorage.getItem('isVisited')
          ? false
          : sessionStorage.getItem('isVisited') === 'false'
            ? true
            : true,
      });
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchUpdateService = createAsyncThunk(
  'expertProfile/fetchUpdateService',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.patch(`${API_PATH.UPDATE_SERVICE}/${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchDeleteService = createAsyncThunk(
  'expertProfile/fetchDeleteService',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.delete(`${API_PATH.UPDATE_SERVICE}/${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const linkToCalendar = createAsyncThunk(
  'expertProfile/linkToCalender',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.LINK_TO_CALENDAR}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchConnectAccountLink = createAsyncThunk(
  'expertProfile/fetchConnectAccountLink',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.CONNECT_ACCOUNT_LINK}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchDashboardStatistics = createAsyncThunk(
  'expertProfile/fetchDashboardStatistics',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.GET_DASHBOARD_STATISTICS}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchExpertSession = createAsyncThunk(
  'expertProfile/fetchExpertSession',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.GET_EXPERT_SESSION}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchSessionDetail = createAsyncThunk(
  'expertProfile/fetchSessionDetail',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.EXPERT_EVENT_DETAIL}${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSeekerSessionDetail = createAsyncThunk(
  'expertProfile/fetchSessionDetail',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.SESSION_EVENT_DETAIL}${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchExpertSessionCancel = createAsyncThunk(
  'expertProfile/fetchExpertSessionCancel',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.CANCEL_SESSION_EXPERT}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchDashboardSessions = createAsyncThunk(
  'expertProfile/fetchDashboardSessions',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(
        `${API_PATH.GET_SESSIONS_LIST_DASHBOARD}?limit=${data.limit}&page=${data.page}`,
        data.body,
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSeekerPaymentIntentCancel = createAsyncThunk(
  'expertProfile/fetchSeekerPaymentCancel',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.SESSION_PAYMENT_INTENT}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSeekerPaymentResponse = createAsyncThunk(
  'expertProfile/fetchSeekerPaymentResponse',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.PAYMENT_RESPONSE}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchCreateIssue = createAsyncThunk(
  'expertProfile/fetchCreateIssue',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.CREATE_ISSUE, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchBankDetails = createAsyncThunk(
  'expertProfile/getBankDetails',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.BANK_DETAILS, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const addAccountDetails = createAsyncThunk(
  'expertProfile/addAccountDetails',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.BANK_DETAILS}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const updateAccountDetails = createAsyncThunk(
  'expertProfile/updateAccountDetails',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.put(`${API_PATH.BANK_DETAILS}/${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const seekerRazorPayPaymentIntent = createAsyncThunk(
  'expertProfile/seekerPaymentIntent',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.SEKKER_PAYMENT_INTENT}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchDashBoardFeedbackList = createAsyncThunk(
  'expertProfile/fetchFeedbackList',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.POSTED_FEEDBACK, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchFeedbackList = createAsyncThunk(
  'expertProfile/fetchFeedbackList',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.EXPERT_FEEDBACK_LIST, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const addFeedbackSeeker = createAsyncThunk(
  'expertProfile/addFeedbackSeeker',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.POST_FEEDBACK}/${data.id}`, data.body);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const postFeedbackToDashboard = createAsyncThunk(
  'expertProfile/addFeedbackSeeker',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.POST_FEEDBACK_DASHBOARD}/${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const deletFeedbackExpert = createAsyncThunk(
  'expertProfile/addFeedbackSeeker',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.delete(`${API_PATH.DELETE_FEEDBACK}/${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const checkFeedbackExpert = createAsyncThunk(
  'expertProfile/addFeedbackSeeker',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.CHECK_FEEDBACK}${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

export {
  fetchLandingSuggestions,
  fetchLandingPage,
  fetchLandingPageDataByUserName,
  fetchLandingPageData,
  fetchUpdateService,
  fetchDeleteService,
  linkToCalendar,
  fetchConnectAccountLink,
  fetchExpertSession,
  fetchSessionDetail,
  fetchExpertSessionCancel,
  fetchDashboardStatistics,
  fetchSeekerSessionDetail,
  fetchSeekerPaymentIntentCancel,
  fetchSeekerPaymentResponse,
  fetchDashboardSessions,
  fetchCreateIssue,
  addAccountDetails,
  fetchBankDetails,
  updateAccountDetails,
  seekerRazorPayPaymentIntent,
  fetchFeedbackList,
  addFeedbackSeeker,
  fetchDashBoardFeedbackList,
  postFeedbackToDashboard,
  deletFeedbackExpert,
  checkFeedbackExpert,
};
