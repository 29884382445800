/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '../../../components/icons/InfoIcon';
import StyledReadExplore from './StyledReadExplore';
import TextField from '../../../components/TextField';
import deleteIcon from '../../../assets/svg/deleteIcon.svg';
import addMore from '../../../assets/svg/addMore.svg';
import { validationBooksCourcesSchema } from '../../../utils/validations/authValidation';
import LandingButtons from '../../../components/LandingButtons';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile/index';
import {
  fetchLandingPage,
  fetchLandingPageData,
} from '../../../store/reducers/expertProfile/apiThunk';
import Modal from '../../../components/Modal';

import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

const ReadExplore = ({ stepNumber = 0, setActiveStep }) => {
  const dispatch = useDispatch();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  const {
    userLandingData: {
      data: {
        readAndExplore: { books: fetchedBooks = [], courses: fetchedCourses = [] } = {},
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  const submitHandler = async (values) => {
    const { books, courses } = values;
    const data = {
      pageType: 4,
      readAndExplore: {
        books: [...books],
        courses: [...courses],
      },
    };
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: data,
    };

    await dispatch(fetchLandingPage(dataRequest));
    await dispatch(fetchLandingPageData());
    setDisableSaveBtn(true);
  };

  const formik = useFormik({
    initialValues: {
      books: [],
      courses: [],
    },
    validationSchema: validationBooksCourcesSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const { setFieldValue } = formik;
    const isCoursesDifferent = JSON.stringify(courses) !== JSON.stringify(fetchedCourses);
    setFieldValue('books', [...JSON.parse(JSON.stringify(fetchedBooks))]);
    setFieldValue('courses', [...JSON.parse(JSON.stringify(fetchedCourses))]);
  }, []);

  const {
    handleSubmit,
    values: { books = [], courses = [] } = {},
    touched: { books: tucBooks = [], courses: tucCourses = [] } = {},
    errors: { books: errBooks = [], courses: errCourses = [] } = {},
  } = formik;

  const handleChange = (e, type, index, name) => {
    setDisableSaveBtn(false);
    const { setFieldValue } = formik;
    switch (type) {
      case 'book':
        setFieldValue(`books[${index}][${name}]`, e.target.value);
        break;
      case 'course':
        setFieldValue(`courses[${index}][${name}]`, e.target.value);
        break;
      default:
        break;
    }
  };

  const isEmptyExist = (type) => {
    if (type === 'book') {
      const emptyBookEle = books.filter((item) => item.name === '');
      return emptyBookEle && emptyBookEle.length === 0;
    }
    const emptyCourseEle = courses.filter((item) => item.name === '');
    return emptyCourseEle && emptyCourseEle.length === 0;
  };

  const renderBookList = () => {
    const type = 'book';
    if (books && books.length) {
      return books.map((listItem, index) => (
        <Grid
          container
          position="relative"
          spacing={{ sm: 3, xs: 0 }}
          key={`${index}+'-'+${type}`}
          className="read-box">
          <Grid item xs={12} sm={3}>
            <TextField
              name={`books[${index}].name`}
              label="Book Name"
              type="text"
              placeholderValue="Write name here"
              value={listItem.name}
              onChange={(e) => handleChange(e, type, index, 'name')}
              error={tucBooks[index] && errBooks[index]?.name}
              helperText={tucBooks[index] && errBooks[index]?.name}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name={`books[${index}].url`}
              label="URL"
              type="text"
              placeholderValue="Enter URL here"
              value={listItem.url}
              onChange={(e) => handleChange(e, type, index, 'url')}
              error={tucBooks[index] && errBooks[index]?.url}
              helperText={tucBooks[index] && errBooks[index]?.url}
            />
          </Grid>
          <Grid item xs={12} sm={1} marginTop={{ sm: '28px', xs: '0' }}>
            <img
              src={deleteIcon}
              className="resp_position_delete"
              alt="delete"
              onClick={() => setOpenLogoutModal({ index, type })}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            marginY="auto"
            marginTop={{ xs: '10px', sm: 'auto' }}
            display={{ xs: 'grid', sm: 'none' }}>
            <SecondaryButton
              onClick={() => setOpenLogoutModal({ index, type })}
              sx={{
                justifyContent: 'center',
                border: '1px solid #e74c3c',
                '&:hover': {
                  backgroundColor: '#e74c3c',
                },
              }}>
              <img src={deleteIcon} width="30px" height="30px" alt="delete" role="presentation" />
              Delete
            </SecondaryButton>
          </Grid> */}
        </Grid>
      ));
    }
    return <div className="emptyMsg">Recommend your book here</div>;
  };

  const renderCourseList = () => {
    const type = 'course';
    if (courses && courses.length) {
      return courses.map((listItem, index) => (
        <Grid
          container
          position="relative"
          spacing={{ sm: 3, xs: 0 }}
          key={`${index}+'-'+${type}`}
          className="read-box">
          <Grid item xs={12} sm={3}>
            <TextField
              name={`courses[${index}].name`}
              label="Course Name"
              type="text"
              placeholderValue="Write name here"
              value={listItem.name}
              onChange={(e) => handleChange(e, type, index, 'name')}
              error={tucCourses[index]?.name && errCourses[index]?.name}
              helperText={tucCourses[index]?.name && errCourses[index]?.name}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name={`courses[${index}].url`}
              label="URL"
              type="text"
              placeholderValue="Enter URL here"
              value={listItem.url}
              onChange={(e) => handleChange(e, type, index, 'url')}
              error={tucCourses[index] && errCourses[index]?.url}
              helperText={tucCourses[index] && errCourses[index]?.url}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1}
            marginTop={{ sm: '28px', xs: '0' }}
            // display={{ xs: 'none', sm: 'grid' }}
          >
            <img
              src={deleteIcon}
              alt="delete"
              className="resp_position_delete"
              onClick={() => setOpenLogoutModal({ index, type })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            marginY="auto"
            marginTop={{ xs: '10px', sm: 'auto' }}
            display={{ xs: 'none', sm: 'none' }}>
            <SecondaryButton
              onClick={() => setOpenLogoutModal({ index, type })}
              sx={{
                justifyContent: 'center',
                border: '1px solid #e74c3c',
                '&:hover': {
                  backgroundColor: '#e74c3c',
                },
              }}>
              <img src={deleteIcon} alt="delete" width="30px" height="30px" role="presentation" />
              Delete
            </SecondaryButton>
          </Grid>
        </Grid>
      ));
    }
    return <div className="emptyMsg">Refer your course here</div>;
  };

  const handleAddNew = (type) => {
    setDisableSaveBtn(false);
    const { setFieldValue } = formik;
    switch (type) {
      case 'book':
        setFieldValue(`books[${books.length}]`, {
          id: 0,
          name: '',
          url: '',
        });
        break;
      case 'course':
        setFieldValue(`courses[${courses.length}]`, {
          id: 0,
          name: '',
          url: '',
        });
        break;
      default:
        break;
    }
  };

  const handleDelete = (index, type) => {
    setDisableSaveBtn(false);
    const { setFieldValue } = formik;
    switch (type) {
      case 'book': {
        const updateList = books.filter((item, bookIndex) => bookIndex !== index);
        setFieldValue('books', updateList);
        break;
      }
      case 'course': {
        const updateList = courses.filter((item, courseIndex) => courseIndex !== index);
        setFieldValue('courses', updateList);
        break;
      }
      default:
        break;
    }
  };

  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };

  const handleModalDelete = () => {
    handleDelete(openLogoutModal.index, openLogoutModal.type);
    setOpenLogoutModal(false);
  };

  const logoutModalContent = () => (
    <>
      <div>
        {`Are you sure you want to delete this ${openLogoutModal.type ? openLogoutModal.type : ''}?`}
      </div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleModalDelete()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseLogoutDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledReadExplore>
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading={`Delete ${openLogoutModal.type ? openLogoutModal.type : ''}`}
        dialogCss="logoutDialogWrap"
      />
      <div className="page-wrap">
        <div className="header-wrap">
          Read & Explore
          <CustomTooltip title="Provide the purchase link of your book/course" />
        </div>
        <div className="content-wrap">
          <Grid sx={{ mb: 3, borderBottom: '1px solid #E2E4E7', pb: '20px' }}>
            <div className="wrap-heading">
              <div>Recommend a Book</div>
              <SecondaryButton
                className="addButton"
                sx={{
                  border: '0px solid #415DF3',
                }}
                disabled={!isEmptyExist('book') || books.length === 5}
                onClick={() => handleAddNew('book')}>
                <img src={addMore} alt="add" role="presentation" />
                Add
              </SecondaryButton>
            </div>
            <div>{renderBookList()}</div>
          </Grid>
          <Grid>
            <div className="wrap-heading">
              <div>Refer a course</div>

              <SecondaryButton
                className="addButton"
                sx={{
                  border: '0px solid #415DF3',
                }}
                disabled={!isEmptyExist('course') || courses.length === 5}
                onClick={() => handleAddNew('course')}>
                <img src={addMore} alt="add" role="presentation" />
                Add
              </SecondaryButton>
            </div>
            <div>{renderCourseList()}</div>
          </Grid>
        </div>
      </div>
      <LandingButtons
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={handleSubmit}
        disableSave={disableSaveBtn}
      />
    </StyledReadExplore>
  );
};

export default ReadExplore;
