import { styled } from '@mui/material/styles';

const StyledBlogDetails = styled('div')(
  () => `
    .about-page-wrap{
      background: #fff;
      color: #1C1C1C;
      text-align: left;
      padding: 24px 0px;
      max-width: 846px;
      margin: 0px auto;
      
      .bread_text, .MuiBreadcrumbs-ol, .MuiBreadcrumbs-li .MuiTypography-root{
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      
      @media screen and (max-width: 900px){
        padding: 50px;
      }
      @media screen and (max-width: 600px){
        padding: 16px;
      }

      .blog_frame_img{
        aspect-ratio:7/4;
        width: 100%;
      }

      .heading_blog{
        color: var(--Black, #1C1C1C);
        font-family: Sora;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 900px){
          font-size: 24px;
        }
      }

      .blog_desc{
        color: var(--Black, #1C1C1C);
        font-family: "Plus Jakarta Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 161.111% */
        @media screen and (max-width: 900px){
          font-size: 16px;
          line-height: 26px;
        }
      }
       .chip_blog{
          padding: 4px 10px;
          border-radius: 15px;
          background: #F8F4F0;
          color:  #1C1C1C;
          font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .related_blogs_heading{
          color: var(--Black, #1C1C1C);
          text-align: center;
          font-family: Sora;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          @media screen and (max-width: 900px){
            font-size: 18px;
          }
        }
    }  
      
`,
);

export default StyledBlogDetails;
