/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/svg/logo.svg';
import StyledFooter from './StyledFooter';
import mailHomeIcon from '../../../assets/svg/mailHomeIcon.svg';
import LinkedinHomeIcon from '../../../assets/svg/LinkedinHomeIcon.svg';
import youtubeHomeIcon from '../../../assets/svg/youtubeHomeIcon.svg';
import twitterHomeIcon from '../../../assets/svg/twitterHomeIcon.svg';
import substackHomeIcon from '../../../assets/svg/substackHomeIcon.svg';

function Footer() {
  const navigate = useNavigate();
  return (
    <StyledFooter>
      <div className="page-footer-wrap">
        <div className="footer-header">
          <div className="header">
            <div className="subHeading">
              <img src={logo} className="logo" alt="Logo Icon" />
            </div>
            <div className="heading">
              Make the most of your time
              <br />
              and audience!
            </div>
            {/* <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </div> */}
            <div className="contact-mail">
              <div className="icon-wrap">
                <img src={mailHomeIcon} alt="Mail Icon" />
              </div>
              <div className="description-wrap">
                <a href="mailto:superconnectsco@gmail.com">superconnectsco@gmail.com</a>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="quicklinks-section-wrap">
              <div className="heading">Quick Links</div>
              <div className="content-wrap">
                <div>
                  <div className="links" onClick={() => navigate('/about')}>
                    About Us
                  </div>
                  <div className="links">Expert</div>
                  <div className="links">Seeker</div>
                  <div className="links left-disclose" onClick={() => navigate('/disclosure')}>
                    Disclosure
                  </div>
                </div>
                <div>
                  <div className="links" onClick={() => navigate('/terms-conditions')}>
                    Terms & Conditions
                  </div>
                  <div className="links" onClick={() => navigate('/privacy-policy')}>
                    Privacy Policy
                  </div>
                  <div className="links right-disclose" onClick={() => navigate('/disclosure')}>
                    Disclosure
                  </div>
                </div>
              </div>
            </div>
            <div className="connect-section-wrap">
              <div className="heading">Connect with us</div>
              <div className="icons">
                <a
                  href="https://www.linkedin.com/company/superconnects/"
                  target="_blank"
                  className="icons-links"
                  rel="noreferrer">
                  <img src={LinkedinHomeIcon} alt="Linkedin" />
                </a>
                <a
                  href="https://twitter.com/superconnectsco"
                  target="_blank"
                  className="icons-links"
                  rel="noreferrer">
                  <img src={twitterHomeIcon} alt="Twitter" />
                </a>
                <img src={youtubeHomeIcon} alt="Youtube" className="icons-links" />
                <img src={substackHomeIcon} alt="Substack" className="icons-links" />
              </div>
            </div>
          </div>
        </div>
        <div className="divideLine" />
        <div className="footer-content">&#169; 2024, SuperConnects. All Rights Reserved.</div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
