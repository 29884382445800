/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
// import { fetchUser } from '../../store/reducers/authentication/apiThunk';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import StyledSeekerCalendar from './StyledSeekerCalendar';
import WeeklyCalendar from './WeeklyCalendar';
import StyledHeader from '../../components/Home/Header/StyledHeader';
import logo from '../../assets/svg/logo.svg';
import { fetchSeekerExpertList } from '../../seekerStore/reducers/seekerProfile/apiThunk';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { updateLoading } from '../../seekerStore/reducers/loader';
import { fetchLandingPageDataByUserName } from '../../store/reducers/expertProfile/apiThunk';

const SeekerCalendar = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [events, setEvent] = useState([]);
  const [expertData, setExpertData] = useState({});
  const navigate = useNavigate();

  const dateCoverter = (dateEvent) => {
    const date = new Date(dateEvent);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const month = date.getUTCMonth();
    const newDate = new Date(year, month, day, hours, minutes);
    return newDate;
  };

  const landingData = async (id) => {
    const dataItem = { username: sessionStorage.getItem('expertUserName') };
    const response = await dispatch(fetchLandingPageDataByUserName(dataItem));
    const { payload: { status = false, data } = {} } = response;
    if (status) {
      const serviceData = data?.expertServices?.filter((item) => item._id === id);
      setExpertData({
        expert: data?.expert,
        expertServices: serviceData?.length > 0 ? serviceData[0] : {},
      });
      sessionStorage.setItem('expertEmail', data?.expert?.primaryEmail);
    }
  };
  const eventList = async () => {
    const email = sessionStorage.getItem('expertEmail');
    const id = sessionStorage.getItem('serviceid');
    const dataItem = {
      timeMin: moment(startDate).toISOString(),
      timeMax: moment(endDate).toISOString(),
      email,
      serviceId: id,
    };
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: dataItem,
    };
    const response = await dispatch(fetchSeekerExpertList(dataRequest));
    const { payload: { status = false, message = '', data = [] } = {} } = response;
    if (status) {
      dispatch(updateLoading(false));
      if (data?.length > 0) {
        setEvent(
          data?.map((item) => ({
            title: !item?.isAvailable ? 'Busy' : 'Available',
            start: dateCoverter(item?.start),
            end: dateCoverter(item?.end),
            isAvailable: item?.isAvailable,
          })),
        );
      } else {
        setEvent([]);
      }
      landingData(id);
    } else if (message) {
      dispatch(updateLoading(false));
      ToastNotifyError(message);
      navigate(-1);
      setEvent([]);
    }
  };
  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      eventList();
      sessionStorage.setItem('transactionSuccess', false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate === null && endDate === null) {
      setStartDate(moment(new Date()).startOf('week'));
      setEndDate(moment(new Date()).endOf('week'));
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('seekerVerified') === 'false' || sessionStorage.length === 0) {
      if (sessionStorage.getItem('expertUserName')) {
        navigate(`/${sessionStorage.getItem('expertUserName')}`);
      } else {
        navigate('/');
      }
    }
  }, []);
  return (
    <div>
      <StyledHeader>
        <Box
          className="page-header-wrap"
          sx={{
            background: '#F8F4F0',
            padding: '20px 30px',
            position: 'fixed',
            width: '100%',
            boxSizing: 'border-box',
            zIndex: 20,
          }}>
          <div className="header-content">
            <img src={logo} className="logoImage" alt="Logo" />
          </div>
          <div className="header-footer" />
        </Box>
      </StyledHeader>
      <StyledSeekerCalendar>
        <Grid container xs={12} justifyContent="space-between" alignItems="center" pt={6}>
          <Grid item xs={12} md={8} sm={12}>
            <Typography className="heading">Expert's availability</Typography>
            <Box className="heading_desc">
              Check expert's availability and schedule the best time for you.
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            pt={{ md: 0, xs: 2.5 }}
            gap={{ md: 1, xs: 2 }}
            direction={{ sm: 'row', xs: 'row' }}
            display="flex"
            justifyContent={{ md: 'flex-end', xs: 'flex-start' }}>
            <div className="slot-div">
              <div className="color-slot-available" />
              <div>Available</div>
            </div>
            <div className="slot-div">
              <div className="color-slot-busy" />
              <div>Busy</div>
            </div>
          </Grid>
        </Grid>
        <WeeklyCalendar
          startDate={setStartDate}
          endDate={setEndDate}
          events={events}
          type="schedule-seeker"
          expertDetail={expertData}
          landingData={landingData}
        />
      </StyledSeekerCalendar>
    </div>
  );
};

export default SeekerCalendar;
