import React from 'react';
import Slider from 'react-slick';
import StyledRightSection from './StyledRightSection';
import expertImage from '../../../assets/images/expertImage.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function RightSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0px',
    arrows: false,
  };
  return (
    <StyledRightSection>
      <div className="right-section-wrap">
        <Slider {...settings}>
          <div>
            <img src={expertImage} className="expertImage" alt="Expert" />
          </div>
          <div>
            <img src={expertImage} className="expertImage" alt="Expert" />
          </div>
          <div>
            <img src={expertImage} className="expertImage" alt="Expert" />
          </div>
        </Slider>
      </div>
    </StyledRightSection>
  );
}
