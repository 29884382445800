import { styled } from '@mui/material/styles';

const StyledSocialLink = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.customColor.black};
    // border: 0px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
      border-radius: 12px;
      background-color:transparent;
    @media screen and (max-width: 480px){
      border-radius: 0px;
    }
    .header-wrap{
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      padding: 24px 24px 30px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 600px){
        padding: 16px 16px 16px 24px;
      }
      .name-wrap{
        display: flex;
        align-items: center;
      }
      .addButton {
        padding: 0px;
        cursor: pointer;
        width: 90px;
      }
    }
    .content-wrap{
      padding: 0px 24px 30px 24px;
      background: ${theme.palette.customColor.primaryWhite};
      @media screen and (max-width: 600px){
        padding: 0px;
      }
      .no_content_social{
        font-size: 16px;
        color: ${theme.palette.customColor.primaryBlack}; 
      }
      .card-wrap{
        @media screen and (max-width: 600px){
          padding: 0px 16px 16px 16px;
        }
        .socialDelete{
          width: 30px;
          height: 30px;
        }
      }
    .MuiListItemIcon-root{
      min-width: 0px;
      img{
        width: 22px;
      }
    }
    .selectIcon{
      width: 25px;
      // margin-left: 8px;
    }
    
    .urlField{
      .MuiInput-input {
        color: ${theme.palette.customColor.stateLinks};
      }
    }
  }
}
   .makeStyles-placeholder-5{
    color: #757575 !important;
  }
  `,
);

export default StyledSocialLink;
