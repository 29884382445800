import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../assets/svg/logo.svg';
import google from '../../assets/svg/google.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import StyledLoginPage from './StyledLoginPage';
import { CONSTANTS } from '../../constants/constants';
import { updateLoading } from '../../store/reducers/loader';
import { fetchLogin, fetchGetProfile } from '../../store/reducers/authentication/apiThunk';
import { logout, selectorAuthentication } from '../../store/reducers/authentication';
import { ROUTE_PATH } from '../../constants/route';
import Modal from '../../components/Modal';
import { PrimaryButton } from '../../components/Button';
import loginBg from '../../assets/images/login-bg.png';

const { LANDING, SIGNUP, DASHBOARD } = ROUTE_PATH;

const {
  GOOGLE_CALENDAR_SCOPE,
  LOGIN_TYPE: { GOOGLE, LINKEDIN },
} = CONSTANTS;

function Login() {
  const dispatch = useDispatch();
  const { auth } = useSelector(selectorAuthentication);
  const naivgate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };

  const handleOpenLogoutDialoag = () => {
    setOpenLogoutModal(true);
  };
  useEffect(() => {
    if (localStorage.getItem('auth') && !auth?.data?.isAlreadyExist) {
      naivgate(SIGNUP);
    } else if (localStorage.getItem('auth') && auth?.data?.isAlreadyExist) {
      dispatch(fetchGetProfile());
      naivgate(DASHBOARD);
    }
  }, [auth]);

  const handleSuccess = async (codeResponse) => {
    const hasCalendarAccess = codeResponse.scope.includes(
      'https://www.googleapis.com/auth/calendar',
    );
    const payload = {
      type: GOOGLE,
      authCode: codeResponse.code,
    };
    if (hasCalendarAccess) {
      const loginResponse = await dispatch(fetchLogin(payload)).then((val) => val);
      console.log('loginResponse', loginResponse);
    } else {
      handleOpenLogoutDialoag();
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      dispatch(updateLoading(false));
      const payload = {
        type: LINKEDIN,
        authCode: code,
      };
      dispatch(fetchLogin(payload));
    },
    onError: (error) => {
      dispatch(updateLoading(false));
      console.log('error', error);
    },
  });

  const handleLogin = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: 'auth-code',
    scope: GOOGLE_CALENDAR_SCOPE,
  });

  const initiateLogin = (type) => {
    dispatch(logout());
    switch (type) {
      case 'google':
        handleLogin();
        break;
      case 'linkedin':
        linkedInLogin();
        break;
      default:
        break;
    }
  };

  const logoutModalContent = () => (
    <>
      <div>
        You need to provide permission to your calendar by selecting the check box in the google
        calendar pop-up. Please try again and make sure to select that checkbox.
      </div>
      <div className="logout-button-wrap">
        <PrimaryButton
          sx={{
            width: '150px',
            '@media screen and (max-width: 768px)': {
              width: '17vw',
            },
          }}
          onClick={() => handleCloseLogoutDialoag()}>
          Okay
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledLoginPage>
      <div className="page-wrap">
        <div className="page-left-wrap">
          <img src={loginBg} alt="" srcSet="" className="loginBg" />
        </div>
        <div className="page-right-wrap">
          <div className="page-right-content">
            <div className="logo-wrap">
              <Link to={LANDING}>
                <img src={logo} alt="" className="logoImage" />
              </Link>
            </div>
            <div className="title">Continue using</div>
            <div className="subTitle">Get started with Superconnects</div>
            <div
              className="loginButton"
              onClick={() => initiateLogin('linkedin')}
              role="presentation">
              <img className="btn-icon" src={linkedin} alt="" />
              Continue with LinkedIn
            </div>
            <div
              className="loginButton"
              onClick={() => initiateLogin('google')}
              role="presentation">
              <img className="btn-icon" src={google} alt="" />
              Continue with Google
            </div>
          </div>
        </div>
      </div>
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Permission required!"
        dialogCss="logoutDialogWrap"
      />
    </StyledLoginPage>
  );
}

export default Login;
