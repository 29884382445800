import { styled } from '@mui/material/styles';

const StyledIconsCard = styled('div')(
  ({ theme }) => `
  .card-wrap{
      border-radius: 20px;
      box-shadow: 0px 10px 2left-card-container3px 0px rgba(0, 0, 0, 0.06);
      background: ${theme.palette.secondary.main};
      display: flex;
      flex-direction: column;
      padding: 20px;
      .icon-wrap{
          margin-top: -35px;
          margin-left: -10px;
          border-radius: 30px;
          background: var(--White, #FFF);
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.05);
          width: fit-content;
          padding: 7px;
          margin-bottom: 13px;
      }
      .card-description{
          color: ${theme.palette.customColor.primaryBlack};
          font-weight: 400;
          font-size: 16px;
      }
  }
    `,
);

export default StyledIconsCard;
