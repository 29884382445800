import { styled } from '@mui/material/styles';

const StyledAbout = styled('div')(
  ({ theme }) => `
  .about-page-wrap{
    padding:24px;
    .about-header-wrap{
      color: ${theme.palette.customColor.primaryBlack};
      font-family: Sora;
      font-size: 32px;
      font-weight: 700;
      line-height: 40.32px;
      text-align: left;
      span{
        color: ${theme.palette.primary.main};
      }
      @media screen and (max-width: 600px){
        font-size: 24px;
      }
    }
    .about-content-wrap{
      margin-top:24px;
      text-align: left;
      color: ${theme.palette.customColor.primaryBlack};
      font-family: Plus Jakarta Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      white-space-collapse: break-spaces;
      overflow-wrap: break-word;
      @media screen and (max-width: 600px){
        font-size: 14px;
        margin-top:16px;
      }
      overflow-y: auto;

      height: calc(100vh - 10.18vh - 11.54vh - 5.98vh - 6.33vh - 102px);

      @media (min-width: 900px) and  (max-height: 800px) {
        height: calc(100vh - 10.18vh - 10.54vh - 152px);
      }

      @media screen and (max-width: 900px){
        height: calc(100vh - 5.20vh - 8.38vh - 2.27vh - 1.81vh - 112px);
      }

      @media screen and (max-width: 600px){
        height: calc(100dvh - 262px); 
      }
    }
    @media screen and (max-width: 600px){
        padding:16px 16px 32px 16px;
      }
}
  `,
);

export default StyledAbout;
