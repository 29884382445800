import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StyledLandingPage, { ColorlibConnector, ColorlibStepIconRoot } from './StyledLandingPage';
import PrivateLayout from '../../layout/PrivateLayout';
import { SecondaryButton } from '../../components/Button';
import { selectorExpertProfile } from '../../store/reducers/expertProfile';
import stepChecked from '../../assets/svg/stepChecked.svg';

import Create from './Create';
import {
  fetchLandingSuggestions,
  fetchLandingPageData,
  fetchBankDetails,
} from '../../store/reducers/expertProfile/apiThunk';
import { fetchGetProfile } from '../../store/reducers/authentication/apiThunk';

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    // 5: 5,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <img src={stepChecked} alt="" /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Information', 'Add Services', 'Social Links', 'Read & Explore'];

const Landing = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const {
    userLandingData: {
      data: {
        bio: fetchedBio = '',
        whoAmI: fetchedWhoAmI = '',
        expertise: fetchedExpertise = '',
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  const handleStepChange = (step) => {
    if (fetchedBio !== '' || fetchedWhoAmI !== '' || fetchedExpertise !== '') {
      setActiveStep(step);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLandingSuggestions());
    dispatch(fetchLandingPageData());
    dispatch(fetchGetProfile());
    dispatch(fetchBankDetails());
  }, []);

  return (
    <StyledLandingPage>
      <Grid container spacing={2} className="page-header-wrap" justifyContent="space-between">
        <Grid item xs={12} sm={10}>
          <div className="stepperWrap">
            <div className="title">
              Please fill the required information to create your landing page.
            </div>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => handleStepChange(index)}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </div>
        </Grid>
        <Grid
          container
          xs={2}
          xl={1.3}
          lg={2}
          marginLeft={{ xs: '16px', sm: '-16px' }}
          className="previewButton-wrap"
          spacing={2}
          alignItems="center">
          <SecondaryButton
            sx={{
              width: '150px',
              background: 'transparent',
              border: '1px solid #415DF3',
              borderRadius: '10px',
              '@media (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => {
              sessionStorage.setItem('isVisited', true);
              navigate('/preview');
            }}
            disabled={fetchedWhoAmI === ''}>
            Preview
          </SecondaryButton>
        </Grid>
      </Grid>
      <Create stepNumber={activeStep} setActiveStep={setActiveStep} />
    </StyledLandingPage>
  );
};

export default PrivateLayout(Landing);
