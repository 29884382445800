/* eslint-disable import/prefer-default-export */
const REGEX = {
  EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
  PASSWORD_PATTERN:
    /(?=[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,16}).*$/, // Passwords must be Include at least 1 lowercase letter, 1 capital letter, 1 number,  1 special character => !@#$%^&*
  USERNAME_PATTERN: /^[a-zA-Z][a-zA-Z0-9_-]{2,20}$/,
  FIRSTNAME_PATTERN: /^[a-zA-Z0-9\s]+$/,
  URL_PATTERN: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/,
  SOCIALMEDIALINKS:
    /^https?:\/\/(www\.)?(linkedin\.com\/in\/[a-zA-Z0-9_-]+|x\.com\/[a-zA-Z0-9_]+|github\.com\/[a-zA-Z0-9_-]+|dribbble\.com\/[a-zA-Z0-9_-]+)\/?$/,
};

const CONSTANTS = {
  GOOGLE_CALENDAR_SCOPE:
    'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile',
  LOGIN_TYPE: {
    GOOGLE: 1,
    LINKEDIN: 2,
  },
  ALLOWED_EXTENSION: ['png', 'jpeg', 'webp', 'jpg'],
  MB_1: 5048576,
  SOCIALLINKS: {
    YOUTUBE: 1,
    TWITTER: 2,
    WHATSAPP: 3,
    TELEGRAM: 4,
    SUBSTACK: 5,
    LINKEDIN: 6,
  },
};

const tempAccordion = [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptater. ',
  },
];

export { REGEX, CONSTANTS, tempAccordion };
