import { styled } from '@mui/material/styles';

const StyledLoader = styled('div')(
  () => `
.backdrop{
    color: {};
    z-index: 99999999;
    .loader{
        color: #415DF3;
    }
}
`,
);

export default StyledLoader;
