import { styled } from '@mui/material/styles';

const StyledPreviewPage = styled('div')(
  ({ theme }) => `
  .content-wrap{
    margin: auto;
    border-radius: 24px;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.06), 0px 0px 23px 0px rgba(0, 0, 0, 0.06);

    .name_container{
      display:flex;
      justify-content:center;
      background:#CDD2F0;
      border-radius: 24px 24px 0px 0px;
      height: 16.97vh;
      
    }

    .user_details_wrap{
      box-sizing: border-box;
      background:#fff;
      border-radius:0px 0px 24px 24px;
      position: relative;
      padding: 14.72vh 0 4.98vh 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: calc(100vh - 16.97vh - 11.54vh - 10.18vh - 4.98vh - 6.33vh);

      @media screen and (max-width: 900px){
        
        height: calc(100vh - 16.97vh - 13.57vh - 2.27vh - 1.81vh);
      }

      @media (min-width: 900px) and  (max-height: 800px) {
        height: calc(100vh - 16.97vh - 13.57vh - 2.27vh - 1.81vh - 40px - 10px);
      }

      @media screen and (max-width: 600px){
        padding:75px 0px 16px 0px;
        height: calc(100dvh - 276px);  // 120+20+16+ 120
      }
    }
    
    .profile-pic-wrap{
      background:#fff;
      position: absolute;
      top: -3.4vh;
      left: calc(50% - 8.5vh);
      width: fit-content;
      border-radius: 80px;    
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;  

      .profile-pic{
        width: 16.97vh;
        height: 16.97vh;
        border-radius: 80px;
        @media screen and (max-width: 600px){
          width: 80px;
          height: 80px;
        }
      }
      @media screen and (max-width: 600px){
          left: calc(50% - 44px);
      }
    }

    .profile_speaker_tag{
      background:#F8F4F0;
      position: absolute;
      bottom:2px;
      right:-17px;
      padding: 7px 12px;
      border-radius: 50%;
      border: 5px solid var(--White, #FFF);
      height: 20px;
      img{
        width: 20px;
        height: 20px;
      }
      @media screen and (max-width: 600px){
        bottom:0px;
        right:-8px;
        padding: 5px 6px;
        border-radius: 50%;
        border: 3.5px solid var(--White, #FFF);
        height: 20px;
        img{
          width: 14px;
          height: 14px;
        }
      }
    }

    
    .hi_img_arrow{
      margin-left:-42px;
      position:absolute;
      right:-98px;
      top:0;
    }
    .wing_svg{
        position: absolute;
        left: -2.5vw;
        top: 2.5vh;
    }
    @media screen and (max-width: 600px){
      .name_container{
        height: 120px;
      }
      .hi_img_arrow{
        right:-80px;
        top: -5px;
        img{
          width:100px;
          height:80px;
        }
      }
      .wing_svg{
        left: -12px;
        img{
        width:15px;
        height:17px;
        }
      }
    }
    .name{
      // color:${theme.palette.customColor.primaryBlack};
      margin: auto 0px;
      img{
      width: 288px;
      height: 55px;
      @media screen and (max-width: 600px){
        width:177px;
        height: 34px;
      }

      }
      display:flex;
      align-items:center;

      @media screen and (max-width: 900px){
        font-size: 28px;    
      }
      
    }

    
    .user_full_name{
      color:  #1C1C1C;
      font-family: Sora;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .expertise{
      margin-top:6px;
      color: #415DF3;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      @media screen and (max-width: 600px){
        font-size: 18px;
      }
    }
    .bio{
      padding:0px 16px;
      margin-top:2.71vh;
      max-width:670px;
      color: #525252;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      overflow-y: auto;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      word-break: break-word;

      @media screen and (max-width: 900px){
        margin-top:16px;
      }
    }

    .socialIcons{
      margin-top:auto;
      display:flex;
      justify-content:center;
      
      div{
        display:flex;
        align-items:center;
        gap:16px;

        img{
          box-shadow: 0px 0px 23px 0px #0000000F;
          border-radius:50%;
        }
      }
    }

    .button_wrap_user{
      display:flex;
      gap:15px;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
      justify-content: center;
    }
    
  }
  `,
);

export default StyledPreviewPage;
