import { styled } from '@mui/material/styles';

const StyledChart = styled('div')(
  () => `
  width: 100%;
  max-width: 330px;
    @media screen and (max-width : 1000px) {
        width: 80%;
    }
    @media screen and (max-width : 480px) {
        width: 100%;
    }
  .chartDiv{
    width: 100%;
}

`,
);

export default StyledChart;
