/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import './styles.css';
import './style.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, TrinaryButton } from '../../components/Button';
import {
  fetchSeekerPaymentIntentCancel,
  // fetchSeekerPaymentResponse
} from '../../store/reducers/expertProfile/apiThunk';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import ConfirmationForm from './confirmationForm';

export default function CheckoutForm() {
  // const stripe = useStripe();
  // const elements = useElements();
  // const dispatch = useDispatch();
  // const [cardComplete, setCardComplete] = useState({
  //   cardNumber: false,
  //   cardExpiry: false,
  //   cardCvc: false,
  // });
  // const [error, setError] = useState(null);
  // const [finalStatus, setfinalStatus] = useState(0);
  // const [finalTransaction, setfinalTransaction] = useState('');
  // const [transactionSuccess, settransactionSuccess] = useState(
  //   sessionStorage.getItem('transactionSuccess'),
  // );
  // const [timeSet, setTimeSet] = useState(false);
  // const navigate = useNavigate();
  // function handleCardElementOnChange(e) {
  //   setError(null);
  //   setCardComplete({ ...cardComplete, [e.elementType]: e.complete });
  // }

  // const closeQuickView = () => {
  //   if (sessionStorage.getItem('transactionSuccess') === 'true') {
  //     sessionStorage.setItem('transactionSuccess', 'false');
  //     navigate(`/${sessionStorage.getItem('expertUserName')}`);
  //   } else {
  //     navigate('/schedule');
  //   }
  // };

  // const handleBackButton = (event) => {
  //   // if (event?.type === 'popstate') {
  //   closeQuickView();
  //   // }
  // };

  // useEffect(() => {
  //   setTimeSet(true);
  //   sessionStorage.setItem('transactionBack', true);
  //   window.history.pushState('fake-route', document.title, window.location.href);
  //   window.onpopstate = handleBackButton;
  //   // Remove the event listener when the component is unmounted
  //   return () => {
  //     window.onpopstate = null;
  //   };
  // }, []);

  // const finalResponse = async (id) => {
  //   const dataSelect = {
  //     transactionId: id,
  //   };
  //   const response = await dispatch(fetchSeekerPaymentResponse(dataSelect)).then((val) => val);
  //   const {
  //     payload: {
  //       data, status, message,
  //     } = {},
  //   } = response;
  //   if (status) {
  //     setfinalStatus(data?.status);
  //     sessionStorage.setItem('transactionSuccess', false);
  //     settransactionSuccess('false');
  //   } else {
  //     ToastNotifyError(message);
  //   }
  // };

  // const payMoney = async (e) => {
  //   e.preventDefault();
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardNumberElement);

  //   // Validate the card details before creating a payment method.
  //   if (!cardElement || !cardElement._complete) {
  //     setError('Please enter valid card information.');
  //     return;
  //   }

  //   const dataItem = {
  //     stripeCustomerId: sessionStorage.getItem('customerID'),
  //     // razorpayCustomerId: sessionStorage.getItem('customerID'),
  //     sessionId: sessionStorage.getItem('sessionId'),
  //   };
  //   const response = await dispatch(fetchSeekerPaymentIntentCancel(dataItem)).then((val) => val);
  //   const { payload: { data, status, message } = {} } = response;
  //   if (status) {
  //     await stripe
  //       .confirmCardPayment(data?.clientSecret, {
  //         payment_method: {
  //           card: cardElement,
  //         },
  //       })
  //       .then((result) => {
  //         // Handle result.error or result.paymentIntent
  //         setError(null);
  //         sessionStorage.setItem('transactionStatus', result?.paymentIntent?.status);
  //         ToastNotifySuccess('Payment method created successfully');
  //         // finalResponse(data?.transactionId);
  //         setfinalTransaction(data?.transactionId);
  //         setfinalStatus(result?.paymentIntent?.status);
  //         sessionStorage.setItem('transactionSuccess', false);
  //         sessionStorage.setItem('transactionId', data?.transactionId);
  //         settransactionSuccess('false');
  //         // window.location.reload();
  //         // Payment method created successfully.
  //         // You can proceed with making the API call to your server to handle payment.
  //       })
  //       .catch((err) => {
  //         setError(err.message);
  //       });
  //   } else {
  //     ToastNotifyError(message);
  //   }
  // };
  // const cancelBut = () => {
  //   sessionStorage.setItem('transactionSuccess', false);
  //   navigate('/schedule');
  // };
  return (
    <>
      {/* {transactionSuccess === 'true' ? (
        <div className="container">
          <div className="bodySubscribe">
            <div className="insideBodySubscribe">
              <div className="sessionText">
                <div className="sessionHeading">Book a Session</div>
                <div className="sessionPrice">
                  {`To avail the service by the expert, complete your booking by paying ${sessionStorage.getItem('seekerUnit')} ${sessionStorage.getItem('serviceprice')}`}
                </div>
                <div className="sessionTC">
                  {'Refer to '}
                  <a style={{ color: 'white', textDecoration: 'none' }} href="/">
                    T&C
                  </a>
                  {' for cancellation policy'}
                </div>
              </div>
              <form onSubmit={payMoney}>
                <div className="stripe">
                  <h6 className="cardtextData">Card Number </h6>
                  <CardNumberElement
                    className="cardTextField"
                    id="cardNumber"
                    onChange={handleCardElementOnChange}
                    options={{
                      onBlur: () => setError(null),
                      onChange: (event) => {
                        if (event.error) {
                          setError(event.error.message);
                        } else {
                          setError(null);
                        }
                      },
                    }}
                  />
                  <div className="row cardInfo">
                    <div className="cardCol">
                      <h6 className="cardtextData">Expiry Date </h6>
                      <CardExpiryElement
                        className="cardTextField"
                        id="expiry"
                        onChange={handleCardElementOnChange}
                        options={{
                          onBlur: () => setError(null),
                          onChange: (event) => {
                            if (event.error) {
                              setError(event.error.message);
                            } else {
                              setError(null);
                            }
                          },
                        }}
                      />
                    </div>
                    <div className="cardCol ccv">
                      <h6 className="cardtextData">CVV </h6>
                      <CardCvcElement
                        className="cardTextField"
                        id="cvc"
                        onChange={handleCardElementOnChange}
                        options={{
                          onBlur: () => setError(null),
                          onChange: (event) => {
                            if (event.error) {
                              setError(event.error.message);
                            } else {
                              setError(null);
                            }
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {error && <p style={{ color: 'red', fontSize: '12px' }}>{error}</p>}
                <div className="sessionInfo">
                  By providing your card information, you allow SuperConnects to charge your card
                  for payments in accordance with their terms
                </div>
                <div className="button-strip">
                  <TrinaryButton className="secondary-stripe" onClick={cancelBut}>
                    Cancel
                  </TrinaryButton>
                  <PrimaryButton
                    className="primary-stripe"
                    style={{ width: '100%' }}
                    onClick={payMoney}
                    disabled={!stripe}>
                    Proceed to pay
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        )} */}
      <ConfirmationForm />
    </>
  );
}
