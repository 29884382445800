import React from 'react';
import { Box } from '@mui/material';
import StyledFrame from './StyledFrame';
import policyFrame from '../../assets/images/policyFrame.png';
import hiWing from '../../assets/svg/hiWingRight.svg';

const BlogsHeadingFrame = () => (
  <StyledFrame>
    <Box>
      <img src={policyFrame} alt="" width="100%" className="image_frame" />
    </Box>
    <Box className="heading_container">
      <Box className="heading_main">
        {'Our '}
        <span>Blogs</span>
        <Box className="wing_container">
          <img src={hiWing} alt="" />
        </Box>
      </Box>
      <Box className="sub_heading_main" width={{ xs: '100%', md: '640px' }} mt={{ xs: 1.5, sm: 2 }}>
        Discover compelling insights, expert tips, and inspiring stories in our blog. Dive into a
        world of knowledge with Superconnects.
      </Box>
    </Box>
  </StyledFrame>
);

export default BlogsHeadingFrame;
