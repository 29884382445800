import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import BlogsCard from '../../../components/Common/BlogsCard/BlogsCard';
import sliderLeftArrow from '../../../assets/svg/slider-leftArrow.svg';
import sliderRightArrow from '../../../assets/svg/slider-rightArrow.svg';
import './StyledSlider.scss';
import { updateLoading } from '../../../store/reducers/loader';

export default function BlogsSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0px',
    arrows: true,
    nextArrow: <img src={sliderRightArrow} alt="sliderRightArrow" />,
    prevArrow: <img src={sliderLeftArrow} alt="sliderLeftArrow" />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
          centerPadding: '20px',
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          swipeToSlide: true,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  const [blogsData, setBlogsData] = useState([]);

  const getPosts = async (limit = 10) => {
    dispatch(updateLoading(true));
    const API_URL = process.env.REACT_APP_BLOG_URL;
    const CONTENT_API_KEY = process.env.REACT_APP_BLOG_KEY;
    try {
      const response = await axios.get(`${API_URL}/ghost/api/v3/content/posts`, {
        params: {
          key: CONTENT_API_KEY,
          limit,
        },
      });
      if (response.status === 200) {
        setBlogsData(response?.data?.posts);
      }
    } catch (error) {
      console.error('Error fetching posts', error);
    }
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <Slider {...settings}>
      {blogsData &&
        blogsData.map((item) => (
          <BlogsCard
            img={item.feature_image}
            title={item.title}
            description={item.excerpt}
            date={item.published_at}
            time={item.reading_time}
            id={item.id}
          />
        ))}
    </Slider>
  );
}
