const DownArrow = (props) => (
  <svg width="34" {...props} height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2819_10521)">
      <path d="M33.2439 16.8111C33.2439 7.98059 26.0189 0.755589 17.1884 0.755589C8.35781 0.755589 1.13281 7.98059 1.13281 16.8111C1.13281 25.6417 8.35781 32.8667 17.1884 32.8667C26.0189 32.8667 33.2439 25.6417 33.2439 16.8111Z" fill="#F4F4F4" />
      <path d="M17.6666 19.9185L22.3333 15.1685C22.6666 14.8351 22.6666 14.3351 22.3333 14.0018C22 13.6685 21.5 13.6685 21.1666 14.0018L17 18.0851L12.8333 14.0018C12.5 13.6685 12 13.6685 11.6666 14.0018C11.5 14.1685 11.4166 14.3351 11.4166 14.5851C11.4166 14.8351 11.5 15.0018 11.6666 15.1685L16.3333 19.9185C16.75 20.2518 17.25 20.2518 17.6666 19.9185C17.5833 19.9185 17.5833 19.9185 17.6666 19.9185Z" fill="#1C1C1C" />
    </g>
    <defs>
      <clipPath id="clip0_2819_10521">
        <rect width="34" height="34" fill="white" transform="translate(0 34) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
);

export default DownArrow;
