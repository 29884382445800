/* eslint-disable max-len */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import PreviewLayout from '../Layout';
import CardsLayout from '../Layout/CardsLayout';
import StyledServices from './StyledServices';
import { PrimaryButton } from '../../../components/Button';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import Modal from '../../../components/Modal';
import TextInput from '../../../components/TextField';
import OtpModal from '../../../components/OtpModal';
import { validationEmailSchema } from '../../../utils/validations/authValidation';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { fetchSendOtp } from '../../../seekerStore/reducers/seekerProfile/apiThunk';
import timer from '../../../assets/svg/timer.svg';
import lang from '../../../constants/lang';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

const Services = () => {
  const dispatch = useDispatch();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [seekerVerify, setseekerVerify] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const params = useParams();

  const {
    EMAIL_PLACEHOLDER,
    SEND_OTP,
    SERVICES,
    BOOK_NOW,
    EMAIL_VERIFICATION_HEADING,
    EMAIL_VERIFICATION_SUBHEADING,
    EMAIL_LABEL,
    EMAIL_NAME,
  } = lang;

  const { userLandingData: { data: { expertServices = [] } = {} } = {} } =
    useSelector(selectorExpertProfile);

  const handleCloseDialoag = () => {
    setOpenEmailModal(false);
    sessionStorage.setItem('seeker', '');
  };

  const submitHandler = async (value) => {
    const verifyOtpRequest = {
      primaryEmail: value.email,
      type: 3,
    };
    const response = await dispatch(fetchSendOtp(verifyOtpRequest));
    const { payload: { status = false, message = '' } = {} } = response;
    if (status) {
      sessionStorage.setItem('seeker', JSON.stringify(value.email));
      sessionStorage.setItem('transactionSuccess', false);
      ToastNotifySuccess(message);
      setOtpModal(true);
      setseekerVerify(true);
      handleCloseDialoag();
    } else {
      sessionStorage.setItem('seeker', '');
    }
  };

  const formik = useFormik({
    initialValues: {
      email: emailValue,
    },
    validationSchema: validationEmailSchema,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: { email } = {},
    touched: { email: tucEmail = false } = {},
    errors: { email: errEmail } = {},
  } = formik;

  const handleEmailChange = (event, name) => {
    setEmailValue(event.target.value);
    formik.setFieldValue(name, event.target.value.trim());
  };

  const emailModalContent = () => (
    <form onSubmit={handleSubmit}>
      <div className="email-content">
        <div className="m-bottom-15">
          <TextInput
            name={EMAIL_NAME}
            type={EMAIL_NAME}
            placeholder={EMAIL_PLACEHOLDER}
            onChange={(e) => handleEmailChange(e, 'email')}
            value={email}
            error={tucEmail && !!errEmail}
            helperText={tucEmail && errEmail}
            id=""
            important
            label={EMAIL_LABEL}
          />
        </div>
        <div>
          <PrimaryButton type="submit">{SEND_OTP}</PrimaryButton>
        </div>
      </div>
    </form>
  );

  return (
    <StyledServices>
      <div className="service-page-wrap">
        <div className="service-header-wrap">{SERVICES}</div>
        <div className="service-content-wrap">
          {expertServices.length === 0 ? (
            <div className="noServiceTitle">No services added yet</div>
          ) : (
            expertServices.map(
              (
                {
                  serviceName = '',
                  description = '',
                  price = '',
                  // unit = '',
                  sessionDuration = '',
                  _id = '',
                },
                index,
              ) => (
                <div
                  className={`service-card-wrap ${index < expertServices.length - 1 ? 'customMarginBottom' : ''}`}>
                  <div className="card-left-wrap">
                    <Box display="flex" justifyContent="space-between">
                      <div className="serviceName">{serviceName}</div>
                      <Box display={{ sx: 'flex', sm: 'none' }} className="price">
                        {price === 0 ? 'free' : `${'₹ '}${price}`}
                      </Box>
                    </Box>
                    <CustomTooltip title={description}>
                      <div className="card-description">{description}</div>
                    </CustomTooltip>
                    <Box display="flex" justifyContent="space-between">
                      <div className="sessionDuration">
                        <img src={timer} alt="" className="timerImage" />
                        {`${sessionDuration} minutes`}
                      </div>
                      <Box display={{ sx: 'flex', sm: 'none' }}>
                        <PrimaryButton
                          sx={{
                            width: 'auto',
                            padding: '14px 33px',
                            height: 'auto',
                            borderRadius: '10px',
                            fontSize: '16px',
                            fontWeight: '500',
                            '@media (max-width: 600px)': {
                              fontSize: '14px',
                              padding: '8px 28px',
                            },
                          }}
                          disabled={Object.keys(params)?.length === 0}
                          onClick={() => {
                            sessionStorage.setItem('serviceid', _id);
                            sessionStorage.setItem('serviceprice', price);
                            setOpenEmailModal(true);
                          }}>
                          {BOOK_NOW}
                        </PrimaryButton>
                      </Box>
                    </Box>
                  </div>
                  <Box
                    className="card-right-wrap"
                    display={{ xs: 'none !important', sm: 'flex !important' }}>
                    {/* <div className="price">{price === 0 ? 'free' : `${unit} ${price}`}</div> */}
                    <div className="price">{price === 0 ? 'free' : `${'₹ '}${price}`}</div>
                    <Box>
                      <PrimaryButton
                        sx={{
                          width: 'auto',
                          padding: '14px 33px',
                          height: 'auto',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontWeight: '500',
                          '@media (max-width: 600px)': {
                            fontSize: '14px',
                            padding: '8px 28px',
                          },
                        }}
                        disabled={Object.keys(params)?.length === 0}
                        onClick={() => {
                          sessionStorage.setItem('serviceid', _id);
                          // sessionStorage.setItem('serviceprice', price);
                          setOpenEmailModal(true);
                        }}>
                        {BOOK_NOW}
                      </PrimaryButton>
                    </Box>
                  </Box>
                </div>
              ),
            )
          )}
        </div>
      </div>
      <Modal
        openDialog={openEmailModal}
        closeDialog={handleCloseDialoag}
        dialogContent={emailModalContent()}
        noHeader={false}
        heading={
          <div className="head" style={{ display: 'block' }}>
            <div className="heading">{EMAIL_VERIFICATION_HEADING}</div>
            <div className="otp-heading">{EMAIL_VERIFICATION_SUBHEADING}</div>
          </div>
        }
        dialogCss="logoutDialogWrap email-modal-content"
      />
      {otpModal ? (
        <OtpModal email={emailValue} setOtpModal={setOtpModal} seekerVerify={seekerVerify} />
      ) : (
        ''
      )}
    </StyledServices>
  );
};

export default PreviewLayout(CardsLayout(Services));
