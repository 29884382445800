import React from 'react';
import { FormControl, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

const CustomRadioButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '120px',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '18px',
  textAlign: 'center',
  '&.Mui-selected': {
    backgroundColor: '#CDD2F0',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: '#CDD2F0',
    },
  },
  '&:not(.Mui-selected)': {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E2E4E7 !important',
    color: '#1C1C1C',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  marginLeft: '0px !important',
  borderRadius: '50px !important',
  padding: '12px',
  textTransform: 'none',
}));

const RadioButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
}));

const StyledRadioGroup = ({ options = [], value, onChange }) => {
  const handleChange = (e, newValue) => {
    // Prevent deselecting if there's only one selected value
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <FormControl component="fieldset">
      <RadioButtonGroup
        value={value}
        exclusive // Ensures only one button can be selected at a time
        onChange={handleChange}
        aria-label="button styled radio group"
      >
        {options.map((option) => (
          <CustomRadioButton key={option.value} value={option.value} aria-label={option.label}>
            {option.label}
          </CustomRadioButton>
        ))}
      </RadioButtonGroup>
    </FormControl>
  );
};

export default StyledRadioGroup;
