import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const StyledCreate = styled('div')(
  ({ theme }) => `
  padding: 40px 0px;
  color: ${theme.palette.customColor.primaryWhite};
  @media screen and (max-width: 480px){
    padding: 12px 0px;
  }
  .MuiAccordion-root{
    border: 0px solid transparent;
    .MuiAccordionSummary-root{
      background-color: ${theme.palette.customColor.black_3};
      color: ${theme.palette.customColor.primaryWhite};
      .MuiAccordionSummary-expandIconWrapper{
          svg{
            fill: ${theme.palette.customColor.primaryWhite};
          }
      }
    }
  }
  .MuiAccordion-root:first-child{
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background-color: ${theme.palette.customColor.black_3};
  }
  .MuiCollapse-root{
    background-color: ${theme.palette.customColor.black};
    color: ${theme.palette.customColor.primaryWhite};
    transition: height 0.1s !important, ;
  }
  .MuiCollapse-entered{
    padding: 2em;
  }

  .profilePic{
    max-width: 100px;
  }
  `,
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export { StyledCreate, Accordion, AccordionSummary, AccordionDetails };
