import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledBlogDetails from './StyledBlogDetails';
import { SecondaryButton } from '../../../components/Button';
import BlogsCard from '../../../components/BlogsCard';
import { updateLoading } from '../../../store/reducers/loader';

function BlogDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [blogsData, setBlogsData] = useState([]);
  const [relatedBlogsData, setRelatedBlogsData] = useState([]);

  const getRelatedPosts = async (limit = 4) => {
    dispatch(updateLoading(true));
    const API_URL = process.env.REACT_APP_BLOG_URL;
    const CONTENT_API_KEY = process.env.REACT_APP_BLOG_KEY;
    try {
      const response = await axios.get(`${API_URL}/ghost/api/v3/content/posts`, {
        params: {
          key: CONTENT_API_KEY,
          limit,
        },
      });
      if (response.status === 200) {
        const posts =
          response?.data?.posts?.filter((val) => val.id !== params?.id).slice(0, 3) || [];
        setRelatedBlogsData(posts);
      } else {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
      console.error('Error fetching posts', error);
    }
    dispatch(updateLoading(false));
  };

  const getPosts = async (id) => {
    dispatch(updateLoading(true));
    const API_URL = process.env.REACT_APP_BLOG_URL;
    const CONTENT_API_KEY = process.env.REACT_APP_BLOG_KEY;
    try {
      const response = await axios.get(`${API_URL}/ghost/api/v3/content/posts/${id}`, {
        params: {
          key: CONTENT_API_KEY,
        },
      });
      if (response.status === 200) {
        setBlogsData(response?.data?.posts[0]);
        getRelatedPosts();
      } else {
        navigate('/blogs');
      }
    } catch (error) {
      navigate('/blogs');
      console.error('Error fetching posts', error);
    }
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params?.id) {
      getPosts(params?.id);
    } else {
      navigate('/blogs');
    }
  }, [params?.id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Link underline="hover" key="1" color="inherit" href="/blogs">
      Our Blogs
    </Link>,
    <Typography
      key="3"
      width={'100%'}
      maxWidth={{ xs: 'calc(100vw - 220px)', sm: '300px', md: '90%' }}
      sx={{
        color: '#415DF3',
        wordWrap: 'normal',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
      {/* {params?.id || ''} */}
      {blogsData?.title}
    </Typography>,
  ];

  const Chip = ({ children }) => <Box className="chip_blog">{children}</Box>;

  return (
    <StyledBlogDetails>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container gap={3}>
              <Grid item xs={12} className="heading_blog">
                {blogsData?.title || ''}
              </Grid>
              <Grid container gap={'6px'}>
                <Chip>{moment(blogsData?.published_at).format('MMM YY')}</Chip>
                <Chip>{blogsData?.reading_time} min read</Chip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <img className="blog_frame_img" src={blogsData?.feature_image} alt="" />
          </Grid>
          <Grid item xs={12} className="blog_desc">
            <div dangerouslySetInnerHTML={{ __html: blogsData?.html }} />
          </Grid>
        </Grid>
        <Grid pt={7.5} pb={5.5} container spacing={5}>
          <Grid item xs={12} className="related_blogs_heading">
            Related blogs
          </Grid>
          <Grid item xs={12} className="related_items">
            <Box
              overflow={'auto'}
              pt={{ xs: 4, md: 0 }}
              pb={{ xs: 4, md: 0 }}
              mt={{ xs: '-32px', md: '0px' }}
              mr={{ xs: '-16px', sm: '0px' }}
              ml={{ xs: '-16px', sm: '0px' }}>
              <Grid
                container
                rowSpacing={{ xs: 2, md: 6 }}
                columnSpacing={2.5}
                flexWrap={{ xs: 'nowrap', md: 'wrap' }}
                width={{ xs: '250%', sm: '140%', md: '100%' }}>
                {console.log('relatedBlogsData: ', relatedBlogsData)}
                {relatedBlogsData &&
                  relatedBlogsData
                    // .splice(0, 3)
                    .map((item, index) => (
                      <BlogsCard data={item} key={`${index + 1000}blogs`} limit={{ lg: 4 }} />
                    ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'} width={'100%'}>
            <SecondaryButton
              onClick={() => navigate('/blogs')}
              sx={{
                width: 'fit-content',
                padding: '12px',
                border: '1px solid #415DF3',
              }}>
              See more recommendation
            </SecondaryButton>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </StyledBlogDetails>
  );
}

export default BlogDetails;
