import { styled } from '@mui/material/styles';

const StyledCardsLayout = styled('div')(
  ({ theme }) => `
  .cards-layout{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:20px;
      .profile-detail-wrap{

        height:inherit;
        box-shadow: 0px 0px 23px 0px #0000000F;
        border-radius: 24px;
        width:300px;
        
        .profile-pic-wrap{
          display:none;
          background:#fff;
          position: absolute;
          top: -30px;
          left: 16px;
          width: fit-content;
          border-radius: 80px;    
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;  
          .profile-pic{
            width: 80px;
            height: 80px;
            border-radius: 80px;
          }
          
        }
          .name_container{
            display:flex;
            justify-content:center;
            background:#CDD2F0;
            border-radius: 24px 24px 0px 0px;
            height: 120px;
          }
          .hi_img_arrow{
            margin-left:-42px;
            position:absolute;
            right:-65px;
            top: -5px;
            img{
              width:92px;
              height:70px;
            }
          }
          .wing_svg{
              position: absolute;
              top: 32px;
              left: -10px;
              img{
              width:15px;
              height:17px;
              }
          }
          
          .name{
            margin: auto 0px;
            display:flex;
            align-items:center;
            img {
              width: 177px;
              height: 34px;
            }
          }

          .user_details_wrap{
            background:#fff;
            border-radius:0px 0px 24px 24px;
            position: relative;
            padding:70px 16px 16px 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: calc(100% - 206px);

          }
          .user_full_name{
            width:100%;
            text-align: left;
            color:  #1C1C1C;
            font-family: Sora;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .expertise{
            width:100%;
            text-align: left;
            margin-top:6px;
            color: #415DF3;
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
          .bio{
            padding:0px 16px;
            margin-top:16px;
            color: #525252;
            width:100%;
            text-align: left;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            overflow-y:auto;
            word-break: break-word;
            // max-height: calc(100dvh - 620px);
            max-height: calc(100vh - 10.18vh - 11.54vh - 4.98vh - 6.33vh - 120px - 90px - 70px - 38px);

            @media (min-width: 900px) and  (max-height: 800px) {
              max-height: calc(100vh - 10.18vh - 10.54vh - 170px - 90px - 70px - 38px);
            }

          }
          .socialIcons{
            margin-top:auto;
            display:flex;
            justify-content:center;
            div{
              display:flex;
              align-items:center;
              gap:12px;

              img{
                box-shadow: 0px 0px 23px 0px #0000000F;
                border-radius:50%;
              }
            }

          }
        @media screen and (max-width: 900px){
            display: none;
          }
      }
      .right-wrap{
        box-shadow: 0px 0px 23px 0px #0000000F;
        border-radius: 18px;
        background: ${theme.palette.customColor.primaryWhite};
        width:calc(100% - 320px);
        overflow:hidden;
        
          @media screen and (max-width: 900px){
            width:100%;
          }
      }
  }
  `,
);

export default StyledCardsLayout;
