/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import StyledChart from './StyledChart';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Chart({ totalSessionCount = 0, sessionTakenCount = 0 }) {
  const [customDataset, setCustomDataset] = useState([]);

  const getData = () => {
    const dataSetVal = [];
    if (totalSessionCount > 50) {
      if (sessionTakenCount > 50) {
        let sessionTemp2 = sessionTakenCount;
        let count = 0;
        while (Math.floor(sessionTemp2 / 50) > 0) {
          dataSetVal.push(
            {
              label: 'Taken',
              data: [50, 0],
              backgroundColor: ['rgba(65, 93, 243, 1)', '#CDD2F0'],
              borderWidth: 0,
            },
            {
              data: [],
            },
          );
          sessionTemp2 -= 50;
          count += 1;
        }
        dataSetVal.push({
          label: 'count',
          data: [50, sessionTakenCount % 50],
          backgroundColor: ['#CDD2F0', 'rgba(65, 93, 243, 1)'],
          borderWidth: 0,
        });
        let remainingData = totalSessionCount - (count + 1) * 50;
        while (remainingData) {
          dataSetVal.push(
            {
              data: [],
            },
            {
              label: 'Not Taken',
              data: [50, 0],
              backgroundColor: ['#CDD2F0', 'rgba(65, 93, 243, 1)'],
              borderWidth: 0,
            },
          );
          remainingData -= 50;
        }
      } else {
        let tempData2 = totalSessionCount;
        dataSetVal.push({
          label: 'count',
          data: [50, sessionTakenCount],
          backgroundColor: ['#CDD2F0', 'rgba(65, 93, 243, 1)'],
          borderWidth: 0,
        });
        while (Math.floor(tempData2 / 50) > 1) {
          dataSetVal.push(
            {
              data: [],
            },
            {
              label: 'Not Taken',
              data: [50, 0],
              backgroundColor: ['#CDD2F0', 'rgba(65, 93, 243, 1)'],
              borderWidth: 0,
            },
          );
          tempData2 -= 50;
        }
      }
    } else {
      dataSetVal.push({
        label: 'count',
        data: [50, sessionTakenCount],
        backgroundColor: ['#CDD2F0', 'rgba(65, 93, 243, 1)'],
        borderWidth: 0,
      });
    }
    setCustomDataset(dataSetVal);
  };

  useEffect(() => {
    getData();
  }, [totalSessionCount, sessionTakenCount]);

  const data = {
    labels: [`Total Sessions ${totalSessionCount}`, `Taken till date ${sessionTakenCount}`],
    datasets: customDataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: customDataset.length === 1 ? '70%' : '40%',
    plugins: {
      legend: {
        onClick: null,
        align: 'top',
        position: 'right',
        labels: {
          boxWidth: 25,
          boxHeight: 25,
          color: '#757575',
        },
      },
    },
  };

  return (
    <StyledChart>
      <div className="chartDiv">
        <Doughnut data={data} options={options} />
      </div>
    </StyledChart>
  );
}
