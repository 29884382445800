import { styled } from '@mui/material/styles';

const StyledServices = styled('div')(
  ({ theme }) => `
  .service-page-wrap{
    padding:24px;
     @media screen and (max-width: 600px){
        padding:16px;
     }
      .service-header-wrap{
        color: ${theme.palette.customColor.primaryBlack};
        text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.10);
        font-family: Sora;
        font-size: 32px;
        font-weight: 700;
        line-height: 40.32px;
        text-align: left;
        @media screen and (max-width: 600px){
          font-size: 24px;
          line-height: 30px;
        }
      }
      .service-content-wrap{
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow-y: auto;
          
          height: calc(100vh - 10.18vh - 11.54vh - 5.98vh - 6.33vh - 98px);

          @media (min-width: 900px) and  (max-height: 800px) {
            height: calc(100vh - 10.18vh - 10.54vh - 148px);
          }

          @media screen and (max-width: 900px){
            height: calc(100vh - 5.20vh - 8.38vh - 2.27vh - 1.81vh - 110px);
          }

          @media screen and (max-width: 600px){
            height: calc(100vh - 240px); 
          }

          margin-top: 20px;
            .noServiceTitle{
              color: ${theme.palette.customColor.primaryBlack};
              font-family: Sora;
              font-size: 20px;
              font-weight: 600;
              line-height: 25.2px;
              text-align: left;
              @media screen and (max-width: 600px){
                  font-size: 16px;
                  line-height: 20px;
                }
            }
            .serviceName{
              color: ${theme.palette.customColor.primaryBlack};
              font-family: Sora;
              font-size: 20px;
              font-weight: 600;
              line-height: 25.2px;
              text-align: left;
              @media screen and (max-width: 600px){
                  font-size: 16px;
                  line-height: 20px;
                }
              }
          .service-card-wrap{
            display: flex;
            justify-content: space-between;
            border-radius: 12px;
            border: 1px solid #E2E4E7;
            padding: 20px;
            
            .card-left-wrap{
              text-align: left;
              width: 75%;
                
              @media screen and (max-width: 600px){
                width: 100%;
              }

              .card-description{
                width: fit-content;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: ${theme.palette.customColor.secondaryGrey};
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                margin-top: 5px;
                margin-bottom: 15px;
                overflow-wrap: anywhere;
                @media screen and (max-width: 600px){
                  font-size: 14px;
                  line-height: 22px;
                  margin-bottom: 25px;
                }
              }

                .sessionDuration{
                    display: flex;
                    align-items: center;
                    color: ${theme.palette.customColor.secondaryGrey};
                    font-size: 14px;
                    font-weight: 400;
                    @media screen and (max-width: 600px){
                        font-size: 12px;
                      }
                      .timerImage{
                          margin-right: 10px;
                          width:16px;
                    }
                }
            }
            .card-right-wrap{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                gap: 8px;
                @media screen and (max-width: 600px){
                    // position: absolute;
                    // right: 20px;
                    // bottom: 16px;                
                }
                
            }
            .price{
                    color: ${theme.palette.secondary.secondaryGrey};
                    font-family: Sora;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22.68px;
                    text-align: right;
                    text-transform: capitalize;
                    border-radius: 10px;
                    background: ${theme.palette.customColor.primaryOrange};
                    padding: 12px 22px;
                    @media screen and (max-width: 600px){
                      font-size: 12px;
                      padding: 6px 12px;

                    }
                }
          }
      }
  }
  `,
);

export default StyledServices;
