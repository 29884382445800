/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import PreviewLayout from '../Layout';
import CardsLayout from '../Layout/CardsLayout';
import StyledAbout from './StyledAbout';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import lang from '../../../constants/lang';

const AboutExpert = () => {
  const { ABOUT } = lang;

  const { userLandingData: { data: { aboutMe = '' } = {} } = {} } =
    useSelector(selectorExpertProfile);

  return (
    <StyledAbout>
      <div className="about-page-wrap">
        <div className="about-header-wrap">
          {`${ABOUT} `}
          <span>me</span>
        </div>
        <div className="about-content-wrap">{aboutMe}</div>
      </div>
    </StyledAbout>
  );
};

export default PreviewLayout(CardsLayout(AboutExpert));
