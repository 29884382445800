import { styled } from '@mui/material/styles';

const StyledSelectBox = styled('div')(
  ({ theme }) => `
  .input-Label_text {
    color: ${theme.palette.customColor.secondaryGrey};
    text-align: left;
    margin-bottom: 7px;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      color: ${theme.palette.customColor.secondaryGreyText};
      margin-right: 10px;
      width: 20px;
     }
    .infoIcon{
      margin-left: 5px;
      .MuiSvgIcon-root {
        width: 16px;
      }
     } 
  }
  .MuiInputBase-root {
    width: 100%;
    padding: 0px;
    .MuiInput-input {
        font-family: 'Plus Jakarta Sans';
        padding: 12px 13px !important;
        padding-right: 24px !important;
        border-radius: 10px !important;
        border: 1px solid ${theme.palette.customColor.grey_6} !important;
        color: ${theme.palette.customColor.primaryBlack};
        span{
          overflow: hidden;
          text-overflow: ellipsis;
        }
    }
    .MuiInput-input:focus {
        border: 1px solid ${theme.palette.customColor.grey_6}  !important;
        border-radius: 10px !important;
        background-color:transparent;
    }
    &.Mui-disabled:before{
      border-bottom-style: none;
    }
    &.Mui-error:before{
      bottom: -1px;
      border-radius: 3px;
      border: none;
    }
  }
  .MuiOutlinedInput-root:hover {
    border: none !important;
  }
  .MuiIconButton-root {
    margin-right: 0 !important;
  }
  .MuiSvgIcon-root {
    margin-right: 0 !important;
    color: ${theme.palette.customColor.secondaryGrey} !important;
  }
  .MuiFormControl-root {
    background-color: transparent !important;
  }
    
  .MuiFormControl-root {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
  }
    
  
   .MuiSvgIcon-root {
    color: ${theme.palette.primary.main};
    width: 33px;
   } 
  .MuiFormHelperText-root.Mui-error {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: ${theme.palette.customColor.errorRed} !important;
  }
  .MuiFormHelperText-root{
    color: ${theme.palette.customColor.errorRed};
  }
  .Mui-disabled {
    background: ${theme.palette.customColor.grey_5};
    color: ${theme.palette.customColor.grey_7};
    -webkit-text-fill-color: unset !important;
  }
  .MuiSelect-select{
      display: flex;
      align-items: center;
  }
  .MuiInputBase-root{
    .Mui-error{
      border: 1px solid ${theme.palette.customColor.stateError};
    }
  }
  .MuiInput-root:before{
    display: none;
  }
  .MuiInput-root:after{
    display: none;
  }
  `,
);

export default StyledSelectBox;
