import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import PrivateLayout from '../../layout/PrivateLayout';
import StyledFeedback from './StyledFeedback';
import notdata from '../../assets/svg/notdata.svg';
import deleteIcon from '../../assets/svg/delete16x16.svg';
import Modal from '../../components/Modal';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { deletFeedbackExpert, fetchFeedbackList, postFeedbackToDashboard } from '../../store/reducers/expertProfile/apiThunk';
import confirmed from '../../assets/svg/confirmed.svg';

const StyledTableCell = styled(TableCell)(({ theme, minWidth, maxWidth }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColor.primaryOrange,
    color: theme.palette.customColor.secondaryGrey,
    fontSize: '14px',
    fontWeight: '500',
    border: 0,
    padding: '18px 16px',
    minWidth: minWidth || 'auto',
    maxWidth: maxWidth || 'auto',
    whiteSpace: 'nowrap', // Ensure content doesn’t overflow, or adjust as needed
    overflow: 'hidden', // Hide overflow if content exceeds max width
    textOverflow: 'ellipsis', // Optionally handle long content
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-child': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
      width: '90px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.customColor.primaryBlack,
    padding: '18px 16px',
    fontWeight: 400,
    borderColor: '#E2E4E7',
  },
  '@media (max-width: 1025px)': {
    [`&.${tableCellClasses.head}`]: {
      padding: '10px 20px',
    },
    [`&.${tableCellClasses.body}`]: {
      padding: '10px 20px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  color: theme.palette.common.white,
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const readMoreModalContent = (readMoreData) => (
  <div className="content-wrap">
    <div className="description"> {readMoreData}</div>
  </div>
);

const Feedbacks = () => {
  const dispatch = useDispatch();
  const [openReadMoreModal, setOpenReadMoreModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [postFeedbackId, setPostFeedbackId] = useState(null);
  const [readMoreData, setReadMoreData] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const handleCloseReadMoreDialoag = () => {
    setOpenReadMoreModal(false);
  };
  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
    setPostFeedbackId(null);
  };
  const handleCloseDeleteDialoag = () => {
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleSessionDetail = async () => {
    const response = await dispatch(fetchFeedbackList());
    const { payload: { data: { result = [] } = {} } = {} } = response;
    setTableData(result);
  };

  useEffect(() => { handleSessionDetail(); }, []);

  const handlePostFeedbackDialog = async (id) => {
    setPostFeedbackId(id);
    setOpenLogoutModal(true);
  };

  const handlePostDeleteDialog = async (id) => {
    setDeleteId(id);
    setOpenDeleteModal(true);
  };
  const handleClosePostConfirmDialoag = () => {
    setOpenConfirmModal(false);
  };
  const handleCloseDeleteConfirmDialoag = () => {
    setOpenConfirmDeleteModal(false);
  };

  const logoutModalContent = () => (
    <>
      <div>Do you want to post this feedback on your Landing page?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          border
          onClick={async () => {
            if (postFeedbackId) {
              await dispatch(postFeedbackToDashboard(postFeedbackId));
              setPostFeedbackId(null);
              setOpenLogoutModal(false);
              setOpenConfirmModal(true);
            }
          }}
        >
          Yes
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleCloseLogoutDialoag()}
        >No
        </PrimaryButton>
      </div>
    </>
  );
  const deleteModalContent = () => (
    <>
      <div>Do you want to delete this feedback??</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          border
          onClick={async () => {
            if (deleteId) {
              await dispatch(deletFeedbackExpert(deleteId));
              await handleSessionDetail();
              setDeleteId(null);
              setOpenDeleteModal(false);
              setOpenConfirmDeleteModal(true);
            }
          }}
        >
          Yes
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleCloseDeleteDialoag()}
        >No
        </PrimaryButton>
      </div>
    </>
  );

  const confirmModalPostContent = () => (
    <div className="feedback-confirm-modal-content">
      <div className="heading-text">This feedback has been successfully posted on your profile.</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ width: '154px' }}
          onClick={() => handleClosePostConfirmDialoag()}>
          Continue
        </PrimaryButton>
      </Box>
    </div>
  );
  const confirmModalDeleteContent = () => (
    <div className="feedback-confirm-modal-content">
      <div className="heading-text">This feedback has been successfully deleted for your profile</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ width: '154px' }}
          onClick={() => handleCloseDeleteConfirmDialoag()}>
          Continue
        </PrimaryButton>
      </Box>
    </div>
  );

  return (
    <>
      <StyledFeedback>
        <div className="page-wrap">
          <div className="table-wrap">
            { tableData?.length === 0 ?
              <div>
                <img src={notdata} alt="" srcSet="" />
                <div className="noSessionsHeading">No Feedback yet</div>
              </div>
              :
              <TableContainer component={Paper} className="tableContainer">
                <Table sx={{ minWidth: 1000 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" minWidth="140px">Seeker Name</StyledTableCell>
                      <StyledTableCell align="left" minWidth="150px">Social Media Link</StyledTableCell>
                      <StyledTableCell align="left" minWidth="160px">Session Name</StyledTableCell>
                      <StyledTableCell align="left" minWidth="150px">Session Date</StyledTableCell>
                      <StyledTableCell align="left" minWidth="320px">Feedback Message</StyledTableCell>
                      <StyledTableCell align="left" maxWidth="100px">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData && tableData?.map((row) => (
                      <StyledTableRow key={row?.socialMediaLink}>
                        <StyledTableCell component="th" scope="row">
                          {row?.name}
                        </StyledTableCell>
                        <StyledTableCell align="left"><div className="socailLinks">{row?.socialMediaLink}</div></StyledTableCell>
                        <StyledTableCell align="left">{row?.session?.title}</StyledTableCell>
                        <StyledTableCell align="left">{dayjs(row?.session?.eventStartTime?.dateTime).format('MMMM D, YYYY')}</StyledTableCell>
                        <StyledTableCell align="left"> <div className="feedbackMessageBox" onClick={() => { setReadMoreData(row.message); setOpenReadMoreModal(true); }}> {row?.message?.length > 80 ? <>{row?.message?.slice(0, 55)}... <span className="read-more">Read more</span> </> : row?.message}</div></StyledTableCell>
                        <StyledTableCell align="left">
                          <div className="buttons-wrap">
                            <div
                              className={row.isPostedByExpert ? 'postButton-disable' : 'postButton'}
                              onClick={() => {
                                if (!row.isPostedByExpert) {
                                  const { _id: id } = row;
                                  console.log(id, 'id');
                                  handlePostFeedbackDialog(id);
                                  setOpenLogoutModal(true);
                                }
                              }}
                      >
                              {row.isPostedByExpert ? 'Posted' : 'Post'}

                            </div>
                            <div
                              className="deleteButton"
                              onClick={() => {
                                const { _id: id } = row;
                                handlePostDeleteDialog(id);
                              }}
                      >
                              <img src={deleteIcon} alt="" srcSet="" />
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> }
            <div className="cards-wrapper">
              {tableData && tableData?.map((item) => (
                <div>
                  <div className="card-wrap">
                    <div className="top-content">
                      <div className="content-box">
                        <div>
                          <div className="heading">Service Name</div>
                          <div className="subheading overflow-text"> {item?.name}</div>
                        </div>
                        <div>
                          <div className="heading">Session Name</div>
                          <div className="subheading overflow-text">{item?.session?.title}</div>
                        </div>
                      </div>
                      <div className="content-box">
                        <div>
                          <div className="heading overflow-text">Social Media Link</div>
                          <div className="subheading overflow-text">{item?.socialMediaLink}</div>
                        </div>
                        <div>
                          <div className="heading">Session Date</div>
                          <div className="subheading">{dayjs(item?.session?.eventStartTime?.dateTime).format('MMMM D, YYYY')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="content-box">
                      <div>
                        <div className="heading">Feedback Message</div>
                        <div className="subheading">{item?.message?.length > 95 ? <>{item?.message?.slice(0, 87)}... <span className="read-more" onClick={() => { setReadMoreData(item.message); setOpenReadMoreModal(true); }}>Read more</span> </> : item.message}</div>
                      </div>
                    </div>
                    <div className="content-box-btn">
                      <div className="buttons-wrap">
                        <div
                          className="postButton"
                          onClick={() => {
                            const { _id: id } = item;
                            handlePostFeedbackDialog(id);
                            setOpenLogoutModal(true);
                          }}
                      >
                          Post
                        </div>
                        <div
                          className="deleteButton"
                          onClick={() => {
                            const { _id: id } = item;
                            handlePostDeleteDialog(id);
                          }}
                      >
                          <img src={deleteIcon} alt="" srcSet="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </StyledFeedback>
      <Modal
        openDialog={openReadMoreModal}
        closeDialog={handleCloseReadMoreDialoag}
        dialogContent={readMoreModalContent(readMoreData)}
        noHeader={false}
        heading="Feedback Message"
        dialogCss="logoutDialogWrap"
        />
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Post Feedback"
        dialogCss="logoutDialogWrap"
      />
      <Modal
        openDialog={openDeleteModal}
        closeDialog={handleCloseDeleteDialoag}
        dialogContent={deleteModalContent()}
        noHeader={false}
        heading="Delete Feedback"
        dialogCss="logoutDialogWrap"
      />
      <Modal
        openDialog={openConfirmModal}
        closeDialog={handleClosePostConfirmDialoag}
        dialogContent={confirmModalPostContent()}
        noHeader={false}
        heading={<img src={confirmed} alt="confirmed" />}
        dialogCss="confirmFeedbackWrap"
        closeButVisible
      />
      <Modal
        openDialog={openConfirmDeleteModal}
        closeDialog={handleCloseDeleteConfirmDialoag}
        dialogContent={confirmModalDeleteContent()}
        noHeader={false}
        heading={<img src={confirmed} alt="confirmed" />}
        dialogCss="confirmFeedbackWrap"
        closeButVisible
      />
    </>
  );
};

export default PrivateLayout(Feedbacks);
