import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledTermsAndCondition from './StyledTermsAndCondition';
import TermsHeadingFrame from '../../../components/TermsFrame';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Typography key="3" sx={{ color: '#415DF3' }}>
      Terms & Conditions
    </Typography>,
  ];

  return (
    <StyledTermsAndCondition>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box>
          <TermsHeadingFrame />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box maxWidth="846px">
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="body_1">
                This Privacy Policy describes how Superconnects ("we", "us", or "our") collects,
                uses, and discloses your personal information when you use our platform (the
                "Service").
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">1. Account Registration</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  You must be 18 years or older to create an account on Superconnects.
                </Box>
                <Box component="li">
                  You agree to provide accurate, current, and complete information during the
                  registration process.
                </Box>
                <Box component="li">
                  You are responsible for maintaining the confidentiality of your account
                  credentials and for all activities that occur under your account.
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">2. Use of the Platform</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  You must be 18 years or older to create an account on Superconnects.
                </Box>
                <Box component="li">
                  You agree to use the Superconnects platform for lawful purposes only and in
                  accordance with these Terms & Conditions.
                </Box>
                <Box component="li">
                  You may not use the platform to transmit any unauthorized or unsolicited
                  advertising, promotional materials, or any other form of solicitation.
                </Box>
                <Box component="li">
                  You are solely responsible for your interactions with other users of the platform.
                  Superconnects is not responsible for any disputes or disagreements that may arise
                  between users.gree to provide accurate, current, and complete information during
                  the registration process.
                </Box>
                <Box component="li">
                  You are responsible for maintaining the confidentiality of your account
                  credentials and for all activities that occur under your account.
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">3. Content Guidelines</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  You may not post or upload any content that is unlawful, defamatory, obscene, or
                  otherwise objectionable.
                </Box>
                <Box component="li">
                  You retain ownership of any content that you post or upload to the platform, but
                  you grant Superconnects a non-exclusive, royalty-free, perpetual, irrevocable, and
                  fully sublicensable right to use, reproduce, modify, adapt, publish, translate,
                  create derivative works from, distribute, and display such content.
                </Box>
              </Box>
            </Stack>

            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">4. Limitation of Liability</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  Superconnects shall not be liable for any indirect, incidental, special,
                  consequential, or punitive damages, including but not limited to loss of profits,
                  loss of data, or other intangible losses, arising out of or in connection with
                  your use of the platform.
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">5. Changes to Terms & Conditions</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  Superconnects reserves the right to modify or amend these Terms & Conditions at
                  any time. Any changes will be effective immediately upon posting on the platform.
                  Your continued use of the platform after the posting of any changes constitutes
                  acceptance of those changes.
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">6. Contact Us</Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  If you have any questions or concerns about these Terms & Conditions, please
                  contact us at contact@superconnects.com.
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
      <Footer />
    </StyledTermsAndCondition>
  );
}

export default About;
