import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledAbout from './StyledAbout';
import aboutDetails from '../../../assets/images/aboutDetails.png';
import { PrimaryButton } from '../../../components/Button';
import AboutHeadingFrame from '../../../components/AboutFrame';

function About() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Typography key="3" sx={{ color: '#415DF3' }}>
      About Us
    </Typography>,
  ];

  const aboutOffer = [
    {
      heading: 'Networking Opportunities',
      content:
        'Connect with professionals from various industries and backgrounds to expand your network and explore new opportunities.',
      emoji: '😃️',
    },
    {
      heading: 'Mentorship Programs',
      content:
        'Engage with experienced mentors who can provide guidance and support in your professional journey.',
      emoji: '🤩️',
    },
    {
      heading: 'Community Events',
      content:
        'Attend workshops, webinars, and networking events to learn new skills, share your expertise, and connect with like-minded individuals.',
      emoji: '🥂',
    },
  ];

  return (
    <StyledAbout>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box>
          <AboutHeadingFrame />
        </Box>
        <Box className="about_sc_container" mt={{ xs: 3, sm: 6 }} display="flex">
          <Box className="about_content_container" width={{ xs: '100%', lg: 'calc(100% - 590px)' }}>
            <Box pr={{ xs: 0, lg: 6 }}>
              <Box className="about_sc_heading" mb={{ xs: 1.5, sm: 2 }}>
                About Superconnects
              </Box>
              <Box className="about_sc_dec">
                Superconnects is a dynamic platform dedicated to fostering meaningful connections
                among professionals worldwide. Our mission is to empower individuals to achieve
                their full potential by facilitating valuable connections and mentorship
                opportunities. Whether you're looking to expand your network, seek guidance from
                experienced mentors, or share your expertise with others, Superconnects is the place
                for you.
                <br />
                <br />
                Superconnects is a dynamic platform dedicated to fostering meaningful connections
                among professionals worldwide. Our mission is to empower individuals.
              </Box>
            </Box>
          </Box>
          <Box className="about_img_container">
            <Box width="100%" display="flex" justifyContent="center">
              <img src={aboutDetails} alt="" className="about_details" />
            </Box>
          </Box>
        </Box>
        <Box className="about_sc_offer" mt={{ xs: 5, sm: 10 }}>
          <Box className="about_sc_offer_heading" mb={{ xs: 3, sm: 7 }}>
            What We Offer
          </Box>
          <Grid container spacing={3.5}>
            {aboutOffer.map((val) => (
              <Grid item xs={12} md={4}>
                <Box className="offer_container">
                  <Box className="offer_sub_heading" mb={1.5}>
                    {val?.heading}
                  </Box>
                  <Box className="offer_content">{val?.content}</Box>
                  <Box className="emoji_container">{val?.emoji}</Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className="sc_join_container" mt={4}>
          <Box className="sc_join_heading">
            Join <span>Superconnects</span> Today
          </Box>
          <Box className="sc_join_content_container" mt={3}>
            <Box className="sc_join_content">
              Ready to expand your network, learn new skills, and take your career to the next
              level? Join Superconnects today and start connecting with professionals who can help
              you achieve your goals. Whether you're a seasoned professional or just starting out in
              your career, Superconnects has something for everyone. Join us and be a part of our
              vibrant and growing community!
            </Box>
          </Box>

          <Box className="sc_join_content_container" mt={6}>
            <PrimaryButton
              onClick={() => navigate('/login')}
              sx={{
                width: '174px',
                '@media (max-width: 480px)': {
                  width: '100%',
                },
              }}>
              Get started
            </PrimaryButton>
          </Box>
        </Box>
      </div>
      <Footer />
    </StyledAbout>
  );
}

export default About;
