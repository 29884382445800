import { theme } from '../../theme';

const {
  palette: {
    primary: {
      main,
    },
  } = {},
} = theme;

const ContactIcon = ({
  color = main,
}) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2633_13628)">
      <path d="M3.5 8.16634C3.5 7.5475 3.74583 6.95401 4.18342 6.51643C4.621 6.07884 5.21449 5.83301 5.83333 5.83301H22.1667C22.7855 5.83301 23.379 6.07884 23.8166 6.51643C24.2542 6.95401 24.5 7.5475 24.5 8.16634V19.833C24.5 20.4518 24.2542 21.0453 23.8166 21.4829C23.379 21.9205 22.7855 22.1663 22.1667 22.1663H5.83333C5.21449 22.1663 4.621 21.9205 4.18342 21.4829C3.74583 21.0453 3.5 20.4518 3.5 19.833V8.16634Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 8.16699L14 15.167L24.5 8.16699" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2633_13628">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default ContactIcon;
