import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledDataDisclosure from './StyledDataDisclosure';
import DiscloseHeadingFrame from '../../../components/DiscloseFrame';

function DataDisclosure() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Typography key="3" sx={{ color: '#415DF3' }}>
      Disclosure of Data
    </Typography>,
  ];

  return (
    <StyledDataDisclosure>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box>
          <DiscloseHeadingFrame />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box maxWidth="846px">
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h1">Disclosure of Data</Box>
              <Box className="body_1">We take the following actions to ensure the safety, compliance, and integrity of our Service as we may disclose your personal data in the good faith belief that such action is necessary to:
              </Box>
              <Box component="ul" className="body_2">
                <Box component="li">Comply with a legal obligation.</Box>
                <Box component="li">Protect and defend the rights or property of Superconnects.</Box>
                <Box component="li">Prevent or investigate possible wrongdoing in connection with the Service.</Box>
                <Box component="li">Protect the personal safety of users of the Service or the public.</Box>
                <Box component="li">Protect against legal liability.</Box>
                <Box component="li">Comply with Google API Services User Data Policy:</Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="body_1">Our app adheres to the Google API Services User Data Policy, including Limited Use requirements. For more information, you can refer to the following links:
              </Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  <a
                    href="https://www.googleapis.com/auth/calendar"
                    target="_blank"
                    className="links-policy"
                    rel="noreferrer">
                    https://www.googleapis.com/auth/calendar
                  </a>
                </Box>
                <Box component="li">
                  <a
                    href="https://www.googleapis.com/auth/userinfo.email"
                    target="_blank"
                    className="links-policy"
                    rel="noreferrer">
                    https://www.googleapis.com/auth/userinfo.email
                  </a>
                </Box>
                <Box component="li">
                  <a
                    href="https://www.googleapis.com/auth/userinfo.profile"
                    target="_blank"
                    className="links-policy"
                    rel="noreferrer">
                    https://www.googleapis.com/auth/userinfo.profile
                  </a>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
      <Footer />
    </StyledDataDisclosure>
  );
}

export default DataDisclosure;
