const ROUTE_PATH = {
  PAGE404: '*',
  LANDING: '/',
  PREVIEW: '/:username/',
  PREVIEW_SERVICES: '/services',
  PREVIEW_ABOUT: '/about-expert',
  PREVIEW_LINKS: '/links',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  SESSIONSLIST: '/dashboard/sessionsList',
  SIGNUP: '/signup',
  LINKEDIN: '/linkedin',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  LANDING_PAGE: '/landing',
  CALENDAR: '/calendar',
  PREVIEW_PAGE: '/preview',
  ABOUT_US: '/about',
  OUR_BLOGS: '/blogs',
  BLOG_DETAILS: '/blogs/:id',
  SCHEDULE_SEEKER: '/schedule',
  SEEKER_SUBSCRIPTION: '/seeker-subscription',
  CONFIRMATION_FORM: '/confirmation-page',
  DATA_DISCLOSURE: '/disclosure',
  FEEDBACKS: '/feedbacks',
  ADD_FEEDBACKS: '/session-feedback',
  EXPERT_FEEDBACK: '/expert-feedback',
};

const API_VERSION = {
  V1: '/api/app/v1',
};

const API_PATH = {
  CREATE_PROFILE: `${API_VERSION.V1}/create-profile`,
  EDIT_PROFILE: `${API_VERSION.V1}/edit-profile`,
  LOGIN: `${API_VERSION.V1}/login`,
  SIGNUP: `${API_VERSION.V1}/signup`,
  GET_PROFILE: `${API_VERSION.V1}/profile`,
  LOGOUT: `${API_VERSION.V1}/logout`,
  GET_S3_URL: `${API_VERSION.V1}/putObjectUrl`,
  CHECK_USERNAME: `${API_VERSION.V1}/username-check`,
  SEND_OTP: `${API_VERSION.V1}/send-otp-email`,
  VERIFY_OTP: `${API_VERSION.V1}/verify-otp`,
  GET_LANDING_SUGGESTIONS: `${API_VERSION.V1}/suggestions`,
  LANDING_PAGE: `${API_VERSION.V1}/landing-page`,
  UPDATE_SERVICE: `${API_VERSION.V1}/service`,
  LINK_TO_CALENDAR: `${API_VERSION.V1}/link-to-calender`,
  CONNECT_ACCOUNT_LINK: `${API_VERSION.V1}/generate-connect-account-link`,
  SEEKER_EXPERT_LIST: `${API_VERSION.V1}/free-slots`,
  EXPERT_EVENT_DETAIL: `${API_VERSION.V1}/session/`,
  GET_EXPERT_SESSION: `${API_VERSION.V1}/expert/session-list`,
  CREATE_SESSION: `${API_VERSION.V1}/session/`,
  CANCEL_SESSION_EXPERT: `${API_VERSION.V1}/cancel-session`,
  SESSION_EVENT_DETAIL: `${API_VERSION.V1}/seeker/session/`,
  GET_DASHBOARD_STATISTICS: `${API_VERSION.V1}/get-expert-dashboard-statistics`,
  // SESSION_PAYMENT_INTENT: `${API_VERSION.V1}/seeker/stripe/payment-intents`,
  // PAYMENT_RESPONSE: `${API_VERSION.V1}/transaction`,
  GET_SESSIONS_LIST_DASHBOARD: `${API_VERSION.V1}/expert/session-list-by-type`,
  CREATE_ISSUE: `${API_VERSION.V1}/ticket`,
  COUNTRIES_LIST: `${API_VERSION.V1}/countries`,
  SPEAKER: `${API_VERSION.V1}/book-expert-public-speaker`,
  BANK_DETAILS: `${API_VERSION.V1}/bank-details`,
  SEKKER_PAYMENT_INTENT: `${API_VERSION.V1}/seeker/razorpay/payment-intents`,
  POSTED_FEEDBACK: `${API_VERSION.V1}/list-posted-feedback`,
  POST_FEEDBACK: `${API_VERSION.V1}/add-feedback`,
  EXPERT_FEEDBACK_LIST: `${API_VERSION.V1}/list-experts-feedback`,
  DELETE_FEEDBACK: `${API_VERSION.V1}/delete-feedback`,
  CHECK_FEEDBACK: `${API_VERSION.V1}/check-feedback/`,
  POST_FEEDBACK_DASHBOARD: `${API_VERSION.V1}/post-feedback`,
};

export { ROUTE_PATH, API_PATH };
