import { styled } from '@mui/material/styles';

const StyledWeeklyCalendar = styled('div')(
  ({ theme }) => `
  padding: 2em 0;
  color: ${theme.palette.customColor.primaryBlack};
  .content-wrap{
    @media screen and (max-width: 600px){
      width: 90vw;
      overflow-x: scroll;
    }
  }
  .rbc-calendar{
    @media screen and (max-width: 600px){
      width: 800px;
    }
  }
  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .rbc-btn-group {
      display: flex;
      white-space: nowrap;
      align-items: center;
      .MuiSvgIcon-root{
        font-size: 35px;
        cursor: pointer;
      }
      .rbc-toolbar-label {
        font-family: Sora;
        font-size: 32px;
        line-height: normal;
        flex-grow: 1;
        padding: 0px 20px;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
    .week-button{
      font-size: 16px;
      font-weight: 400;
      border-radius: 10px;
      border: 2px solid #E2E4E7;
      padding: 10px 15px;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .rbc-time-view {
    border: 0px solid #5B5B5B;
    background-color:#fff;
    border-radius: 24px;
    overflow: hidden;
    .rbc-time-header {
      .rbc-label {
        padding: 0;
        min-width:57px !important;
        max-width:57px !important;
      }
      .rbc-time-header-content {
        border-left: none;
        margin-left: 3.6px;
        .rbc-time-header-cell{
          .rbc-header {
            padding: 10px 0px;
            border-bottom: 1px solid #E2E4E7;
            border-top: 1px solid #E2E4E7;
            border-left: 1px solid #E2E4E7;
          }
        }
        .custom-header{
          .block-wrap{
            .weekday-block {
              color: #1C1C1C;
              padding-bottom: 7px;
              text-transform: uppercase;
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .date-block {
              color: #525252;
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .rbc-allday-cell{
          display: none;
        }
      }
    }
    .rbc-time-content{
      border: none;
      .rbc-time-gutter{
        background: rgb(255 255 255 / 9%);
        margin: 0 1.2px !important;
        .rbc-timeslot-group{
          border: none;
          min-height: 55px;
          .rbc-time-slot{
            display: flex !important;
            align-items: center !important;
            justify-content: flex-end !important;
            margin: 0px 2px !important;
            text-align:right;
          .rbc-label {
            max-width:55px;
            padding: 0 7px !important;
            color: #1C1C1C;
            text-align: right;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        }
      }
      .rbc-day-slot {
        .rbc-timeslot-group{
          border-left: 1px solid #E2E4E7;
          border-bottom: 1px solid #E2E4E7;
          min-height: 55px;
          display: block;
          .rbc-time-slot{
            // border-top: 1px solid #5b5b5b61;
          }
        }
        .rbc-events-container{
          border: none;
          margin: 0;
          .rbc-event{
            border:0;
            background: #fff;
            //  box-shadow: 0 0 8px 0 #5b5b5b61;
            border-radius: 6px;
            display: block;
            padding: 0;
            .rbc-event-label{
              display: none;
            }
            .rbc-event-content{
              .normal-tag{
                background: var(--Dashboard-BG, #F8F4F0);
                justify-content: center;
                padding: 0px 8px;

              }
              .custom-tag{
                padding: 0px 8px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow-wrap: anywhere;
                background: rgba(218, 51, 51, 0.10);
                color:#DA3333;

              }
            }
          }
        }
      }
    }
    .rbc-today{
      background-color: rgb(18 18 18 / 0%);
    }
    .rbc-current-time-indicator{
      display: none;
    }
  }
  `,
);

export default StyledWeeklyCalendar;

export { StyledWeeklyCalendar };
