import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledPrivacyPolicy from './StyledPrivacyPolicy';
import PolicyHeadingFrame from '../../../components/PolicyFrame';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Typography key="3" sx={{ color: '#415DF3' }}>
      Privacy Policy
    </Typography>,
  ];

  return (
    <StyledPrivacyPolicy>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box>
          <PolicyHeadingFrame />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box maxWidth="846px">
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h1">Privacy Policy</Box>
              <Box className="body_1">
                Superconnects ("we", "us", or "our") is committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, and disclose your personal information,
                including any data from your Google account, when you use our platform (the
                "Service").
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">Information Collection and Use</Box>
              <Box className="body_1">
                We collect personal data for various purposes to provide and improve our Service.
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h3">The types of data collected and their usage include:</Box>
              {/* <Box className="body_2">
                We may use your personal data for various purposes, including but not limited to:
              </Box> */}
              <Box component="ul" className="body_2">
                <Box component="li">
                  Providing and maintaining our Service :{' '}
                  <span>We use your personal data to operate the Service.</span>
                </Box>
                <Box component="li">
                  Notifying you of changes :{' '}
                  <span>
                    Your data allows us to inform you of updates or changes to our Service.
                  </span>
                </Box>
                <Box component="li">
                  Participation in interactive features :{' '}
                  <span>
                    When you choose to engage with features of the Service, your personal data helps
                    us enhance your experience.
                  </span>
                </Box>
                <Box component="li">
                  Customer support :{' '}
                  <span>
                    Personal data helps us respond to your inquiries and support requests.
                  </span>
                </Box>
                <Box component="li">
                  Usage monitoring :{' '}
                  <span>We may monitor how you use the Service to improve functionality.</span>
                </Box>
                <Box component="li">
                  Addressing technical issues :{' '}
                  <span>Your data helps us detect and fix technical problems.</span>
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">Google User Data Access</Box>
            </Stack>
            <Stack mt={2} flexDirection="column" spacing={2}>
              <Box className="heading_h3">
                We request access to specific Google user data for the following purposes:
              </Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  Email, First Name, and Last Name :{' '}
                  <span>
                    When you sign up with Google, we collect your email, first name, and last name
                    to facilitate your login process and identify your account within our Service.
                  </span>
                </Box>
                <Box component="li">
                  Google Calendar Data :{' '}
                  <span>
                    We may request access to your Google Calendar to read data related to your free
                    and busy slots. This allows us to display your availability on our app in
                    real-time to assist with scheduling meetings. We do not store this calendar
                    data, it is fetched directly from Google and displayed in real-time.
                  </span>
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">Data Sharing and Disclosure</Box>
            </Stack>
            <Stack mt={2} flexDirection="column" spacing={2}>
              <Box className="heading_h3">
                We do not share, sell, or disclose any Google user data with third parties, except:
              </Box>
              <Box component="ul" className="body_2">
                <Box component="li">
                  To comply with legal obligations :{' '}
                  <span>
                    To protect the rights, property, or safety of Superconnects, our users, or the
                    public.
                  </span>
                </Box>
                <Box component="li">
                  <span>
                    We never transfer or sell your data to third-party entities for marketing or any
                    other purposes.
                  </span>
                </Box>
                <Box component="li">
                  Data Protection Mechanisms :{' '}
                  <span>
                    We prioritize the protection of your data by using industry-standard security
                    practices. This includes encryption, secure access controls, and secure data
                    transmission protocols to ensure that your Google user data is safe from
                    unauthorized access or breaches.
                  </span>
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">Data Retention and Deletion</Box>
            </Stack>
            <Stack mt={2} flexDirection="column" spacing={2}>
              <Box component="ul" className="body_2">
                <Box component="li">
                  Retention :{' '}
                  <span>
                    We store your Google account data (email, first name, and last name) as long as
                    necessary to provide the Service or until you request its deletion.
                  </span>
                </Box>
                <Box component="li">
                  Google Calendar Data :{' '}
                  <span>
                    As mentioned, Google Calendar data is not stored by us. It is retrieved and used
                    in real-time and is discarded immediately after the scheduling process.
                  </span>
                </Box>
                <Box component="li">
                  Deletion :{' '}
                  <span>
                    You can request the deletion of your account and associated data at any time by
                    contacting us. We will promptly delete your Google user data from our systems,
                    as per your request.
                  </span>
                </Box>
              </Box>
            </Stack>
            <Stack mt={4} flexDirection="column" spacing={2}>
              <Box className="heading_h2">Changes to This Privacy Policy</Box>
              <Box className="body_1">
                We may update our Privacy Policy from time to time. We will notify you of any
                changes by posting the new Privacy Policy on this page. You are advised to review
                this Privacy Policy periodically for any changes.
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
      <Footer />
    </StyledPrivacyPolicy>
  );
}

export default About;
