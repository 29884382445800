const lang = {
  EMAIL_PLACEHOLDER: 'Enter email',
  EMAIL_LABEL: 'EMAIL',
  EMAIL_NAME: 'email',
  SEND_OTP: 'Send OTP',
  SERVICES: 'Services',
  ABOUT: 'About',
  POSTS: 'Posts',
  BOOK: 'Book',
  COURSE: 'Course',
  SOCIALS: 'Social',
  BOOKS_COURSES: 'Books & Courses',
  BOOK_NOW: 'Book Now',
  EMAIL_VERIFICATION_HEADING: 'Email Verification',
  EMAIL_VERIFICATION_SUBHEADING: 'We’ll send a OTP on your Email',
  COPY_LINK: 'Copy Link',
  COPY_TO_CLIPBOARD: 'Copied to clipboard',
  JOIN_MEETING: 'Join Meeting',
  MY_FEEDBACK: 'My Feedback',
};

export default lang;
