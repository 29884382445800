import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)(
  ({ theme }) => `
&.common_layout {
    backdrop-filter: blur(20px);
  .MuiDialog-paper {
    box-shadow: ${theme.palette.boxShadow.seventh} !important;
    border-radius: 10px !important;
    margin: 0px;
    @media screen and (max-width: 600px){
      margin: 0px 16px;
    }
    .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      @media screen and (max-width: 600px){
        padding: 20px 16px;
      }
    }
    .MuiDialogContent-root{
      padding:0px;
    }
  }
  @media screen and (max-width: 1200px) {
    .dialogBox {
      .MuiDialog-paper {
        .MuiDialogTitle-root {
          .dialog-title {
            padding: 0;
          }
        }
      }
    }
  }
}


&.social_post_content{
  .MuiDialog-container{
    .MuiPaper-root{
      .MuiDialogTitle-root{
        border-bottom: 1px solid #E2E4E7;

        .dialog-title{
          @media screen and (max-width: 600px) {
                img{
                  width: 24px;
                  height: 24px;
                }
          }
          .head{
            
            .heading_pop_social{
              color: var(--Black, #1C1C1C);
              font-family: Sora;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media screen and (max-width: 600px) {
                font-size: 18px;
                font-weight: 600;
              }
            }
            .sub_heading_social{
              color: var(--Para, #525252);
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; 
              @media screen and (max-width: 600px) {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
      }
      .content_wrap{
        padding:16px 24px;
        @media screen and (max-width: 600px) {
          padding:20px 16px;
          img{
            width: 24px;
            height: 24px;
          }
        }

        .header-wrap{
          text-align: end;
          
        }

        .url-section{
          border-radius: 10px;
          background: var(--Neutral-BG, #F4F4F4);
          padding:16px;

          .card-header-wrap{
            display: flex;
            justify-content: space-between;
          }

          .heading{
            color: var(--Black, #1C1C1C);
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; 
          }
        }
      }
      .footer-wrap{
        border-top: 1px solid #E2E4E7;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 600px) {
            padding:20px 16px;
        }
      }
    }
  }
}

&.social_post_how{
  .MuiDialog-container{
    .MuiPaper-root{
      .MuiDialogTitle-root{
        border-bottom: 1px solid #E2E4E7;

        .dialog-title{
          width: 100%;
          .head{
            .heading_pop_social{
              color: var(--Black, #1C1C1C);
              font-family: Sora;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media screen and (max-width: 600px) {
                font-size: 18px;
             }
            }
          }
        }
      }
      .content_wrap{
        padding:24px 24px;
         @media screen and (max-width: 600px) {
            padding:20px 16px;
          }

        .heading{
          color: var(--Placeholder, #757575);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */ 
        }
        .desc{
          color: var(--Black, #1C1C1C);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
      .footer-wrap{
        border-top: 1px solid #E2E4E7;
        padding: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 600px) {
          padding:20px 16px;
        }
      }
    }
  }
}

&.speaker_content{
  .MuiDialog-container{
    .MuiPaper-root{
      width: 498px;
      @media screen and (max-width: 600px) {
        width: calc(100% - 32px);
      }
      .MuiDialogTitle-root{
        .dialog-title{
          width: 100%;
          .head{
            .heading_pop_social{
              color: var(--Black, #1C1C1C);
              font-family: Sora;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media screen and (max-width: 600px) {
                font-size: 18px;
             }
            }
          }
        }
      }
      .content_wrap{
        padding:0px 24px;
         @media screen and (max-width: 600px) {
            padding:0px 16px;
          }

        .message_content{
          textarea{
            height:auto !important;
          }
        }

        .heading{
          color: var(--Placeholder, #757575);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */ 
        }

        .desc{
          color: var(--Black, #1C1C1C);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
      .footer-wrap{
        padding: 40px 24px 16px 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 600px) {
          padding:20px 16px;
        }
      }
    }
  }
}
`,
);
export default StyledModal;
