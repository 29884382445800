import { useEffect, useState } from 'react';
import { Box, Grid, Pagination } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../../components/Home/Header';
import Footer from '../../../components/Home/Footer';
import StyledBlogs from './StyledBlogs';
import BlogsHeadingFrame from '../../../components/BlogsFrame';
import BlogsCard from '../../../components/BlogsCard';
import { updateLoading } from '../../../store/reducers/loader';

function Blogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [blogsData, setBlogsData] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Home
    </Link>,
    <Typography key="3" sx={{ color: '#415DF3' }}>
      Our Blogs
    </Typography>,
  ];

  const getPosts = async (limit = 10) => {
    dispatch(updateLoading(true));
    const API_URL = process.env.REACT_APP_BLOG_URL;
    const CONTENT_API_KEY = process.env.REACT_APP_BLOG_KEY;
    try {
      const response = await axios.get(`${API_URL}/ghost/api/v3/content/posts`, {
        params: {
          key: CONTENT_API_KEY,
          limit,
          page,
        },
      });
      if (response.status === 200) {
        setPagination(response?.data?.meta?.pagination);
        setBlogsData(response?.data?.posts);
      } else {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
      console.error('Error fetching posts', error);
    }
    dispatch(updateLoading(false));
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <StyledBlogs>
      <Header />
      <div className="about-page-wrap">
        <Box className="bread_text" mb={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box>
          <BlogsHeadingFrame />
        </Box>
        <Grid
          container
          className="blogs_cards_container"
          mt={{ xs: 2, sm: 4 }}
          rowSpacing={{ xs: 2, md: 6 }}
          columnSpacing={2.5}>
          {blogsData &&
            blogsData.map((val, index) => <BlogsCard data={val} key={`${index + 100}blogs`} />)}
        </Grid>
        <Box mt={{ xs: 5, md: 7.5 }} justifyContent={'center'} display={'flex'}>
          <Pagination
            count={pagination?.pages || 0}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Box>
      </div>
      <Footer />
    </StyledBlogs>
  );
}

export default Blogs;
