import * as React from 'react';
// import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { FormHelperText } from '@material-ui/core';
import StyledTimePicker from './StyledTimePicker';

export default function TimePickerValue(props) {
  const {
    customLabel = '',
    helperText = '',
    value = null,
    onChange,
    name = '',
    important = false,
  } = props;
  return (
    <StyledTimePicker>
      <div className="input-Label_text label-space">
        {customLabel}
        {important ? <span className="important">*</span> : ''}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['TimePicker', 'TimePicker']}>
          <div>
            <DesktopTimePicker value={value} onChange={onChange} name={name} />
            <FormHelperText>{helperText}</FormHelperText>
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </StyledTimePicker>
  );
}
