import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledWeeklyCalendar from './StyledWeeklyCalendar';
import Modal from '../../components/Modal';
import {
  fetchSessionDetail,
  fetchExpertSessionCancel,
} from '../../store/reducers/expertProfile/apiThunk';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { selectorAuthentication } from '../../store/reducers/authentication/index';
import CalenderForwardIcon from './CalenderForwardIcon';
import CalenderBackIcon from './CalenderBackIcon';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';

const CustomToolbar = (toolbar) => {
  const goToPrevious = () => {
    const newDate = moment(toolbar.date).subtract(7, 'days').toDate();
    toolbar.onNavigate('prev', newDate);
  };

  const goToNext = () => {
    const newDate = moment(toolbar.date).add(7, 'days').toDate();
    toolbar.onNavigate('next', newDate);
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <CalenderBackIcon onClick={goToPrevious} />
        <span className="rbc-toolbar-label">
          {momentLocalizer(moment).format(toolbar.date, 'MMMM, yyyy', 'en')}
        </span>
        <CalenderForwardIcon onClick={goToNext} />
      </div>
      <div className="week-button">Week</div>
    </div>
  );
};

const CustomHeader = ({ date }) => {
  const weekday = moment(date).format('ddd');
  const getDate = moment(date).format('D');
  const month = moment(date).format('MMM');

  return (
    <div className="custom-header">
      <div className="block-wrap">
        <div className="weekday-block">{weekday}</div>
        <div className="date-block">{`${getDate} ${month}`}</div>
      </div>
    </div>
  );
};

export default function WeeklyCalendar({ startDate, endDate, events, fetchCalendarData = '' }) {
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [sessionModalContent, setSessionModalContent] = useState(null);
  const [openCancelSessionModal, setOpenCancelSessionModal] = useState(false);

  const { userData: { data: { _id = '' } = {} } = {} } = useSelector(selectorAuthentication);

  const handleSessionDetail = async (passedId) => {
    const data = {
      id: passedId,
    };
    const response = await dispatch(fetchSessionDetail(data)).then((val) => val);
    const {
      payload: { data: sessionData = {} },
    } = response;
    const updatedData = {
      ...sessionData,
      id: passedId,
    };
    setSessionModalContent(updatedData);
    setOpenSessionModal(true);
  };

  const CustomEventComponent = ({ event }) => {
    // Custom formatting logic for event content
    const formattedContent = `${event.title}`;
    return (
      <div className="custom-tag" onClick={() => handleSessionDetail(event.id)}>
        <div>{formattedContent}</div>
      </div>
    );
  };

  const handleRangeChange = (range) => {
    startDate(range[0]);
    endDate(range[range.length - 1]);
  };

  const formats = {
    timeGutterFormat: (date) => moment(date).format('h A'),
  };

  const handleCloseLogoutDialoag = () => {
    setOpenSessionModal(false);
  };

  const handleCancelModal = () => {
    setOpenCancelSessionModal(true);
    handleCloseLogoutDialoag();
  };

  const logoutModalContent = () => {
    const formattedDate = moment(sessionModalContent?.eventStartTime?.dateTime).format(
      'MMMM D, YYYY [at] h:mm A',
    );
    const hours = Math.floor(sessionModalContent?.duration / 60);
    const remainingMinutes = sessionModalContent?.duration % 60;
    return (
      <>
        <div className="content-wrap">
          <div className="popup-title">{sessionModalContent?.title}</div>
          <div className="duration">
            <div className="heading">Duration</div>
            <div className="description">
              {`${hours !== 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : ''} ${remainingMinutes !== 0 ? (remainingMinutes === 1 ? `${remainingMinutes} minute` : `${remainingMinutes} minutes`) : ''} `}
            </div>
          </div>
          <div className="duration">
            <div className="heading">Time & Date</div>
            <div className="description">{formattedDate}</div>
          </div>
          <div className="duration">
            <div className="heading">Meeting Link</div>
            <a
              className="meet-link"
              href={sessionModalContent?.googleMeetLink}
              target="_blank"
              rel="noreferrer">
              {sessionModalContent?.googleMeetLink}
            </a>
          </div>
          <div className="duration">
            <div className="heading">Seeker’s Email</div>
            <div className="description">{sessionModalContent?.primaryEmail}</div>
          </div>
        </div>
        <div className="footer-wrap">
          <div className="cancel_session_btn" onClick={() => handleCancelModal()}>
            Cancel Session
          </div>
        </div>
      </>
    );
  };

  const handleCancelSession = async () => {
    const data = {
      id: _id,
      sessionId: sessionModalContent.id,
      type: 2,
    };
    setOpenCancelSessionModal(false);
    setOpenSessionModal(false);

    const response = await dispatch(fetchExpertSessionCancel(data));
    const { payload: { status = false, message = '' } = {} } = response;
    if (status && message) {
      ToastNotifySuccess(message);
    }
    fetchCalendarData();
  };

  const handleCloseCancelSessionDialoag = () => {
    setOpenCancelSessionModal(false);
  };

  const cancelSessionModalContent = () => (
    <>
      <div>Are you sure you want to cancel this session?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleCancelSession()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseCancelSessionDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledWeeklyCalendar>
      <Modal
        openDialog={openSessionModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Session Details"
        dialogCss="sessionDetailPopup"
      />

      <Modal
        openDialog={openCancelSessionModal}
        closeDialog={handleCloseCancelSessionDialoag}
        dialogContent={cancelSessionModalContent()}
        noHeader={false}
        heading="Cancel Session"
        dialogCss="logoutDialogWrap"
      />
      <div className="page-content-wrap">
        <div className="content-wrap">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            defaultDate={new Date()}
            views={['week']}
            onRangeChange={handleRangeChange}
            components={{
              toolbar: CustomToolbar,
              header: CustomHeader,
              event: CustomEventComponent,
            }}
            formats={formats}
          />
        </div>
      </div>
    </StyledWeeklyCalendar>
  );
}
