import { styled } from '@mui/material/styles';

const StyledTextField = styled('div')(
  ({ theme }) => `
  width: 100%;
.custom-field {
  border-radius: 12px;
}
.input-Label_text {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #525252;
  text-align: left;
  margin-bottom: 7px;
}
.MuiInputBase-root {
  border-radius: 10px !important;
  border: 1px solid ${theme.palette.customColor.grey_6};
  padding: 0px;
}
.MuiOutlinedInput-root:hover {
  border: none !important;
}
.MuiIconButton-root {
  margin-right: 0 !important;
}
.MuiFormControl-root {
  background-color: transparent !important;
}
  
.MuiFormControl-root {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}
  
.MuiInput-input {
  font-family: 'Plus Jakarta Sans';
  padding: 13px !important;
  border-radius: 10px !important;
  color: ${theme.palette.customColor.primaryBlack};
}
.MuiInput-input:focus {
  outline: 1px solid ${theme.palette.primary.main};
  border-radius: 10px !important; 
}
 .MuiSvgIcon-root {
  color: ${theme.palette.primary.main};
  margin-right: 10px;
  width: 20px;
 } 
.MuiFormHelperText-root.Mui-error {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: ${theme.palette.customColor.errorRed} !important;
  border: none;
}
.Mui-disabled {
  background: ${theme.palette.customColor.grey_5};
  color: ${theme.palette.customColor.grey_7};
  -webkit-text-fill-color: unset !important;
}

.wordCount{
  position: absolute;
  right: 0px;
  padding: 8px 12px;
  height: auto;
  max-height: none;
  bottom: 0px;
  p{
    font-weight: 400;
    font-size: 12px;
    color: ${theme.palette.customColor.grey_7};
  }
}
.Mui-error{
  border: 1px solid ${theme.palette.customColor.errorRed};
}
`,
);

export default StyledTextField;
