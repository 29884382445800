import { styled } from '@mui/material/styles';

const StyledFeedback = styled('div')(
  ({ theme }) => `
  .page-wrap{
    padding: 32px 40px 40px;
    @media screen and (max-width: 768px){
      padding: 20px;
    }
    @media screen and (max-width: 481px){
      padding: 0px;
    }
    .tableContainer{
        @media screen and (max-width: 480px){
          display: none;
        }
      }
      .MuiTableContainer-root{
        box-shadow: none;
      }
    .table-wrap{
        padding:20px;
        background:#fff;
        .MuiTableContainer-root{
            border-radius:12px;
        }
        @media screen and (max-width: 480px){
          width: 100%;
          padding: 0px 16px;
          padding-bottom: 20px;
        }
        .noSessionsHeading{
          color: ${theme.palette.customColor.secondaryGrey};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
          margin-bottom: 36px;
          @media screen and (max-width: 480px){
            font-size: 18px;
          }
        }
       .socailLinks {
        max-width: 100px;
        word-break: break-all;
       }
       .feedbackMessageBox{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(3 * 1.5em);
       }
       .read-more{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
        text-align: left; 
        color:#415DF3;
        cursor:pointer;       
       }
      }
      
      .buttons-wrap{
        display: flex;
        align-items: center;
        column-gap: 18px;
        justify-content:flex-end;
        width: 100%;
        .postButton{
            display:inline-flex;
            align-items: center;
            justify-content:center;
            width:59px;
            height:24px;
            padding:4px 12px;
            border: 1px solid #415DF3;
            border-radius: 6px;
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            cursor:pointer;
        }
        .postButton-disable{
          display:inline-flex;
            align-items: center;
            justify-content:center;
            width:59px;
            height:24px;
            padding:4px 12px;
            border: 1px solid #B4B4B4;
            border-radius: 6px;
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
        }
        .deleteButton{
            background: ${theme.palette.customColor.bg_red};
            display:inline-flex;
            align-items: center;
            justify-content:center;
            width:34px;
            height:34px;
            border-radius: 50%;
            cursor:pointer;
        }
      }
  }
  .cards-wrapper{
    display: none;
    @media screen and (max-width: 480px){
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
    .card-wrap{
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid ${theme.palette.customColor.grey_6};
        background: ${theme.palette.secondary.main};
        display: flex;
        padding: 12px 17px;
        align-items: center;
        row-gap:19px;
        .top-content{
        display: flex;
        justify-content: space-between;
        width: 100%;
        }
        .content-box{
            width:100%;
            display:flex;
            justify-content:space-between;
            text-align:left;
            flex-direction:column;
            gap:19px;
            .heading{
                color: ${theme.palette.customColor.grey_7};
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 6px;
            }
            .subheading{
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
               
            }
            .overflow-text{
                max-width:150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .content-box-btn{
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
     
        .viewDetailsButton{
            color: ${theme.palette.primary.main};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .cancelButton{
            color: ${theme.palette.customColor.stateError};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 8px;
            background: ${theme.palette.customColor.bg_red};
            margin: 0px;
            padding: 8px 10px;
        }
    }
  }
  `,
);

export default StyledFeedback;
