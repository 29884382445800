import React from 'react';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import StyledHeader from '../../components/Home/Header/StyledHeader';
import logo from '../../assets/svg/logo.svg';
import StyledSubscription from './StyledSubscription';
import CheckoutForm from './CheckoutForm';

export default function PaymentForm() {
  return (
    // <Elements stripe={stripePromise}>
    <>
      <StyledHeader>
        <div className="page-header-wrap" style={{ padding: '20px 30px', background: '#FFFFFF26' }}>
          <div className="header-content">
            <img src={logo} className="logoImage" alt="Logo" />
          </div>
          <div className="header-footer" />
        </div>
      </StyledHeader>
      <StyledSubscription>
        <CheckoutForm />
      </StyledSubscription>
    </>
  // </Elements>
  );
}
