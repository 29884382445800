import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../../components/Button';
import StyledHeader from '../../../components/Home/Header/StyledHeader';
import Modal from '../../../components/Modal';
import TextField from '../../../components/TextField';
import { validationSchemaAddFeedback } from '../../../utils/validations/authValidation';
import logo from '../../../assets/svg/logo.svg';
import confirmed from '../../../assets/svg/confirmed.svg';
import { addFeedbackSeeker, checkFeedbackExpert } from '../../../store/reducers/expertProfile/apiThunk';

const AddFeedbackModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [expertName, setExpertName] = useState('');
  const [feedBackGivenModal, setIsFeedBackGivenModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setSessionId(params.get('sessionId'));
      setExpertName(params.get('name'));
    }
  }, []);

  const checkSession = async () => {
    const response = await dispatch(checkFeedbackExpert(sessionId)).then((val) => val);
    const { payload: { data: { isFeedBackGiven } } = {} } = response;
    if (isFeedBackGiven && sessionId) {
      setIsFeedBackGivenModal(true);
    } else {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    if (sessionId) {
      checkSession();
    }
  }, [sessionId]);

  const feedbackEmptyValues = {
    name: '',
    link: '',
    message: null,
  };
  const handleCloseModal = () => {
    setOpenDialog(false);
    navigate('/');
  };
  const handleClosefeedBackGivenModal = () => {
    setIsFeedBackGivenModal(false);
    navigate('/');
  };

  const submitHandler = async (values, { resetForm }) => {
    const { name, link, message } = values;
    try {
      resetForm({ values: feedbackEmptyValues });
      const dataTosend = {
        name,
        socialMediaLink: link,
        message,
      };
      const data = {
        id: sessionId,
        body: dataTosend,

      };
      const response = await dispatch(addFeedbackSeeker(data));
      const { payload: { status = false } = {} } = response;
      if (status) {
        setOpenConfirmModal(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const formik = useFormik({
    initialValues: feedbackEmptyValues,
    validationSchema: validationSchemaAddFeedback,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values: {
      name,
      link,
      message,
    } = {},
    touched: {
      name: tucName = false,
      link: tucLink = false,
      message: tucMessage = false,
    } = {},
    errors: {
      name: errName = false,
      link: errLink = false,
      message: errMessage = false,
    } = {},
  } = formik;

  const calculateWordCount = (text) => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };
  const handleChangeName = (e) => {
    const { name: fieldName, value } = e.target;
    // Remove all characters except alphabets and spaces
    const sanitizedValue = value
      .replace(/[^A-Za-z\s]/g, '') // Remove non-alphabet and non-space characters
      .replace(/\s{2,}/g, ' '); // Replace consecutive spaces with a single space

    // Update the form value (assuming you have Formik or a similar setup)
    formik.setFieldValue(fieldName, sanitizedValue);
  };

  const addFeedbackModalContent = () => (
    <div className="content-wrap">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          columnSpacing={2.5}
          rowSpacing={2}
          className="input-fields-wrap"
        >
          <Grid item xs={12}>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Enter your name"
                  name="name"
                  type="text"
                  label="Name"
                  important
                  onChange={handleChangeName}
                  onBlur={handleBlur}
                  value={name}
                  error={tucName && !!errName}
                  helperText={tucName && errName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="e.g. linkedin.com/in/johndoe"
                  name="link"
                  type="text"
                  label="Social Media Link"
                  important
                  showTooltip
                  tooltip="Add any social media profile link ( linkedin/twitter/ github/dribbble)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={link}
                  error={tucLink && !!errLink}
                  helperText={tucLink && errLink}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Write feedback for expert"
                  name="message"
                  type="text"
                  classes="description"
                  multiline
                  rows={2}
                  label="Feedback Message"
                  important
                  onChange={handleChange}
                  value={message}
                  error={tucMessage && !!errMessage}
                  helperText={tucMessage && errMessage}
                  icon={`${calculateWordCount(message)}/100`}
                  wordCount
              />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={3} px={3}>
          <PrimaryButton sx={{ width: '100%' }} type="submit">
            Submit
          </PrimaryButton>
        </Grid>
      </form>
    </div>
  );

  const handleCloseConfirmDialoag = () => {
    setOpenConfirmModal(false);
    navigate('/');
  };

  const confirmModalContent = () => (
    <div className="feedback-confirm-modal-content">
      <div className="heading-text">Your feedback has been successfully submitted.</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ width: '154px' }}
          onClick={() => handleCloseConfirmDialoag()}>
          Okay
        </PrimaryButton>
      </Box>
    </div>
  );

  const alreadyFeedbackGivenModalContent = () => (
    <div className="feedback-confirm-modal-content">
      <div className="heading-text">You've already given your feedback for this session.</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ width: '154px' }}
          onClick={() => handleClosefeedBackGivenModal()}>
          Okay
        </PrimaryButton>
      </Box>
    </div>
  );

  return (
    <>
      <StyledHeader>
        <Box
          className="page-header-wrap"
          sx={{
            background: '#F8F4F0',
            padding: '20px 30px',
            position: 'fixed',
            width: '100%',
            boxSizing: 'border-box',
            zIndex: 20,
          }}>
          <div className="header-content">
            <img src={logo} className="logoImage" alt="Logo" />
          </div>
          <div className="header-footer" />
        </Box>
      </StyledHeader>
      <Modal
        openDialog={openDialog}
        closeDialog={handleCloseModal}
        noHeader={false}
        dialogCss="razorpayDialogWrap"
        dialogContent={addFeedbackModalContent()}
        heading={
          <div>
            <div>
              Provide your feedack
            </div>
            <div className="sub-heading">How was your experience with {expertName}?
            </div>
          </div>
        }
      />
      <Modal
        openDialog={openConfirmModal}
        closeDialog={handleCloseConfirmDialoag}
        dialogContent={confirmModalContent()}
        noHeader={false}
        heading={<img src={confirmed} alt="confirmed" />}
        dialogCss="confirmFeedbackWrap"
        closeButVisible
        />

      <Modal
        openDialog={feedBackGivenModal}
        closeDialog={handleClosefeedBackGivenModal}
        dialogContent={alreadyFeedbackGivenModalContent()}
        noHeader={false}
        heading={<img src={confirmed} alt="confirmed" />}
        dialogCss="confirmFeedbackWrap"
        closeButVisible
    />;

    </>
  );
};

export default AddFeedbackModal;
