/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../Button';
import StyledTable from './StyledTable';
import Modal from '../Modal';
import {
  fetchSessionDetail,
  fetchExpertSessionCancel,
} from '../../store/reducers/expertProfile/apiThunk';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColor.primaryOrange,
    color: theme.palette.customColor.secondaryGrey,
    fontSize: '14px',
    fontWeight: '500',
    border: 0,
    padding: '18px 16px',
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:last-child': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
      width: '24%',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.customColor.primaryBlack,
    padding: '18px 16px',
    fontWeight: 400,
    borderColor: '#E2E4E7',
  },
  '@media (max-width: 1025px)': {
    [`&.${tableCellClasses.head}`]: {
      padding: '10px 20px',
    },
    [`&.${tableCellClasses.body}`]: {
      padding: '10px 20px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  color: theme.palette.common.white,
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables({ tableData = [], type = 0, tableDataUpdate = '' }) {
  const dispatch = useDispatch();
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [openCancelSessionModal, setOpenCancelSessionModal] = useState(false);
  const [cancelSessionDetails, setCancelSessionDetails] = useState('');
  const [popupModalContent, setPopupModalContent] = useState({});

  const handleOpenSessionDialog = async (e, sessionId) => {
    if (
      e.target.innerText !== 'Join' &&
      e.target.innerText !== 'Cancel Session' &&
      e.target.innerText !== ''
    ) {
      const requestData = {
        id: sessionId,
      };
      const responseData = await dispatch(fetchSessionDetail(requestData)).then((val) => val);
      const { payload: { data = {} } = {} } = responseData;
      setPopupModalContent(data);
      setOpenSessionModal(true);
    }
  };

  const handleCloseLogoutDialoag = () => {
    setOpenSessionModal(false);
  };

  const handleCancelModal = (passedSessionId, expertId) => {
    setCancelSessionDetails({
      id: expertId,
      sessionId: passedSessionId,
      type: 2,
    });
    setOpenCancelSessionModal(true);
  };

  const logoutModalContent = () => {
    const momentObj = moment(popupModalContent?.eventStartTime?.dateTime);
    const date = momentObj.format('DD');
    const monthName = momentObj.format('MMMM');
    const year = momentObj.format('YYYY');
    const time = momentObj.format('hh:mm A');
    const numberValue = parseInt(popupModalContent?.duration);
    const hours = Math.floor(numberValue / 60);
    const minutes = numberValue % 60;
    const { expert: { _id: expertId = '' } = {} } = popupModalContent;
    return (
      <div className="modal-wrap">
        <div className="serviceName">{popupModalContent?.title}</div>
        <div className="content-wrap">
          {/* <div className="content">
              <div className="title">Price</div>
              <div className="description">
                {`${popupModalContent?.unit} ${popupModalContent?.price}`}
              </div>
            </div> */}
          <div className="content">
            <div className="title">Duration</div>
            <div className="description">
              {hours !== 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : ''}{' '}
              {minutes !== 0 ? (minutes === 1 ? `${minutes} min` : `${minutes} mins`) : ''}{' '}
            </div>
          </div>
          <div className="content">
            <div className="title">Time & Date</div>
            <div className="description">
              {monthName} {date}, {year} at {time}
            </div>
          </div>
          {!type ? (
            <div className="meet-link">
              <div className="title">Meeting Link</div>
              <a
                href={popupModalContent?.googleMeetLink}
                target="_blank"
                className="description"
                rel="noreferrer">
                {popupModalContent?.googleMeetLink}
              </a>
            </div>
          ) : (
            ''
          )}
          <div className="content">
            <div className="title">Seeker’s Email</div>
            <div className="description">{popupModalContent?.primaryEmail}</div>
          </div>
        </div>
        {!type ? (
          <div
            className="footer-wrap"
            onClick={() => handleCancelModal(popupModalContent?.id, expertId)}>
            Cancel Session
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const handleCancelSession = async () => {
    setOpenCancelSessionModal(false);
    await dispatch(fetchExpertSessionCancel(cancelSessionDetails));
    tableDataUpdate();
  };

  const handleCloseCancelSessionDialoag = () => {
    setOpenCancelSessionModal(false);
  };

  const cancelSessionModalContent = () => (
    <>
      <div>Are you sure you want to cancel this session?</div>
      <div className="logout-button-wrap">
        <SecondaryButton border onClick={() => handleCancelSession()}>
          Yes
        </SecondaryButton>
        <PrimaryButton onClick={() => handleCloseCancelSessionDialoag()}>No</PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledTable>
      <Modal
        openDialog={openSessionModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Session Details"
        dialogCss="dashboardSessionDetail"
      />

      <Modal
        openDialog={openCancelSessionModal}
        closeDialog={handleCloseCancelSessionDialoag}
        dialogContent={cancelSessionModalContent()}
        noHeader={false}
        heading="Cancel Session"
        dialogCss="logoutDialogWrap"
      />

      <TableContainer component={Paper} className="tableContainer">
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {type ? (
                <>
                  <StyledTableCell align="left">Service</StyledTableCell>
                  <StyledTableCell align="left">Date & Time</StyledTableCell>
                  <StyledTableCell align="left">Pricing</StyledTableCell>
                  <StyledTableCell align="left">Duration</StyledTableCell>
                  <StyledTableCell align="left">Seeker&apos;s Email</StyledTableCell>
                </>
              ) : (
                <>
                  <StyledTableCell align="left">Session</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Duration</StyledTableCell>
                  <StyledTableCell align="left">Start time</StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <StyledTableRow key={row.session + row.startTime}>
                {type ? (
                  <>
                    <StyledTableCell component="th" scope="row" style={{ padding: '30px 30px' }}>
                      {row.session}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.date} at
                      {row.startTime}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                      {}
                      {`${row.unit} ${row.price}`}
                    </StyledTableCell> */}
                    <StyledTableCell align="left">{row.duration}</StyledTableCell>
                    <StyledTableCell align="left">{row.seekerEmail}</StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell component="th" scope="row">
                      {row.session}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.date}</StyledTableCell>
                    <StyledTableCell align="left">{row.duration}</StyledTableCell>
                    <StyledTableCell align="left">{row.startTime}</StyledTableCell>
                    <StyledTableCell align="left" className="buttons-wrap">
                      <div
                        className="viewDetailButton"
                        onClick={(e) => handleOpenSessionDialog(e, row.sessionId)}>
                        View Details
                      </div>
                      <div
                        className="cancelBtn-wrap"
                        onClick={() => handleCancelModal(row.sessionId, row.expertId)}>
                        Cancel Session
                      </div>
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="cards-wrapper">
        {tableData.map((item) => (
          <div className="card-wrap">
            <div className="left-wrap">
              <div>
                <div className="heading">Service Name</div>
                <div className="subheading">{item.session}</div>
              </div>
              <div>
                <div className="heading">Duration</div>
                <div className="subheading">{item.duration}</div>
              </div>
              <div
                className="viewDetailsButton"
                onClick={(e) => handleOpenSessionDialog(e, item.sessionId)}>
                View Details
              </div>
            </div>
            <div className="right-wrap">
              <div>
                <div className="heading">Date</div>
                <div className="subheading">{item.date}</div>
              </div>
              <div>
                <div className="heading">Start time</div>
                <div className="subheading">{item.startTime}</div>
              </div>
              <div
                className="cancelButton"
                onClick={() => handleCancelModal(item.sessionId, item.expertId)}>
                Cancel Session
              </div>
            </div>
          </div>
        ))}
      </div>
    </StyledTable>
  );
}
