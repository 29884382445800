/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import StyledAccordianList from './StyledAccordianList';
import deleteIcon from '../../assets/svg/delete16x16.svg';
import LinkIcon from '../icons/LinkIcon';
import moreThreeDots from '../../assets/svg/moreThreeDots.svg';
import {
  StyledHeaderMenu,
  StyledHeaderMenuItem,
} from '../../layout/PrivateLayout/StyledPrivateLayout';
import DownArrow from '../icons/DownArrow';
import lang from '../../constants/lang';
import { fetchExpertSessionCancel } from '../../store/reducers/expertProfile/apiThunk';
import { PrimaryButton, SecondaryButton } from '../Button';
import Modal from '../Modal';

const { COPY_LINK, COPY_TO_CLIPBOARD, JOIN_MEETING } = lang;

export default function AccordianList({ data = [], tableDataUpdate = () => {}, tabIndex = 1 }) {
  const dispatch = useDispatch();
  const [menuEle, setMenuEle] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [copyText, setCopyText] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [cancelSessionDetails, setCancelSessionDetails] = useState('');
  const [openCancelSessionModal, setOpenCancelSessionModal] = useState(false);

  const open = Boolean(menuEle);

  const handleIconClick = (panel) => {
    if (expanded === panel) {
      setExpanded(null);
    } else {
      setExpanded(panel);
    }
  };

  const handleCancelSession = async () => {
    setOpenCancelSessionModal(false);
    await dispatch(fetchExpertSessionCancel(cancelSessionDetails));
    tableDataUpdate();
  };

  const handleCloseCancelSessionDialoag = () => {
    setOpenCancelSessionModal(false);
  };

  const cancelSessionModalContent = () => (
    <>
      <div>Are you sure you want to cancel this session?</div>
      <div className="logout-button-wrap">
        <SecondaryButton border onClick={() => handleCancelSession()}>
          Yes
        </SecondaryButton>
        <PrimaryButton onClick={() => handleCloseCancelSessionDialoag()}>No</PrimaryButton>
      </div>
    </>
  );

  const handleCopy = async (link) => {
    await navigator?.clipboard?.writeText(link);
    setCopyText(COPY_TO_CLIPBOARD);
  };

  const handleMenuOpen = (event, index) => {
    setMenuEle(event.currentTarget);
    setActiveItem(index);
  };

  const handleMenuClose = () => {
    setMenuEle(null);
    setActiveItem(null);
  };

  return (
    <>
      <Modal
        openDialog={openCancelSessionModal}
        closeDialog={handleCloseCancelSessionDialoag}
        dialogContent={cancelSessionModalContent()}
        noHeader={false}
        heading="Cancel Session"
        dialogCss="logoutDialogWrap"
      />

      <StyledAccordianList>
        {data.map((item, index) => (
          <Accordion key={item?.sessionId} expanded={expanded === index}>
            <AccordionSummary
              expandIcon={
                tabIndex === 1 ? <DownArrow onClick={() => handleIconClick(index)} /> : null
              }>
              <div className="serviceNameButtonWrap fullHeightWrap">
                <div>
                  <div className="heading">Service Name</div>
                  <div className="content">{item?.session}</div>
                </div>
                {tabIndex === 1 && (
                  <div className="displayBlockWrap" style={{ marginLeft: 'auto' }}>
                    <a
                      href={item?.meetLink}
                      className="button-wrap"
                      target="_blank"
                      rel="noreferrer">
                      {JOIN_MEETING}
                    </a>
                  </div>
                )}
              </div>
              <div className="fullHeightWrap" style={{ width: 'max-content' }}>
                <div className="heading">Date & Time</div>
                <div className="content">{`${item?.date} at ${item?.startTime}`}</div>
              </div>

              {tabIndex === 1 && (
                <div className="fullHeightWrap" style={{ width: 'max-content' }}>
                  <div className="heading">Duration</div>
                  <div className="content">{item?.duration}</div>
                </div>
              )}
              {tabIndex === 3 && (
                <div className="fullHeightWrap" style={{ width: 'fit-content' }}>
                  <div className="heading">Cancelled by</div>
                  <div className="content">{item?.cancelledBy === 1 ? 'Expert' : 'Seeker'}</div>
                </div>
              )}
              {tabIndex === 3 && (
                <div className="fullHeightWrap" style={{ width: 'max-content' }}>
                  <div className="heading">Cancelled on</div>
                  <div className="content">
                    {moment(item?.updatedAt).format('MMMM DD, YYYY [at] hh:mm A')}
                  </div>
                </div>
              )}
              {tabIndex !== 1 && (
                <div className="fullHeightWrap" style={{ width: 'max-content' }}>
                  <div className="heading">Seeker’s Email</div>
                  <div className="content">{item?.seekerEmail}</div>
                </div>
              )}

              {tabIndex === 1 && (
                <>
                  <div className="displayNoneWrap fullHeightWrap">
                    <div className="heading">Meeting Link</div>
                    <a
                      href={item?.meetLink}
                      className="button-wrap"
                      target="_blank"
                      rel="noreferrer">
                      Join
                    </a>
                  </div>
                  <div className="threeDotsWrap">
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleMenuOpen(e, index)}
                      sx={{
                        minWidth: 'auto',
                      }}>
                      <img src={moreThreeDots} alt="moreThreeDots" />
                    </Button>

                    <StyledHeaderMenu
                      className="header-menu"
                      anchorEl={menuEle}
                      open={open}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <Tooltip
                        title={copyText}
                        placement="right"
                        arrow
                        disableFocusListener
                        disableTouchListener>
                        <StyledHeaderMenuItem
                          onClick={() => handleCopy(data[activeItem]?.meetLink)}
                          onMouseOut={() => setCopyText(null)}>
                          <LinkIcon />
                          {COPY_LINK}
                        </StyledHeaderMenuItem>
                      </Tooltip>
                      <StyledHeaderMenuItem
                        onClick={() => {
                          const { expertId = '', sessionId = '' } = data[activeItem];
                          setCancelSessionDetails({
                            id: expertId,
                            sessionId,
                            type: 2,
                          });
                          setOpenCancelSessionModal(true);
                          setMenuEle(null);
                        }}
                        >
                        <img src={deleteIcon} alt="" srcSet="" />
                        Cancel Session
                      </StyledHeaderMenuItem>
                    </StyledHeaderMenu>
                  </div>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className="heading">Seeker’s Email</div>
                <div className="content">{item?.seekerEmail}</div>
              </div>
              {/* <div>
              <div className="heading">Pricing</div>
              <div className="content">{`${item?.unit} ${item?.price}`}</div>
            </div> */}
            </AccordionDetails>
          </Accordion>
        ))}
      </StyledAccordianList>
    </>
  );
}
