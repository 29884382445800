import React from 'react';
import { Box } from '@mui/material';
import StyledFrame from './StyledFrame';
import policyFrame from '../../assets/images/policyFrame.png';
import hiWing from '../../assets/svg/hiWingRight.svg';

const DiscloseHeadingFrame = () => (
  <StyledFrame>
    <Box>
      <img src={policyFrame} alt="" width="100%" className="image_frame" />
    </Box>
    <Box className="heading_container">
      <Box className="heading_main">
        {'Disclosure'}
        <Box className="wing_container">
          <img src={hiWing} alt="" />
        </Box>
      </Box>
    </Box>
  </StyledFrame>
);

export default DiscloseHeadingFrame;
