import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { fetchSendOtp, fetchSeekerVerifyOtp, fetchCreateSession } from './apiThunk';

const initialData = {
  otp: {},
  createSession: {},
};
const seekerSlice = createSlice({
  name: 'seeker',
  initialState: { ...initialData },
  reducers: {
    updateVerified: (state, action) => {
      state.otp = action.payload;
    },
    updatecreateSession: (state, action) => {
      state.createSession = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSendOtp.fulfilled, (state, action) => {
      state.otp = action.payload;
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchSendOtp.rejected, (state, action) => {
      state.otp = action.payload;
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchSeekerVerifyOtp.fulfilled, (state, action) => {
      state.otp = action.payload;
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchSeekerVerifyOtp.rejected, (state, action) => {
      state.otp = action.payload;
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchCreateSession.fulfilled, (state, action) => {
      state.createSession = action.payload;
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchCreateSession.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
  },
});

const { reducer } = seekerSlice;
const selectorseeker = (state) => state.seeker;
const { updateVerified, updatecreateSession } = seekerSlice.actions;
export { updateVerified, updatecreateSession, selectorseeker };

export default reducer;
