/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import PreviewLayout from '../Layout';
import CardsLayout from '../Layout/CardsLayout';
import Modal from '../../../components/Modal';
import lang from '../../../constants/lang';
import StyledFeedback from './StyledFeedback';
import quote from '../../../assets/svg/quote.svg';
import linkdin from '../../../assets/svg/linkdin-feedback.svg';
import twitter from '../../../assets/svg/twitter-feedback.svg';
import github from '../../../assets/svg/github-feedback.svg';
import dribble from '../../../assets/svg/dribble-feedback.svg';
import { fetchDashBoardFeedbackList } from '../../../store/reducers/expertProfile/apiThunk';

const Feedback = () => {
  const dispatch = useDispatch();
  const [feedbacks, setFeedbacks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [readMoreData, setReadMoreData] = useState(null);

  const { MY_FEEDBACK } = lang;

  const getIcon = (url) => {
    if (url.includes('x.com')) {
      return twitter;
    } if (url.includes('github')) {
      return github;
    } if (url.includes('dribbble')) {
      return dribble;
    } if (url.includes('linkedin')) {
      return linkdin;
    }
    return ''; // default case if no match is found
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setReadMoreData(null);
  };

  const fetchDashBoardFeedback = async () => {
    const response = await dispatch(fetchDashBoardFeedbackList());
    const { payload: { data: { result = [{}] } = {} } = {} } = response;
    setFeedbacks(result);
  };
  useEffect(() => { fetchDashBoardFeedback(); }, []);

  const feedbackModalContent = () => (
    <div className="content-wrap">
      <div className="feedback-content-wrap">
        <div className="content-top-wrap">
          <div className="content-top">
            <div className="top-left">
              <a href={readMoreData?.socialMediaLink} target="_blank" rel="noreferrer">
                <img alt="" src={readMoreData ? getIcon(readMoreData?.socialMediaLink) : null} className="social-img" />
              </a>
            </div>
            <div className="top-right">
              <div className="seeker-name">{readMoreData?.name}</div>
              <div className="feedback-date">{dayjs(readMoreData?.session?.eventStartTime?.dateTime).format('MMMM D, YYYY')}</div>
            </div>
          </div>
          <div>
            <img alt="" src={quote} className="quote-img" />
          </div>
        </div>
        <div className="description">{readMoreData?.message}
        </div>
        <div className="session-info-box">
          <div className="heading">Session Taken: </div>
          <div className="title">{readMoreData?.session?.title}</div>
        </div>
      </div>
    </div>
  );

  return (
    <StyledFeedback>
      <div className="feedback-page-wrap">
        <div className="feedback-header-wrap">{MY_FEEDBACK}</div>
        <div className="feedback-content-wrap">
          {feedbacks && feedbacks?.length === 0 ? (
            <div className="noFeedbackTitle">No Feedback yet</div>
          ) : (
            <Grid
              container
              xs={12}
              direction={{ xs: 'row', sm: 'row' }}
              spacing={2}
              >{
              feedbacks && feedbacks?.map(
                (
                  item,
                  index,
                ) => (
                  <Grid item xs={feedbacks.length > 3 ? 6 : 12}>
                    <div
                      className={`feedback-card-wrap ${index < feedbacks?.length - 1 ? 'customMarginBottom' : ''}`}>
                      <img alt="" src={quote} className="quote-img" />
                      <div className="content-top">
                        <div className="top-left">
                          <a href={item?.socialMediaLink} target="_blank" rel="noreferrer">
                            <img alt="" src={getIcon(item?.socialMediaLink)} className="social-img" />
                          </a>
                        </div>
                        <div className="top-right">
                          <div className="seeker-name">{item?.name}</div>
                          <div className="feedback-date">{dayjs(item?.session?.eventStartTime?.dateTime).format('MMMM D, YYYY')}</div>
                        </div>
                      </div>
                      <div className="description">{item?.message?.length > 90 ? <>{item?.message?.slice(0, 78)}... <span className="read-more" onClick={() => { setReadMoreData(item); setOpenDialog(true); }}>Read more</span> </> : item?.message} </div>
                      <div className="session-info-box">
                        <div className="heading">Session Taken: </div>
                        <div className="title">{item?.session?.title}</div>
                      </div>
                    </div>
                  </Grid>
                ),
              )
            }
            </Grid>
          )}
        </div>
      </div>
      <Modal
        openDialog={openDialog}
        closeDialog={closeDialog}
        noHeader={false}
        dialogCss="razorpayDialogWrap"
        dialogContent={feedbackModalContent()}
        heading={'Feedback Message'}
      />
    </StyledFeedback>
  );
};

export default PreviewLayout(CardsLayout(Feedback));
