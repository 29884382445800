import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import {
  fetchLogin,
  fetchLogout,
  fetchCreateProfile,
  fetchSignUp,
  fetchGetProfile,
  fetchEditProfile,
  fetchUsername,
  fetchSendOtp,
  fetchVerifyOtp,
} from './apiThunk';

const initialData = {
  userData: {},
  auth: {},
  signUp: {},
  domain: {},
  otp: {},
  temp: '',
};
const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: { ...initialData },
  reducers: {
    updateUser: (state, action) => {
      state.userData = action.payload;
    },
    updateAuth: (state, action) => {
      state.auth = action.payload;
    },
    logout: () => initialData,
    updateVerifiedEmail: (state, action) => {
      state.auth.data.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.auth = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchLogout.fulfilled, (state) => (
      { ...state, userData: {}, auth: {} }));
    builder.addCase(fetchLogout.rejected, (state, action) => {
      state.auth = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchCreateProfile.fulfilled, (state, action) => {
      state.temp = action.payload;
    });
    builder.addCase(fetchCreateProfile.rejected, (state, action) => {
      state.temp = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchSignUp.fulfilled, (state, action) => {
      state.signUp = action.payload;
    });
    builder.addCase(fetchSignUp.rejected, (state, action) => {
      state.signUp = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchGetProfile.fulfilled, (state, action) => {
      state.userData = action.payload;
    });
    builder.addCase(fetchGetProfile.rejected, (state, action) => {
      state.userData = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchEditProfile.fulfilled, (state, action) => {
      state.temp = action.payload;
    });
    builder.addCase(fetchEditProfile.rejected, (state, action) => {
      state.temp = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchUsername.fulfilled, (state, action) => {
      state.domain = action.payload;
    });
    builder.addCase(fetchUsername.rejected, (state, action) => {
      state.domain = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchSendOtp.fulfilled, (state, action) => {
      state.otp = { status: true };
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchSendOtp.rejected, (state, action) => {
      state.otp = action.payload;
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchVerifyOtp.fulfilled, (state, action) => {
      state.otp = { status: false };
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchVerifyOtp.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
  },
});

const { reducer } = authenticationSlice;
const selectorAuthentication = (state) => state.authentication;
const {
  updateUser,
  updateAuth,
  logout,
  updateVerifiedEmail,
} = authenticationSlice.actions;
export {
  updateUser,
  updateAuth,
  logout,
  updateVerifiedEmail,
  selectorAuthentication,
};

export default reducer;
