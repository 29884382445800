import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledOtpModal = styled(Dialog)(
  ({ theme }) => `
  &.otpModal{
    background-color: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(20px);
    .MuiModal-backdrop{
      background-color: unset;
    }
    .MuiDialog-paper{
      color: ${theme.palette.customColor.primaryBlack};
      width: 500px;
      text-align: center;
      padding: 24px 0px;
      border-radius: 10px;
      @media screen and (max-width: 480px){
        width: 90%;
        margin: 0px;
      }
    }
    .MuiDialogTitle-root{
      display: grid;
      grid-template-columns: 1fr 0fr;
      padding: 0px 0px;
      padding-left: 50px;
      padding-right: 20px;
    }
    .MuiDialogContent-root{
      @media screen and (max-width: 480px){
        padding: 0px 16px;
        padding-bottom: 16px;
      }
    }
    .otp-title{
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      font-family: Sora;
      color: ${theme.palette.customColor.primaryBlack};
      @media screen and (max-width: 600px){
        font-size: 16px;
      }
    }
    .otp-content-wrap{
      width: 450px;
      margin: auto;
      @media screen and (max-width: 768px){
        width: auto;
      }
      .otp-subheading{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-family: "Plus Jakarta Sans";
        color: ${theme.palette.customColor.primaryBlack};
        .subheading-mail{
          color: ${theme.palette.customColor.primaryBlack};
          font-weight: 600;
        }
        @media screen and (max-width: 600px){
          line-height: 18px;
          font-size: 12px;
        }
      }
      .otp-input-wrap{
        display: flex;
        flex-direction: column;
        margin: 40px auto;
        margin-bottom: 0px;
        text-align: left;
        .otp-label{
          margin-bottom: 6px;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          letter-spacing: -0.017em;
          color: ${theme.palette.customColor.primaryLightGray};
        }
        div{
        justify-content: space-between;
        .input-box {
          width: 66px !important;
          padding: 16px;
          border: 1px solid ${theme.palette.customColor.grey_6};
          color: ${theme.palette.customColor.primaryBlack};
          border-radius: 10px;
          font-weight: 400;
          font-size: 16px;
          @media screen and (max-width: 768px){
            width: 31px !important;
            padding: 14px;
          }
        }
        }
      }
    }
    .dialogAction{
      margin: 0px 24px;
    .footer-content{
      margin-bottom: 40px;
      @media screen and (max-width: 480px){
        margin-bottom: 32px;
      }
      .resendbtn{
        color: ${theme.palette.primary.main};
        cursor: pointer;
      }
      @media screen and (max-width: 600px){
        line-height: 18px;
        font-size: 12px;
      }
    }
    }
  }
`,
);
export default StyledOtpModal;
