import React from 'react';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/Button';
import StyledLeftSection from './StyledLeftSection';
import IconCards from '../../../components/Common/IconCard/IconCards';

export default function LeftSection() {
  const navigate = useNavigate();
  const data = [
    {
      icon: '🤩',
      description: 'Create own profile page & Link all your social handles',
    },
    {
      icon: '🎤 ',
      description: 'Get Invited for Speaking Gigs & Podcasts',
    },
    {
      icon: '📚',
      description: 'Sell Digital Products - Courses , Research Papers, Books',
    },
  ];
  return (
    <StyledLeftSection>
      <div className="left-section-wrap">
        <div className="subHeading">Welcome 😀</div>
        <div className="heading">
          Made for
          <br />
          Professionals to
          <br />
          Mentor, Learn, &
          <br />
          Grow
          <span className="highlightText"> Together!</span>
        </div>
        <div className="description">
          Monetize every interaction, from hosting 1:1 conversations and webinars to answering
          questions and selling digital products.
        </div>
        <PrimaryButton onClick={() => navigate('/login')} sx={{ width: '150px' }} endIcon={<EastIcon />}>
          Get Started
        </PrimaryButton>
        <div className="left-card-container">
          {data?.map((item) => (
            <IconCards icon={item.icon} description={item.description} className="left-card-wrap" />
          ))}
        </div>
      </div>
    </StyledLeftSection>
  );
}
