/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, TrinaryButton } from '../../components/Button';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { fetchSeekerSessionDetail } from '../../store/reducers/expertProfile/apiThunk';
import { updateLoading } from '../../store/reducers/loader';

export default function ConfirmationForm({ finalTransaction }) {
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({});
  // const paymentStatus = sessionStorage.getItem('transactionStatus');
  const navigate = useNavigate();
  const [timeSet, setTimeSet] = useState(false);

  // const closeQuickView = () => {
  //   if (sessionStorage.getItem('transactionSuccess') === 'true') {
  //     sessionStorage.setItem('transactionSuccess', 'false');
  //     navigate(`/${sessionStorage.getItem('expertUserName')}`);
  //   } else {
  //     navigate('/schedule');
  //   }
  // };

  // const handleBackButton = (event) => {
  //   // if (event?.type === 'popstate') {
  //   closeQuickView();
  //   // }
  // };

  // useEffect(() => {
  //   setTimeSet(true);
  //   sessionStorage.setItem('transactionBack', true);
  //   window.history.pushState('fake-route', document.title, window.location.href);
  //   window.onpopstate = handleBackButton;
  //   // Remove the event listener when the component is unmounted
  //   return () => {
  //     window.onpopstate = null;
  //   };
  // }, []);

  const dataFetch = async () => {
    const dataItem = {
      id: sessionStorage.getItem('sessionId'),
    };
    const response = await dispatch(fetchSeekerSessionDetail(dataItem)).then((val) => val);
    const { payload: { data, status: type, message } = {} } = response;
    if (type) {
      setUserDetail(data);
    } else {
      ToastNotifyError(message);
      navigate('/');
    }
  };
  useEffect(() => {
    dispatch(updateLoading(true));
    setTimeout(() => {
      dataFetch();
      dispatch(updateLoading(false));
    }, 5000);
  }, []);
  return (
    <div className="payment-response">
      <div className="payment-type">Your session has been scheduled successfully</div>
      {/* {sessionStorage.getItem('isSessionScheduledWithMeeting') === 'true' ? (
        <div className="payment-type">Your session has been scheduled successfully</div>
      ) : (
        <>
          {' '}
          <div className="payment-type">
            {'Payment '}
            {paymentStatus === 'processing' ? (
              <span>in progress.</span>
            ) : paymentStatus === 'succeeded' ? (
              <span>Successful.</span>
            ) : (
              <span>Failed.</span>
            )}
          </div>
          <div className="payment-text">
            Your payment with the transaction ID{' '}
            <span style={{ fontWeight: 'bold' }}>
              ‘{finalTransaction || sessionStorage.getItem('transactionId')}’
            </span>{' '}
            {paymentStatus === 'processing' ? (
              <span>is in progress.</span>
            ) : paymentStatus === 'succeeded' ? (
              <span>is successful.</span>
            ) : (
              <span>failed.</span>
            )}
          </div>
          {paymentStatus !== 'payment_failed' && (
            <div className="payment-text">Check your mail for further details.</div>
          )}
        </>
      )} */}

      <div className="session-detail">
        <div className="session_heading">{userDetail?.title || '-'}</div>

        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6}>
            <div className="session_sub">
              <div className="session_head">Date & Time</div>
              <div className="session_content">
                {userDetail?.eventStartTime?.dateTime
                  ? moment(userDetail?.eventStartTime?.dateTime).format(
                      'MMMM DD, YYYY [at] hh:mm A',
                    )
                  : '-'}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="session_sub">
              <div className="session_head">Duration</div>
              <div className="session_content">
                {userDetail?.sessionDuration ? `${userDetail?.sessionDuration} minutes` : '-'}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="session_sub">
              <div className="session_head">Expert’s Name</div>
              <div className="session_content">{userDetail?.expertName || '-'}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="session_sub">
              <div className="session_head">Meeting Link</div>
              <div
                className="session_content link"
                onClick={() => window.open(userDetail?.googleMeetLink, '_blank')}>
                {userDetail?.googleMeetLink || '-'}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* {paymentStatus === 'payment_failed' ? (
        <div className="button-failed">
          <TrinaryButton className="secondary-stripe" onClick={() => navigate('/schedule')}>
            Edit Session
          </TrinaryButton>
          <PrimaryButton
            className="primary-stripe"
            style={{ width: '100%' }}
            onClick={() => {
              sessionStorage.setItem('transactionSuccess', true);
              navigate('/seeker-subscription');
              window.location.reload();
            }}>
            Retry Payment
          </PrimaryButton>
        </div>
      ) : (
        <div className="button-failed">
          <PrimaryButton className="payment-button" onClick={() => navigate('/schedule')}>
            Continue
          </PrimaryButton>
        </div>
      )} */}

      <div className="button-failed">
        <PrimaryButton className="payment-button" onClick={() => navigate('/schedule')}>
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
}
