import { theme } from '../../theme';

const {
  palette: {
    primary: {
      main,
    },
  } = {},
} = theme;

const CalendarIcon = ({
  color = main,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.2753 5.63416L15.4669 16.9092C15.2669 17.7508 14.517 18.3341 13.6503 18.3341H2.70027C1.44194 18.3341 0.541951 17.1008 0.916951 15.8924L4.42528 4.62585C4.66694 3.84252 5.39196 3.30078 6.20862 3.30078H16.4586C17.2503 3.30078 17.9086 3.78412 18.1836 4.45079C18.342 4.80912 18.3753 5.21749 18.2753 5.63416Z" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" />
    <path d="M13.3333 18.3333H17.3166C18.3916 18.3333 19.2332 17.425 19.1582 16.35L18.3333 5" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.06689 5.31675L8.93357 1.7168" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.6497 5.32465L14.433 1.70801" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.41699 10H13.0837" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.58325 13.3325H12.2499" stroke={color} strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default CalendarIcon;
