/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../Button';
import TextInput from '../TextField';
import StyledContactFooter from './StyledContactFooter';
import Modal from '../Modal';
import { validationMailDescription } from '../../utils/validations/authValidation';
import { selectorAuthentication } from '../../store/reducers/authentication/index';
import { fetchCreateIssue } from '../../store/reducers/expertProfile/apiThunk';

const ContactFooter = () => {
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const { userData: { data: { primaryEmail: fetchedEmail = '' } = {} } = {} } =
    useSelector(selectorAuthentication);
  const disptach = useDispatch();
  const submitHandler = async (values, { resetForm }) => {
    const { bio } = values;
    const data = {
      description: bio,
    };
    const response = await disptach(fetchCreateIssue(data));
    const {
      payload: { status = false },
    } = response;
    if (status) {
      setOpenEmailModal(false);
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      bio: '',
    },
    validationSchema: validationMailDescription,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: { bio } = {},
    touched: { bio: tucBio = false } = {},
    errors: { bio: errBio } = {},
  } = formik;

  const handleCloseDialoag = () => {
    setOpenEmailModal(false);
    formik.resetForm();
  };

  const handleChange = (e, name) => {
    formik.setFieldValue(name, e.target.value);
  };

  const emailModalContent = () => (
    <form onSubmit={handleSubmit}>
      <div className="email-content">
        <div className="m-bottom-15">
          <TextInput
            name="email"
            type="email"
            important
            placeholder="Enter email"
            value={fetchedEmail}
            label="Email"
            disabled
          />
        </div>
        <div>
          <TextInput
            placeholder="Describe your issue"
            name="bio"
            type="text"
            classes="description"
            multiline
            rows={2}
            label="Describe your issue"
            important
            onChange={(e) => handleChange(e, 'bio')}
            value={bio}
            error={tucBio && !!errBio}
            helperText={tucBio && errBio}
            icon={`${bio.length}/500`}
            wordCount
            inputProps={{ maxLength: 500 }}
          />
        </div>
        <div className="footer-wrap">
          <PrimaryButton
            type="submit"
            sx={{
              width: '48%',
              '@media (max-width: 480px)': {
                width: '100%',
              },
            }}>
            Submit
          </PrimaryButton>
          <SecondaryButton
            sx={{
              width: '48%',
              '@media (max-width: 480px)': {
                width: '100%',
              },
              border: '1px solid rgba(255, 255, 255, 0.1)',
              color: '#DA3333' && {
                '&:hover': {
                  backgroundColor: '#DA3333',
                },
              },
            }}
            onClick={() => handleCloseDialoag()}>
            Cancel
          </SecondaryButton>
        </div>
      </div>
    </form>
  );

  const handleModalOpen = () => {
    setOpenEmailModal(true);
  };

  return (
    <StyledContactFooter>
      <Modal
        openDialog={openEmailModal}
        closeDialog={handleCloseDialoag}
        dialogContent={emailModalContent()}
        noHeader={false}
        heading={
          <div className="head" style={{ display: 'block' }}>
            <div className="heading">Tell us the issue</div>
            <div className="otp-heading">We&apos;ll connect with you with a solution</div>
          </div>
        }
        dialogCss="email-modal-content issueBox"
      />
      <div className="link-wrap-heading">Facing issue in setting up your landing page?</div>
      <div className="link-wrap">
        {'Write us on '}
        <span className="link" onClick={() => handleModalOpen()}>
          superconnectsco@gmail.com
        </span>
      </div>
    </StyledContactFooter>
  );
};

export default ContactFooter;
