import axios from 'axios';
import { ToastNotifyError } from '../components/Toast/ToastNotify';

const client = ({ isAuthHeader = true }, headers = {}) => {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };
  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  API.interceptors.request.use(
    (config) => {
      if (localStorage.getItem('auth') && isAuthHeader) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('auth')}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response: { data: { message } = {}, status } = {} } = error;
      // console.log('status', error.response.data);
      if (status === 401) {
        // Auth Failed Logout
        // console.log('status', status);
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        window.location.href = '/';
        ToastNotifyError(message);
      } else if (parseInt(status / 100) === 4) {
        ToastNotifyError(message);
      } else if (parseInt(status / 100) === 5) {
        // Sever Failed (Something Went Worng)
        ToastNotifyError(message);
      }
    },
  );

  return {
    get: (url, options = {}) => API.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) => API.post(url, data, { ...defaultOptions, ...options }),
    patch: (url, data, options = {}) => API.patch(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) => API.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) => API.delete(url, { ...defaultOptions, ...options }),
  };
};

const getAuthorization = (getState, ...restProps) => {
  const { authentication: { auth: { data: { token = '' } = {} } = {} } = {} } = getState();
  const API = client({ token, ...restProps[0] });
  return API;
};

export { getAuthorization };
export default client;
