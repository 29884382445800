import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import StyledTimerPicker from './StyledTimerPicker';

const minTimeDefault = dayjs().set('hour', 0).set('minute', 30).startOf('minute');

export default function BasicTimePicker(props) {
  const {
    customLabel = '',
    helperText = '',
    error = false,
    value = null,
    onChange,
    name = '',
    minTime = minTimeDefault,
    important = false,
    showAmPm = false,
  } = props;

  return (
    <StyledTimerPicker>
      <div className="input-Label_text label-space">
        {customLabel}
        {important ? <span className="important">*</span> : ''}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['TimePicker']}>
          <Box sx={{ width: '100%' }}>
            <DesktopTimePicker
              minTime={minTime}
              ampm={showAmPm}
              name={name}
              format="HH:mm"
              value={error ? '' : value}
              onChange={onChange}
              className="custom_time_ui"
              slotProps={{
                actionBar: {
                  style: {
                    justifyContent: 'center',
                  },
                },
              }}
            />
            <FormHelperText>{helperText}</FormHelperText>
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    </StyledTimerPicker>
  );
}
