import { styled } from '@mui/material/styles';
import homeScreenStar from '../../../assets/svg/homeScreenStar.svg';

const StyledRightSection = styled('div')(
  ({ theme }) => `
  width: 40%;
  @media screen and (max-width: 1024px){
    width: 60%;
    margin: auto;
  }
  @media screen and (max-width: 769px){
    width: 100%;
  }
  @media screen and (max-width: 480px){
    width: 100%;
  }
  .right-section-wrap{
    position: relative;
    width: 100%;
    @media screen and (max-width: 769px){
      margin-top: 0px;
    }
    .expertImage{
      width: 100%;
      @media screen and (max-width: 769px){
        width: 100%;
      }
    }
  }
  .right-section-wrap::before{
    content: '';
    position: absolute;
    background-image: url(${homeScreenStar});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
    width: 35%;
    top: 29%;
    height: 31%;
    left: -8%;
    @media screen and (max-width: 480px){
      display: none;
    }
  }
  .slick-dots {
    padding-left: 50px;
    @media screen and (max-width: 768px){
      padding-left: 0px;
    }
    .slick-active {
      width: 50px;
      background: ${theme.palette.primary.main};
    }
  }
  .slick-dots li {
    width: 20px;
    height: 8px;
    margin: 0 6px;
    // color: grey;
    color: ${theme.palette.customColor.purpleLight};
    background: ${theme.palette.customColor.purpleLight};
    border-radius: 10px;
    overflow: hidden;
  }
  .slick-dots li button {
    width: 20px;
    height: 8px;
    padding: 5px;
  }
  .slick-slide div {
    outline: none;
   }
    `,
);

export default StyledRightSection;
