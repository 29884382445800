import { theme } from '../../theme';

const {
  palette: {
    primary: {
      main,
    },
  } = {},
} = theme;

const StarIcon = ({
  color = main,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.3333 9.08366V3.41699C18.3333 2.16699 17.8 1.66699 16.475 1.66699H13.1083C11.7833 1.66699 11.25 2.16699 11.25 3.41699V9.08366C11.25 10.3337 11.7833 10.8337 13.1083 10.8337H16.475C17.8 10.8337 18.3333 10.3337 18.3333 9.08366Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3333 16.583V15.083C18.3333 13.833 17.8 13.333 16.475 13.333H13.1083C11.7833 13.333 11.25 13.833 11.25 15.083V16.583C11.25 17.833 11.7833 18.333 13.1083 18.333H16.475C17.8 18.333 18.3333 17.833 18.3333 16.583Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75008 10.917V16.5837C8.75008 17.8337 8.21675 18.3337 6.89175 18.3337H3.52508C2.20008 18.3337 1.66675 17.8337 1.66675 16.5837V10.917C1.66675 9.66699 2.20008 9.16699 3.52508 9.16699H6.89175C8.21675 9.16699 8.75008 9.66699 8.75008 10.917Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75008 3.41699V4.91699C8.75008 6.16699 8.21675 6.66699 6.89175 6.66699H3.52508C2.20008 6.66699 1.66675 6.16699 1.66675 4.91699V3.41699C1.66675 2.16699 2.20008 1.66699 3.52508 1.66699H6.89175C8.21675 1.66699 8.75008 2.16699 8.75008 3.41699Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default StarIcon;
