import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFrame = styled(Grid)(
  () => `
  position:relative;
      .blog_container{
        height:100%;
        @media screen and (max-width: 900px){
          border-radius: 24px;
          background: var(--White, #FFF);
          box-shadow: 0px 0px 40px 10px rgba(89, 48, 11, 0.08);
        }
      }

  .blog_frame_img{
    aspect-ratio: 7 / 5;
    width: 100%;
    border-radius: 20px;
  }

  .blogs_heading{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color:  #1C1C1C;
    font-family: Sora;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .blogs_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color:  #525252;
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .chip_blog{
    padding: 4px 10px;
    border-radius: 15px;
    background: #F8F4F0;
    color:  #1C1C1C;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  
      
    @media screen and (max-width: 900px){
      
    }
    
      
`,
);

export default StyledFrame;
