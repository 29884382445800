import * as React from 'react';
import { StyledCreate } from './StyledCreate';
import PersonalInfo from './PersonalInfo';
import SocialLink from './SocialLink';
import ReadExplore from './ReadExplore';
import Service from './Service';
// import Payment from './Payment';

export default function CreateLanding({ stepNumber = 0, setActiveStep }) {
  const handleRenderSection = () => {
    switch (stepNumber) {
      case 0:
        return <PersonalInfo stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 1:
        return <Service stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 2:
        return <SocialLink stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 3:
        return <ReadExplore stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      // case 4:
      // return <Payment stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      default:
        return '';
    }
  };

  return (
    <StyledCreate>
      <div className="page-content-wrap">
        <div className="content-wrap">{handleRenderSection()}</div>
      </div>
    </StyledCreate>
  );
}
