import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import StyledBlogsCard from './StyledBlogsCard';

export default function BlogsCard({
  img = '',
  title = '',
  description = '',
  date = '',
  time = '',
  id,
}) {
  const navigate = useNavigate();
  const Chip = ({ children }) => <Box className="chip_blog">{children}</Box>;
  return (
    <StyledBlogsCard>
      <Grid container gap={2} className="blog_container" p={1.5}>
        <Grid item xs={12}>
          <Box>
            <img className="blog_frame_img" src={img} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1} className="blogs_heading" onClick={() => navigate(`/blogs/${id}`)}>
            {title}
          </Box>
          <Box className="blogs_desc">
            {/* <div dangerouslySetInnerHTML={{ __html: data?.html }} /> */}
            {description}
          </Box>
        </Grid>
        <Grid item xs={12} mt={'auto'}>
          <Stack flexDirection={'row'} gap={'6px'}>
            <Chip>{moment(date).format('MMM YY')}</Chip>
            <Chip>{time} min read</Chip>
          </Stack>
        </Grid>
      </Grid>
    </StyledBlogsCard>
  );
}
