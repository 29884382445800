import { styled } from '@mui/material/styles';

const StyledTermsAndCondition = styled('div')(
  () => `
    .about-page-wrap{
      background: #fff;
      color: #1C1C1C;
      text-align: left;
      padding: 24px 100px;
      
      .bread_text, .MuiBreadcrumbs-ol, .MuiBreadcrumbs-li .MuiTypography-root{
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      
      @media screen and (max-width: 900px){
        padding: 50px;
      }
      @media screen and (max-width: 600px){
        padding: 16px;
      }
      
       .heading_h1{
        color: var(--Black, #1C1C1C);
        font-family: Sora;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 600px){
          font-size: 20px;
        }
      }
      .heading_h2{
        color: var(--Black, #1C1C1C);
        font-family: Sora;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 600px){
          font-size: 18px;
        }
      }
      .heading_h3{
        color: var(--Black, #1C1C1C);
        font-family: Sora;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 600px){
          font-size: 16px;
        }
      }

      .body_1{
        color: var(--Black, #1C1C1C);
        font-family: "Plus Jakarta Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; 
        @media screen and (max-width: 600px){
          font-size: 16px;
          line-height: 27px; 
        }
      }
      .body_2{
        color: var(--Black, #1C1C1C);
        font-family: "Plus Jakarta Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; 
        @media screen and (max-width: 600px){
          font-size: 14px;
          line-height: 24px;
        }
      }
      ul{
        padding-inline-start: 30px;
      }
      li{
        margin-bottom:8px;
      }
    }
      
    }
`,
);

export default StyledTermsAndCondition;
