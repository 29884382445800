import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';
import loader from './loader';
import expertProfile from './expertProfile';

const rootReducer = combineReducers({
  authentication,
  loader,
  expertProfile,
});

export default rootReducer;
