import { styled } from '@mui/material/styles';
import homeSection1bg from '../../assets/images/home-section1bg.png';
import homeSection1bgMobile from '../../assets/images/home-section1bg-mobile.png';
import homeSection3bgMobile from '../../assets/images/home-section3bg-mobile.png';
import homeSection5bgMobile from '../../assets/images/home-section5bg-mobile.png';
import homeSection2bg from '../../assets/images/home-section2bg.png';
import homeSection3bg from '../../assets/images/home-section3bg.png';
import homeSection5bg from '../../assets/images/home-section5bg.png';
import homeScreenRound from '../../assets/svg/homeScreenRound.svg';
import homeScreeenSeekerSectionCircle from '../../assets/svg/homeScreeenSeekerSectionCircle.svg';
import homeScreenBlogsSectionCircle from '../../assets/svg/homeScreenBlogsSectionCircle.svg';
import homeScreenStar from '../../assets/svg/homeScreenStar.svg';
import homeScreenArrow from '../../assets/svg/homeScreenArrow.svg';
import homeScreenThreeLines from '../../assets/svg/homeScreenThreeLines.svg';

const StyledHomePage = styled('div')(
  ({ theme }) => `
  .homePage-wrap {
    .bg-image-wrap{
      background-image: url(${homeSection1bg});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;    
      .welcome-section-wrap{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 50px 100px;
        @media screen and (max-width: 1024px){
          flex-direction: column;
          row-gap: 20px;
        }
        @media screen and (max-width: 769px){
          flex-direction: column;
        }
        @media screen and (max-width: 550px){
          background-image: url(${homeSection1bgMobile});
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;    
          padding: 50px 30px;
        }
        @media screen and (max-width: 480px){
          padding: 24px 16px;
          padding-bottom: 42px;
        }
        .heading{
          color: ${theme.palette.customColor.primaryBlack};
          font-weight: 800;
          font-size: 58px;
          line-height: 79px;
          margin: 16px 0px;
          @media screen and (max-width: 1250px){
            font-size: 38px;
            line-height: normal;
          }
          @media screen and (max-width: 480px){
            margin: 12px 0px;
            font-size: 32px;
            line-height: 44px;
          }
        }
        .description{
          color: ${theme.palette.customColor.primaryBlack};
          font-weight: 500;
          font-size: 18px;
          font-family: "Plus Jakarta Sans";
          line-height: 27px;
          margin-bottom: 24px;
          @media screen and (max-width: 480px){
            line-height: 22px;
            font-size: 14px;
          }
        }
      }
    }
    .page-content-wrap{
      .getStarted-section-wrap{
        background-image: url(${homeSection2bg});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;      
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 80px;
        row-gap: 80px;
        @media screen and (max-width: 769px){
          flex-direction: column;
        }
        @media screen and (max-width: 550px){
          padding: 30px;
        }
        @media screen and (max-width: 480px){
          padding: 40px 16px;
        }
        .left-section-wrap{
          display: flex;
          flex-direction: column;
          align-items: center;      
          @media screen and (max-width: 769px){
            width: 100%;
          }
          .heading{
            width: fit-content;
            position: relative;
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            @media screen and (max-width: 1250px){
              line-height: normal;
              font-size: 36px;
            }
            @media screen and (max-width: 480px){
              font-size: 24px;
              line-height: 32px;
            }
          }
          .heading::before{
            content: '';
            position: absolute;
            background-image: url(${homeScreenStar});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            width: 126px;
            height: 126px;
            top: -35px;
            left: -79px;
            @media screen and (max-width: 480px){
              width: 102px;
              height: 102px;
              top: -42px;
              left: -66px;
            }
          }
          .heading::after{
            content: '';
            position: absolute;
            background-image: url(${homeScreenThreeLines});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            width: 33px;
            height: 36px;
            right: 79px;
            bottom: 30px;
            @media screen and (max-width: 1250px){
              right: 46px;
              bottom: 14px;          
            }
            @media screen and (max-width: 480px){
              right: 35px;
              width: 14px;
              height: 19px;
            }
          }
          .description{
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 500;
            font-size: 18px;
            font-family: "Plus Jakarta Sans";
            line-height: 27px;
            margin-top: 16px;
            margin-bottom: 40px;
            width: 50%;
            @media screen and (max-width: 1250px){
              width: auto;
            }
            @media screen and (max-width: 480px){
              font-size: 14px;
              line-height: 22px;
            }
          }
          .getStarted-button-wrap{
            position: relative;
          }
          .getStarted-button-wrap::before{
            content: '';
            position: absolute;
            background-image: url(${homeScreenArrow});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            width: 146px;
            height: 39px;
            bottom: -64px;
            right: -97px;
            @media screen and (max-width: 480px){
              width: 127px;
              height: 33px;
              right: -48px;
            }
          }
        }
        .right-section-wrap{
          width: 100%;
          @media screen and (max-width: 769px){
            width: 100%;
          }
          .expertImage{
            width: 100%;
            @media screen and (max-width: 480px){
              display: none;
            }
          }
          .mobile-expertImage{
            display: none;
            @media screen and (max-width: 480px){
              display: block;
              width: 100%;
            }
          }
        }
      }
      .forExpert-section-wrap{
        background-image: url(${homeSection3bg});
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 90%;
        margin: auto;
        border-radius: 20px;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;    
        padding-top: 38px;
        padding-right: 30px;
        padding-left: 40px;
        @media screen and (max-width: 1024px){
          flex-direction: column;
          row-gap: 20px;
        }
        @media screen and (max-width: 768px){
          margin-bottom: 40px;
          width: auto;
        }
        @media screen and (max-width: 480px){
          row-gap: 0px;
        }
        @media screen and (max-width: 480px){
          background-image: url(${homeSection3bgMobile});
          background-position: center;
          padding: 0px;
          width: 100%;
          min-height: 1026px;
        }
        .left-section-wrap{
          display: flex;
          flex-direction: column;
          gap: 25px;
          @media screen and (max-width: 1250px){
            gap: 14px;
          }
          @media screen and (max-width: 1024px){
            align-items: center;
          }
          @media screen and (max-width: 480px){
            align-items: flex-start;
          }
          .subHeading{
            font-size: 16px;
            font-family: "Plus Jakarta Sans";
            font-weight: 700;
            line-height: normal;
            width: fit-content;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: ${theme.palette.customColor.primaryBlack};
            background: ${theme.palette.secondary.main};
            border-radius: 10px;
            box-shadow: ${theme.palette.boxShadow.secondary};
            padding: 10px;
            @media screen and (max-width: 1250px){
              font-size: 15px;
            } 
            @media screen and (max-width: 480px){
              font-size: 12px;
              margin-top: 24px;
              margin-left: 16px;
              letter-spacing: 1.2px;
            }
          }
          .heading{
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 800;
            font-size: 58px;
            line-height: 79px;
            @media screen and (max-width: 1250px){
              font-size: 34px;
              line-height: normal;
            }
            @media screen and (max-width: 1024px){
              br{
                display: none;
              }
            }
            @media screen and (max-width: 480px){
              font-size: 24px;
              line-height: 32px;
              margin-left: 16px;
              br{
                display: none;
              }
            }
          }
        }
        .right-section-wrap{
          width: 44vw;
          @media screen and (max-width: 1250px){
            width: 60vw;
            margin: auto;
          }
          @media screen and (max-width: 480px){
            display: none;
          }
          .expertContentImage{
            width: 100%;
          }
        }
      }
      .forSeeker-section-wrap{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 720px;
        background: ${theme.palette.customColor.tertiaryGradient};
        flex-direction: row;
        padding: 88px 80px;
        @media screen and (max-width: 1024px){
          flex-direction: column;
          row-gap: 32px;
        }
        @media screen and (max-width: 769px){
          flex-direction: column;
          padding: 0px;
        }
        @media screen and (max-width: 480px){
          padding: 0px;
          margin-top: 40px;
          padding-bottom: 100px;
        }
        .left-section-wrap{
          display: flex;
          flex-direction: column;
          text-align: left;
          border-radius: 5px;
          width: 100%;
          padding-left: 78px;
          @media screen and (max-width: 1024px){
            padding-left: 0px;
          }
          @media screen and (max-width: 769px){
            padding: 30px 100px;
            width: -webkit-fill-available;
            border-radius: 0;
          }
          @media screen and (max-width: 550px){
            padding: 30px;
            width: -webkit-fill-available;
            border-radius: 0;
          }
          @media screen and (max-width: 480px){
            padding: 16px;
            width: -webkit-fill-available;
            border-radius: 0;
          }
          .header-wrap{
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            @media screen and (max-width: 480px){
              row-gap: 20px;
            }
            .subHeading{
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 1.6px;
              text-transform: uppercase;
              color: ${theme.palette.customColor.primaryBlack};
              background: ${theme.palette.secondary.main};
              width: fit-content;
              padding: 12px 20px;
              border-radius: 10px;
              box-shadow: ${theme.palette.boxShadow.secondary};
              @media screen and (max-width: 1250px){
                font-size: 15px;
              }
              @media screen and (max-width: 480px){
                font-size: 12px;
              }
            }
            .heading{
              position: relative;
              color: ${theme.palette.customColor.primaryBlack};
              font-weight: 800;
              font-size: 58px;
              line-height: 79px;
              width: fit-content;
              @media screen and (max-width: 1250px){
                font-size: 34px;
                line-height: normal;
              }
              @media screen and (max-width: 480px){
                font-size: 24px;
                line-height: 32px;
                br{
                  display: none;
                }
              }
              .highlightText::before{
                content: '';
                position: absolute;
                background-image: url(${homeScreeenSeekerSectionCircle});
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 111px;
                z-index: 2;
                top: 2px;
                height: 65px;
                left: 8px;
                @media screen and (max-width: 1250px){
                  width: 65px;
                  top: 1px;
                  height: 38px;
                }
                @media screen and (max-width: 480px){
                  width: 44px;
                  height: 26px;
                  left: 5px;
                }
              }
            }
            .heading::after{
              content: '';
              position: absolute;
              background-image: url(${homeScreenThreeLines});
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              z-index: 1;
              width: 33px;
              height: 36px;
              right: -34px;
              bottom: 46px;          
              @media screen and (max-width: 1250px){
                bottom: 20px;          
              }
              @media screen and (max-width: 480px){
                display: none;
              }
            }  
            .description{
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 162.5% */
              color: ${theme.palette.customColor.secondaryGrey};
              @media screen and (max-width: 1250px){
                font-size: 14px;
                line-height: 27px;
                margin-bottom: 20px;
              }
              @media screen and (max-width: 480px){
                line-height: 24px;
              }
            }
          }
          .content-wrap{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 52px;
            column-gap: 20px;
            row-gap: 30px; 
            @media screen and (max-width: 480px){
              margin-top: 40px;
            }
            .left-card-wrap{
              .card-wrap{
                @media screen and (max-width: 768px){
                  max-width: 132px;
                  min-height: 55px;
                }
                @media screen and (max-width: 480px){
                  min-height: 49px;
                  padding: 16px;
                }
                .card-description{
                  @media screen and (max-width: 480px){
                    text-wrap: wrap;
                    font-size: 14px;
                  }
                }
              }
            }       
            .detail-wrap{
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              .card-header{

              } 
              .card-content{
                font-weight: 700;
                font-size: 18px;
                text-transform: capitalize;
                color: ${theme.palette.customColor.secondaryWhite};
                margin-left: 10px;
                @media screen and (max-width: 1250px){
                  font-size: 12px;
                }
                @media screen and (max-width: 480px){
                  font-size: 16px;
                }
              }
            }
          }
        }
        .right-section-wrap{
          width: 82vw;
          max-width: 575px;
          @media screen and (max-width: 1024px){
            margin: auto;
          }
          @media screen and (max-width: 480px){
            width: 94vw;
            margin: auto;
          }
          .seekerImage{
            width: 100%;
            @media screen and (max-width: 769px){
              width: 100%;
            }
          }
        }
      }
      .practice-section-wrap{
        background-image: url(${homeSection5bg});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin: auto;    
        margin-top: -170px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        min-height: 805px;
        width: 95vw;
        @media screen and (max-width: 1025px){
          margin-top: -70px;
          min-height: unset;
          background-size: cover;
          padding: 50px 0px;
        }
        @media screen and (max-width: 769px){
          width: 90vw;
          margin: auto;
          margin-top: -42px;
        }
        @media screen and (max-width: 480px){
          padding: 33px 0px;
          width: 100vw;
          background-image: url(${homeSection5bgMobile});
        }
        .header-wrap{
          margin-top: 84px;
          @media screen and (max-width: 1250px){
            margin-top: 10vw;
          }
          @media screen and (max-width: 1024px){
            margin-top: 0vw;
          }
          @media screen and (max-width: 480px){
            width: 80%;
          }
          .subHeading{
            border-radius: 10px;
            padding: 12px 20px;
            box-shadow: ${theme.palette.boxShadow.secondary};
            width: fit-content;
            margin: auto;        
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: ${theme.palette.customColor.primaryBlack};
            background: ${theme.palette.secondary.main};
            @media screen and (max-width: 1250px){
              font-size: 20px;
            } 
            @media screen and (max-width: 480px){
              font-size: 12px;
              letter-spacing: 1.2px;
              padding: 8px 12px;
            }
          }
          .heading{
            position: relative;
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 800;
            font-size: 58px;
            line-height: 79px;
            z-index: 2;
            margin: 14px 0px;
            @media screen and (max-width: 1250px){
              font-size: 34px;
              line-height: normal;
              margin: 24px 0px;
            }
            @media screen and (max-width: 480px){
              font-size: 24px;
              line-height: 32px;
              margin-top: 20px;
              margin-bottom: 16px;
            }
          }
          .heading::before{
            content: '';
            position: absolute;
            background-image: url(${homeScreenStar});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            width: 126px;
            height: 126px;
            top: -75px;
            right: -86px;
            @media screen and (max-width: 1250px){
              width: 90px;
              height: 90px;
              top: -50px;
              right: 2px;
            }
            @media screen and (max-width: 480px){
              width: 71px;
              height: 71px;
              top: -50px;
              right: -11px;
            }
          }
          .heading::after{
            content: '';
            position: absolute;
            background-image: url(${homeScreenThreeLines});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            width: 33px;
            height: 36px;
            top: -9px;
            left: -24px;
            transform: rotateY(180deg);
            @media screen and (max-width: 1250px){
              top: -17px;
              left: 41px;          
            }
            @media screen and (max-width: 480px){
              width: 14px;
              height: 19px;
              left: 21px;
              top: -7px;
            }
          }
          .description{
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            color: ${theme.palette.customColor.secondaryGrey};
          @media screen and (max-width: 480px){
              padding: 0px;
            }
            @media screen and (max-width: 480px){
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .content-wrap{
          display: flex;
          justify-content: space-between;
          margin-top: 64px;
          @media screen and (max-width: 769px){
            flex-wrap: wrap;
          }
          @media screen and (max-width: 480px){
            width: 80%;
            margin-top: 64px;
            justify-content: center;
          }
          .card-wrap{
            border-radius: 24px;
            background: ${theme.palette.secondary.main};
            box-shadow: ${theme.palette.boxShadow.tertiary};
            border-radius: 10px;
            padding: 30px;
            width: 300px;
            margin: 0px 20px;
            @media screen and (max-width: 1024px){
              padding: 20px;
            }
            @media screen and (max-width: 769px){
              margin: 30px 20px;
              width: auto;
            }
            @media screen and (max-width: 480px){
              margin: 0px;
              margin-bottom: 50px;
              width: auto;
            }
            .card-header{
              margin: auto;
              margin-top: -70px;
              font-size: 45.043px;
              border-radius: 100%;
              background: ${theme.palette.secondary.main};
              width: fit-content;
              padding: 10px;
              box-shadow: ${theme.palette.boxShadow.fourth};
              @media screen and (max-width: 1024px){
                margin-top: -55px;
                font-size: 32.043px;
              }
            }
            .card-content{
              .heading{
                margin-top: 24px;
                font-family: "Plus Jakarta Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px; /* 135% */
                text-align: center;
                color: ${theme.palette.customColor.primaryBlack};
                @media screen and (max-width: 1024px){
                  margin-top: 8px;
                }
                @media screen and (max-width: 480px){
                  font-size: 16px;
                }
              }
              hr{
                width: 68px;
                height: 2px;
                background: ${theme.palette.customColor.primaryBlack};
                color: ${theme.palette.customColor.primaryBlack};
              }
              .description{
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                text-align: center;
                @media screen and (max-width: 1250px){
                  font-size: 14px;
                } 
                @media screen and (max-width: 480px){
                  font-size: 14px;
                }
              }
            }
            .card-footer{
              margin: auto;
              margin-top: 32px;
              width: 50%;
              @media screen and (max-width: 1024px){
                margin-top: 20px;
              }
              @media screen and (max-width: 1024px){
                width: 170px;
              }
            }
          }
        }
      }
      .ourBlogs-section-wrap{
        padding: 60px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        @media screen and (max-width: 768px){
          padding: 30px;
        }
        @media screen and (max-width: 480px){
          padding: 16px;
        }
        .heading-wrap{
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          @media screen and (max-width: 480px){
            row-gap: 0px;
          }
          .heading{
            color: ${theme.palette.customColor.primaryBlack};
            text-align: center;
            font-family: Sora;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            @media screen and (max-width: 1250px){
              font-size: 38px;
            }
            @media screen and (max-width: 480px){
              font-size: 24px;
              line-height: normal;
            }
            .highlightText::after{
              content: '';
              position: absolute;
              background-image: url(${homeScreenThreeLines});
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              z-index: 0;
              width: 33px;
              height: 36px;
              bottom: 30px;
              @media screen and (max-width: 1250px){
                bottom: 22px;
              }
              @media screen and (max-width: 480px){
                width: 14px;
                height: 18px;
                bottom: 16px;            
              }
            }  
            .highlightText::before{
              content: '';
              position: absolute;
              background-image: url(${homeScreenBlogsSectionCircle});
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              width: 161px;
              z-index: 2;
              top: -1px;
              height: 64px;
              left: 7px;
              @media screen and (max-width: 1250px){
                width: 121px;
                top: -1px;
                height: 49px;
                left: 3px;
              }
              @media screen and (max-width: 480px){
                width: 76px;
                height: 31px;
              }
            }
          }
          .description{
            color: ${theme.palette.customColor.secondaryGrey};
            text-align: center;
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.18px;
            @media screen and (max-width: 480px){
              font-size: 14px;
              line-height: normal;
            }
          }
        }
        .button-wrap{
          width: 145px;
          margin: auto;
        }
      }
      .faq-section-wrap{
        background: ${theme.palette.customColor.grey_5};
        padding: 80px;
        display: flex;
        @media screen and (max-width: 1024px){
          flex-direction: column;
          row-gap: 24px;
        }
        @media screen and (max-width: 769px){
          flex-direction: column;
        }
        @media screen and (max-width: 550px){
          padding: 30px;  
        }
        @media screen and (max-width: 480px){
          padding: 30px 16px;
        }
        .faq-left-section{
          display: flex;
          flex-direction: column;
          row-gap: 24px;      
          .subHeading{
            border-radius: 10px;
            padding: 12px 20px;
            box-shadow: ${theme.palette.boxShadow.secondary};
            width: fit-content;
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: ${theme.palette.customColor.primaryBlack};
            background: ${theme.palette.secondary.main};
  
          }
          .heading{
            position: relative;
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 800;
            font-size: 58px;
            line-height: 79px;
            text-align: left;
            @media screen and (max-width: 1250px){
              font-size: 38px;
              line-height: normal;
            }
            @media screen and (max-width: 480px){
              font-size: 16px;
              font-weight: 700;
              margin-right: 0px;
              line-height: normal;
            }
          }
          .heading::after{
            content: '';
            position: absolute;
            background-image: url(${homeScreenThreeLines});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: 0;
            width: 33px;
            height: 36px;
            bottom: 45px;
            @media screen and (max-width: 1250px){
              bottom: 0px;
            }
          }
          .description{
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            text-align: left;
            color: ${theme.palette.customColor.secondaryGrey};
          }
        }
        .content{
          width: 75%;
          @media screen and (max-width: 1024px){
            width: 100%;
          }
          @media screen and (max-width: 769px){
            width: 100%;          
          }
        }
      }
    }
    .highlightText{
      color: ${theme.palette.primary.main};
      position: relative;
      text-wrap: nowrap;
    }
    .highlightText::before{
      content: '';
      position: absolute;
      background-image: url(${homeScreenRound});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 321px;
      z-index: 2;
      top: -12px;
      height: 91px;
      left: 0px;
      @media screen and (max-width: 1250px){
        width: 201px;
        top: -6px;
        height: 56px;
      }
      @media screen and (max-width: 480px){
        width: 155px;
        height: 45px;
      }
    }
   .MuiAccordion-root{
     color: white;
     padding: 0px 24px;
     margin: 12px 0px;
     border-radius: 16px;
     background: ${theme.palette.secondary.main};
     box-shadow: ${theme.palette.boxShadow.tertiary};
   }
   .MuiAccordionSummary-root{
    padding: 0px;
   }
   .MuiAccordionSummary-content {
    margin: 20px 0;
    .MuiTypography-root{
      color: ${theme.palette.customColor.primaryBlack};
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      @media screen and (max-width: 480px){
        font-size: 14px;
        font-weight: 700;
        margin-right: 30px;
      }
    }
  }
  .MuiPaper-root::before{
    display: none;
  }
  .MuiAccordionDetails-root{
    color: ${theme.palette.customColor.secondaryGrey};
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding: 0px;
    padding-bottom: 20px;
    text-align: left;
    word-break: break-all;
    @media screen and (max-width: 480px){
      font-size: 12px;
      font-weight: 400;
    }
  }
  .MuiSvgIcon-root{
    color: ${theme.palette.customColor.purpleLight};
  }
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
    transform: rotate(135deg);
  }
    
`,
);

export default StyledHomePage;
