import { styled } from '@mui/material/styles';

const StyledLinks = styled('div')(
  ({ theme }) => `
  .links-page-wrap{
    padding:24px;
    box-shadow: 0px 0px 23px 0px #0000000F;
    height:calc(100% - 48px);
    border-radius:24px;
    .links-header-wrap{
      color: ${theme.palette.customColor.primaryBlack};
      text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.10);
      font-size: 32px;
      font-weight: 700;
      text-align: left;
      @media screen and (max-width: 480px){
        font-size: 24px;
      }
    }
    .links-content-wrap{
        margin-top: 20px;
        .inner-header-wrap{
            display: flex;
            margin-bottom: 20px;
            .button-tabs{
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.64px;
                text-align: left;
                border-radius: 50px;
                border: 1px solid #415DF3;
                padding: 10px 20px;
                margin-right: 15px;
                cursor: pointer;
                @media screen and (max-width: 480px){
                    font-size: 14px;
                }
            }
        }
        .content-wrap{
            overflow-y: auto;
            height: calc(100vh - 10.18vh - 11.54vh - 5.98vh - 6.33vh - 98px - 60px);

            @media (min-width: 900px) and  (max-height: 800px) {
                height: calc(100vh - 10.18vh - 10.54vh - 148px - 60px);
            }

            @media screen and (max-width: 900px){
                height: calc(100vh - 5.20vh - 8.38vh - 2.27vh - 1.81vh - 108px - 60px);
            }

            @media screen and (max-width: 600px){
                height: calc(100dvh - 264px - 60px); 
            }

            .noContentTitle{
                color: ${theme.palette.customColor.primaryBlack};
                font-size: 16px;
                text-align: left;
                overflow-wrap: break-word;
                text-align: center;
                margin-top: 20px;
                @media screen and (max-width: 480px){
                    font-size: 14px;
                }
            }
            .card-wrap{
                display: flex;
                flex-direction:column;
                gap:16px;
                border-radius: 12px;
                border: 1px solid #E2E4E7;
                box-shadow: 0px 4px 13px 0px #00000005;
                padding: 20px;
                .mediaImage{
                    width:16px;
                    height:16px;
                }
                .title{
                    color: ${theme.palette.customColor.primaryBlack};
                    font-family: Sora;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20.16px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    @media screen and (max-width: 60px){
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
                .type_container{
                    padding: 7px 10px;
                    border-radius: 10px;
                    background: #F8F4F0;
                    font-family: Sora;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: left;
                    color: #1C1C1C;
                }
                .mobileTitle{
                    width: 100%;
                    @media screen and (max-width: 768px){
                        width: 87%;
                    }
                    @media screen and (max-width: 480px){
                        width: 80%;
                    }
                }
                .course-tag-wrap{
                    display: flex;
                    border-radius: 4px;
                    background: #2D32591A;
                    padding: 2px 10px;
                    width: max-content;
                    margin-top: 8px;
                    .courseImage{

                    }
                    .tag-name{
                        color: #415DF3;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
.active{
    background: ${theme.palette.customColor.blue2};
    color:#fff !important;
    font-weight: 500 !important;

}
  `,
);

export default StyledLinks;
