import { styled } from '@mui/material/styles';

const StyledTextField = styled('div')(
  ({ theme }) => `
  width: 100%;
.custom-field {
  border-radius: 12px;
}
.input-Label_text {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${theme.palette.customColor.secondaryGrey};
  text-align: left;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}
.MuiInputBase-root {
  border-radius: 4px !important;
}
.MuiIconButton-root {
  margin-right: 0 !important;
}
.MuiFormControl-root {
  background-color: transparent !important;
}
  
.MuiFormControl-root {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}
.MuiAutocomplete-root {
  width: 100% !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-family: "Plus Jakarta Sans";
    padding: 4px 4px 4px 4px;
    color: ${theme.palette.customColor.primaryBlack};
  }  
.MuiInput-input {
  padding: 10px !important;
  border-radius: 10px !important;
  color: ${theme.palette.customColor.secondaryGreyText};
}
.Mui-focused .MuiOutlinedInput-root{
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 10px !important; 
}
 .MuiSvgIcon-root {
  color: ${theme.palette.customColor.secondaryGreyText};
  margin-right: 10px;
  width: 20px;
 }
 .infoIcon{
  margin-left: 5px;
  .MuiSvgIcon-root {
    width: 16px;
  }
 } 
.MuiFormHelperText-root.Mui-error {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: ${theme.palette.customColor.errorRed} !important;
  margin-left: 0px;
}

.Mui-error .MuiOutlinedInput-notchedOutline{
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
  border-width: 1px;
}

.MuiInputBase-root{
    border: 1px solid ${theme.palette.customColor.grey_6};
}
.MuiOutlinedInput-notchedOutline{
  border: 0px;
}

.MuiOutlinedInput-root{
  border-radius: 10px !important;
}
`,
);

export default StyledTextField;
