import React from 'react';

const CalenderForwardIcon = (props) => (
  <svg
    {...props}
    width="20"
    style={{ cursor: 'pointer' }}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3L14 10L7 17"
      stroke="#525252"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalenderForwardIcon;
