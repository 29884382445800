import { styled } from '@mui/material/styles';

const StyledReadExplore = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.customColor.primaryWhite};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    @media screen and (max-width: 480px){
      border-radius: 0px;
    }
    .header-wrap{
      display: flex;
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      align-items: center;
      padding: 30px 24px;
      @media screen and (max-width: 480px){
        padding: 15px 30px;
      }
    }
    .content-wrap{
      padding: 0px 24px 30px 24px;
      @media screen and (max-width: 768px){
        padding: 30px;
        @media screen and (max-width: 480px){
          padding: 20px;
        }
      }
      .read-box{
        .resp_position_delete{
          width:30px;
          height:30px;
        }
        @media screen and (max-width: 600px){
          .resp_position_delete{
            position:absolute;
            top:0;
            right:0;
          width:24px;
          height:24px;
          
        }
          display: block;
          background-color: #FFFFFF;
          padding: 12px 0px;
          margin: 16px 0px;
          border-radius: 10px;
        }
      }
  .wrap-heading{
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;        
    color: ${theme.palette.customColor.primaryBlack};
    margin-bottom: 16px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    // .highlight{
    //   color: ${theme.palette.primary.primaryBlack};
    //   padding-right: 20px;
    // }
    .addButton{
      padding: 0px;
      width: 90px;
      justify-content: space-evenly;
    }
  }
    .MuiListItemIcon-root{
      min-width: 0px;
      img{
        width: 22px;
      }
    }

    .urlField{
      .MuiInput-input {
        color: ${theme.palette.customColor.stateLinks};
      }
    }
  }
  .emptyMsg{
        margin-bottom: 50px;
  }
}
  `,
);

export default StyledReadExplore;
