import { styled } from '@mui/material/styles';
import { theme } from '../../theme';

const StyledSubscription = styled('div')(
  () => `
    height: calc(100dvh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-sizing: border-box;
    color: ${theme.palette.customColor.primaryWhite};
    background: #F8F4F0;
    @media screen and (max-width : 600px){
        min-height: calc(100dvh - 62px);
        padding: 16px;
    }
    .payment-response{
        width: 100%;
        .payment-type{
            color: white;
            font-size: 32px;
            font-weight: 700;
            word-wrap: break-word;
            margin-bottom: 20px;
            color: #1c1c1c;
            @media screen and (max-width : 600px){
                font-size: 24px;
                margin-bottom: 9px;
            }
        }
        .payment-text{
            color: #1c1c1c;
            font-size: 20px;
            font-weight: 400;
            line-height: 36px;
            word-wrap: break-word;
            @media screen and (max-width : 600px){
                font-size: 12px;
                line-height: 20px;
            }
        }
        .payment-button{
            margin-top: 20px;
            width: fit-content;
            padding: 0px 30px;
        }
        .session-detail{
            background: #ffffff;
            text-align: left;
            color: #1c1c1c;
            border-radius: 10px;
            width: 670px;
            margin: auto;
            margin-top: 30px;
            padding: 32px;
            box-sizing: border-box;
            @media screen and (max-width : 1200px){
                width: 670px;
            }
            @media screen and (max-width : 700px){
                width: 100%;
            }
            @media screen and (max-width : 600px){
                margin-top: 24px;
                padding: 16px;

            }
            .session_heading{
                font-family: Plus Jakarta Sans;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                text-align: left;
                color: #1c1c1c;
                padding-bottom: 16px;
            }
                .session_sub{
                background: #F8F4F0;
                padding: 20px;
                border-radius: 16px;
                }
                .session_head{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.64px;
                    text-align: left;
                    color: #757575;
                    padding-bottom: 8px;
                }
                .session_content{
                    font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20.16px;
                    text-align: left;
                    color: #1c1c1c;
                    white-space: nowrap;     
                    overflow: hidden;  
                    text-overflow: ellipsis; 
                }
                    .link{
                        color: #415DF3;
                        text-decoration: underline;
                        cursor: pointer;
                    }
            
        }
        .button-failed{
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width : 600px){
                 justify-content: flex-end;


            }
            .secondary-stripe{
                width: 200px !important;
                margin: 10px;
                height: 48px !important;
                margin-top: 20px !important;
            }
            .primary-stripe{
                width: 200px !important;
                margin: 10px;
                margin-top: 20px !important;
            }
        }
    }
  `,
);

export default StyledSubscription;
