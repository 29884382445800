/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../Button';
import StyledOtpModal from './StyledOtpModal';
import crossIcon from '../../assets/svg/crossIcon.svg';
import { fetchVerifyOtp, fetchSendOtp } from '../../store/reducers/authentication/apiThunk';
import { updateVerifiedEmail } from '../../store/reducers/authentication';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import { fetchSeekerVerifyOtp } from '../../seekerStore/reducers/seekerProfile/apiThunk';
import { updateVerified } from '../../seekerStore/reducers/seekerProfile';

function Otp({ email, setOtpModal, setIsEmailVerified, seekerVerify }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const num = localStorage.getItem('counter') <= 0 ? 30 : localStorage.getItem('counter');
  const [counter, setCounter] = useState(num);

  useEffect(() => {
    localStorage.setItem('counter', counter);
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  const closePopup = () => {
    setOpen(false);
    setOtpModal(false);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    sessionStorage.setItem('seeker', '');
    closePopup();
  };

  const handleVerfiyOtp = async () => {
    const verifyOtpRequest = {
      primaryEmail: email,
      otp: otpValue,
      type: seekerVerify ? 3 : 2,
    };
    if (seekerVerify) {
      const response = await dispatch(fetchSeekerVerifyOtp(verifyOtpRequest));
      const { payload: { status = false, message = '',
        data = {},
      } = {} } = response;
      if (status) {
        sessionStorage.setItem('seeker', JSON.stringify(email));
        sessionStorage.setItem('seekerVerified', true);
        sessionStorage.setItem('customerID', data?.data?.razorpayCustomerId);
        ToastNotifySuccess('Email verified');
        dispatch(updateVerified(email));
        navigate('/schedule');
        closePopup();
      } else if (message) {
        ToastNotifyError(message);
      }
    } else {
      const response = await dispatch(fetchVerifyOtp(verifyOtpRequest));
      const { payload: { status = false, message = '' } = {} } = response;
      if (status) {
        ToastNotifySuccess('Email verified');
        dispatch(updateVerifiedEmail(email));
        setIsEmailVerified(true);
        closePopup();
      } else if (message) {
        ToastNotifyError(message);
      }
    }
  };

  const handleResendOtp = () => {
    const sendOtpRequest = {
      primaryEmail: email,
    };
    dispatch(fetchSendOtp(sendOtpRequest));
    ToastNotifySuccess('OTP resend successfully');
    setOtpValue('');
    setCounter(30);
  };

  return (
    <StyledOtpModal
      open={open}
      disableBackdropClick="true"
      className="otpModal"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="otp-title">OTP Verification</div>
        <div onClick={handleClose} className="crossIcon" role="presentation">
          <img
            src={crossIcon}
            alt=""
            style={{ color: '#212B36', cursor: 'pointer' }}
            onClick={handleClose}
            role="presentation"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="otp-content-wrap">
          <div className="otp-subheading">
            Please enter verification code sent to
            <br />
            <span className="subheading-mail">{email}</span>
          </div>
          <div className="otp-input-wrap">
            <OtpInput
              className="custom-otp-field"
              isInputNum
              value={otpValue}
              onChange={setOtpValue}
              numInputs={4}
              renderInput={(props) => <input {...props} className="input-box" />}
            />
          </div>
        </div>
      </DialogContent>
      <div className="dialogAction">
        <div className="footer-content">
          {'Didn’t receive code? '}
          {counter > 0 ? (
            <span>{` Please wait for ${counter} seconds`}</span>
          ) : (
            <span className="resendbtn" onClick={() => handleResendOtp()}>
              Resend
            </span>
          )}
        </div>
        <PrimaryButton
          onClick={() => handleVerfiyOtp()}
          sx={{
            width: '100%',
          }}>
          Verify
        </PrimaryButton>
      </div>
    </StyledOtpModal>
  );
}

export default Otp;
