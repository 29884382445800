import { styled } from '@mui/material/styles';

const StyledFeedback = styled('div')(
  ({ theme }) => `
  .feedback-page-wrap{
    padding:24px;
     @media screen and (max-width: 600px){
        padding:16px;
     }
      .feedback-header-wrap{
        color: ${theme.palette.customColor.primaryBlack};
        text-shadow: 0px 3px 16px rgba(0, 0, 0, 0.10);
        font-family: Sora;
        font-size: 32px;
        font-weight: 700;
        line-height: 40.32px;
        text-align: left;
        @media screen and (max-width: 600px){
          font-size: 24px;
          line-height: 30px;
        }
      }
      .feedback-content-wrap{
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow-y: auto;
          
          height: calc(100vh - 10.18vh - 11.54vh - 5.98vh - 6.33vh - 98px);

          @media (min-width: 900px) and  (max-height: 800px) {
            height: calc(100vh - 10.18vh - 10.54vh - 148px);
          }

          @media screen and (max-width: 900px){
            height: calc(100vh - 5.20vh - 8.38vh - 2.27vh - 1.81vh - 110px);
          }

          @media screen and (max-width: 600px){
            height: calc(100vh - 240px); 
          }
          margin-top: 20px;

            .noFeedbackTitle{
              color: ${theme.palette.customColor.primaryBlack};
              font-family: Sora;
              font-size: 20px;
              font-weight: 600;
              line-height: 25.2px;
              text-align: left;
              @media screen and (max-width: 600px){
                  font-size: 16px;
                  line-height: 20px;
                }
            }

            .serviceName{
              color: ${theme.palette.customColor.primaryBlack};
              font-family: Sora;
              font-size: 20px;
              font-weight: 600;
              line-height: 25.2px;
              text-align: left;
              @media screen and (max-width: 600px){
                  font-size: 16px;
                  line-height: 20px;
                }
              }

            .feedback-card-wrap{
                display: flex;
                justify-content: space-between;
                border-radius: 24px;
                flex-direction:column;
                border: 1px solid #E2E4E7;
                padding: 20px;
                position:relative;
                margin-top:12px;

                .quote-img{
                    position: absolute;
                    right: 24px;
                    top: -20px;
                    width:46px;
                    height:46px;
                }
                .content-top{
                    display:inline-flex;
                    align-items:center;
                    gap:12px;
                    .social-img:{
                        width:44px;
                        height:44px;
                    }
                    .top-right{
                        .seeker-name{
                            font-family: Sora;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 17.64px;
                            text-align: left;
                            color:${theme.palette.customColor.primaryBlack};
                        }
                        .feedback-date{
                            margin-top:4px;
                            font-family: Plus Jakarta Sans;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 15.12px;
                            text-align: left;
                            color:${theme.palette.customColor.secondaryGrey};
                        }
                    }
                }
                .description{
                    margin-top:12px;
                    font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    color:${theme.palette.customColor.primaryBlack};

                }
                .read-more{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.64px;
                    text-align: left; 
                    color:#415DF3;
                    cursor:pointer;       
                   }
                .session-info-box{
                    margin-top:12px;
                    padding:8px;
                    background-color:#F8F4F0;
                    border-radius: 10px;
                    .heading{
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15.12px;
                        text-align: left;
                        color:${theme.palette.customColor.primaryBlack};
                    }
                    .title{
                        margin-top:4px;
                        font-family: Sora;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        color:${theme.palette.customColor.primaryBlack};
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;

                    }
                }
                
            }
          }
      }
  }
  `,
);

export default StyledFeedback;
