import { styled } from '@mui/material/styles';

const StyledTimerPicker = styled('div')(
  ({ theme }) => `
  width: 100%;
  .input-Label_text {
    color: #1C1C1C;
    text-align: left;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 7px;
  }
  .MuiStack-root{
    padding: 0px;
  }
  .MuiInputBase-root {
    border-radius: 10px !important;
    border: 1px solid #E2E4E7;
        color: #1C1C1C;

  }
  .MuiInputBase-input, .MuiOutlinedInput-notchedOutline{
    border:0 !important;
    outline:0 !important;
  }
  .MuiInputAdornment-root{
        color: #1C1C1C;

    .MuiIconButton-root{
            color: #1C1C1C;

    }
  }
  .MuiFormHelperText-root {
    color: ${theme.palette.customColor.stateError};
}
  .MuiInput-input {
    padding: 10px !important;
    /*background: #f5f5f7e6 !important; */
    border-radius: 10px !important;
  }
  .MuiOutlinedInput-input {
    padding: 13px 14px;
  }
  .MuiFormControl-root{
    width: 100%;
  }
  .MuiIconButton-root{
    color: #525252 !important;
  }
  `,
);

export default StyledTimerPicker;
