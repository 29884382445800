/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Tabs, Tab, AppBar } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardSessions } from '../../store/reducers/expertProfile/apiThunk';
import PrivateLayout from '../../layout/PrivateLayout';
import StyledSessionList from './StyledSessionList';
import backArrow from '../../assets/svg/backArrow.svg';
import AccordianList from '../../components/AccordianList';

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

const rowsPerPage = 10;

const SessionList = () => {
  const [value, setValue] = useState(1);
  const [tablePage, setTablePage] = useState(0);
  const [paginationData, setPaginationData] = useState(null);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const dispatch = useDispatch();
  const [fetchedSessionData, setFetchedSessionData] = useState([]);

  const handleFetchTableData = async () => {
    const requestData = {
      limit: rowsPerPage,
      page: tablePage + 1,
      body: {
        type: value,
      },
    };
    const dataFetched = await dispatch(fetchDashboardSessions(requestData)).then((val) => val);
    const { payload: { data: { count = 0, page = 0, sessionList = [] } = {}, status = '' } = {} } =
      dataFetched;
    setPaginationData({
      count,
      page,
    });
    if (status && sessionList.length !== 0) {
      const refracteredData = sessionList.map((obj) => {
        const momentObj = moment(obj.eventStartTime.dateTime);
        const date = momentObj.format('DD');
        const monthName = momentObj.format('MMMM');
        const year = momentObj.format('YYYY');
        const time = momentObj.format('hh:mm A');
        const hours = Math.floor(obj.sessionDuration / 60);
        const minutes = obj.sessionDuration % 60;

        return {
          sessionId: obj._id,
          seekerId: obj.seeker._id,
          expertId: obj.expert._id,
          session: obj.title,
          date: `${monthName} ${date}, ${year}`,
          duration: `${hours !== 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : ''} ${minutes !== 0 ? (minutes === 1 ? `${minutes} minute` : `${minutes} minutes`) : ''} `,
          startTime: time,
          meetLink: obj.googleMeetLink,
          // price: obj.price,
          unit: obj.unit,
          seekerEmail: obj.seeker.primaryEmail,
          cancelledBy: obj?.cancelledBy,
          updatedAt: obj?.updatedAt,
        };
      });
      setFetchedSessionData(refracteredData);
    } else {
      setFetchedSessionData([]);
    }
  };

  useEffect(() => {
    handleFetchTableData();
  }, [value, tablePage, rowsPerPage]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    setTablePage(0);
  };

  return (
    <StyledSessionList>
      <div className="page-wrap">
        <div className="backButton-wrap" onClick={() => navigate(-1)}>
          <img src={backArrow} alt="backArrow" srcSet="" />
          Sessions
        </div>
        <div className="header-wrap">
          <AppBar>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab label="Upcoming" value={1} />
              <Tab label="Taken" value={2} />
              <Tab label="Cancelled" value={3} />
            </Tabs>
          </AppBar>
        </div>
        <div className="content-wrap">
          {fetchedSessionData.length === 0 ? (
            <div className="noSessionsHeading">No Sessions yet</div>
          ) : (
            <AccordianList
              data={fetchedSessionData}
              tableDataUpdate={handleFetchTableData}
              tabIndex={value}
            />
          )}
        </div>
        <div className="footer-wrap">
          {fetchedSessionData.length !== 0 ? (
            <TablePagination
              component="div"
              count={paginationData?.count}
              page={tablePage}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </StyledSessionList>
  );
};

export default PrivateLayout(SessionList);
