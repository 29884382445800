import { styled } from '@mui/material/styles';

const StyledTable = styled('div')(
  ({ theme }) => `
  .tableContainer{
    @media screen and (max-width: 480px){
      display: none;
    }
  }
  .MuiTableContainer-root{
    box-shadow: none;
  }
  .buttons-wrap{
    display: flex;
    align-items: center;
    column-gap: 18px;
    width: 100%;
  }
  .viewDetailButton{
    color: ${theme.palette.primary.main};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
    cursor: pointer;
  }
  .cancelBtn-wrap{
    width: fit-content;
    padding: 8px 10px;
    cursor: pointer;
    color: ${theme.palette.customColor.stateError};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    background: ${theme.palette.customColor.bg_red};
  }

  .cards-wrapper{
    display: none;
    @media screen and (max-width: 480px){
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
    .card-wrap{
        justify-content: space-between;
        text-align: left;
        border-radius: 8px;
        border: 1px solid ${theme.palette.customColor.grey_6};
        background: ${theme.palette.secondary.main};
        display: flex;
        padding: 12px 17px;
        align-items: center;
        .left-wrap{
            display: flex;
            flex-direction: column;
            row-gap: 19px;
        }
        .right-wrap{
            display: flex;
            flex-direction: column;
            row-gap: 19px;
        }
        .heading{
            color: ${theme.palette.customColor.grey_7};
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 6px;
        }
        .subheading{
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .viewDetailsButton{
            color: ${theme.palette.primary.main};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .cancelButton{
            color: ${theme.palette.customColor.stateError};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 8px;
            background: ${theme.palette.customColor.bg_red};
            margin: 0px;
            padding: 8px 10px;
        }
    }
  }
  `,
);

export default StyledTable;
