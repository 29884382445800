import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import StyledFrame from './StyledFrame';

const BlogsCard = ({ data, limit = { lg: 3, xl: 2.4 } }) => {
  const navigate = useNavigate();
  const Chip = ({ children }) => <Box className="chip_blog">{children}</Box>;

  return (
    <StyledFrame item xs={12} sm={6} md={4} {...limit}>
      <Grid container gap={2} className="blog_container" p={1.5}>
        <Grid item xs={12}>
          <Box>
            <img className="blog_frame_img" src={data?.feature_image} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1} className="blogs_heading" onClick={() => navigate(`/blogs/${data?.id}`)}>
            {data?.title}
          </Box>
          <Box className="blogs_desc">
            {/* <div dangerouslySetInnerHTML={{ __html: data?.html }} /> */}
            {data?.excerpt}
          </Box>
        </Grid>
        <Grid item xs={12} mt={'auto'}>
          <Stack flexDirection={'row'} gap={'6px'}>
            <Chip>{moment(data?.published_at).format('MMM YY')}</Chip>
            <Chip>{data?.reading_time} min read</Chip>
          </Stack>
        </Grid>
      </Grid>
    </StyledFrame>
  );
};

export default BlogsCard;
