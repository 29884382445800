/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLogout = createAsyncThunk(
  'authentication/logout',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.LOGOUT, data);
      dispatch(updateLoading(false));
      localStorage.clear();
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchLogin = createAsyncThunk(
  'authentication/login',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.LOGIN, data);
      dispatch(updateLoading(false));
      // console.log('token', response);
      const { data: { data: { token = '' } = {} } = {} } = response;
      if (token) {
        localStorage.setItem('auth', token);
      }
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchCreateProfile = createAsyncThunk(
  'authentication/createProfile',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.CREATE_PROFILE, data);
      dispatch(updateLoading(false));
      return response.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchGetProfile = createAsyncThunk(
  'authentication/getProfile',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.GET_PROFILE, data);
      dispatch(updateLoading(false));
      return response.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchEditProfile = createAsyncThunk(
  'authentication/editProfile',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const API_WITHOUT_HEADER = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      const { extension, uploadFile, profileImagePath, ...resData } = data;
      const s3Data = {
        extension,
      };
      let profileImagePathTemp = profileImagePath || '';
      if (extension) {
        const signedUrlRes = await API.post(API_PATH.GET_S3_URL, s3Data);
        const { data: { data: { path, url } = {} } = {} } = signedUrlRes;
        profileImagePathTemp = path;

        await API_WITHOUT_HEADER.put(url, uploadFile);
      }
      const profileData = {
        ...resData,
        profileImagePath: profileImagePathTemp,
      };
      const response = await API.post(API_PATH.EDIT_PROFILE, profileData);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSignUp = createAsyncThunk(
  'authentication/signUp',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const API_WITHOUT_HEADER = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      const { fileExtension, uploadPicture, ...resData } = data;
      const s3Data = {
        extension: fileExtension,
      };
      let profileImagePath = '';
      if (fileExtension) {
        const signedUrlRes = await API.post(API_PATH.GET_S3_URL, s3Data);
        const { data: { data: { path, url } = {} } = {} } = signedUrlRes;
        profileImagePath = path;

        await API_WITHOUT_HEADER.put(url, uploadPicture);
      }
      const signupData = {
        ...resData,
        profileImagePath,
      };
      const response = await API.patch(API_PATH.SIGNUP, signupData);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchUsername = createAsyncThunk('authentication/domain', async (data, { getState }) => {
  const API = getAuthorization(getState);
  try {
    // dispatch(updateLoading(true));
    const response = await API.post(API_PATH.CHECK_USERNAME, data);
    // dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchCountrieslists = createAsyncThunk(
  'authentication/fetchCountrieslists',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.COUNTRIES_LIST, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSendOtp = createAsyncThunk(
  'authentication/SendOtp',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.SEND_OTP, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchVerifyOtp = createAsyncThunk(
  'authentication/VerifyOtp',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.VERIFY_OTP, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

export {
  fetchLogin,
  fetchLogout,
  fetchCreateProfile,
  fetchSignUp,
  fetchGetProfile,
  fetchEditProfile,
  fetchUsername,
  fetchSendOtp,
  fetchVerifyOtp,
  fetchCountrieslists,
};
