/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import link from '../../../assets/svg/link.svg';
import timer from '../../../assets/svg/timer.svg';
import checked from '../../../assets/svg/greenChecked.svg';
import arrow from '../../../assets/svg/arrow.svg';
import AutoComplete from '../../../components/AutoComplete/index';
import { CONSTANTS } from '../../../constants/constants';
import TextField from '../../../components/TextField';
import { selectorAuthentication } from '../../../store/reducers/authentication/index';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile/index';
import StyledPersonalInfo from './StyledPersonalInfo';
import LandingButtons from '../../../components/LandingButtons';
import {
  validationPersonalInfoSchema,
  validateFileType,
  validateFileSize,
} from '../../../utils/validations/authValidation';
import { SecondaryButton, PrimaryButton } from '../../../components/Button';
import {
  fetchLandingPage,
  fetchLandingPageData,
  linkToCalendar,
} from '../../../store/reducers/expertProfile/apiThunk';
import { fetchGetProfile, fetchEditProfile } from '../../../store/reducers/authentication/apiThunk';
import Modal from '../../../components/Modal';
import AvailabilityModal from '../../../components/AvailabilityModal';
import changeProfile from '../../../assets/svg/changeProfile.svg';
import profilePic from '../../../assets/svg/profilePic.svg';
import message from '../../../constants/message';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import checkedGreen from '../../../assets/svg/checked-green.svg';

const { GOOGLE_CALENDAR_SCOPE } = CONSTANTS;

function PersonalInfo({ stepNumber = 0, setActiveStep }) {
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [openLinkedCalendarModal, setOpenLinkedCalendarModal] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);
  const [file, setFile] = useState(null);
  const { MSG_FILE_FORMATE, MSG_FILE_SIZE } = message;

  const profileImageInput = useRef(null);

  const {
    userData: {
      data: {
        firstName: userFistName,
        lastName: userLastName,
        profileImagePath: userProfileImagePath,
        isGoogleLinked = false,
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const exprtProfile = useSelector(selectorExpertProfile);
  const {
    suggestions: {
      data: { whoAmI: suggestionsWhoAmI = [], expertise: suggestionsExpertise = [] } = {},
    } = {},
    userLandingData: {
      data: {
        bio: fetchedBio = '',
        aboutMe: fetchedAboutMe = '',
        whoAmI: fetchedWhoAmI = '',
        expertise: fetchedExpertise = '',
        dateAvailability: fetchedDateAvailability = [],
        dayAvailability: fetchedDayAvailability = [],
      } = {},
    } = {},
  } = exprtProfile;

  const submitHandler = async (values) => {
    const { whoAmI, expertise, bio, aboutMe } = values;
    const data = {
      pageType: 1,
      whoAmI,
      expertise,
      bio,
      aboutMe,
      dateAvailability: fetchedDateAvailability,
      dayAvailability: fetchedDayAvailability,
    };
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: data,
    };
    await dispatch(fetchLandingPage(dataRequest));
    dispatch(fetchLandingPageData());
    setDisableSaveBtn(true);
  };

  const formik = useFormik({
    initialValues: {
      whoAmI: '',
      expertise: '',
      bio: '',
      aboutMe: '',
    },
    validationSchema: validationPersonalInfoSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    formik.setFieldValue('whoAmI', fetchedWhoAmI);
    formik.setFieldValue('expertise', fetchedExpertise);
    formik.setFieldValue('bio', fetchedBio);
    formik.setFieldValue('aboutMe', fetchedAboutMe);
  }, [exprtProfile]);

  const {
    // handleSubmit,
    values: { whoAmI, expertise, bio, aboutMe } = {},
    touched: {
      whoAmI: tucWhoAmI = false,
      expertise: tucExpertise = false,
      bio: tucBio = false,
      aboutMe: tucAboutMe = false,
    } = {},
    errors: { whoAmI: errWhoAmI, expertise: errExpertise, bio: errBio, aboutMe: errAboutMe } = {},
  } = formik;

  const handleChange = (e, name) => {
    setDisableSaveBtn(false);
    formik.setFieldValue(name, e.target.value);
  };

  const customHandleChange = (e, name) => {
    setDisableSaveBtn(false);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  const handleSuccess = async (codeResponse) => {
    const payload = {
      authCode: codeResponse.code,
    };
    const linkToCalendarResponse = await dispatch(linkToCalendar(payload)).then((val) => val);
    if (linkToCalendarResponse) {
      await dispatch(fetchGetProfile());
      setOpenLinkedCalendarModal(true);
    }
  };

  const handleLinkCalendar = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: 'auth-code',
    scope: GOOGLE_CALENDAR_SCOPE,
  });

  const handleCloseDialoag = () => {
    setOpenLinkedCalendarModal(false);
  };

  const handleContinue = () => {
    handleCloseDialoag();
    setShowAvailability(true);
  };

  const linkedCalendarModalContent = () => (
    <>
      <div>
        <img src={checked} alt="" srcSet="" />
      </div>
      <div className="dialog-title">Your Google calendar is linked!</div>
      <div className="google-calendar-button-wrap">
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleContinue()}>
          Continue
          <img src={arrow} alt="" srcSet="" className="arrow-icon" />
        </PrimaryButton>
      </div>
    </>
  );

  const handleShowAvailability = () => {
    setShowAvailability(!showAvailability);
  };

  const handleImage = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    const updatedData = {
      profileImagePath: userProfileImagePath || '',
      uploadFile: e.target.files[0],
      extension: e.target.files[0].name.split('.').pop(),
    };
    if (updatedData?.extension && !validateFileType(updatedData?.extension)) {
      ToastNotifyError(MSG_FILE_FORMATE);
    } else if (updatedData?.uploadFile && validateFileSize(updatedData?.uploadFile)) {
      ToastNotifyError(MSG_FILE_SIZE);
    } else {
      const response = await dispatch(fetchEditProfile(updatedData)).then((val) => val);
      const { payload: { status } = {} } = response;
      if (status) {
        await dispatch(fetchGetProfile());
        await dispatch(fetchLandingPageData());
      }
    }
  };

  return (
    <StyledPersonalInfo>
      <Modal
        openDialog={openLinkedCalendarModal}
        closeDialog={handleCloseDialoag}
        dialogContent={linkedCalendarModalContent()}
        noHeader
        dialogCss="logoutDialogWrap"
      />
      {showAvailability ? (
        <AvailabilityModal openDialog={showAvailability} closeDialog={handleShowAvailability} />
      ) : (
        ''
      )}
      <div className="page-wrap">
        <div className="header-wrap">Information</div>
        <div className="content-wrap">
          <div className="profileSection-wrap">
            <div className="profilepic-wrap">
              <img
                src={
                  file ||
                  `${
                    userProfileImagePath
                      ? `${process.env.REACT_APP_IMAGE_URL}/${userProfileImagePath}`
                      : profilePic
                  }`
                }
                alt="Profile Pic"
                className="profilePic"
              />
              <img
                src={changeProfile}
                alt=""
                className="changeProfile"
                onClick={() => profileImageInput.current.click()}
              />
              <input
                type="file"
                ref={profileImageInput}
                id="file-input"
                accept="image/png, image/jpeg"
                className="fileUpload"
                onChange={(e) => handleImage(e)}
              />
            </div>
            <div className="name-wrap">{`${userFistName} ${userLastName}`}</div>
          </div>
          <div className="right-section-wrap">
            <div className="flex-row">
              <AutoComplete
                name="whoAmI"
                type="text"
                label="Who Am I"
                classes="whoAmI"
                important
                showTooltip={false}
                optionsValue={suggestionsWhoAmI.map((obj) => obj.value)}
                changeAutoComplete={(e) => customHandleChange(e, 'whoAmI')}
                value={whoAmI}
                error={tucWhoAmI && !!errWhoAmI}
                helperText={tucWhoAmI && errWhoAmI}
                icon={<ExpandMoreOutlinedIcon />}
              />
              <AutoComplete
                name="expertise"
                type="text"
                label="Expertise"
                classes="expertise"
                important
                optionsValue={suggestionsExpertise.map((obj) => obj.value)}
                changeAutoComplete={(e) => customHandleChange(e, 'expertise')}
                value={expertise}
                showTooltip
                tooltip="What's your domain?"
                error={tucExpertise && !!errExpertise}
                helperText={tucExpertise && errExpertise}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </div>
            <div>
              <TextField
                placeholder="Write description"
                name="bio"
                type="text"
                classes="description"
                multiline
                rows={1}
                label="Description"
                important
                onChange={(e) => handleChange(e, 'bio')}
                value={bio}
                error={tucBio && !!errBio}
                helperText={tucBio && errBio}
                icon={`${bio?.length ? bio.length : 0}/350`}
                wordCount
                inputProps={{ maxLength: 350 }}
              />
            </div>
            <div>
              <TextField
                placeholder="Write more about yourself"
                name="aboutMe"
                type="text"
                classes="aboutMe"
                multiline
                rows={2}
                label="Write more about yourself"
                important
                onChange={(e) => handleChange(e, 'aboutMe')}
                value={aboutMe}
                error={tucAboutMe && !!errAboutMe}
                helperText={tucAboutMe && errAboutMe}
                icon={`${aboutMe?.length ? aboutMe.length : 0}/3500`}
                wordCount
                inputProps={{ maxLength: 3500 }}
              />
            </div>
            <div className="calendarbtn-wrap">
              <div>
                {isGoogleLinked || fetchedWhoAmI === '' ? (
                  <div className="calendarLinked">
                    <img src={checkedGreen} alt="checked" />
                    Calender Linked
                  </div>
                ) : (
                  <SecondaryButton
                    border
                    onClick={() => handleLinkCalendar()}
                    sx={{
                      width: 'max-content',
                    }}>
                    <img src={link} alt="link" srcSet="" />
                    Link Your Calendar
                  </SecondaryButton>
                )}
              </div>
              {fetchedWhoAmI !== '' && isGoogleLinked ? (
                <SecondaryButton
                  border
                  onClick={() => handleShowAvailability()}
                  sx={{
                    width: 'max-content',
                  }}>
                  <img src={timer} alt="" srcSet="" />
                  {fetchedDateAvailability.length !== 0 || fetchedDayAvailability.length !== 0
                    ? 'Edit Availability'
                    : 'Set Availability'}
                </SecondaryButton>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <LandingButtons
        showBackButton={false}
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!bio || !whoAmI || !expertise || !aboutMe}
        disableSave={disableSaveBtn}
      />
    </StyledPersonalInfo>
  );
}

export default PersonalInfo;
