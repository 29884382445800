/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../assets/svg/logo.svg';
import avatar from '../../assets/svg/avatar.svg';
import StyledPrivateLayout, {
  StyledHeaderMenu,
  StyledHeaderMenuItem,
  AppBar,
  Main,
  StyledDrawer,
} from './StyledPrivateLayout';
import StarIcon from '../../components/icons/StarIcon';
import PersonPinIcon from '../../components/icons/PersonPinIcon';
import CalendarIcon from '../../components/icons/CalendarIcon';
import NoteIcon from '../../components/icons/NoteIcon';
import ContactIcon from '../../components/icons/ContactIcon';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import { ROUTE_PATH } from '../../constants/route';
import UserIcon from '../../components/icons/UserIcon';
import LogoutIcon from '../../components/icons/LogoutIcon';

import ProfileModal from '../../components/Modal/Profile';
import TextField from '../../components/TextField';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import profilePic from '../../assets/svg/profilePic.svg';
import {
  validationEditProfileSchema,
  validateFileType,
  validateFileSize,
} from '../../utils/validations/authValidation';
import { selectorAuthentication, logout } from '../../store/reducers/authentication';
import { resetExpertProfile } from '../../store/reducers/expertProfile';
import {
  fetchEditProfile,
  fetchGetProfile,
  fetchLogout,
} from '../../store/reducers/authentication/apiThunk';
import changeProfile from '../../assets/svg/changeProfile.svg';
import Modal from '../../components/Modal';
import message from '../../constants/message';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import ContactFooter from '../../components/ContactFooter';
import AccountIcon from '../../components/icons/AccountIcon';
import AccountsDetailsModal from '../../components/Common/AccountsDetailsModal';
import FeedbackIcon from '../../components/icons/FeedbackIcon';
import FeedbackIconGray from '../../components/icons/FeedbackIconGray';

const { MSG_FILE_FORMATE, MSG_FILE_SIZE } = message;

const { DASHBOARD, LOGIN, TERMS_AND_CONDITIONS, LANDING_PAGE, CALENDAR, FEEDBACKS } = ROUTE_PATH;

const listItems = [
  {
    title: 'Dashboard',
    icon: 'dashboard',
    url: DASHBOARD,
  },
  {
    title: 'Landing Page',
    icon: 'landing',
    url: LANDING_PAGE,
  },
  {
    title: 'Calendar',
    icon: 'calendar',
    url: CALENDAR,
  },
  {
    title: 'Feedbacks',
    icon: 'feedbacks',
    url: FEEDBACKS,
  },
];

const PrivateLayout = (Component) => () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    userData: {
      data: {
        firstName: userFistName = '',
        lastName: userLastName = '',
        primaryEmail: userEmail = '',
        username: userUsername = 'username',
        profileImagePath: userProfileImagePath,
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const profilePicRef = useRef(null);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [menuEle, setMenuEle] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadFile, setUploadFile] = useState();
  const [extension, setExtension] = useState('');
  const [mobileOpen, setMobileOpen] = useState(true);
  const [openAccountModal, setOpenAccountModal] = useState(false);

  const open = Boolean(menuEle);
  const { pathname } = location;

  useEffect(() => {
    if (!desktop) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true);
    }
  }, [desktop]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseProfileDialoag = () => {
    setOpenProfileDialog(false);
  };

  const handleOpenProfileDialoag = () => {
    setOpenProfileDialog(true);
  };

  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };

  const handleOpenLogoutDialoag = () => {
    setOpenLogoutModal(true);
  };

  const handleOpenAccountDialoag = () => {
    setOpenAccountModal(true);
  };

  const handleCloseAccountDialoag = () => {
    setOpenAccountModal(false);
  };

  const handleLogoutUser = async () => {
    await dispatch(fetchLogout());
    await dispatch(logout());
    await dispatch(resetExpertProfile());
    await localStorage.clear();
    navigate(LOGIN);
  };

  const handleGetNavTitle = () => {
    const selectedItem = listItems.filter((item) => item.url === pathname);
    if (selectedItem.length === 0) {
      const dashboardPath = pathname.slice(0, pathname.lastIndexOf('/'));
      const filteredData = listItems.filter((item) => item.url === dashboardPath);
      return filteredData[0].title;
    }
    return selectedItem[0].title;
  };

  const handleClick = (event) => {
    setMenuEle(event.currentTarget);
  };
  const handleClose = (type = '') => {
    setMenuEle(null);
    switch (type) {
      case 'profile':
        handleOpenProfileDialoag(true);
        break;
      case 'logout':
        handleOpenLogoutDialoag();
        break;
      default:
        break;
    }
  };

  const submitHandler = async (values) => {
    const { firstName, lastName } = values;
    const updatedData = {
      firstName,
      lastName,
      profileImagePath: userProfileImagePath || '',
      uploadFile,
      extension,
    };

    if (extension && !validateFileType(extension)) {
      ToastNotifyError(MSG_FILE_FORMATE);
    } else if (uploadFile && validateFileSize(uploadFile)) {
      ToastNotifyError(MSG_FILE_SIZE);
    } else {
      const response = await dispatch(fetchEditProfile(updatedData)).then((val) => val);
      const { payload: { status } = {} } = response;
      if (status) {
        dispatch(fetchGetProfile());
        setOpenProfileDialog(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: userFistName,
      lastName: userLastName,
      email: userEmail,
      userName: userUsername,
    },
    validationSchema: validationEditProfileSchema,
    onSubmit: submitHandler,
  });
  const {
    handleSubmit,
    values: { firstName, lastName, email, userName } = {},
    touched: {
      firstName: tucFirstName = false,
      lastName: tucLastName = false,
      email: tucEmail = false,
      userName: tucUserName = false,
    } = {},
    errors: {
      firstName: errFirstName,
      lastName: errLastName,
      email: errEmail,
      userName: errUserName,
    } = {},
  } = formik;

  useEffect(() => {
    const { setFieldValue } = formik;
    setFieldValue('firstName', userFistName);
    setFieldValue('lastName', userLastName);
    setFieldValue('email', userEmail);
    setFieldValue('userName', userUsername);
  }, [openProfileDialog]);

  const handleChange = (e, name) => {
    if (['email'].includes(name)) {
      formik.setFieldValue(name, e.target.value.trim());
    } else {
      formik.setFieldValue(name, e.target.value);
      if (['firstName', 'lastName'].includes(name)) {
        const convertedString = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        formik.setFieldValue(name, convertedString);
      }
    }
  };

  const getColor = (path) => {
    if (pathname === path) {
      return theme.palette.primary.main;
    }
    return theme.palette.customColor.grey_7;
  };

  const handleGetIcon = (type) => {
    switch (type) {
      case 'dashboard':
        return <StarIcon color={getColor(DASHBOARD)} />;
      case 'landing':
        return <PersonPinIcon color={getColor(LANDING_PAGE)} />;
      case 'calendar':
        return <CalendarIcon color={getColor(CALENDAR)} />;
      case 'terms':
        return <NoteIcon color={getColor(TERMS_AND_CONDITIONS)} />;
      case 'feedbacks':
        return pathname === FEEDBACKS ? <FeedbackIcon /> : <FeedbackIconGray />;
      case 'contact':
        return <ContactIcon color={getColor()} />;
      default:
        break;
    }
    return '';
  };

  function handleImage(e) {
    setUploadFile(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const addImage = (event) => {
    setExtension(event.target.files[0].name.split('.').pop());
    handleImage(event);
  };

  const profileModalContent = () => (
    <form onSubmit={handleSubmit}>
      <div className="profileFormWrap">
        <div className="wrapper-1">
          <div className="profilePicWrap">
            <img
              src={
                file ||
                `${
                  userProfileImagePath
                    ? `${process.env.REACT_APP_IMAGE_URL}/${userProfileImagePath}`
                    : profilePic
                }`
              }
              alt=""
              className="profileImage"
            />
            <img
              src={changeProfile}
              alt=""
              className="changeProfile"
              onClick={() => profilePicRef.current.click()}
            />
            <input
              ref={profilePicRef}
              type="file"
              accept="image/png, image/jpeg"
              className="fileUpload"
              onChange={(e) => addImage(e)}
            />
          </div>
          <div className="splitInput">
            <TextField
              name="firstName"
              type="text"
              label="First Name"
              important
              onChange={(e) => handleChange(e, 'firstName')}
              value={firstName}
              error={tucFirstName && !!errFirstName}
              helperText={tucFirstName && errFirstName}
            />
            <TextField
              name="lastName"
              type="text"
              label="Last Name"
              important
              onChange={(e) => handleChange(e, 'lastName')}
              value={lastName}
              error={tucLastName && !!errLastName}
              helperText={tucLastName && errLastName}
            />
          </div>
        </div>
        <div>
          <TextField
            name="email"
            type="email"
            disabled
            onChange={(e) => handleChange(e, 'email')}
            value={email}
            error={tucEmail && !!errEmail}
            helperText={tucEmail && errEmail}
            label="Email"
            important
          />
        </div>
        <div>
          <TextField
            name="userName"
            type="text"
            disabled
            onChange={(e) => handleChange(e, 'userName')}
            value={userName}
            error={tucUserName && !!errUserName}
            helperText={tucUserName && errUserName}
            label="Username"
            important
          />
        </div>
        <div>
          <PrimaryButton
            type="submit"
            sx={{
              marginTop: '15px',
            }}>
            Save
          </PrimaryButton>
        </div>
      </div>
    </form>
  );

  const logoutModalContent = () => (
    <>
      <div>Are you sure you want to Logout?</div>
      <div className="logout-button-wrap">
        <SecondaryButton border onClick={() => handleLogoutUser()}>
          Yes
        </SecondaryButton>
        <PrimaryButton onClick={() => handleCloseLogoutDialoag()}>No</PrimaryButton>
      </div>
    </>
  );

  return (
    <StyledPrivateLayout>
      <ProfileModal
        openDialog={openProfileDialog}
        closeDialog={handleCloseProfileDialoag}
        dialogContent={profileModalContent()}
        noHeader={false}
        heading="Profile"
        dialogCss="profileDialodWrap"
      />
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Logout"
        dialogCss="logoutDialogWrap"
      />
      <CssBaseline />

      <AppBar position="fixed" open={mobileOpen}>
        <Toolbar className="main-header">
          <div style={{ display: 'flex' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                color: 'white',
                mr: 2,
                display: { sm: `${mobileOpen ? 'none' : ''}` },
              }}>
              <MenuIcon />
            </IconButton>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 700,
                fontSize: '24px',
              }}
              variant="h6"
              noWrap
              component="div">
              {handleGetNavTitle()}
            </Typography>
          </div>
          <div className="profile-wrap">
            <Avatar
              alt="Remy Sharp"
              src={
                userProfileImagePath
                  ? `${process.env.REACT_APP_IMAGE_URL}/${userProfileImagePath}`
                  : avatar
              }
            />
            <Typography className="title" noWrap component="div">
              {userFistName}
            </Typography>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                minWidth: 'auto',
              }}>
              <ArrowDownIcon />
            </Button>
            <StyledHeaderMenu
              className="header-menu"
              anchorEl={menuEle}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <StyledHeaderMenuItem onClick={() => handleClose('profile')}>
                <UserIcon width={25} height={25} />
                My Profile
              </StyledHeaderMenuItem>
              {/* <StyledHeaderMenuItem onClick={() => handleClose('account')}>
                <EditIcon width={25} height={25} width={25} height={25} />
                {isBankDetailsAdded ? 'Edit' : 'Add'} Bank Account Details
              </StyledHeaderMenuItem> */}
              <StyledHeaderMenuItem onClick={() => handleClose('logout')}>
                <LogoutIcon width={25} height={25} />
                Logout
              </StyledHeaderMenuItem>
            </StyledHeaderMenu>
          </div>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        open={mobileOpen}
        onClose={handleDrawerToggle}
        variant={`${desktop ? 'permanent' : 'temporary'}`}
        anchor="left">
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="" />
          </Box>
          <IconButton
            className="drawerIcon"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="end"
            sx={{
              color: 'white',
              padding: '0px',
              ...(open && { display: 'none' }),
            }}>
            {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Toolbar>
        <List className="link-wrap">
          {listItems.map(({ title, icon, url }) => (
            <div key={title} className="listDiv">
              <Link to={url} key={url}>
                <ListItem
                  className={
                    url === pathname || url === pathname.slice(0, pathname.lastIndexOf('/'))
                      ? 'active'
                      : ''
                  }
                  key={title}
                  disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{handleGetIcon(icon)}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              </Link>
            </div>
          ))}
          <div className="listDiv" onClick={() => handleOpenAccountDialoag()}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><AccountIcon width={20} height={20} /></ListItemIcon>
                <ListItemText primary="Bank Account Details" />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </StyledDrawer>
      <Main component="mainWrap" open={mobileOpen}>
        <Component />
        <ContactFooter />
      </Main>
      <AccountsDetailsModal
        openDialog={openAccountModal}
        closeDialog={handleCloseAccountDialoag}
      />
    </StyledPrivateLayout>
  );
};

export default PrivateLayout;
