import { styled } from '@mui/material/styles';
import copylinkDashboardbg from '../../assets/svg/copylinkDashboardbg.svg';

const StyledDashboard = styled('div')(
  ({ theme }) => `
  .page-wrap{
    padding: 40px;
    @media screen and (max-width: 768px){
      padding: 20px;
    }
    @media screen and (max-width: 481px){
      padding: 0px;
    }
    .page-stats-wrap{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px){
        flex-direction: column;
      }
      .left-section-wrap{
        width: 60%;
        @media screen and (max-width: 600px){
          width: 100%;
        }
        .header-wrap{
          display: flex;
          justify-content: space-between;
           margin: 20px 0px;
          @media screen and (max-width: 480px){
            justify-content: space-evenly;
            padding: 16px 0px;
          }
          .profileVisits{
            border-radius: 12px;
            padding: 20px;
            width: 48%;
            background: ${theme.palette.secondary.main};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: max-content;
            align-items: baseline;
            flex-direction: row;
            @media screen and (max-width: 600px){
              flex-direction: column-reverse;
              row-gap: 10px;
            }
            @media screen and (max-width: 480px){
              width: 45%;
              padding: 12px;
            }
            .countTabsIcon{
              @media screen and (max-width: 1130px){
                display: none;
              }
              @media screen and (max-width: 600px){
                display: block;
                width: 40px;
              }
            }
            .left-wrap{
              margin: auto 0px;
              @media screen and (max-width: 480px){
                width: 100%;
              }
              .heading{
                color: ${theme.palette.customColor.grey_7};
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                @media screen and (max-width: 480px){
                  font-size: 14px;
                }
              }
              .count{
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Sora;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: left;
                margin-bottom: 16px;
                @media screen and (max-width: 600px){
                  margin-bottom: 10px;
                }
                @media screen and (max-width: 480px){
                  font-size: 20px;
                }
              } 
            }
          }
        }
        .content-wrap{
          .page-url{
            border-radius: 10px;
            display: flex;
            padding: 15px;
            padding: 20px 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: url(${copylinkDashboardbg});
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: 480px){
              width: 93%;
              margin: auto;
              background: white;
              border-radius: 12px;
              margin-bottom: 16px;          
            }
            .heading{
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Plus Jakarta Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
              @media screen and (max-width: 480px){
                font-size: 17px;
              }
            }
            .username-url{
              display: flex;
              align-items: center;
              margin-top: 20px;
              width: 35vw;
              max-width: 450px;
              @media screen and (max-width: 768px){
                width: 100%;
                max-width: unset;
              }
              @media screen and (max-width: 480px){
                margin-top: 16px;
                width: 100%;
              }
              .input-Label_text{
                display: none;
              }
              .MuiFormControl-root{
                margin-bottom: 0px!important;
              }
              .MuiInputBase-root{
                border-radius: 10px !important;
              }
              .Mui-disabled{
                overflow: hidden;
                background: ${theme.palette.customColor.grey_5};
                color: ${theme.palette.customColor.primaryBlack};
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                border: 0;
                padding: 13px !important;
                @media screen and (max-width: 480px){
                  font-size: 14px;
                }  
              }
              .MuiInput-input{
                padding: 0px !important;
              }
              .copyBtn{
                color: ${theme.palette.primary.main};
                text-align: center;
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;  
                background: ${theme.palette.customColor.grey_5};
                display: flex;
                align-items: center;
                cursor: pointer;
                img{
                  margin-right: 7px;
                }
              }
            }
          }
        }
      }
      .right-section-wrap{
        // width: 30vw;
        width: 37%;
        @media screen and (max-width: 600px){
          width: 100%;
        }
        @media screen and (max-width: 480px){
          width: 93%;
          margin: auto;
          border-radius: 12px;
          overflow: hidden;
          margin-bottom: 16px;
        }
        .chart{
          border-radius: 10px;
          background: ${theme.palette.secondary.main};
          padding: 10px 15px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // min-width: 29.5vw;
          min-height: 265px;
          @media screen and (max-width: 481px){
            border-radius: 0px;
            margin: 0px;
          }
          .title{
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @media screen and (max-width: 480px){
              font-size: 17px;
            }     
          }
          .content{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 15px;        
          }
        }
      }
    }
    .page-table-wrap{
      border-radius: 10px;
      background: ${theme.palette.secondary.main};
      display: flex;
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 480px){
        width: 93%;
        margin: auto;
        border-radius: 12px;
        margin-bottom: 20px;
      }
      .title{
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;  
        padding: 20px; 
        @media screen and (max-width: 480px){
          font-size: 17px;
          padding: 16px; 
        }
        .seeAll{
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";  
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          cursor: pointer;
          column-gap: 10px;
        }
      }
      .table-wrap{
        padding: 0px 20px;
        @media screen and (max-width: 480px){
          width: 100%;
          padding: 0px 16px;
          padding-bottom: 20px;
        }
        .noSessionsHeading{
          color: ${theme.palette.customColor.secondaryGrey};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
          margin-bottom: 36px;
          @media screen and (max-width: 480px){
            font-size: 18px;
          }
        }
      }
    }
  }

  .noData-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50vh;
    .heading{
      color: ${theme.palette.customColor.primaryBlack};
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 25px;
      @media screen and (max-width: 481px){
        font-size: 18px;
        padding: 0px 20px;
      }
    }
  }
  `,
);

export default StyledDashboard;
