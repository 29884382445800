/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectorExpertProfile } from '../../../../store/reducers/expertProfile';
import linkedin from '../../../../assets/svg/socialBlue/linkedinBlue.svg';
import twitter from '../../../../assets/svg/socialBlue/twitterBlue.svg';
import youtube from '../../../../assets/svg/socialBlue/youtubeBlue.svg';
import whatsapp from '../../../../assets/svg/socialBlue/whatsappBlue.svg';
import telegram from '../../../../assets/svg/socialBlue/telegramBlue.svg';
import substack from '../../../../assets/svg/socialBlue/substackBlue.svg';
import hiArrow from '../../../../assets/svg/hiArrow.svg';
import hiWing from '../../../../assets/svg/hiWing.svg';
import { CONSTANTS } from '../../../../constants/constants';
import StyledCardsLayout from './StyledCardsLayout';
import HelloText from '../../../../assets/images/helloText.png';

const CardsLayout = (Component) => () => {
  const {
    SOCIALLINKS: { YOUTUBE, TWITTER, WHATSAPP, TELEGRAM, SUBSTACK, LINKEDIN },
  } = CONSTANTS;
  const {
    userLandingData: {
      data: {
        expert: { firstName = '', lastName = '', profileImagePath = '' } = {},
        bio: fetchedBio = '',
        whoAmI: fetchedWhoAmI = '',
        socialMedia = [],
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  // const navigate = useNavigate();

  const getIcon = (type) => {
    switch (type) {
      case TWITTER:
        return twitter;
      case YOUTUBE:
        return youtube;
      case SUBSTACK:
        return substack;
      case WHATSAPP:
        return whatsapp;
      case TELEGRAM:
        return telegram;
      case LINKEDIN:
        return linkedin;
      default:
        return '';
    }
  };

  return (
    <StyledCardsLayout>
      <div className="cards-layout">
        <div className="profile-detail-wrap">
          <div className="name_container">
            <div style={{ position: 'relative', display: 'flex' }}>
              <div className="name">
                <img src={HelloText} alt="" />
              </div>
              <div className="hi_img_arrow">
                <img src={hiArrow} alt="" />
              </div>
              <div className="wing_svg">
                <img src={hiWing} alt="" />
              </div>
            </div>
          </div>
          <div className="user_details_wrap">
            <div className="profile-pic-wrap">
              <img
                src={`${profileImagePath ? `${process.env.REACT_APP_IMAGE_URL}/${profileImagePath}` : ''}`}
                className="profile-pic"
                alt=""
              />
            </div>

            <div className="user_full_name">{`${firstName} ${lastName}`}</div>
            <div className="expertise">{fetchedWhoAmI}</div>
            <div className="bio">{fetchedBio}</div>
            <div className="socialIcons">
              <Box mt={1}>
                {socialMedia.map(({ mediaType = '', mediaProfileUrl = '' }) => (
                  <a href={mediaProfileUrl} target="_blank" rel="noreferrer">
                    <img src={getIcon(mediaType)} className="socialIconsImages" alt="" />
                  </a>
                ))}
              </Box>
            </div>
          </div>
        </div>
        <div className="right-wrap">
          <div className="content-wrap">
            <Component />
          </div>
        </div>
      </div>
    </StyledCardsLayout>
  );
};

export default CardsLayout;
