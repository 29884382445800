import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@material-ui/core';
import StyledTextField from './StyledTextField';
import '../../styles/_global.scss';
import CustomTooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  root: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
});

export default function TextInput(props) {
  const classes2 = useStyles();
  const {
    classes = '',
    label = '',
    id = '',
    icon = '',
    startIcon = '',
    maxLength,
    minLength,
    placeholderValue = '',
    important = false,
    wordCount = false,
    showTooltip = false,
    tooltip = '',
    ...restData
  } = props;

  return (
    <StyledTextField className={classes} key={id}>
      <div className="input-Label_text label-space">
        {label}
        {important ? <span className="">*</span> : ''}
        {showTooltip ? <CustomTooltip title={tooltip} /> : ''}
      </div>
      <TextField
        fullWidth
        id={id}
        focused={false}
        autoFocus={false}
        className="custom-field"
        size="small"
        variant="standard"
        margin="normal"
        placeholder={placeholderValue}
        InputProps={{
          classes: {
            root: classes2.root,
          },
          endAdornment: icon ? (
            <InputAdornment position="end" className={wordCount ? 'wordCount' : ''}>
              {icon}
            </InputAdornment>
          ) : (
            ''
          ),
          autoComplete: 'off',
          disableUnderline: true,
          maxLength,
          minLength,
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : (
            ''
          ),
        }}
        {...restData}
        label=""
      />
    </StyledTextField>
  );
}
