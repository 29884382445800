import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
// import { fetchUser } from '../../store/reducers/authentication/apiThunk';
import moment from 'moment';
import timer from '../../assets/svg/timerWhite.svg';
import PrivateLayout from '../../layout/PrivateLayout';
import StyledCalendar from './StyledCalendar';
import { fetchExpertSession } from '../../store/reducers/expertProfile/apiThunk';
import { PrimaryButton } from '../../components/Button';
import WeeklyCalendar from './WeeklyCalendar';
import { selectorExpertProfile } from '../../store/reducers/expertProfile/index';
import AvailabilityModal from '../../components/AvailabilityModal';
import { selectorAuthentication } from '../../store/reducers/authentication/index';

const Calendar = () => {
  const dispatch = useDispatch();
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [eventLists, setEventLists] = useState([]);
  const expertProfile = useSelector(selectorExpertProfile);
  const [showAvailability, setShowAvailability] = useState(false);

  const {
    userLandingData: {
      data: {
        whoAmI: fetchedWhoAmI = '',
        dateAvailability: fetchedDateAvailability = [],
        dayAvailability: fetchedDayAvailability = [],
      } = {},
    } = {},
    calendarData: { data: fetchEventList = [] } = {},
  } = expertProfile;

  const { userData: { data: { isGoogleLinked } = {} } = {} } = useSelector(selectorAuthentication);

  useEffect(() => {
    const updatedData = fetchEventList.map(
      ({ eventStartTime = null, eventEndTime = null, title = '', _id }) => {
        const startDateObject = new Date(eventStartTime?.dateTime);
        const endDateObject = new Date(eventEndTime?.dateTime);
        return {
          id: _id,
          title,
          start: startDateObject,
          end: endDateObject,
        };
      },
    );
    setEventLists(updatedData);
  }, [expertProfile]);

  const fetchCalendarData = () => {
    const data = {
      timeMin: moment(rangeStartDate).format('YYYY-MM-DD'),
      timeMax: moment(rangeEndDate).format('YYYY-MM-DD'),
    };
    dispatch(fetchExpertSession(data));
  };

  useEffect(() => {
    fetchCalendarData();
  }, [rangeStartDate, rangeEndDate]);

  const handleAvailabilityModal = () => {
    setShowAvailability(true);
  };

  const handleShowAvailability = () => {
    setShowAvailability(!showAvailability);
  };

  useEffect(() => {
    if (rangeStartDate === null && rangeStartDate === null) {
      setRangeStartDate(moment(new Date()).startOf('week'));
      setRangeEndDate(moment(new Date()).endOf('week'));
    }
  }, []);

  return (
    <StyledCalendar>
      {showAvailability ? (
        <AvailabilityModal openDialog={showAvailability} closeDialog={handleShowAvailability} />
      ) : (
        ''
      )}
      <Grid
        container
        xs={12}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-end', sm: 'center' }}
        justifyContent="space-between">
        <Grid item xs={12} sm={8}>
          <Typography className="heading">Availability</Typography>
          <Typography
            className="subtitle"
            sx={{
              margin: '15px 0px',
            }}>
            We&apos;ve created the landing page for you. Please fill the required information
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent={{ xs: 'start', sm: 'flex-end' }}
          width={{ xs: '100%', sm: 'auto' }}
          alignItems="center">
          <PrimaryButton
            disabled={!isGoogleLinked || !fetchedWhoAmI}
            sx={{
              width: 'fit-content',
              fontSize: '16px !important',
              padding: '12px 20px !important',
              '@media (max-width: 600px)': {
                fontSize: '14px !important',
                padding: '8px 20px !important',
              },
            }}
            onClick={() => handleAvailabilityModal()}>
            <img
              src={timer}
              alt="timer Logo"
              srcSet=""
              className="timer-icon"
              style={{ marginRight: '8px' }}
            />
            {fetchedDateAvailability.length !== 0 || fetchedDayAvailability.length !== 0
              ? 'Edit Availability'
              : 'Set Availability'}
          </PrimaryButton>
        </Grid>
      </Grid>
      <WeeklyCalendar
        startDate={setRangeStartDate}
        endDate={setRangeEndDate}
        events={eventLists}
        fetchCalendarData={fetchCalendarData}
      />
    </StyledCalendar>
  );
};

export default PrivateLayout(Calendar);
