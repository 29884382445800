const LogoutIcon = ({ width = 16, height = 16 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path d="M11.6267 9.74683L13.3333 8.04016L11.6267 6.3335" stroke="#DA3333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.50668 8.04004H13.2867" stroke="#DA3333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.84002 13.3332C4.89335 13.3332 2.50668 11.3332 2.50668 7.99984C2.50668 4.6665 4.89335 2.6665 7.84002 2.6665" stroke="#DA3333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default LogoutIcon;
