/* eslint-disable max-len */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@material-ui/core';
import StyledAutoComplete from './StyledAutoComplete';
import CustomTooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  option: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    lineHeight: '40px',
  },
  listbox: {
    padding: '0',
    background: '#fff',
    color: '#1C1C1C',
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
});
export default function autoComplete(props) {
  const classes2 = useStyles();
  const {
    classes = '',
    label = '',
    value = '',
    changeAutoComplete = () => {},
    optionsValue = [],
    placeholderValue = '',
    tooltip = '',
    showTooltip,
    important = false,
  } = props;
  return (
    <StyledAutoComplete className={classes}>
      <div className="input-Label_text label-space">
        {label}
        {important ? <span className="important">*</span> : ''}
        {showTooltip ? <CustomTooltip title={tooltip} /> : ''}
      </div>
      <Autocomplete
        classes={{
          option: classes2.option,
          listbox: classes2.listbox,
          input: classes2.input,
        }}
        disableClearable
        forcePopupIcon={false}
        value={value}
        onChange={changeAutoComplete}
        freeSolo
        id="free-solo-demo"
        options={optionsValue}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            onChange={changeAutoComplete}
            label=""
            placeholder={placeholderValue}
            InputProps={{
              ...params.InputProps,
              endAdornment: props.icon ? (
                <InputAdornment position="end">{props.icon}</InputAdornment>
              ) : (
                ''
              ),
              startAdornment: props.startIcon ? (
                <InputAdornment position="start">
                  <div className="startAdornment startIconClass">{props.startIcon}</div>
                </InputAdornment>
              ) : (
                ''
              ),
            }}
          />
        )}
      />
    </StyledAutoComplete>
  );
}
