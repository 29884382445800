import { createBrowserRouter } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { ROUTE_PATH } from '../constants/route';
import LoginPage from '../pages/login';
import SignUpPage from '../pages/signUp';
import DashboardPage from '../pages/dashboard';
import PrivateRoute from './privateRoute';
import Home from '../pages/home';
import Landing from '../pages/landing';
import Calendar from '../pages/calendar';
import Preview from '../pages/previewPage/Home';
import Services from '../pages/previewPage/Services';
import Feedback from '../pages/previewPage/Feedback';
import AboutExpert from '../pages/previewPage/About';
import Links from '../pages/previewPage/Links';
import About from '../pages/home/about';
import TermsAndCondition from '../pages/home/termsAndCondition';
import PrivacyPolicy from '../pages/home/privacyPolicy';
import SeekerCalendar from '../pages/seekerCalendar';
import SeekerSuscription from '../pages/SeekerSubscription';
import SessionLists from '../pages/SessionList';
import Blogs from '../pages/home/blogs';
import BlogDetails from '../pages/home/blogs/BlogDetails';
import DataDisclosure from '../pages/home/dataDisclosure';
import AddFeedbackModal from '../pages/feedbacks/addFeedback';
import Feedbacks from '../pages/feedbacks';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: (
      <PrivateRoute>
        <DashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.SESSIONSLIST,
    element: (
      <PrivateRoute>
        <SessionLists />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.SIGNUP,
    element: <SignUpPage />,
  },
  {
    path: ROUTE_PATH.LINKEDIN,
    element: <LinkedInCallback />,
  },
  {
    path: ROUTE_PATH.LANDING,
    element: <Home />,
  },
  {
    path: ROUTE_PATH.LANDING_PAGE,
    element: (
      <PrivateRoute>
        <Landing />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.CALENDAR,
    element: (
      <PrivateRoute>
        <Calendar />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.PREVIEW,
    element: <Preview />,
  },
  {
    path: ROUTE_PATH.PREVIEW_SERVICES,
    element: <Services />,
  },
  {
    path: `${ROUTE_PATH.PREVIEW_SERVICES}/:username`,
    element: <Services />,
  },
  {
    path: ROUTE_PATH.PREVIEW_ABOUT,
    element: <AboutExpert />,
  },
  {
    path: `${ROUTE_PATH.PREVIEW_ABOUT}/:username`,
    element: <AboutExpert />,
  },
  {
    path: ROUTE_PATH.PREVIEW_LINKS,
    element: <Links />,
  },
  {
    path: `${ROUTE_PATH.PREVIEW_LINKS}/:username`,
    element: <Links />,
  },
  {
    path: `${ROUTE_PATH.EXPERT_FEEDBACK}`,
    element: <Feedback />,
  },
  {
    path: `${ROUTE_PATH.EXPERT_FEEDBACK}/:username`,
    element: <Feedback />,
  },
  {
    path: ROUTE_PATH.PREVIEW_PAGE,
    element: (
      <PrivateRoute>
        <Preview />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.ABOUT_US,
    element: <About />,
  },
  {
    path: ROUTE_PATH.OUR_BLOGS,
    element: <Blogs />,
  },

  {
    path: ROUTE_PATH.BLOG_DETAILS,
    element: <BlogDetails />,
  },
  {
    path: ROUTE_PATH.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTE_PATH.TERMS_AND_CONDITIONS,
    element: <TermsAndCondition />,
  },
  {
    path: ROUTE_PATH.SCHEDULE_SEEKER,
    element: <SeekerCalendar />,
  },
  {
    path: ROUTE_PATH.SEEKER_SUBSCRIPTION,
    element: <SeekerSuscription />,
  },
  {
    path: ROUTE_PATH.DATA_DISCLOSURE,
    element: <DataDisclosure />,
  },
  {
    path: ROUTE_PATH.ADD_FEEDBACKS,
    element: <AddFeedbackModal />,
  },
  {
    path: ROUTE_PATH.FEEDBACKS,
    element: (
      <PrivateRoute>
        <Feedbacks />
      </PrivateRoute>
    ),
  },
]);

export default routes;
