/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
// import { Info } from '@mui/icons-material';
// import Tooltip from '@mui/material/Tooltip';
// import { CheckBox } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import {
  Box,
  Divider,
  //  FormHelperText,
  Stack,
} from '@mui/material';
// import InfoIcon from '../../../components/icons/InfoIcon';
import AutoComplete from '../../../components/AutoComplete/index';
import StyledService from './StyledService';
import editIcon from '../../../assets/svg/editIcon.svg';
import deleteIcon from '../../../assets/svg/deleteIcon.svg';
import link from '../../../assets/svg/link.svg';
import addMore from '../../../assets/svg/addMore.svg';
// import connect from '../../../assets/svg/connect.svg';
import checked from '../../../assets/svg/greenChecked.svg';
import checkedIcon from '../../../assets/svg/CheckboxChecked.svg';
import unCheckedIcon from '../../../assets/svg/CheckboxUnChecked.svg';
import becomeSpeaker from '../../../assets/svg/becomeSpeaker.svg';
import arrow from '../../../assets/svg/arrow.svg';
import TextField from '../../../components/TextField';
import Modal from '../../../components/Modal';
import { CONSTANTS } from '../../../constants/constants';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile/index';
import { selectorAuthentication } from '../../../store/reducers/authentication/index';
import { SecondaryButton, PrimaryButton } from '../../../components/Button';
import {
  fetchLandingPage,
  fetchLandingPageData,
  fetchDeleteService,
  fetchUpdateService,
  linkToCalendar,
  // fetchConnectAccountLink,
} from '../../../store/reducers/expertProfile/apiThunk';
import BasicTimePicker from '../../../components/TimePicker';
import { validationAddServiceSchema } from '../../../utils/validations/authValidation';
import LandingButtons from '../../../components/LandingButtons';
// import SelectBox from '../../../components/SelectBox';
// import { fetchGetProfile } from '../../../store/reducers/authentication/apiThunk';
import CustomTooltip from '../../../components/Tooltip/Tooltip';
import SelectDropdownIcon from './SelectDropdownIcon';
import AccountsDetailsModal from '../../../components/Common/AccountsDetailsModal';
import LinkIcon from '../../../components/icons/LinkIcon';
import AccountIcon from '../../../components/icons/AccountIcon';

const { GOOGLE_CALENDAR_SCOPE } = CONSTANTS;

const Service = ({ stepNumber = 0, setActiveStep }) => {
  const {
    suggestions: { data: { expertise: suggestionsExpertise = [] } = {} } = {},
    userLandingData: {
      data: { expertServices: fetchedExpertServices = [], isAvailableForPublicSpeaking } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  const { userData: { data: { isBankDetailsAdded = false } = {} } = {} } =
    useSelector(selectorAuthentication);

  const {
    userData: {
      data: {
        isGoogleLinked,
        // connectAccountId,
        // transferConnectAccount,
        // connectAccountStatus,
        // currency,
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  const dispatch = useDispatch();
  const [showField, setShowField] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  // const [openStripeModal, setOpenStripeModal] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [openLinkCalendarModal, setOpenLinkCalendarModal] = useState(false);
  const [openLinkedCalendarModal, setOpenLinkedCalendarModal] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [isSpeaker, setIsSpeaker] = useState(isAvailableForPublicSpeaking || false);
  const [openAccountDialoag, setOpenAccountDialoag] = useState(false);
  const [openEditAccountDialoag, setOpenEditAccountDialoag] = useState(false);

  const handleChangeSpeaker = () => {
    setIsSpeaker(!isSpeaker);
  };

  useEffect(() => {
    if (isAvailableForPublicSpeaking !== isSpeaker) {
      setDisableSaveBtn(false);
    } else {
      setDisableSaveBtn(true);
    }
  }, [isSpeaker]);

  const handleDeleteItem = async (id) => {
    const data = {
      id,
      body: '',
    };
    await dispatch(fetchDeleteService(data));
    dispatch(fetchLandingPageData());
    setShowField(false);
  };

  const handleListRender = () => {
    if (fetchedExpertServices && fetchedExpertServices.length) {
      return (
        <>
          <div className="service-list-wrap">
            {fetchedExpertServices.map((list, index) => {
              const { _id: id } = list;
              const itemHours = Math.trunc(list.sessionDuration / 60);
              const itemMinutes = Math.trunc(list.sessionDuration % 60);
              return (
                <Fragment key={`${index}service`}>
                  <Divider sx={{ borderColor: '#E2E4E7' }} />
                  <Grid
                    className="serviceList"
                    key={id + index}
                    container
                    padding="16px 0px"
                    columnSpacing={2}>
                    <Grid item xs={3}>
                      <Box>
                        <div className="listHeader">Service Name</div>
                        <div className="listContent">{list.serviceName}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box width={'100%'} style={{ boxSizing: 'border-box' }}>
                        <div className="listHeader">Service Description</div>
                        <CustomTooltip title={list.description}>
                          <div className="listContent desc_new">{list.description}</div>
                        </CustomTooltip>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={1.5}>
                      <Box>
                        <div className="listHeader">Pricing</div>
                        <div className="listContent">{`${list.unit} ${list.price}`}</div>
                      </Box>
                    </Grid> */}
                    <Grid item xs={2}>
                      <Box>
                        <div className="listHeader">Duration</div>
                        <div className="listContent">{`${itemHours}h ${itemMinutes}m`}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={3}
                        width={'100%'}
                        height={'100%'}>
                        <IconButton disabled={showField}>
                          <img src={editIcon} alt="Edit" onClick={() => handleEditItem(list, id)} />
                        </IconButton>
                        <IconButton disabled={showField}>
                          <img
                            src={deleteIcon}
                            alt="DeleteIcon"
                            onClick={() => setOpenLogoutModal(id)}
                          />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
          </div>
          <div className="service_list_wrap_resp">
            {fetchedExpertServices.map((list, index) => {
              const { _id: id } = list;
              const itemHours = Math.trunc(list.sessionDuration / 60);
              const itemMinutes = Math.trunc(list.sessionDuration % 60);
              return (
                <div className="service_list_outer_resp" key={index}>
                  <div style={{ marginBottom: '16px' }}>
                    <div className="heading">Service Name</div>
                    <div className="desc">{list.serviceName}</div>
                  </div>

                  <div style={{ marginBottom: '16px' }}>
                    <div className="heading">Service Description</div>
                    <CustomTooltip placement="bottom" title={list.description}>
                      <div className="desc">{list.description}</div>
                    </CustomTooltip>
                  </div>
                  {/* <div style={{ marginBottom: '16px' }}>
                    <div className="heading">Pricing</div>
                    <div className="desc">{`${list.unit} ${list.price}`}</div>
                  </div> */}
                  <Box display={'flex'} width={'100%'}>
                    <Box flexGrow={1}>
                      <div className="heading">Duration</div>
                      <div className="desc">{`${itemHours}h ${itemMinutes}m`}</div>
                    </Box>
                    <Box display={'flex'}>
                      <IconButton disabled={showField}>
                        <img
                          src={editIcon}
                          alt="Edit"
                          width={'24px'}
                          height={'24px'}
                          onClick={() => handleEditItem(list, id)}
                        />
                      </IconButton>
                      <IconButton disabled={showField}>
                        <img
                          src={deleteIcon}
                          alt="DeleteIcon"
                          width={'24px'}
                          height={'24px'}
                          onClick={() => setOpenLogoutModal(id)}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </div>
              );
            })}
          </div>
        </>
      );
    }
    if (!showField) {
      return <div className="emptyMsg">Add your services here</div>;
    }
    return '';
  };

  const fieldsEmptyValues = {
    id: '',
    serviceName: '',
    unit: 'INR',
    price: '',
    duration: 30,
    description: '',
  };

  const submitHandler = async (values, { resetForm }) => {
    const { id: itemId, serviceName, unit, price, duration, description } = values;

    // if (
    // price > 0 &&
    // connectAccountStatus !== 1) {
    // Popup to show an alert that the stripe is not connected
    // setOpenStripeModal(true);
    // } else if (unit === 'INR'
    // && price > 0 && price < 50
    // ) {
    // setPriceError('Price in INR should be greater than 50');
    // } else {
    if (!isBankDetailsAdded && price > 0) {
      setOpenEditAccountDialoag(true);
    } else {
      if (unit === 'INR' && price > 99999) {
        setPriceError('Price should be max of 5 digit');
      }

      const data = {
        serviceName,
        unit: 'INR',
        price: price ?? 0,
        sessionDuration: duration,
        description,
      };
      if (itemId) {
        const requestData = {
          id: itemId,
          body: data,
        };
        await dispatch(fetchUpdateService(requestData));
        dispatch(fetchLandingPageData());
      } else {
        handleSubmitData(data);
      }
      setShowField(false);
      resetForm({ values: fieldsEmptyValues });
    }
    // }
  };

  const handleSubmitData = async (data) => {
    const requestDataObject = {
      pageType: 2,
      services: [data],
      isAvailableForPublicSpeaking: isSpeaker,
    };
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: requestDataObject,
    };
    await dispatch(fetchLandingPage(dataRequest));
    dispatch(fetchLandingPageData());
  };

  const handleSubmitSpeakerData = async () => {
    const requestDataObject = {
      pageType: 2,
      services: [],
      isAvailableForPublicSpeaking: isSpeaker,
    };
    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: requestDataObject,
    };
    await dispatch(fetchLandingPage(dataRequest));
    await dispatch(fetchLandingPageData());
  };

  const formik = useFormik({
    initialValues: fieldsEmptyValues,
    validationSchema: validationAddServiceSchema,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    values: { serviceName, unit = 'INR', price, duration, description } = {},
    touched: {
      serviceName: tucServiceName = false,
      price: tucPrice = false,
      duration: tucDuration = false,
      description: tucDescription = false,
    } = {},
    errors: {
      serviceName: errServiceName,
      price: errPrice,
      duration: errDuration,
      description: errDescription,
    } = {},
  } = formik;

  const handleChange = (e, name) => {
    setFieldValue(name, e.target.value);
  };

  const customhandleChange = (event, name) => {
    if (Number.isInteger(event.target.value)) {
      const changedValue = event.target.innerText;
      setFieldValue(name, changedValue);
    } else {
      setFieldValue(name, event.target.value);
    }
    // setPriceError(false);
  };

  const customTimeChange = (e, name) => {
    let totalMinutes = 0;
    const hours = e?.hour();
    const minutes = e?.minute();
    totalMinutes = hours * 60 + minutes;
    setFieldValue(name, totalMinutes);
  };

  const convertMinutesToFormat = (minutes) => {
    let formattedTime = dayjs();
    if (minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      formattedTime = formattedTime.set('hour', hours).set('minute', mins);
    } else {
      formattedTime = formattedTime.set('hour', 0).set('minute', 0);
    }
    return formattedTime;
  };

  const isEmptyExist = () => {
    const emptyEle = fetchedExpertServices.filter((item) => item.serviceName === '');
    return emptyEle && emptyEle.length === 0;
  };

  const handleShowInputField = () => {
    setValues({ ...fieldsEmptyValues });
    setShowField(true);
  };

  const handleEditItem = (list, itemId) => {
    const editListObject = {
      id: itemId,
      serviceName: list.serviceName,
      unit: list.unit,
      price: list.price,
      duration: list.sessionDuration,
      description: list.description,
    };
    setValues({ ...editListObject });
    setShowField(true);
  };

  const handlePriceChange = (event) => {
    const { value } = event.target;

    // Allow only digits (integer values) using regex
    if (/^\d*$/.test(value)) {
      setFieldValue('price', value);

      setPriceError(null);
    } else {
      setPriceError('Please enter a valid integer value.');
    }
  };
  const getHeight = () => {
    if (errPrice === 'This field is required') {
      return priceError ? '43px' : '23px';
    }
    return errPrice ? '43px' : '23px';
  };

  const renderInputFields = () => (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2.5} rowSpacing={2} className="input-fields-wrap" mb={3}>
        <Grid item xs={12} lg={8.5}>
          <Grid container columnSpacing={2.5} rowSpacing={2}>
            <Grid item md={5} xs={12} alignSelf={'baseline'}>
              <AutoComplete
                name="serviceName"
                type="text"
                label="Service Name"
                important
                optionsValue={suggestionsExpertise.map((obj) => obj.value)}
                changeAutoComplete={(e) => customhandleChange(e, 'serviceName')}
                value={serviceName}
                error={tucServiceName && !!errServiceName}
                helperText={tucServiceName && errServiceName}
                icon={<SelectDropdownIcon />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={3.5}
              md={6}
              sm={6}
              width={'100%'}
              alignItems="flex-start"
              justifyContent="center">
              <BasicTimePicker
                customLabel="Duration"
                important
                name="duration"
                onChange={(e) => customTimeChange(e, 'duration')}
                value={convertMinutesToFormat(duration)}
                error={tucDuration && !!errDuration}
                helperText={tucDuration && errDuration}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={3.5}
              md={6}
              sm={6}
              display={'flex'}
              alignItems="flex-end"
              justifyContent="center"
              alignSelf={'baseline'}
              position={'relative'}>
              <div style={{ position: 'absolute', top: '14px', left: '60px' }}>
                <CustomTooltip
                  title={
                    'Create a free service by setting the amount to "0" or a paid service with a minimum amount of "50"'
                  }
                />
              </div>

              {!isBankDetailsAdded && price ? (
                <div
                  className="add-bankaccount-link-btn"
                  onClick={() => handleOpenAccountDialoag()}>
                  <span>
                    <LinkIcon color="#415DF3" />
                  </span>
                  <span>Add Bank Account</span>{' '}
                </div>
              ) : null}
              {isBankDetailsAdded ? (
                <div
                  className="add-bankaccount-link-btn"
                  onClick={() => handleOpenAccountDialoag()}>
                  <span>
                    <AccountIcon width={14} height={14} color={'#415DF3'} />
                  </span>
                  <span>Edit Bank Details</span>{' '}
                </div>
              ) : null}
              <Box width={'78px'}>
                <TextField
                  className="price_currency"
                  label="Price"
                  important
                  placeholder="₹"
                  name="unit"
                  disabled
                  value={'₹' ?? unit}
                />
                {(tucPrice && errPrice) || priceError ? (
                  <Box
                    sx={{
                      height: {
                        xs: '22px',
                        lg: getHeight(),
                      },
                    }}
                  />
                ) : null}
              </Box>
              <Box width={'calc(100% - 78px)'}>
                <TextField
                  className="price_amt"
                  placeholder="50"
                  name="price"
                  onChange={(e) => handlePriceChange(e)}
                  value={price}
                  error={priceError || (tucPrice && !!errPrice)}
                  helperText={priceError || (tucPrice && errPrice)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} marginBottom={{ sm: '0px', xs: '10px' }}>
              <TextField
                placeholder="Write description"
                name="description"
                type="text"
                label="Description"
                important
                onChange={(e) => handleChange(e, 'description')}
                value={description}
                error={tucDescription && !!errDescription}
                helperText={tucDescription && errDescription}
                icon={`${description.length}/250`}
                wordCount
                inputProps={{ maxLength: 250 }}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3.5}>
          <Grid container columnSpacing={2.5} rowSpacing={2} height={'100%'}>
            <Grid item xs={12} mt={{ lg: '38px', xs: '0px' }}>
              <div className="forCancellation">
                {'For cancellation, refer to '}
                <a href="/terms-conditions" target="_blank" className="linkHighlight">
                  T&C
                </a>
              </div>
            </Grid>
            <Grid item xs={12} gap={2} mt={{ lg: '50px', xs: '0px' }}>
              <Stack
                flexDirection={'row'}
                justifyContent={{ xs: 'flex-end', sm: 'flex-start' }}
                width={'100%'}>
                <SecondaryButton
                  sx={{
                    border: '1px solid #415DF3',
                    color: '#1C1C1C',
                    width: '74px',
                    height: '34px',
                    minHeight: 'auto',
                    padding: '12px 0px',
                    fontFamily: '"Plus Jakarta Sans"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                  type="submit">
                  Save
                </SecondaryButton>
                <SecondaryButton
                  sx={{
                    border: '1px solid #E2E4E7',
                    color: '#1C1C1C',
                    width: '74px',
                    height: '34px',
                    minHeight: 'auto',
                    padding: '12px 0px',
                    marginLeft: '16px',
                    fontFamily: '"Plus Jakarta Sans"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                  onClick={() => {
                    formik.resetForm();
                    setShowField(false);
                  }}>
                  Cancel
                </SecondaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  const handleCloseLogoutDialoag = () => {
    setOpenLogoutModal(false);
  };

  // const handleCloseStripeDialoag = () => {
  //   setOpenStripeModal(false);
  // };

  const handleModalDelete = () => {
    handleDeleteItem(openLogoutModal);
    setOpenLogoutModal(false);
  };

  const logoutModalContent = () => (
    <>
      <div>Are you sure you want to delete this service?</div>
      <div className="logout-button-wrap">
        <SecondaryButton
          sx={{ width: '150px', border: '1px solid #415DF3' }}
          onClick={() => handleModalDelete()}>
          Yes
        </SecondaryButton>
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseLogoutDialoag()}>
          No
        </PrimaryButton>
      </div>
    </>
  );

  const addBankDetailsModalContent = () => (
    <>
      <div>You need to add bank account details for session amount and to receive payments.</div>
      <div className="logout-button-wrap">
        <PrimaryButton
          sx={{ minWidth: '150px' }}
          onClick={() => {
            setOpenEditAccountDialoag(false);
            handleOpenAccountDialoag();
          }}>
          Add Bank Account Details
        </PrimaryButton>
      </div>
    </>
  );

  // const stripeModalContent = () => (
  //   <>
  //     <div>
  //       Seems like you have not connected to stripe yet. Please connect to stripe to add paid
  //       services.
  //     </div>
  //     <div className="logout-button-wrap">
  //       <SecondaryButton
  //         sx={{
  //           border: '1px solid #415DF3',
  //           width: '200px',
  //         }}
  //         onClick={connectToStripe}>
  //         {' '}
  //         <img src={connect} alt="connect Logo" srcSet="" className="connect-icon" />
  //         Connect to Stripe
  //       </SecondaryButton>
  //     </div>
  //   </>
  // );

  // const connectToStripe = async () => {
  //   const requestData = {
  //     connectAccountId,
  //     transferConnectAccount,
  //   };
  //   const accountLink = await dispatch(fetchConnectAccountLink(requestData));
  //   if (accountLink && accountLink.payload && accountLink.payload.status) {
  //     handleCloseStripeDialoag();
  //     window.location.href = accountLink.payload.data.url;
  //   }
  // };

  const handleCloseLinkCalendarDialoag = () => {
    setOpenLinkCalendarModal(false);
  };

  const handleGoogleLinkCalendar = () => {
    handleLinkCalendar();
    setOpenLinkCalendarModal(false);
  };

  const linkCalendarModalContent = () => (
    <>
      <div className="dialog-title">Please link your google calendar first!</div>
      <div className="logout-button-wrap">
        <PrimaryButton sx={{ width: '180px' }} onClick={() => handleGoogleLinkCalendar()}>
          <img src={link} alt="" srcSet="" />
          Link Your Calendar
        </PrimaryButton>
      </div>
    </>
  );

  const handleSuccess = async (codeResponse) => {
    const payload = {
      authCode: codeResponse.code,
    };
    const linkToCalendarResponse = await dispatch(linkToCalendar(payload)).then((val) => val);
    if (linkToCalendarResponse) {
      // const updateProfile = await dispatch(fetchGetProfile());
      setOpenLinkedCalendarModal(true);
    }
  };

  const handleLinkCalendar = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: 'auth-code',
    scope: GOOGLE_CALENDAR_SCOPE,
  });

  const handleCloseDialoag = () => {
    setOpenLinkedCalendarModal(false);
  };

  const linkedCalendarModalContent = () => (
    <>
      <div>
        <img src={checked} alt="" srcSet="" />
      </div>
      <div className="dialog-title">Your Google Calendar is linked!</div>
      <div className="google-calendar-button-wrap">
        <PrimaryButton sx={{ width: '150px' }} onClick={() => handleCloseDialoag()}>
          Continue
          <img src={arrow} alt="" srcSet="" className="arrow-icon" />
        </PrimaryButton>
      </div>
    </>
  );

  const handleAdd = () => {
    if (isGoogleLinked) {
      handleShowInputField();
    } else {
      setOpenLinkCalendarModal(true);
    }
  };

  const handleCloseAccountDialoag = () => {
    setOpenAccountDialoag(false);
  };
  const handleOpenAccountDialoag = () => {
    setOpenAccountDialoag(true);
  };

  useEffect(() => {
    if (price > 0 && !isBankDetailsAdded) {
      setOpenEditAccountDialoag(true);
    }
  }, [price, isBankDetailsAdded]);

  return (
    <StyledService>
      <Box className="card_container">
        <Stack gap={3}>
          {/*
           <Stack flexDirection={'row'} alignItems={'flex-end'}>
            <Box className="card_heading">Become a Public Speaker</Box>
            <CustomTooltip title="Become a Public Speaker" />
          </Stack> */}
          <Box className={`card_container2 ${isSpeaker ? 'active_speaker' : ''}`}>
            <Stack gap={2} flexDirection={'row'} alignItems={'flex-start'}>
              <Box className="checkbox_wrap">
                {isSpeaker ? (
                  <img src={checkedIcon} alt="" onClick={handleChangeSpeaker} />
                ) : (
                  <img src={unCheckedIcon} alt="" onClick={handleChangeSpeaker} />
                )}
              </Box>
              <Stack gap={1} flexGrow={1}>
                <Box className="card_heading2">
                  Available for public <span>{' speaking?'}</span>
                </Box>
                <Box className="card_body">
                  Get invited to podcasts, events, and speaking gigs! Make yourself available for
                  seekers to reach out for public speaking engagements.
                </Box>
              </Stack>
              <Box>
                <img src={becomeSpeaker} alt="" />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <div className="page-wrap card_container">
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'calc(100% + 48px)',
            background: '#415DF31A',
            borderWidth: '1px 0px 1px 0px',
            borderStyle: 'solid',
            borderColor: '#CDD2F0',
            marginLeft: '-24px',
            marginRight: '-24px',
          }}>
          {/* <Box
            style={{
              width: 'fit-content',
              padding: '6px 12px',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'flex-start',
              fontSize: '14px',
              color: '#1C1C1C',
              fontFamily: 'Sora',
              fontWeight: '600',
            }}>
            <Info style={{ color: '#3152FF', width: '18px', height: '18px', marginRight: '6px' }} />
            All services are free for now!
          </Box> */}
        </Box>
        <Stack gap={2} flexDirection={'row'} alignItems={'center'}>
          <Stack flexDirection={'row'} alignItems={'flex-end'} flexGrow={1}>
            <Box className="card_heading">Add Services</Box>
            <CustomTooltip title="Add 'Services' for the seekers." />
          </Stack>
          <Box className="AddMoreBtn" mt={1}>
            <SecondaryButton
              sx={{
                border: '0px solid #415DF3',
                cursor:
                  !isEmptyExist() || fetchedExpertServices.length === 4
                    ? 'not-allowed !important'
                    : 'pointer',
                pointerEvents: 'auto !important',
              }}
              disabled={!isEmptyExist() || fetchedExpertServices.length === 4}
              onClick={() => handleAdd()}>
              <img src={addMore} alt="add other" />
              Add more
            </SecondaryButton>
          </Box>
        </Stack>
        <div className="content-wrap">
          {showField ? renderInputFields() : ''}
          {handleListRender()}
        </div>
      </div>
      <LandingButtons
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        disableSave={disableSaveBtn}
        handleSubmit={handleSubmitSpeakerData}
      />
      {/* <Modal
        openDialog={openStripeModal}
        closeDialog={handleCloseStripeDialoag}
        dialogContent={stripeModalContent()}
        noHeader={false}
        heading="Required!"
        dialogCss="logoutDialogWrap"
      /> */}
      <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        heading="Delete Service"
        dialogCss="logoutDialogWrap"
      />
      <Modal
        openDialog={openLinkCalendarModal}
        closeDialog={handleCloseLinkCalendarDialoag}
        dialogContent={linkCalendarModalContent()}
        noHeader
        dialogCss="logoutDialogWrap"
      />
      <Modal
        openDialog={openLinkedCalendarModal}
        closeDialog={handleCloseDialoag}
        dialogContent={linkedCalendarModalContent()}
        noHeader
        dialogCss="logoutDialogWrap"
      />
      <AccountsDetailsModal
        openDialog={openAccountDialoag}
        closeDialog={handleCloseAccountDialoag}
      />
      <Modal
        openDialog={openEditAccountDialoag}
        closeDialog={() => setOpenEditAccountDialoag(false)}
        dialogContent={addBankDetailsModalContent()}
        noHeader={false}
        heading="Add Bank Details"
        dialogCss="logoutDialogWrap"
      />
    </StyledService>
  );
};

export default Service;
