import { styled } from '@mui/material/styles';

const StyledFrame = styled('div')(
  () => `
  position:relative;

  .image_frame{
    height:300px;
    @media screen and (max-width: 600px){
      height:130px;
      }
  }



  .heading_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;

    .heading_main{
      width: fit-content;
      position: relative;
      color: #1C1C1C;
      font-family: Sora;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      
      @media screen and (max-width: 600px){
      font-size: 24px;
      line-height: 24px;

      }

      span{
        color: #415DF3;
      }

      .wing_container{
        position: absolute;
        right:-36px;
        top:-18px;

        @media screen and (max-width: 600px){
          right:-14px;
          top:-10px;
        }
        
        img{
          width: 32.609px;
          height: 35.94px;
          
          @media screen and (max-width: 600px){
            width: 13.739px;
            height: 18.182px;
          }
        }
      }
    }
    .sub_heading_main{
      color:  #525252;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0.18px;
      text-align: center;

      @media screen and (max-width: 600px){
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  
      
    @media screen and (max-width: 900px){
      
    }
    @media screen and (max-width: 600px){
      
    }
      
`,
);

export default StyledFrame;
