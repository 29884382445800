import React from 'react';

const CalenderBackIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    style={{ cursor: 'pointer' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 3L6 10L13 17"
      stroke="#525252"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalenderBackIcon;
