import { styled } from '@mui/material/styles';

const StyledSessionLists = styled('div')(
  ({ theme }) => `
  .page-wrap{
    padding: 32px 40px;
    @media screen and (max-width: 480px){
        padding: 16px 20px;
    }
    .backButton-wrap{
        display: flex;
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        column-gap: 12px;
        width: fit-content;
        margin-bottom: 24px;
        cursor: pointer;
        @media screen and (max-width: 600px){
            margin-bottom: 20px;
            font-size: 18px;

        }
    }
    .header-wrap{
        // width: 384px;
        .MuiAppBar-root{
            position: relative;
            background: none;
            z-index: 1;
            
            .MuiTabs-flexContainer{
                column-gap: 12px;
                .MuiButtonBase-root{
                    border-radius: 50px;
                    border: 1px solid ${theme.palette.primary.main};
                    color: ${theme.palette.customColor.primaryBlack};
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 10px 16px;
                    max-width: 120px;
                    min-height:44px;
                    text-transform: capitalize;
                    @media screen and (max-width: 600px){
                        max-width: auto;
                    }
                }
                .Mui-selected{
                    border-radius: 50px;
                    border: 1px solid ${theme.palette.primary.main};
                    background: ${theme.palette.primary.main};
                    color: ${theme.palette.secondary.main};
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
        
                    @media screen and (max-width: 600px){
                        max-width: auto;
                    }
                }            
            }
            .MuiTabs-indicator {
                display: none;
            }
        }
    }
    .content-wrap{
        margin-top: 24px;
        @media screen and (max-width: 481px){
          min-width: 250px;
          max-width: 460px;
          margin: auto;
          margin-top: 20px;
        }
        .noSessionsHeading{
            color: ${theme.palette.customColor.primaryBlack};
            font-size: 20px;
            font-weight: 700;
            background: ${theme.palette.secondary.main};
            padding: 35px;
            border-radius: 10px;
        }
    }
    .footer-wrap{
        .MuiTablePagination-displayedRows{
            color: ${theme.palette.customColor.primaryBlack};
            font-size: 16px;        
        }
        .MuiTablePagination-actions{
            color: ${theme.palette.customColor.primaryBlack};
            font-size: 16px;
        }
        .Mui-disabled{
            // color: ${theme.palette.customColor.grey_5};
        }
    }
  }
  `,
);

export default StyledSessionLists;
